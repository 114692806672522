import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {submit} from "./PlacementsCustomRoleModalSubmit";
import {change, Field, FieldArray, formValueSelector, initialize, reduxForm} from "redux-form";
import ReduxTreeSelect from "../../basics/tree-select/ReduxTreeSelect";
import buildingsDB from "../../../database/buildingsDB";
import Button from "../../basics/button/Button";
import {connect} from "react-redux";
import ReduxTableGrid from "../../basics/table-grid/redux-table-grid/ReduxTableGrid";
import {getLocationName} from "../../../utils/BuildingUtils";
import {cloneDeep, findIndex, get, isEmpty, isEqual} from "lodash";
import InfoBox from "../../basics/info-box/InfoBox";

export const ModalName = "placements-custom-role-modal";

const LocationTypes = {
    NOTFOUND: 0,
    BUILDING: 1,
    SECTOR: 2,
    CHAMBER: 3,
    BOX: 4,
};

class PlacementsCustomRoleModal extends React.Component {

    constructor(props) {
        super(props);
        const {farm, initialPlacements} = props;
        const locations = buildingsDB.getObjectForTreeSelect(farm, true);
        this.state = {
            locations,
            toDelete: [],
        };
        this.props.dispatch(initialize(ModalName, {
            chosenLocations: this.initializeChosenLocations(initialPlacements),
        }));
    }

    initializeChosenLocations(placements) {
        const initialPlcmnts = [];
        placements.forEach((id) => {
            const location = buildingsDB.getLocationByID(id);
            if (location) {
                initialPlcmnts.push({
                    locName: getLocationName(location),
                    locType: location.BName ? LocationTypes.BUILDING : location.SName ? LocationTypes.SECTOR : location.CName ? LocationTypes.CHAMBER : LocationTypes.BOX,
                    locationID: id,
                })
            }
        });
        return initialPlcmnts;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!isEqual(prevProps.location, this.props.location)) {
            const {location} = this.props;
            const type = this.getLocationType(location);
            const placements = this.checkPlacement({...location, type});
            const toDelete = get(placements, 'deletedPlcmtns', []);
            this.setState({
                toDelete,
                userClicked: false
            })
        }
    }

    getLocationType = (location) => {
        const {name} = location;
        switch (name) {
            case "BName":
                return LocationTypes.BUILDING;
            case "SName":
                return LocationTypes.SECTOR;
            case "CName":
                return LocationTypes.CHAMBER;
            case "BoxesName":
                return LocationTypes.BOX;
            default:
                return LocationTypes.NOTFOUND;
        }
    };

    checkPlacement(location) {
        const {type, children, parent} = location;
        const {chosenLocations} = this.props;
        let chosenPlcmnts = cloneDeep(chosenLocations);
        const deletedPlcmtns = [];
        if (type === LocationTypes.BUILDING) {
            if (!isEmpty(children) && Array.isArray(children)) {
                let fixedLocations = chosenPlcmnts.filter((item) => {
                    const childItem = findIndex(children, (el) => el.object[el.key] === item.locationID);
                    if (childItem === -1) {
                        children.forEach((sector) => {
                            const sectorChildItem = findIndex(get(sector, "children", []), (el) => el.object[el.key] === item.locationID);
                            if (sectorChildItem === -1) {
                                get(sector, "children", []).forEach((chamber) => {
                                    const chamberChildItem = findIndex(get(chamber, "children", []), (el) => el.object[el.key] === item.locationID);
                                    if (chamberChildItem === -1) return item;
                                    else deletedPlcmtns.push(chamber.children[chamberChildItem].object[chamber.children[chamberChildItem].name]);
                                });
                            } else deletedPlcmtns.push(sector.children[sectorChildItem].object[sector.children[sectorChildItem].name]);
                        });
                    } else deletedPlcmtns.push(children[childItem].object[children[childItem].name]);
                }).filter((item) => item.locationID !== location.object[location.key]);
                chosenPlcmnts = [...fixedLocations, {
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                }];
            } else {
                chosenPlcmnts.push({
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                });
            }
        } else if (type === LocationTypes.SECTOR) {
            if (!isEmpty(parent) && Array.isArray(parent) || !isEmpty(children) && Array.isArray(children)) {
                let fixedLocations = chosenPlcmnts.filter((item) => {
                    const childIndex = findIndex(children, (el) => el.object[el.key] === item.locationID);
                    const parentIndex = findIndex(parent, (el) => el.object[el.key] === item.locationID);
                    if (childIndex === -1 && parentIndex === -1) {
                        children.forEach((chamber) => {
                            const chamberChildItem = findIndex(get(chamber, "children", []), (el) => el.object[el.key] === item.locationID);
                            if (chamberChildItem === -1) return item;
                            else deletedPlcmtns.push(chamber.children[chamberChildItem].object[chamber.children[chamberChildItem].name]);
                        });
                    } else if (childIndex >= 0) deletedPlcmtns.push(children[childIndex].object[children[childIndex].name]);
                    else if (parentIndex >= 0) deletedPlcmtns.push(parent[parentIndex].object[parent[parentIndex].name]);
                }).filter((item) => item.locationID !== location.object[location.key]);
                chosenPlcmnts = [...fixedLocations, {
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                }];
            } else {
                chosenPlcmnts.push({
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                });
            }
        } else if (type === LocationTypes.CHAMBER) {
            if (!isEmpty(parent) && Array.isArray(parent) || !isEmpty(children) && Array.isArray(children)) {
                let fixedLocations = chosenPlcmnts.filter((item) => {
                    const childIndex = findIndex(children, (el) => el.object[el.key] === item.locationID);
                    const parentIndex = findIndex(parent, (el) => el.object[el.key] === item.locationID);
                    if (childIndex === -1 && parentIndex === -1) return item;
                    else if (childIndex >= 0) deletedPlcmtns.push(children[childIndex].object[children[childIndex].name]);
                    else if (parentIndex >= 0) deletedPlcmtns.push(parent[parentIndex].object[parent[parentIndex].name]);
                }).filter((item) => item.locationID !== location.object[location.key]);
                chosenPlcmnts = [...fixedLocations, {
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                }];
            } else {
                chosenPlcmnts.push({
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                });
            }
        } else if (type === LocationTypes.BOX) {
            if (!isEmpty(parent) && Array.isArray(parent)) {
                let fixedLocations = chosenPlcmnts.filter((item) => {
                    const parentIndex = findIndex(parent, (el) => el.object[el.key] === item.locationID);
                    if (parentIndex === -1) return item;
                    else deletedPlcmtns.push(parent[parentIndex].object[parent[parentIndex].name]);
                }).filter((item) => item.locationID !== location.object[location.key]);
                chosenPlcmnts = [...fixedLocations, {
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                }];
            } else {
                chosenPlcmnts.push({
                    locName: getLocationName(location.object),
                    locType: type,
                    locationID: location.object[location.key]
                })
            }
        }
        return {
            chosenPlcmnts,
            deletedPlcmtns
        }
    }

    onAddLocationButtonClick = () => {
        const {location, dispatch} = this.props;
        const type = this.getLocationType(location);
        const result = this.checkPlacement({...location, type});
        dispatch(change(ModalName, "chosenLocations", result.chosenPlcmnts));
        this.setState({
            userClicked: true
        })
    };

    locationTypeFormatter = (value) => {
        const {t} = this.props;
        switch (value) {
            case LocationTypes.BUILDING:
                return t("newSettings.buildings.building");
            case LocationTypes.SECTOR:
                return t("newSettings.buildings.sector");
            case LocationTypes.CHAMBER:
                return t("newSettings.buildings.chamber");
            case LocationTypes.BOX:
                return t("standing");
            default:
                return t("notFound.dataNotFound")
        }
    };

    render() {
        const {show, handleHide, handleSubmit, adding, t} = this.props;
        const {locations, userClicked, toDelete} = this.state;
        let names = "";
        if (!isEmpty(toDelete)) {
            names = toDelete.join(", ");
        }
        const headers = [
            {
                name: t("designation"),
                field: "locName",
                dontShowInput: true,
            },
            {
                name: t("type"),
                field: "locType",
                dontShowInput: true,
                valueFormatter: this.locationTypeFormatter
            }
        ];
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("locactionSelection")} onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        {
                            !isEmpty(toDelete) && !userClicked && <InfoBox boxColor={"warning"}>
                                {t("modals.placementsCustomRoleModal.warningInfo", {names})}
                            </InfoBox>
                        }
                        <label>{t("pickLocation")}</label>
                        <Row className="w-100 justify-content-center">
                            <Col lg={10}>
                                <Field
                                    name="location"
                                    component={ReduxTreeSelect}
                                    expanded
                                    options={locations}
                                />
                            </Col>
                            <Button type={"button"} buttonColor={"success"} onClick={this.onAddLocationButtonClick}>
                                <i className={"fas fa-plus"}/> {t("add")}
                            </Button>
                        </Row>
                        <hr/>
                        <label>{t("chosenLocations")}</label>
                        <FieldArray component={ReduxTableGrid} name="chosenLocations" headers={headers} addRemoveButton
                                    minLength={0}/>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("save")}
                        formName={ModalName}
                        onCloseClick={handleHide}
                        submitting={adding}
                    />
                </form>
            </Modal>
        );
    }
}

PlacementsCustomRoleModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    touchOnChange: true
})(PlacementsCustomRoleModal);

const selector = formValueSelector(ModalName);

PlacementsCustomRoleModal = connect((state) => ({
    location: selector(state, "location"),
    chosenLocations: selector(state, "chosenLocations"),
}))(PlacementsCustomRoleModal);

PlacementsCustomRoleModal = connectModal({name: ModalName})(PlacementsCustomRoleModal);
export default withTranslation()(PlacementsCustomRoleModal);
