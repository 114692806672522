import React from "react";
import EventData from "./EventData";
import {Field} from "redux-form";
import PropTypes from "prop-types";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {withTranslation} from "react-i18next";

export class ParturitionData extends React.Component {

    render() {
        const {member, t} = this.props;
        return (
            <EventData member={member}>
                <Field
                    name={member ? `${member}.EvData.HealthyCnt` : "EvData.HealthyCnt"}
                    component={ReduxLabeledInput}
                    type="number"
                    label={t("eventData.alive")}
                    parse={value => value ? +value : null}
                />
                <Field
                    name={member ? `${member}.EvData.DeadCnt` : "EvData.DeadCnt"}
                    component={ReduxLabeledInput}
                    type="number"
                    label={t("eventData.dead")}
                    parse={value => value ? +value : null}
                />
                <Field
                    name={member ? `${member}.EvData.MummyCnt` : "EvData.MummyCnt"}
                    component={ReduxLabeledInput}
                    type="number"
                    label={t("eventData.mummy")}
                    parse={value => value ? +value : null}
                />
            </EventData>
        );
    }

}

ParturitionData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(ParturitionData);