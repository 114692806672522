import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import "../../animal-documents/css/_basic-info-box.scss";
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {Field, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import InfoBox from "../../basics/info-box/InfoBox";
import {swapForageIDonChambers} from "../../../utils/BuildingUtils";
import {updateSettingDynamoDB} from "../../../actions/settingsAction";
import {updateLocation} from "../../../actions/farmsActions";
import {DevType} from "../../../constans/devices";
import {setFeedEFCTime} from "../../../IOT/device-functions/GatewayFunctions";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {getLicenseByName} from "../../../selectors/roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import {LicenseItemCounts} from "../../../constans/licenseCounts";

export const ModalName = 'forage-reactivate-modal';


class ForageReactivate extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
    }


    getName = ({index, forage}) => {
        return forage ? `${index + 1} (${forage.SetData.Name})` : index + 1;
    };

    render() {
        const {
            show, place, license, handleHide, forage, t, foragesWithEmptySpaces = [], handleSubmit
        } = this.props;
        const options = foragesWithEmptySpaces.map(o => ({
            value: o,
            name: this.getName(o)
        })).slice(0, license === LicPackageLevel.EXTENDED ? LicenseItemCounts.FEEDING_FORAGE_EXTENDED : LicenseItemCounts.FEEDING_FORAGE_BASIC);
        return (
            <Modal show={show} size={"lg"} onHide={handleHide}>
                <ModalHeader
                    title={i18next.t("modals.forageReactivateModal.reactivateXcurve", {type: forage.SetData.Name})}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={12}>
                                {
                                    !!place && !!place.forage &&
                                    <InfoBox boxColor={"warning"}>
                                        {t("modals.forageReactivateModal.foundActiveForage")}
                                    </InfoBox>
                                }
                                <Field component={ReduxLabeledSelect} name={'place'} options={options}
                                       label={t("modals.forageReactivateModal.choosePlace")}/>
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

const submit = (values, dispatch, props) => {
    const newForage = props.forage.clone();
    const {gateways} = props;
    const {place: {forage, index}} = values;
    const oldForage = forage ? forage.clone() : null;
    if (oldForage) {
        const buildingsToUpdate = swapForageIDonChambers(props.buildings, newForage.SetID, oldForage.SetID);
        oldForage.deleteSetting();
        buildingsToUpdate.forEach(building => {
            dispatch(updateLocation(building));
        })
    }
    newForage.unDeleteSetting();
    newForage.SetData.Index = index;
    if (oldForage) {
        dispatch(updateSettingDynamoDB(oldForage.prepareBeanToSave()));
    }
    dispatch(updateSettingDynamoDB(newForage.prepareBeanToSave(), () => {
        gateways.forEach((gateway) => {
            setFeedEFCTime(gateway, newForage);
        })
    }));
    props.handleHide();
};

const validate = (values, props) => {
    const errors = {};
    const {t} = props;
    if (!values.place) {
        errors.place = t("required");
    }
    return errors;
};

ForageReactivate.propTypes = {
    foragesWithEmptySpaces: PropTypes.array.isRequired,
    forage: PropTypes.object
};
ForageReactivate = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(ForageReactivate);
const selector = formValueSelector(ModalName);

ForageReactivate = connect((state) => ({
    place: selector(state, "place"),
    buildings: state.farms.buildings,
    gateways: state.farmDevices.devices.filter(dev => dev.DevType === DevType.GATEWAY),
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))(ForageReactivate);
ForageReactivate = connectModal({name: ModalName})(ForageReactivate);
export default withTranslation()(ForageReactivate);
