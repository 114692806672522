import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./AddLocationSubmit"
import ReduxLabeledInput from "../../../components/basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import {Collapse, Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import ReduxSwitch from "../../../components/basics/switch/ReduxSwitch";
import ReduxItemPicker from "../../basics/item-picker/ReduxItemPicker";
import SS0 from "../../../resources/images/standings-sorting/0.svg";
import SS1 from "../../../resources/images/standings-sorting/1.svg";
import SS2 from "../../../resources/images/standings-sorting/2.svg";
import SS3 from "../../../resources/images/standings-sorting/3.svg";
import SS4 from "../../../resources/images/standings-sorting/4.svg";
import SS5 from "../../../resources/images/standings-sorting/5.svg";
import SS6 from "../../../resources/images/standings-sorting/6.svg";
import SS7 from "../../../resources/images/standings-sorting/7.svg";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import i18next from "i18next";


const ModalName = 'settings-buildings-add-location-modal';

class AddLocationModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            STypes: i18next.t("SType", {returnObjects: true})
        };

        ReactGA.modalview(ModalName);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.setState({
                STypes: i18next.t("SType", {returnObjects: true})
            })
        }
    }

    renderSectorAttributes = () => {
        const {t} = this.props;
        let {STypes} = this.state;
        const sectorTypeOptions = [];
        STypes.forEach((type, index) => {
            sectorTypeOptions.push({
                name: type,
                value: index + 1
            })
        });
        return (
            <Field
                name="sectorType"
                id="sectorType"
                type="text"
                required
                component={ReduxLabeledSelect}
                options={sectorTypeOptions}
                label={t("sectorType")}
            />
        )
    };

    renderChamberAttributes = () => {
        const {chamberIndividualFeeding, t} = this.props;
        return (
            <React.Fragment>
                <Field
                    name="chamberIndividualFeeding"
                    id="chamberIndividualFeeding"
                    type="text"
                    component={ReduxSwitch}
                    label={t("modals.addLocationModal.individualFeeding")}
                />
                <Collapse in={chamberIndividualFeeding} mountOnEnter unmountOnExit>
                    <div>

                        <Field
                            name="chamberStandingInRow"
                            id="chamberStandingInRow"
                            type="number"
                            required
                            component={ReduxLabeledInput}
                            label={t("standingsInRow")}
                        />
                        <Field
                            name="chamberStandingsOrder"
                            id="chamberStandingsOrder"
                            type="text"
                            showLabels
                            required
                            component={ReduxItemPicker}
                            options={[{value: 0, svgPath: SS0, label: 'A1'}, {
                                value: 1,
                                svgPath: SS1,
                                label: 'A2'
                            }, {value: 2, svgPath: SS2, label: 'A3'}, {value: 3, svgPath: SS3, label: 'A4'}, {
                                value: 4,
                                svgPath: SS4,
                                label: 'B1'
                            }, {value: 5, svgPath: SS5, label: 'B2'}, {value: 6, svgPath: SS6, label: 'B3'}, {
                                value: 7,
                                svgPath: SS7,
                                label: 'B4'
                            }]}
                            label={t("modals.addLocationModal.standingsOrder")}
                        />
                        <hr/>
                        <Field
                            name="chamberStandingName"
                            id="chamberStandingName"
                            type="text"
                            required
                            component={ReduxLabeledInput}
                            label={t("modals.addLocationModal.standingsName")}
                        />
                        <Field
                            name="chamberStandingFrom"
                            id="chamberStandingFrom"
                            required
                            type="number"
                            component={ReduxLabeledInput}
                            label={t("modals.addLocationModal.standingsStartNumber")}
                        />
                        <Field
                            name="chamberStandingSize"
                            id="chamberStandingSize"
                            required
                            type="number"
                            component={ReduxLabeledInput}
                            label={t("modals.addLocationModal.standingsSize")}
                        />
                    </div>
                </Collapse>
                <Collapse in={!chamberIndividualFeeding} unmountOnExit mountOnEnter>
                    <div>
                        <Field
                            name="chamberSize"
                            id="chamberSize"
                            required
                            type="number"
                            component={ReduxLabeledInput}
                            label={t("modals.addLocationModal.chamberSize")}
                        />
                    </div>
                </Collapse>
            </React.Fragment>
        )
    };

    renderStandingAttributes = () => {
        const {t} = this.props;
        return (
            <React.Fragment>
                <Field
                    name="boxFrom"
                    id="boxFrom"
                    type="number"
                    required
                    component={ReduxLabeledInput}
                    label={t("modals.addLocationModal.boxFrom")}
                />
                <Field
                    name="boxAmount"
                    id="boxAmount"
                    type="number"
                    required
                    component={ReduxLabeledInput}
                    label={t("modals.addLocationModal.boxAmount")}
                />
            </React.Fragment>
        )
    };

    render() {
        const {parent = {}, adding, show, handleHide, t, handleSubmit} = this.props;
        console.log(this.props);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={i18next.t("modals.addLocationModal.addLocationTo", {type: parent.BName || parent.CName || parent.BoxesName || parent.FarmName || parent.SName})}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <Field
                            name="locationName"
                            id="locationName"
                            type="text"
                            required
                            component={ReduxLabeledInput}
                            label={t("designation")}
                        />
                        {
                            parent.BgID &&
                            this.renderSectorAttributes()
                        }
                        {
                            parent.SID &&
                            this.renderChamberAttributes()
                        }
                        {
                            parent.CID &&
                            this.renderStandingAttributes()
                        }
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("add")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                        submitting={adding}
                    />
                </form>
            </Modal>
        );
    }

}

AddLocationModal.propTypes = {
    parent: PropTypes.string.isRequired
};

AddLocationModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(AddLocationModal);
AddLocationModal = connect(state => ({
    farm: state.location.farm,
    adding: state.farms.adding,
}))(AddLocationModal);
const selector = formValueSelector(ModalName);
const mapStateToProps = state => {
    return {
        chamberIndividualFeeding: !!selector(state, 'chamberIndividualFeeding'),
    }
};
export let _AddLocationModal = connect(mapStateToProps)(AddLocationModal);
_AddLocationModal = connectModal({name: ModalName})(_AddLocationModal)

export default withTranslation()(_AddLocationModal);
