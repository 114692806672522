import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'react-bootstrap'
import {connectModal} from 'redux-modal'
import ModalFooter from "../ModalFooter";
import ModalBody from "../ModalBody";
import ReactGA from "react-ga";
import i18next from "i18next";

export const ModalName = 'delayed-action-modal';

/**
 * Modal wylogowujący po {counter} sekundach
 * @param props
 * @constructor
 */
class DelayedActionModal extends Component {

    static propTypes = {
        handleHide: PropTypes.func.isRequired,
        counter: PropTypes.number.isRequired,
        action: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        confirmButtonText: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            counter: props.counter
        }
        ReactGA.modalview(ModalName);
    }

    onButtonClick() {
        this.props.action();
        this.props.handleHide();
    }

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.counter < 1) {
                this.onButtonClick()
            } else {
                this.setState({counter: this.state.counter - 1})
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {show, counter, title, text, confirmButtonText} = this.props;
        return (
            <Modal onHide={()=>{}} show={show} counter={counter}>
                <Modal.Header>
                    <h4>{title}</h4>
                </Modal.Header>
                <ModalBody className={ModalName}>
                    <p>{i18next.t(text, {counter: this.state.counter})}</p>
                </ModalBody>
                <ModalFooter hasConfirmButton={true} hasCloseButton={false} confirmText={confirmButtonText}
                             onConfirmed={() => this.onButtonClick()}/>
            </Modal>
        );
    }
}

DelayedActionModal = connectModal({name: ModalName})(DelayedActionModal);
export default DelayedActionModal;