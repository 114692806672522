import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ViewContainerModal from "../../basics/view-containter-modal/ViewContainerModal";
import Card from "../../basics/card/Card";
import TaskAddModalForm from "./TaskAddModalForm";
import Button from "../../basics/button/Button";
import "./_task-add-modal-drawer.scss"
import {isSubmitting, submit} from "redux-form";
import {getSelectedTask} from "../../../selectors/taskSelectors";
import {ModalName} from "./TaskAddModal";

function mapStateToProps(state) {
    return {
        task: getSelectedTask(state),
        submitting: isSubmitting(ModalName)(state)
    };
}

class TaskAddModalDrawer extends Component {

    onSubmitClick = () => {
        this.props.dispatch(submit("task-add-modal"));
    }

    render() {
        const {show, t, onHide, task, submitting} = this.props;
        return (
            <ViewContainerModal name={task ? t("editTask") : t("addTask")} show={show} className="task-add-drawer"
                                onHide={onHide}>
                <Card>
                    <TaskAddModalForm task={task} hide={onHide}/>
                </Card>
                <Button className="task-add-drawer-submit" buttonColor={"success"}
                        onClick={this.onSubmitClick} isLoading={submitting}>{t("save")}</Button>
            </ViewContainerModal>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(TaskAddModalDrawer);
