import {createTask, createTaskNotificationFailure, createTaskNotificationSuccess} from "../../../api/task/createTask";
import moment from "moment";
import {editTask, editTaskNotificationFailure, editTaskNotificationSuccess} from "../../../api/task/editTasks";

export function submit(values, dispatch, props) {
    console.log(values);
    const {task} = props;
    const {AssignTo, DtaPlanned, Private, Category, Priority, ListTODO, Title, Description, EstimatedTime} = values;
    const planned = +moment.utc(DtaPlanned).startOf("day");
    const listTODO = [];
    for (let row of ListTODO) {
        if (row.Title) {
            listTODO.push({Title: row.Title, Done: row.Done});
        }
    }
    if (!task) {
        // dodanie nowego
        return createTask({
            AssignTo,
            DtaPlanned: planned,
            Private,
            Category,
            Priority,
            ListTODO: listTODO,
            Title,
            Description,
            EstimatedTime
        }).then(res => {
            createTaskNotificationSuccess(res);
            props.hide();
        }).catch(e => {
            createTaskNotificationFailure(e);
        });
    } else {
        // edycja
        return editTask(task.IssueID, {
            AssignTo,
            DtaPlanned: planned,
            Private,
            Category,
            Priority,
            ListTODO: listTODO,
            Title,
            Description,
            EstimatedTime
        }).then(res => {
            editTaskNotificationSuccess(res);
            props.hide();
        }).catch(e => {
            editTaskNotificationFailure(e);
        });
    }
}

export function validate(values, props) {
    const {t} = props;
    const errors = {};

    if (!values.date) {
        errors.date = t("required");
    }
    if (!values.name) {
        errors.name = t("required");
    }
    if (!values.description) {
        errors.description = t("required");
    }
    if (!values.operator) {
        errors.operator = t("required");
    }
    if (!values.priority) {
        errors.priority = t("required");
    }
    if (!values.time) {
        errors.time = t("required");
    }

    return errors;
}