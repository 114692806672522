import {groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {setClimateSingleMat} from "../../../IOT/device-functions/ClimateFunctions";
import {DevType} from "../../../constans/devices";

export function submit(values, dispatch, props) {
    const {climates} = props;
    const {selectedMat, day, deviation} = values;
    const devices = groupDevicesByGatewayID(climates);
    return Promise.all([...devices.values()].map(deviceList => {
        return new Promise((resolve, reject) => {
            setClimateSingleMat(deviceList[DevType.CLIMATE_SK3], {
                    number: selectedMat + 1,
                    day,
                    deviation
                },
                {onSuccess: resolve, onFailure: reject});
        })
    })).then(() => {
        props.handleHide();
    })
}
