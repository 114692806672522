import React from "react";
import EventData from "./EventData";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Field} from "redux-form";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {withTranslation} from "react-i18next";

export class NoPregnancyData extends React.Component {

    render() {
        const {member, reasons, lang, t} = this.props;
        return (
            <EventData member={member}>
                <Field
                    name={member ? `${member}.EvData.Reason` : "EvData.Reason"}
                    component={ReduxLabeledSelect}
                    label={t("reason")}
                    options={reasons.WData[lang].map(val => ({
                        name: val.Value,
                        value: val.ID
                    }))}
                />
            </EventData>
        );
    }

}

NoPregnancyData = connect(state => ({
    reasons: state.dictionary.noPreganancy,
    lang: state.language.lang.lang
}))(NoPregnancyData);

NoPregnancyData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(NoPregnancyData);