import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import ColorPicker from "../../../redux-color-picker/ColorPicker";
import ReduxLabeledSlider from "../../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {Col, Row} from "react-bootstrap";
import Button from "../../../basics/button/Button";
import _ from "lodash";
import {convertVolumeUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import Card from "../../../basics/card/Card";
import Cage from "../../../../beans/devices/Cage";
import {updateDeviceDynamoDB} from "../../../../actions/devicesActions";
import {connect} from "react-redux";
import {groupDevicesByGatewayID} from "../../../../utils/DevicesUtils";
import {DevType} from "../../../../constans/devices";
import {resetMarkerUsage} from "../../../../IOT/device-functions/SeparationCageFunctions";
import {getColorByName} from "../../../../utils/ColorUtils";
import {withTranslation} from "react-i18next";

const FormName = 'cage-marker-fill-page-form';

class CageMarkerFillPage extends Component {

    constructor(props) {
        super(props);
        const {initialize, cages, marker} = this.props;
        initialize({
            color: _.get(cages, `[0]Settings.Marker[${marker - 1}].Color`, getColorByName("primary")),
            amount: _.get(cages, `[0]Settings.Marker[${marker - 1}].Amount`, 1800)
        })
    }


    render() {
        const {handleSubmit, t} = this.props;
        return (
            <Card className="draw-long-shadow overflow-visible">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <label>{t("modals.cageMarkerModal.cageMarkerFillPage.color")}</label>
                            <Field
                                name="color"
                                id="color"
                                component={ColorPicker}
                            />
                        </Col>
                        <Col md={6}>
                            <Field
                                name="amount"
                                id="amount"
                                type="number"
                                label={t("modals.cageMarkerModal.cageMarkerFillPage.amount")}
                                min={0}
                                max={1800}
                                step={5}
                                valueFormatter={(value) => convertVolumeUnitTo(value, {
                                    unit: UnitTypes.SMALL,
                                    showUnit: true,
                                    fixed: 0
                                })}
                                component={ReduxLabeledSlider}
                            />
                        </Col>
                    </Row>
                    <Button icon={<i
                        className="fas fa-check"/>}>{t("save")}</Button>
                </form>
            </Card>
        )
    }
}

const submit = (values, dispatch, props) => {
    console.log('CageMarkerFillPageSubmit', values, props);
    const update = [];
    const {cages, farm, user: {LocalUserID, ClientID}, marker} = props;
    cages.forEach(c => {
        if (c instanceof Cage) {
            const tmp = _.cloneDeep(c);
            tmp.setMarker(marker, {fluidAmount: values.amount, fluidColor: values.color});
            update.push(tmp);
        }
    });
    dispatch(updateDeviceDynamoDB(update, farm, ClientID, LocalUserID));
    let devs = groupDevicesByGatewayID(update);
    if (devs) {
        for (let [gwID, devList] of devs.entries()) {
            if (gwID && devList[DevType.CAGE].length) {
                resetMarkerUsage(devList[DevType.CAGE], {marker1: marker === 1, marker2: marker === 2})
            }
        }
    }
    props.toggle();
};
CageMarkerFillPage = reduxForm({
    form: FormName,
    onSubmit: submit
})(CageMarkerFillPage);
CageMarkerFillPage = connect(state => ({
    farm: state.location.farm,
    user: state.user.user,
}))(CageMarkerFillPage);
export default withTranslation()(CageMarkerFillPage);