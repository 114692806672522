import React from "react";
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./FeedingSetAlarmSubmit"
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal, show as showModal} from "redux-modal";
import PropTypes from "prop-types";
import Button from "../../basics/button/Button";
import {bindActionCreators} from "redux";
import {ModalName as ConfirmModalName} from "../confirm-modal/ConfirmModal";
import ReactGA from "react-ga";
import _ from "lodash";
import TinyList from "../../basics/tiny-list/TinyList";
import {withTranslation} from "react-i18next";

export const ModalName = 'feeding-set-alarm-modal';

class FeedingSetAlarmModal extends React.Component {

    onSetAlertButtonClick = () => {
        const {showModal, receivers, t, clearSelection} = this.props;
        showModal(ConfirmModalName, {
            title: t("modals.feedingSetAlarmModal.confirmAlarmSet"),
            text: t("modals.feedingSetAlarmModal.confirmAlarmSetLong"),
            confirmText: t("yes"),
            onConfirmed: (props) => {
                const {handleHide} = props;
                submit({alert: true}, {}, {
                    receivers,
                    handleHide: this.props.handleHide,
                    clearSelection: clearSelection
                });
                handleHide();
                clearSelection && clearSelection();
            }
        });
        ReactGA.modalview(ModalName);
    };

    errorsParser = (code) => {
        const {t} = this.props;
        return t([`notificationsCenter.errorCodes.S${[code]}`, `notificationsCenter.errorCodes.${[code]}`, `${code}`]);
    };

    render() {
        const {show, handleHide, selectedNodes, t, handleSubmit} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.feedingSetAlarmModal.alarmsManage")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <div>
                            {
                                selectedNodes.map((stand) => (
                                    <TinyList
                                        header={stand.name}
                                        notFoundText={t("modals.feedingSetAlarmModal.alarmsNotFound")}
                                        list={stand.errors ? stand.errors.map(code => `${this.errorsParser(code, stand.name)} (${t("notificationsCenter.code", {code: code})})`) : []}/>
                                ))
                            }
                            {
                                !!selectedNodes.filter(o => o.receiver && _.isNil(o.receiver.index)).length &&
                                <Button onClick={this.onSetAlertButtonClick} className={"mt-2"} type={"button"}
                                        buttonStyle={"text"}
                                        icon={<i className="fas fa-exclamation-triangle"/>}
                                        buttonColor={"error"}>
                                    {t("modals.feedingSetAlarmModal.alert")}
                                </Button>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("clear")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }
}

FeedingSetAlarmModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired,
    receivers: PropTypes.array.isRequired
};

FeedingSetAlarmModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(FeedingSetAlarmModal);
FeedingSetAlarmModal = connect(
    null,
    dispatch => bindActionCreators({showModal}, dispatch)
)(FeedingSetAlarmModal);

FeedingSetAlarmModal = connectModal({name: ModalName})(FeedingSetAlarmModal);
export default withTranslation()(FeedingSetAlarmModal);
export const _FeedingSetAlarmModal_ = FeedingSetAlarmModal;
