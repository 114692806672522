import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {flatten, get, has} from "lodash";
import {
    minutesFormatter,
    percentageFormatter
} from "../../../../views/new-settings-view/settings/climate-sk-3/settings/utils";

class UpdateDispenserNRFPunishments extends Component {

    getData = (obj) => {
        const {t} = this.props;
        return flatten(new Array(3).fill(0).map((o, i) => [
            {
                name: t("$t(punishment) - $t(newSettings.feeding.general.time)", {type: i + 1}),
                value: has(obj, `[${i}].time`) ? minutesFormatter(60 * 1000 * get(obj, `[${i}].time`)) : null
            },
            {
                name: t("$t(punishment) - $t(newSettings.feeding.general.percentage)", {type: i + 1}),
                value: has(obj, `[${i}].percentage`) ? percentageFormatter(get(obj, `[${i}].percentage`)) : null
            },
            {
                name: t("$t(punishment) - $t(newSettings.feeding.general.trigger)", {type: i + 1}),
                value: has(obj, `[${i}].trigger`) ? get(obj, `[${i}].trigger`) : null
            }
        ]));
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateDispenserNRFPunishments);
