import LogDetail from "./LogDetail";
import {LogTableTypes} from "../../../constans/logCodeTypes";
import UpdateNutritionCurveDetail from "./custom-details/UpdateNutritionCurveDetail";
import React from "react";
import {get, isBoolean, isEqual, isNil, isObject, isString, transform} from "lodash";
import animalsDB from "../../../database/animalsDB";
import eventsDB from "../../../database/eventsDB";
import moment from "moment";
import i18next from "i18next";
import UpdateNutritionScheduleDetail from "./custom-details/UpdateNutritionScheduleDetail";
import UpdateDispenserConfigurationDetail from "./custom-details/UpdateDispenserConfigurationDetail";
import UpdateScaleConfigurationDetail from "./custom-details/UpdateScaleConfigurationDetail";
import UpdateClimateConfigurationDetail from "./custom-details/UpdateClimateConfigurationDetail";
import UpdateCageWorkType from "./custom-details/UpdateCageWorkType";
import UpdateDispenserNRFWorkType from "./custom-details/UpdateDispenserNRFWorkType";
import UpdateChainFeedingWorkType from "./custom-details/UpdateChainFeedingWorkType";
import UpdateCageWorkTypeDurationDetail from "./custom-details/UpdateCageWorkTypeDurationDetail";
import UpdateDispenserNRFMotorSetup from "./custom-details/UpdateDispenserNRFMotorSetup";
import UpdateClimateClockMenuDetail from "./custom-details/UpdateClimateClockMenuDetail";
import UpdateClimateServiceMenuDetail from "./custom-details/UpdateClimateServiceMenuDetail";
import UpdateDispenserNRFPunishments from "./custom-details/UpdateDispenserNRFPunishments";
import UpdateDictionaryDetail from "./custom-details/UpdateDictionaryDetail";
import devicesDB from "../../../database/devicesDB";
import UpdateControlListDetail from "./custom-details/UpdateControlListDetail";
import UpdateMedicineDetail from "./custom-details/UpdateMedicineDetail";
import UpdateGraftingProgramDetail from "./custom-details/UpdateGraftingProgramDetail";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";

export const getLogDetailRenderer = (log, code) => {
    // console.log(log, code);
    switch (code) {
        case 2080: //update krzywek
            return <UpdateNutritionCurveDetail/>;
        case 2081: //update schedula
            return <UpdateNutritionScheduleDetail/>;
        case 2090: //update dozownika wst
            return <UpdateDispenserConfigurationDetail/>;
        case 2091: //update silosa
            return <UpdateScaleConfigurationDetail/>;
        case 2092: //update klimatu
            return <UpdateClimateConfigurationDetail/>;
        case 2093: //update trybu pracy kl selekcyjnej
            return <UpdateCageWorkType/>;
        case 2094: //update trybu pracy kl selekcyjnej
            return <UpdateDispenserNRFWorkType/>;
        case 2095: //update trybu pracy kl selekcyjnej
            return <UpdateChainFeedingWorkType/>
        case 2104: //update ilosci trwania t. pracy na kl sel
            return <UpdateCageWorkTypeDurationDetail/>
        case 2108: //update ilosci trwania t. pracy na kl sel
            return <UpdateDispenserNRFMotorSetup/>
        case 2111: //clock menu klimat
            return <UpdateClimateClockMenuDetail/>
        case 2112: //clock menu klimat
            return <UpdateClimateServiceMenuDetail/>
        case 2116: //clock menu klimat
            return <UpdateDispenserNRFPunishments/>
        case 2210: //clock menu klimat
            return <UpdateDictionaryDetail/>
        case 2211:
            return <UpdateControlListDetail/>
        case 2212:
            return <UpdateMedicineDetail/>
        case 2213:
            return <UpdateGraftingProgramDetail/>
        default:
            return checkIfUserIsService() ? <LogDetail/> : null;
    }
}

export const universalFormatter = (value) => isNil(value) ? i18next.t("none") : isBoolean(value) ?
    <i className={`fas fa-${value ? "check" : "times"}`}/> : isString(value) ? value.startsWith("#") ?
        <div style={{background: value, height: "100%"}}/> : value : value

export const difference = (object, base) => {
    return transform(object, (result, value, key) => {
        if (isObject(base) && !isEqual(value, base[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
        }
    });
}

export const getLocalItemByTableAndID = ({ID, TableName}) => {
    switch (TableName) {
        case LogTableTypes.ANIMALS:
            return animalsDB.getAnimalById(ID, {joinEvents: false});
        case LogTableTypes.EVENTS:
            return eventsDB.getEventByID(ID, {joinEvents: false})[0];
        case LogTableTypes.DEVICES:
            return devicesDB.getDeviceByID(ID);
        default:
            return null;
    }
}

export const getRowClassName = (object) => isEqual(object.value, object.valueBefore) ? "" : (!isNil(object.value) && !isNil(object.valueBefore)) ? "info" : (isNil(object.value) ? "error" : "success");

export const getLocalItemNameByTableAndID = ({ID, TableName}) => {
    const name = getName({ID, TableName});
    return isNil(name) ? i18next.t("none") : name;
}
const getName = ({ID, TableName}) => {
    const value = getLocalItemByTableAndID({ID, TableName});
    console.log(ID, TableName, value);
    if (isNil(value)) return null;
    switch (TableName) {
        case LogTableTypes.ANIMALS:
            return get(value, "AnmID")
        case LogTableTypes.EVENTS:
            return `${i18next.t(`eventTypes.${get(value, "EvCode")}`)} ${moment(get(value, "EvTime")).format("DD.MM.YY")}`;
        case LogTableTypes.DEVICES:
            return get(value, "Name")
        default:
            break;
    }
    return null;
}

export const timeFormatter = (value) => moment(value).format("HH:mm")

export const dateFormatter = (value) => moment(value).format("DD.MM.YY")


export const fakeGetIDByTableName = (id, tableName) => new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve({ID: id, TableName: tableName})
    }, 200)
})
