import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types"
import LabeledSelect from "../../basics/select/labeled-select/LabeledSelect";
import {FieldArray, reduxForm} from "redux-form";
import InseminationData from "./InseminationData";
import {convertCycles, submit, validate} from "./EditSowCycleModalSubmit"
import {EventTypes} from "../../../constans/eventTypes";
import USGData from "./USGData";
import NoPregnancyData from "./NoPregnancyData";
import ParturitionData from "./ParturitionData";
import SeparationToPiglethouseData from "./SeparationToPiglethouseData";
import SeparationToMommyData from "./SeparationToMommyData";
import MommyData from "./MommyData";
import FallPigletsData from "./FallPigletsData";
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {convertRowsToCycles} from "../../../utils/AnimalDocumentsUtils";
import WeightingData from "./WeightingData";
import ActiveNipplesData from "./ActiveNipplesData";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = "edit-sow-cycle-modal";

export class EditSowCycleModal extends React.Component {

    constructor(props) {
        super(props);
        let data = convertCycles(props.cycles);
        let converted = convertRowsToCycles(data);

        let selectData = converted.map((cycle) => ({
            name: i18next.t("cycle", {count: cycle.cycle}),
            value: cycle
        }));

        let startValue = selectData.length > 0 ? selectData[selectData.length - 1].value : null;

        this.state = {
            selectValue: startValue,
            selectData
        };

        this.props.initialize(startValue);
        ReactGA.modalview(ModalName);
    }

    onCycleChange = val => {
        this.setState({
            selectValue: val
        });
        this.props.initialize(val);
    };

    onRemoveClick(fields, index) {
        let event = fields.get(index);
        this.props.array.push("deleted", event);
        fields.remove(index);
    }

    /*
        Edycja eventów znajdujących się tylko na karcie lochy.
     */
    static getEventRenderer(type, props) {
        switch (type) {
            case EventTypes.INSEMINATION:
                return <InseminationData {...props}/>;
            case EventTypes.USG:
                return <USGData {...props}/>;
            case EventTypes.PARTURITION:
                return <ParturitionData {...props}/>;
            case EventTypes.FALL_PIGLETS:
                return <FallPigletsData {...props}/>;
            case EventTypes.SEPARATION_TO_MOMMY:
                return <SeparationToMommyData {...props}/>;
            case EventTypes.MOMMY:
                return <MommyData {...props}/>;
            case EventTypes.SEPARATION:
                return <SeparationToPiglethouseData {...props}/>;
            case EventTypes.ACTIVE_NIPPLES:
                return <ActiveNipplesData {...props}/>;
            case EventTypes.WEIGHTING:
                return <WeightingData {...props}/>;
            case EventTypes.NO_PREGNANCY:
                return <NoPregnancyData {...props}/>;
            default:
                // nie powinno wystapic wiec nie tlumacze
                return <div>Nie rozpoznano</div>
        }
    }

    renderData = ({fields, type}) => (
        <>
            {
                fields.map((member, index) => (
                    <>
                        <div className="d-flex justify-content-between">
                            <strong>{index + 1}</strong>
                            <i className="fas fa-trash pointer" onClick={() => this.onRemoveClick(fields, index)}/>
                        </div>
                        {EditSowCycleModal.getEventRenderer(type, {member})}
                        <hr/>
                    </>
                ))
            }
            {
                fields.length === 0 &&
                <p className="empty-list"><i>- {this.props.t("modals.editSowCycle.noData")} -</i></p>
            }
        </>
    );

    render() {
        const {show, handleHide, animal, t, submitting} = this.props;
        const {selectData, selectValue} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader title={i18next.t("modals.editSowCycle.title", {type: animal.AnmNo1})}
                             onCloseClick={handleHide}/>
                <ModalBody>
                    <LabeledSelect label={t("cycle")} options={selectData} value={selectValue}
                                   onChange={this.onCycleChange}/>
                    {
                        selectValue &&
                        <>
                            <h5>{t("modals.editSowCycle.inseminations")}</h5>
                            <FieldArray name="I" component={this.renderData} type={EventTypes.INSEMINATION}/>
                            <h5>{t("modals.editSowCycle.usgs")}</h5>
                            <FieldArray name="U" component={this.renderData} type={EventTypes.USG}/>
                            <h5>{t("birth")}</h5>
                            <FieldArray name="P" component={this.renderData} type={EventTypes.PARTURITION}/>
                            <h5>{t("fallPiglets")}</h5>
                            <FieldArray name="V" component={this.renderData} type={EventTypes.FALL_PIGLETS}/>
                            <h5>{t("modals.editSowCycle.separationToMommy")}</h5>
                            <FieldArray name="M" component={this.renderData} type={EventTypes.SEPARATION_TO_MOMMY}/>
                            <h5>{t("mommy")}</h5>
                            <FieldArray name="W" component={this.renderData} type={EventTypes.MOMMY}/>
                            <h5>{t("modals.editSowCycle.separationToPiglethouse")}</h5>
                            <FieldArray name="S" component={this.renderData} type={EventTypes.SEPARATION}/>
                            <h5>{t("activeNipples")}</h5>
                            <FieldArray name="AN" component={this.renderData} type={EventTypes.ACTIVE_NIPPLES}/>
                            <h5>{t("weighting")}</h5>
                            <FieldArray name="WE" component={this.renderData} type={EventTypes.WEIGHTING}/>
                            <h5>{t("noPregnancy")}</h5>
                            <FieldArray name="N" component={this.renderData} type={EventTypes.NO_PREGNANCY}/>
                        </>
                    }
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={t("save")} submitting={submitting} onCloseClick={handleHide}
                             formName={ModalName}/>
            </Modal>
        );
    }

}

EditSowCycleModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(EditSowCycleModal);

export const _EditSowCycleModal = connect(state => ({
    user: state.user.user,
    farm: state.location.farm
}))(EditSowCycleModal);

EditSowCycleModal = connectModal({name: ModalName})(_EditSowCycleModal);

EditSowCycleModal.propTypes = {
    animal: PropTypes.object.isRequired,
    cycles: PropTypes.array.isRequired
};

export default withTranslation()(EditSowCycleModal);
