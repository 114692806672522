import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {bindActionCreators, compose} from "redux";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {Modal} from "react-bootstrap";
import ModalFooter from "../ModalFooter";
import {connectModal, show} from "redux-modal";
import Button from "../../basics/button/Button";
import {ModalName as TaskAddModalName} from "../task-add-modal/TaskAddModal";
import TaskViewModalBody from "./TaskViewModalBody";
import {TaskStatus} from "../../../constans/task";
import {
    changeTaskStatus,
    changeTaskStatusNotificationFailure,
    changeTaskStatusNotificationSuccess
} from "../../../api/task/changeTaskStatus";
import {getSelectedTask} from "../../../selectors/taskSelectors";
import {removeTask, removeTaskNotificationFailure, removeTaskNotificationSuccess} from "../../../api/task/removeTask";

function mapStateToProps(state) {
    return {
        task: getSelectedTask(state)
    };
}

function mapsDispatchToProps(dispatch) {
    return {
        dispatch,
        ...bindActionCreators({show}, dispatch)
    }
}

export const ModalName = "task-view-modal";

class TaskViewModal extends Component {

    state = {
        changingStatus: false,
        removingTask: false
    }

    onEditClick = () => {
        const {task, handleHide} = this.props;
        this.props.show(TaskAddModalName, {task});
        handleHide();
    }

    onConfirmed = async () => {
        const {task} = this.props;
        try {
            this.setState({
                changingStatus: true
            })
            let res = await changeTaskStatus(task.IssueID);
            changeTaskStatusNotificationSuccess(res);
            this.setState({
                changingStatus: false
            })
        } catch (e) {
            changeTaskStatusNotificationFailure(e);
            this.setState({
                changingStatus: false
            })
        }
    }

    onDeleteClick = async () => {
        const {task, handleHide} = this.props;
        try {
            this.setState({
                removingTask: true
            })
            let res = await removeTask(task.IssueID);
            removeTaskNotificationSuccess(res);
            handleHide();
        } catch (e) {
            removeTaskNotificationFailure(e);
            this.setState({
                removingTask: false
            })
        }
    }

    render() {
        const {handleHide, show, task, t} = this.props;
        const {changingStatus, removingTask} = this.state;
        if (!task) return null;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("taskDescription")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <TaskViewModalBody task={task}/>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    hasCloseButton={false}
                    confirmText={t("taskDone") + "?"}
                    confirmIcon={task && task.Status === TaskStatus.DONE ? <i className="fas fa-check-square"/> :
                        <i className="fas fa-square"/>}
                    optionalButtons={[
                        <Button key={1} icon={<i className="fas fa-edit"/>}
                                onClick={this.onEditClick}>{t("edit")}</Button>,
                        <Button buttonColor={"error"} key={2}
                                icon={<i className="fas fa-trash"/>} onClick={this.onDeleteClick}
                                isLoading={removingTask}>{t("delete")}</Button>
                    ]}
                    onConfirmed={this.onConfirmed}
                    submitting={changingStatus}
                />
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(mapStateToProps, mapsDispatchToProps),
)(TaskViewModal);