import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {Field, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {submit, validate} from "./HelpContactModalSubmit";
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {loadReCaptcha, ReCaptcha} from 'react-recaptcha-google';
import {withTranslation} from "react-i18next";

export const ModalName = "help-contact-modal";

export class HelpContactModal extends React.Component {

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCaptcha = this.verifyCaptcha.bind(this);
        this.state = {
            language: this.props.lang,
            send: false
        }
    }

    componentDidMount() {
        if (this.props.user) {
            this.setState({
                send: true
            });
        } else {
            loadReCaptcha();
            if (this.captchaDemo) {
                this.captchaDemo.reset();
            }
        }
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
        }
    }

    verifyCaptcha() {
        this.setState({
            send: true
        })
    }

    render() {
        const {show, handleHide, handleSubmit, user} = this.props;
        const {send, language} = this.state;
        return (
            <Modal show={show} size={"lg"} onHide={handleHide}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={this.props.t("contact")} onCloseClick={handleHide}/>
                    <ModalBody>
                        {user &&
                        <Field
                            name="name"
                            id="name"
                            type="text"
                            label={this.props.t("name")}
                            component={ReduxLabeledInput}
                        />}
                        {user &&
                        <Field
                            name="surname"
                            id="surname"
                            type="text"
                            label={this.props.t("lastName")}
                            component={ReduxLabeledInput}
                        />
                        }
                        {!user &&
                        <Field
                            name="name"
                            id="name"
                            type="text"
                            label={this.props.t("helpView.nick")}
                            component={ReduxLabeledInput}
                        />
                        }

                        <Field
                            name="phone"
                            id="phone"
                            type="text"
                            label={this.props.t("phoneNumber")}
                            component={ReduxLabeledInput}
                        />
                        <Field
                            name="email"
                            id="email"
                            type="text"
                            label={this.props.t("email")}
                            required
                            component={ReduxLabeledInput}
                        />
                        <Field
                            name="placement"
                            id="placement"
                            type="text"
                            label={this.props.t("placement")}
                            component={ReduxLabeledInput}
                        />
                        <Field
                            name="message"
                            id="message"
                            type="textarea"
                            label={this.props.t("helpView.message")}
                            required
                            component={ReduxLabeledInput}
                        />
                        {!user &&
                        <ReCaptcha
                            ref={(el) => {
                                this.captchaDemo = el;
                            }}
                            size="normal"
                            hl={language.lang.toString()}
                            data-theme="dark"
                            render="explicit"
                            sitekey="6Ldsx8UUAAAAAOFoZ9Up22CRa2wCoGgPzUNVi9c5"
                            onloadCallback={this.onLoadRecaptcha}
                            verifyCallback={this.verifyCaptcha}
                        />}
                    </ModalBody>

                    <ModalFooter hasConfirmButton={send} confirmText={this.props.t("send")}
                                 onCloseClick={handleHide}
                                 formName={ModalName}/>
                </form>
            </Modal>
        )
    }
}

HelpContactModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(HelpContactModal);

HelpContactModal = connect((state) => ({
    lang: state.language.lang,
    user: state.user.user.ClientID
}))(HelpContactModal);


HelpContactModal = connectModal({name: ModalName})(HelpContactModal);

export default withTranslation()(HelpContactModal);