import React from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {hide, show} from "redux-modal";
import Button from "../basics/button/Button";

export class ModalHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.title
        };
    }

    render() {
        const {title} = this.state;
        const {onCloseClick, loading} = this.props;
        return (
            <div className="modal-header">
                <h4><span>{title}{!!loading && <i className={"fas fa-circle-notch fa-spin ml-1"}/>}</span></h4>
                <Button className={"modal-exit"} onClick={() => onCloseClick()} buttonStyle={"text"}
                        icon={<i className={"fas fa-times"}/>} type="button"/>
            </div>
        );
    }
}

ModalHeader = connect(null, dispatch => bindActionCreators({show, hide}, dispatch))(ModalHeader);
const mapStateToProps = state => ({
    modals: state.modal,
    inactive: state.inactive.inactive
});
export default connect(mapStateToProps)(ModalHeader)

ModalHeader.propTypes = {
    title: PropTypes.string.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    loading: PropTypes.bool
};