import React, {Component} from "react";
import PropTypes from 'prop-types';
import {submit} from 'redux-form'
import Button from "../basics/button/Button";
import "./_modal.scss";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

class ModalFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmButtonClicked: false
        }
    }

    onClick = (e) => {
        e.preventDefault();
        const {formName, onConfirmed, dispatch} = this.props;
        this.setState({confirmButtonClicked: true});
        if (formName) {
            dispatch(submit(formName))
        }
        if (onConfirmed) {
            onConfirmed();
        }
    }

    handleOnEnter = (e) => {
        if (e.key === 'Enter' && !e.Handled) {
            //e.preventDefault();
            this.onClick(e);
        }
        e.Handled = true;
    };

    componentDidMount() {
        window.addEventListener('keydown', this.handleOnEnter)
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleOnEnter)
    }

    render() {
        const {hasConfirmButton, confirmText, confirmIcon, hasCloseButton, closeText, optionalButtons, onCloseClick, submitting, forceLoadingOnClick, t, hasButtonDisabled} = this.props;
        const {confirmButtonClicked} = this.state;
        return (
            <div className="modal-footer">
                {
                    hasCloseButton &&
                    <Button
                        type={"button"}
                        buttonColor={"secondary"}
                        buttonStyle={"text"}
                        icon={confirmIcon}
                        onClick={() => {
                            onCloseClick();
                        }}
                    >
                        {closeText ? closeText : t("close")}
                    </Button>

                }
                {optionalButtons}
                {
                    hasConfirmButton &&
                    <Button
                        buttonColor={"primary"}
                        icon={confirmIcon}
                        isLoading={submitting || (confirmButtonClicked && forceLoadingOnClick)}
                        onClick={this.onClick}
                        disabled={hasButtonDisabled}
                    >
                        {confirmText ? confirmText : 'Ok'}
                    </Button>
                }
            </div>
        );
    }
}

ModalFooter.propTypes = {
    hasConfirmButton: PropTypes.bool,
    confirmText: PropTypes.string,
    confirmIcon: PropTypes.node,
    hasCloseButton: PropTypes.bool,
    closeText: PropTypes.string,
    optionalButtons: PropTypes.node,
    onCloseClick: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    formName: PropTypes.string,
    forceLoadingOnClicked: PropTypes.bool,
    onConfirmed: PropTypes.func,
    hasButtonDisabled: PropTypes.bool
};

ModalFooter.defaultProps = {
    hasCloseButton: true,
    hasConfirmButton: false,
    forceLoadingOnClicked: false,
    hasButtonDisabled: false
};

ModalFooter = connect()(ModalFooter);

ModalFooter = withTranslation()(ModalFooter);

export default ModalFooter;
