import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import "./_colors-modal.scss";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";

export const ModalName = "help-colors-modal";

export class HelpColorsModal extends React.Component {

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, handleHide} = this.props;

        return (
            <Modal show={show} size={"lg"} onHide={handleHide} >
                <ModalHeader title={this.props.t("helpView.legend")} onCloseClick={handleHide}/>
                <ModalBody>
                    <p>{this.props.t("helpView.primaryDescription")}</p>
                    <div className={"colors-container"}>
                        <p>
                            <i style={{color: "#33cc33"}} className={"fas fa-square"}/>
                            {this.props.t("helpView.successDescription")}
                        </p>
                        <p>
                            <i style={{color: "#fd3b60"}} className={"fas fa-square"}/>
                            {this.props.t("helpView.dangerDescription")}
                        </p>
                        <p>
                            <i style={{color: "#ffc007"}} className={"fas fa-square"}/>
                            {this.props.t("helpView.warningDescription")}
                        </p>
                        <p>
                            <i style={{color: "#aaaaaa"}} className={"fas fa-square"}/>
                            {this.props.t("helpView.disabledDescription")}
                        </p>
                        <p>
                            <i style={{color: "#00afff"}} className={"fas fa-square"}/>
                            {this.props.t("helpView.infoDescription")}
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter onCloseClick={handleHide}/>
            </Modal>
        )
    }
}

HelpColorsModal = connectModal({name: ModalName})(HelpColorsModal);

export default withTranslation()(HelpColorsModal);