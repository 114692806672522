import {setAlert} from "../../../IOT/device-functions/DispenserNRFFunctions";
// import {setClearFeedProblem, setClearMotorProblem} from "../../../IOT/device-functions/DispenserDriverFunctions";
import {clearAlarmsOnDevices} from "../../../IOT/device-functions/GatewayFunctions";
import _ from "lodash";

export function submit(values = {}, dispatch, props) {
    //console.log(values, props, "FeedingSetAlarmSubmit");
    const {alert = false} = values;
    const {receivers, handleHide, clearSelection} = props;
    if (alert) {
        for (let devs of receivers.values()) {
            if (devs.individualNRF.devices.length) {
                setAlert(devs.individualNRF.devices, true);
            }
            // if (devs.individualDTM.length) {
            //     devs.individualDTM.forEach(dev => dev.outputs.forEach((out) => {
            //         setClearFeedProblem(dev.DevID, out.number);
            //         setClearMotorProblem(dev.DevID, out.number);
            //     }));
            // }
        }
    } else {
        for (let [gwId, devs] of receivers.entries()) {
            let devices = [
                ...devs.individualNRF.devices.map(devId => ({DevID: devId})),
                ...devs.individualDTM.map(dev => {
                    return dev.outputs.map(out => ({DevID: dev.DevID, number: out.number}))
                })
            ];
            clearAlarmsOnDevices(gwId, {DeviceIDs: _.flatten(devices)});
        }
    }
    clearSelection && clearSelection();
    handleHide();
}

export function validate(values, props) {
    const errors = {};
    return errors;
}
