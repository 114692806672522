import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";

class UpdateCageWorkType extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const dayFormatter = (value) => t("newSettings.feeding.curves.editDays.days", {count: value});
        return [
            {
                name: t(`chamber.separationCageInfo.work.1`),
                value: has(obj, "training1") ? dayFormatter(get(obj, "training1")) : null
            },
            {
                name: t(`chamber.separationCageInfo.work.2`),
                value: has(obj, "training2") ? dayFormatter(get(obj, "training2")) : null
            },
            {
                name: t(`chamber.separationCageInfo.work.3`),
                value: has(obj, "training3") ? dayFormatter(get(obj, "training3")) : null
            },
            {
                name: t(`chamber.separationCageInfo.work.4`),
                value: has(obj, "separation") ? dayFormatter(get(obj, "separation")) : null
            },
            {
                name: t(`chamber.separationCageInfo.work.5`),
                value: has(obj, "expedition") ? dayFormatter(get(obj, "expedition")) : null
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateCageWorkType);
