import React from "react";
import {Field, reduxForm} from "redux-form";
import {validate} from "../CageWeightConfigurationModalSubmit"
import ReduxLabeledSelect from "../../../basics/select/labeled-select/ReduxLabeledSelect";
import InfoBox from "../../../basics/info-box/InfoBox";
import {DevType} from "../../../../constans/devices";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

const ModalName = "cage-weight-configurator";

export class CageWeightConfigurationModalPage1 extends React.Component {

    render() {
        const {devices, handleSubmit} = this.props;
        let cages = devices.filter(item => item.DevType === DevType.CAGE).map(cage => ({
            name: cage.Name,
            value: cage
        }));
        return (
            <form onSubmit={handleSubmit}>
                <Field
                    name="cage"
                    component={ReduxLabeledSelect}
                    label={this.props.t("modals.cageWeightConfiguration.separationCage")}
                    options={cages}
                />
                <InfoBox boxColor="info">
                    {this.props.t("modals.cageWeightConfiguration.info1")}
                </InfoBox>
                <div className="placeholder">
                    PLACEHOLDER
                </div>
            </form>
        );
    }

}

CageWeightConfigurationModalPage1 = reduxForm({
    form: ModalName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(CageWeightConfigurationModalPage1);

CageWeightConfigurationModalPage1 = connect(state => ({
    devices: state.farmDevices.devices
}))(CageWeightConfigurationModalPage1);

export default withTranslation()(CageWeightConfigurationModalPage1);