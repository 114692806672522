import React, {Component} from 'react';
import {connect} from 'react-redux';
import SearchInput from "../basics/search-input/SearchInput";
import {
    listLogsDynamoDB, removeCode,
    setCode,
    setObjectId,
    setSearchText,
    setStartFrom,
    setUserID
} from "../../actions/logsActions";
import {debounce, get, isEqual} from "lodash";
import {getSearchParams} from "../../selectors/logSelector";
import LogChip from "./LogChip";
import {compareTwoStrings, findBestMatch} from "string-similarity";
import {CSSTransition} from "react-transition-group";

function mapStateToProps(state) {
    return {
        search: getSearchParams(state),
        objectId: state.logs.objectId,
        startFrom: state.logs.startFrom,
        searchCode: state.logs.searchCode,
        text: state.logs.search,
        userId: state.logs.userId
    };
}

class LogSearch extends Component {

    state = {
        text: ""
    }

    constructor(props) {
        super(props);
        this.handleChange = debounce(this.handleChange, 200);
        this.refresh = debounce(this.refresh, 500);
    }


    handleLocalChange = (text = "") => {
        this.setState({text: text});
        this.handleChange(text);
    }

    handleChange = (text) => {
        const {dispatch} = this.props;
        dispatch(setSearchText(text));
    }

    refresh = ({ObjID, DtaCrtTime, UserID, Codes}) => {
        const {dispatch} = this.props;
        console.table({ObjID, DtaCrtTime, UserID, Codes});
        dispatch(listLogsDynamoDB({ObjID, DtaCrtTime, UserID, Codes}));
    }

    handleClearClick = () => {
        const {dispatch} = this.props;
        dispatch(setStartFrom(null));
        dispatch(setUserID(null));
        dispatch(setObjectId(null));
        dispatch(setCode(null));
    }

    handleEnterClick = () => {
        const {dispatch, search: {dates, users, objects, codes}, userId, text, startFrom, objectId, searchCode} = this.props;
        const candidates = [dates[0], users[0], objects[0], codes[0]].filter(o => !!o);
        let winner = null;
        if (candidates.length === 0) return null;
        if (candidates.length === 1) winner = candidates[0];
        else {
            const bestMatch = findBestMatch(text.toLowerCase(), candidates.map(u => u.name.toLowerCase()));
            winner = candidates[bestMatch.bestMatchIndex];
        }
        if (!winner) return null;
        let newUserId = userId;
        let newObjectId = objectId;
        let newStartFrom = startFrom;
        switch (winner.type) {
            case "date":
                newStartFrom = winner.value;
                dispatch(setStartFrom(newStartFrom));
                break;
            case "user":
                newUserId = winner.value
                dispatch(setUserID(newUserId));
                break;
            case "codes":
                if (!searchCode.includes(winner.value)) {
                    dispatch(setCode(winner.value));
                }
                break;
            default:
                newObjectId = winner.value;
                dispatch(setObjectId(newObjectId))
                break;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {objectId, text, startFrom, userId, searchCode} = this.props;
        const {text: localText} = this.state;
        //sync textu w stacie
        if (prevState.text === localText && (localText !== text)) {
            this.setState({
                text: text
            })
        }
        const refresh = (!isEqual(prevProps.searchCode, searchCode) || prevProps.objectId !== objectId) || (prevProps.startFrom !== startFrom) || (prevProps.userId !== userId);
        if (refresh) {
            this.refresh({ObjID: objectId, DtaCrtTime: startFrom, UserID: userId, Codes: searchCode});
        }
    }

    getParams = (obj) => {
        switch (obj.type) {
            case "date":
                return {
                    handleChange: setStartFrom,
                    valueKey: "startFrom"
                };
            case "user":
                return {
                    handleChange: setUserID,
                    valueKey: "userId"
                };
            case "codes":
                return {
                    handleChange: setCode,
                    onRemove: removeCode,
                    valueKey: "searchCode"
                };
            default:
                return {
                    handleChange: setObjectId,
                    valueKey: "objectId"
                };
        }
    }

    renderChip = (obj, key) => {
        if (!obj) return null;
        const params = this.getParams(obj);
        return (
            <CSSTransition key={key} in={true} timeout={300}
                           classNames="fade-in" mountOnEnter unmountOnExit appear>
                <LogChip selected={`${key}`.startsWith("selected")} key={`${obj.type}_${key}`}
                         onSelect={params.handleChange}
                         name={obj.name} value={obj.value}
                         type={obj.type} onRemove={params.onRemove}/>
            </CSSTransition>

        )
    }

    handleKey = (e) => {
        if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
            e.preventDefault();
            try {
                const el = document.getElementById("log-search-input");
                el.focus();
                document.body.scrollIntoView();
            } catch (e) {
                //unluko
            }
        }
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKey);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKey);
    }

    render() {
        const maxSize = 3;
        const {text, search: {objects, dates, users, selectedDate, selectedObject, selectedUser, codes, selectedCodes}} = this.props;
        const suggestions = [dates.slice(0, maxSize), users.slice(0, maxSize), objects.slice(0, maxSize), codes.slice(0, maxSize)];
        const textTrimmed = text.trim().toLowerCase()
        suggestions.sort((o2, o1) => compareTwoStrings(textTrimmed, get(o1, "[0].name", "").toLowerCase()) - compareTwoStrings(textTrimmed, get(o2, "[0].name", "").toLowerCase()))
        return (
            <>
                <SearchInput id={"log-search-input"} className={"mb-4"} onClear={this.handleClearClick}
                             onEnter={this.handleEnterClick}
                             onChange={this.handleLocalChange} value={this.state.text}/>
                <div className={"mb-4"}>
                    {
                        (!!selectedDate || !!selectedObject || !!selectedUser || !!selectedCodes.length) &&
                        <>
                            <div className={"font-weight-bold opacity-50"}>Aktywne filtry</div>
                            {
                                this.renderChip(selectedDate, "selected_00")
                            }
                            {
                                this.renderChip(selectedObject, "selected_11")
                            }
                            {
                                this.renderChip(selectedUser, "selected_22")
                            }
                            {
                                selectedCodes.map((code, i) => (
                                    this.renderChip(code, `selected_${i}`)

                                ))
                            }
                        </>
                    }
                    {
                        (!!dates.length || !!users.length || !!objects.length || !!codes.length) &&
                        <>
                            <div className={"font-weight-bold opacity-50"}>Suggestie</div>
                            {

                                suggestions.map(arr => arr.map((o, i) => this.renderChip(o, i)))
                            }
                        </>
                    }
                </div>


            </>
        );
    }
}

export default connect(
    mapStateToProps,
)(LogSearch);
