import React from "react";
import {Field} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import {isEventDateInvalid} from "../../../utils/EventUtils";
import {withTranslation} from "react-i18next";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";

export class EventData extends React.Component {

    checkDate = (value) => {
        const {farm, t} = this.props;
        const isService = checkIfUserIsService();
        if (!isService && isEventDateInvalid(value, farm)) {
            return t("required");
        }
        return undefined;
    };

    render() {
        const {member, children, t, onChange} = this.props;
        return (
            <>
                <Field
                    name={member ? `${member}.EvTime` : "EvTime"}
                    component={ReduxLabeledInput}
                    label={t("eventData.time")}
                    type="datetime-local"
                    onChange={onChange}
                    validate={this.checkDate}
                />
                {children}
                <Field
                    name={member ? `${member}.Comment` : "Comment"}
                    component={ReduxLabeledInput}
                    label={t("comment")}
                    type="text"
                />
            </>
        );
    }

}

EventData = connect(state => ({
    farm: state.location.farm,
}))(EventData);

EventData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(EventData);
