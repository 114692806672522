import React, {Component} from 'react';
import {connect} from 'react-redux';
import {listLogsDynamoDB} from "../../actions/logsActions";
import ListItem from "../basics/list/list-item/ListItem";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        fetching: state.logs.fetching,
        lastEvaluatedKey: state.logs.lastEvaluatedKey,
        objectId: state.logs.objectId,
        startFrom: state.logs.startFrom,
        searchCode: state.logs.searchCode,
        userId: state.logs.userId
    };
}

/**
 * komponent do odswiezania logów
 */
class LogRefresh extends Component {

    //flaga potrzebna do sprawdzenia czy klikniecie w ten komponent zainiciowalo ladowanie
    clicked = false;

    refresh = () => {
        const {dispatch, objectId, startFrom, userId, searchCode} = this.props;
        this.clicked = true;
        dispatch(listLogsDynamoDB({ObjID: objectId, DtaCrtTime: startFrom, UserID: userId, Codes: searchCode}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {fetching} = this.props;
        const {fetching: prevFetching} = prevProps;
        //zeby ladowanie nam sie nie zalaczalo jesli nie kliknelismy z tego widoczku
        if (prevFetching && (prevFetching !== fetching)) {
            if (this.clicked === true) {
                this.clicked = false;
            }
        }
    }

    render() {
        const {props: {index, fetching, lastEvaluatedKey, t}, clicked} = this;
        const showLoading = !!fetching && clicked && !lastEvaluatedKey;
        return (
            <ListItem index={index} disableHover className={"row pb-2 pt-2 pointer"}>
                <div onClick={this.refresh}
                     className={"d-flex justify-content-center w-100 align-items-center"}>
                    {t("logsView.loadNewer")}
                    {
                        !!showLoading &&
                        <i className={"fas fa-fw fa-circle-notch fa-spin"}/>
                    }
                </div>
            </ListItem>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(LogRefresh);
