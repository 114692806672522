import React from "react";
import EventData from "./EventData";
import PropTypes from "prop-types";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {Field} from "redux-form";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

export class FallPigletsData extends React.Component {

    render() {
        const {member, reasons, lang, t} = this.props;
        return (
            <EventData member={member}>
                <Field
                    name={member ? `${member}.EvData.Piglets` : "EvData.Piglets"}
                    component={ReduxLabeledInput}
                    type="number"
                    label={t("eventData.piglets")}
                    parse={value => value ? +value : null}
                />
                <Field
                    name={member ? `${member}.EvData.Weight` : "EvData.Weight"}
                    component={ReduxLabeledInput}
                    type="number"
                    label={t("weight")}
                    unit="g"
                    parse={value => value ? +value : null}
                />
                <Field
                    name={member ? `${member}.EvData.Reasn` : "EvData.Reasn"}
                    component={ReduxLabeledSelect}
                    label={t("reason")}
                    options={reasons.WData[lang].map(val => ({
                        name: val.Value,
                        value: val.ID
                    }))}
                    init={true}
                />
            </EventData>
        );
    }

}

FallPigletsData = connect(state => ({
    reasons: state.dictionary.fallReasons,
    lang: state.language.lang.lang,
}))(FallPigletsData);

FallPigletsData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(FallPigletsData);