import moment from "moment";
import {convertWeightToBaseUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {getLocationID, getLocationName} from "../../../utils/BuildingUtils";
import {
    createSettlementForDevice,
    createSettlementNotificationFailure,
    createSettlementNotificationSuccess
} from "../../../api/feeding/Settlement";
import {updateDevices} from "../../../api/devices/updateDevices";
import {setNewInsertion} from "../../../IOT/device-functions/GatewayFunctions";

export function submit(values, dispatch, props) {
    console.log(values);

    const {cages, settlements} = props;

    let date = moment.utc(values.date);
    let weight = convertWeightToBaseUnit(values.weight, {fromUnit: UnitTypes.MEDIUM});
    let onlyUpdateDevice = [];
    let promises = [];
    for (let cage of cages) {
        let locs = cage.getLocation();
        let locationID = getLocationID(locs[0] || {});
        if (locationID) {
            let currentSettlement = settlements.find(item => item.PlcmntID === locationID && !item.DtaEndTime);
            if (currentSettlement) {
                let copy = cage.prepareBeanToSave();
                copy.startInsertion(currentSettlement.DtaStartTime, weight, +values.number);
                onlyUpdateDevice.push(copy);
            } else {
                promises.push(createSettlementForDevice({
                    DevID: cage.DevID,
                    time: date.toDate().getTime(),
                    weight,
                    amount: +values.number
                }))
            }
        }
        if (values.sendToDevice) {
            setNewInsertion(cage.GatewayID, {
                DevID: cage.DevID,
                animalsNumber: +values.number,
                weight: weight,
                workType: 1
            })
        }
    }
    if (onlyUpdateDevice.length > 0) {
        promises.push(updateDevices(onlyUpdateDevice));
    }
    return Promise.all(promises).then(r => {
        props.handleHide();
        createSettlementNotificationSuccess(r);
    }).catch(e => {
        createSettlementNotificationFailure(e);
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.date) {
        errors.date = t("required")
    } else {
        let date = moment.utc(values.date);
        if (date.isAfter(moment.utc(), "days")) {
            errors.date = t("errors.future");
        } else {
            for (let cage of props.cages) {
                let locs = cage.getLocation();
                let locationID = getLocationID(locs[0] || {});
                if (locationID) {
                    let settlements = props.settlements.filter(item => item.PlcmntID === locationID);
                    for (let settlement of settlements) {
                        if (date.isBetween(settlement.DtaStartTime, settlement.DtaEndTime)) {
                            let locationName = getLocationName(locs[0]);
                            errors.date = t("errors.hasSettlement", {name: locationName});
                            break;
                        }
                        if (date.isBefore(settlement.DtaStartTime)) {
                            let locationName = getLocationName(locs[0]);
                            errors.date = t("errors.hasLaterSettlement", {name: locationName});
                            break;
                        }
                    }
                }
            }
        }
    }

    if (!values.number) {
        errors.number = t("required")
    }

    if (!values.endDate) {
        errors.endDate = t("required")
    }

    return errors;
}