import React from "react";
import {connectModal} from "redux-modal";
import {Field, reduxForm} from "redux-form";
import {Modal} from "react-bootstrap";
import {ModalHeader} from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {submit, validate} from "./NewTranslationModalSubmit"
import {withTranslation} from "react-i18next";

export const ModalName = 'new-translation-modal';

export class NewTranslationModal extends React.Component {

    state = {
        languages: Object.keys(this.props.languages)
            .sort((a, b) => a.localeCompare(b))
            .filter(item => !this.props.current.includes(item))
            .map(key => ({
                value: key,
                name: this.props.languages[key]
            }))
    }

    render() {
        const {show, handleHide, handleSubmit, submitting, t} = this.props;
        const {languages} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"xl"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("modals.newTranslation.title")} onCloseClick={handleHide}/>
                    <ModalBody>
                        <Field
                            name="code"
                            component={ReduxLabeledSelect}
                            label={t("language")}
                            options={languages}
                            disableSort
                            clearButton={false}
                        />
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("save")}
                                 onCloseClick={handleHide}
                                 formName={ModalName} submitting={submitting}/>
                </form>
            </Modal>
        )
    }
}

NewTranslationModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(NewTranslationModal);
NewTranslationModal = connectModal({name: ModalName})(NewTranslationModal);
export default withTranslation()(NewTranslationModal);
