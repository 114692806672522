import {Modal} from "react-bootstrap";
import React from "react";
import "./ModalSignSuccessfull.css";
import {withTranslation} from "react-i18next";
import ModalFooter from "../ModalFooter";


class ModalSignSuccessfull extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: props.counter
        }
    }

    onButtonClick = () => {
        this.props.action();
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.state.counter < 1) {
                this.onButtonClick()
            } else {
                this.setState({
                    counter: this.state.counter - 1
                })
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const {show, counter, t} = this.props;
        return (
            <Modal className="confirmModal" onHide={() => {
            }} show={show} counter={counter}>
                <Modal.Header><h1>{t("signSuccessfullModal.header")}</h1></Modal.Header>
                <Modal.Body>
                    <a>{t("signSuccessfullModal.message1")}</a>
                    <a>{t("signSuccessfullModal.message2")}</a>
                    <a>{t("signSuccessfullModal.message3")}{this.state.counter}.</a>
                </Modal.Body>
                <ModalFooter confirmText={t("signSuccessfullModal.goToLogin")} hasCloseButton={false}
                             hasConfirmButton={true} onConfirmed={this.onButtonClick}/>
            </Modal>
        );
    }
}

export default withTranslation()(ModalSignSuccessfull);