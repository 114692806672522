import {setTypeEvent} from "../../../IOT/device-functions/DispenserNRFFunctions";
import moment from "moment";

export function submit(values, dispatch, props) {
    const {receivers, handleHide, clearSelection} = props;
    const {startDate, eventType} = values;
    let data = {
        type: +eventType,
        time: +moment(startDate, "YYYY-MM-DD").startOf("day"),
    };
    for (let devs of receivers.values()) {
        if (devs.individualNRF.devices.length > 0) {
            setTypeEvent(devs.individualNRF.devices, data);
        }
    }
    handleHide();
    clearSelection();
}

export function validate(values, props) {
    const errors = {};
    const {startDate} = values;
    const {t} = props;
    if (!startDate) {
        errors.startDate = t("required");
    }
    return errors;
}
