import React from "react";
import {change, Field, formValueSelector} from "redux-form";
import ReduxLabeledSelect from "../../../basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledSlider from "../../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

export class InseminationsListFields extends React.Component {

    onIsArrayChange = (event, newValue) => {
        setTimeout(() => {
            if (newValue) {
                this.props.dispatch(change(this.props.formName, "WData.InseminationTime", [this.props.InseminationTime, 30]));
            } else {
                this.props.dispatch(change(this.props.formName, "WData.InseminationTime", this.props.InseminationTime[0]));
            }
        })
    };

    render() {
        const {isArray, t} = this.props;
        return (
            <>
                <Field
                    name="isArray"
                    component={ReduxLabeledSelect}
                    label={"Typ"}
                    options={[
                        {
                            name: t("modals.editControlListSettingsModal.oneDay"),
                            value: false
                        },
                        {
                            name: t("modals.editControlListSettingsModal.rangeOfDays"),
                            value: true
                        }
                    ]}
                    onChange={this.onIsArrayChange}
                />
                {
                    isArray &&
                    <Field
                        name={"InseminationTime"}
                        component={ReduxLabeledSlider}
                        isRange
                        label={t("modals.editControlListSettingsModal.daysToHeat")}
                        min={15}
                        max={30}
                        valueFormatter={value => `${value[0]} - ${value[1]}`}
                    />
                }
                {
                    !isArray &&
                    <Field
                        name={"InseminationTime"}
                        component={ReduxLabeledSlider}
                        label={t("modals.editControlListSettingsModal.daysToHeat")}
                        min={15}
                        max={30}
                        valueFormatter={value => value}
                    />
                }
            </>
        );
    }

}

InseminationsListFields = connect((state, props) => {
    let selector = formValueSelector(props.formName);
    return {
        isArray: selector(state, "WData.isArray"),
        InseminationTime: selector(state, "WData.InseminationTime"),
    }
})(InseminationsListFields);

export default withTranslation()(InseminationsListFields);