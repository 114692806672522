import {setConfiguration} from "../../../IOT/device-functions/ClimateDriverFunctions";

export function submit(values, dispatch, props) {
    console.log(values);
    const {expectedTemperature, minimumVentilation, maximumVentilation, minimumAlarm, maximumAlarm} = values;
    const {climates} = props;
    // ustawienie zwyklych ustawien
    setConfiguration(climates, {
        requestedAirTemperature: expectedTemperature,
        ventilationConfiguration: {
            minimumVentilation,
            maximumVentilation
        },
        alarms: {
            minimumTemperatureAlarmDeviation: minimumAlarm,
            maximumTemperatureAlarmDeviation: maximumAlarm
        }
    });
    // Daniel & Ciesiu kazali wylaczyc
    // setClockMenu(climates, {
    //     currentCurveDay: 0
    // })

    props.handleHide();
}

export function validate(values, props) {
    const {t} = props;
    const errors = {};

    if (!values.curve) {
        errors.curve = t("required");
    }

    return errors;
}
