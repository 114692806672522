import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {connect} from "react-redux";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {calculateVentilation} from "../../../utils/DevicesUtils";
import {submit, validate} from "./ClimateConfigurationModalSubmit"
import ClimateCurveChart from "../../charts/ClimateCurveChart";
import "./_climate-configuration-modal.scss"
import ReactGA from "react-ga";
import {isEqual} from "lodash"
import {withTranslation} from "react-i18next";

export const ModalName = "climate-configuration";

export class ClimateConfigurationModal extends React.Component {

    constructor(props) {
        super(props);

        let climate = props.climates[0];
        let shadow = props.shadows.get(climate.DevID);
        try {
            let configuration = shadow.configuration;
            const {climateCurves} = props;
            let curve = climateCurves.find(item => isEqual(item.getBackendStages(), shadow.climateCurve.stages)) || null;
            props.initialize({
                expectedTemperature: configuration.requestedAirTemperature,
                minimumVentilation: configuration.ventilationConfiguration.minimumVentilation,
                maximumVentilation: configuration.ventilationConfiguration.maximumVentilation,
                minimumAlarm: configuration.alarms.minimumTemperatureAlarmDeviation,
                maximumAlarm: configuration.alarms.maximumTemperatureAlarmDeviation,
                curveDay: curve && shadow.climateCurve.currentCurveDay ? shadow.climateCurve.currentCurveDay + curve.getMinAndMax().min - 1 : 1,
                curveActive: false,
                curve
            })
        } catch (e) {
            console.log(e, "ERROR CLIMAT CONFIG MODAL");
            props.initialize({
                expectedTemperature: 10,
                minimumVentilation: 0,
                maximumVentilation: 0,
                minimumAlarm: 9.8,
                maximumAlarm: 12,
                curveActive: false
            })
        }
        ReactGA.modalview(ModalName);
    }

    onCurveChange = (event, newValue) => {
        let min = newValue.getMinAndMax().min;
        this.props.change("curveDay", min);
    };

    render() {
        const {show, handleHide, climateCurves, curveActive, minimumVentilation, curve, t, expectedTemperature} = this.props;
        console.log(curve);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.climateConfiguration.title")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    {/*<Field*/}
                    {/*    component={ReduxSwitch}*/}
                    {/*    name="curveActive"*/}
                    {/*    label={t("modals.climateConfiguration.curveActive")}*/}
                    {/*/>*/}
                    {
                        curveActive &&
                        <>
                            <ClimateCurveChart stages={curve ? curve.SetData.Stages : []}/>
                            <Field
                                component={ReduxLabeledSelect}
                                name="curve"
                                label={t("climateCurve")}
                                options={climateCurves.map(curve => ({
                                    name: curve.SetData.Name,
                                    value: curve
                                }))}
                                onChange={this.onCurveChange}
                            />
                            {
                                curve &&
                                <Field
                                    component={ReduxLabeledSlider}
                                    name="curveDay"
                                    label={t("curveDay")}
                                    {...curve.getMinAndMax()}
                                />
                            }
                        </>
                    }
                    {
                        !curveActive &&
                        <>
                            <Field
                                component={ReduxLabeledSlider}
                                name="expectedTemperature"
                                label={t("requestedTemperature")}
                                min={10}
                                max={50}
                                step={0.2}
                                valueFormatter={value => value ? `${value.toFixed(1)}°C` : ""}
                            />
                            <Field
                                component={ReduxLabeledSlider}
                                name="minimumVentilation"
                                label={t("minimumVentilation")}
                                min={0}
                                max={190}
                                valueFormatter={value => value !== undefined ? `${calculateVentilation(value)}%` : ""}
                            />
                            <Field
                                component={ReduxLabeledSlider}
                                name="maximumVentilation"
                                label={t("maximumVentilation")}
                                min={minimumVentilation}
                                max={190}
                                valueFormatter={value => value !== undefined ? `${calculateVentilation(value)}%` : ""}
                            />
                            <Field
                                component={ReduxLabeledSlider}
                                name="minimumAlarm"
                                label={t("minTempAlarm")}
                                min={-40}
                                max={-2}
                                step={2}
                                valueFormatter={value => value ? `${(expectedTemperature + (value / 10)).toFixed(1)}°C` : ""}
                                parse={value => value ? Math.abs(value) / 10 : null}
                                format={value => value ? -value * 10 : null}
                            />
                            <Field
                                component={ReduxLabeledSlider}
                                name="maximumAlarm"
                                label={t("maxTempAlarm")}
                                min={2}
                                max={20}
                                step={0.2}
                                valueFormatter={value => value ? `${(value + expectedTemperature).toFixed(1)}°C` : ""}
                            />
                        </>
                    }
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

ClimateConfigurationModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(ClimateConfigurationModal);

const selector = formValueSelector(ModalName);
export const _ClimateConfigurationModal = connect(state => ({
    climateCurves: state.settings.climateCurves,
    shadows: state.shadows.shadows,
    curveActive: selector(state, "curveActive"),
    minimumVentilation: selector(state, "minimumVentilation"),
    curve: selector(state, "curve"),
    expectedTemperature: selector(state, "expectedTemperature")
}))(ClimateConfigurationModal);

ClimateConfigurationModal = connectModal({name: ModalName})(_ClimateConfigurationModal);

export default withTranslation()(ClimateConfigurationModal);
