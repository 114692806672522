import React, {Component} from 'react';
import ListItem from "../basics/list/list-item/ListItem";
import {Col, Row} from "react-bootstrap";
import {Trans} from "react-i18next";
import moment from "moment";
import "./_log-item.scss"
import LogDetails from "./log-details/LogDetails";
// import {getLogDetailRenderer} from "./log-details/utils";
// import {isNil} from "lodash";

class LogItem extends Component {

    onClick = () => {
        console.table(this.props.log.log)

    }

    render() {
        const {index, style, log: {log, name, iconClassName, translation, params, user}} = this.props;
        const isSameDay = moment(log.DtaCrtTime).startOf("day").isSame(moment().startOf("day"));
        const date = moment(log.DtaCrtTime);
        // const ExpandComponent = getLogDetailRenderer(log);
        // const showExpand = !isNil(ExpandComponent);
        return (
            <ListItem style={style} className={"log-item pt-2 pb-2 col"} onClick={this.onClick} index={index}>
                <Row>
                    <Col
                        title={name}
                        xs={6} sm={6} md={6} lg={6} xl={6}
                        className={"no-break-word font-weight-bold"}>
                        <i className={iconClassName}/>
                        <Trans
                            i18nKey={name}
                            defaults={name}
                        />
                    </Col>
                    <Col
                        title={date.format("DD.MM.YY HH:mm")}
                        xs={6} sm={6} md={6} lg={6} xl={6}
                        className={"text-right opacity-75"}>
                        {date.format(isSameDay ? "HH:mm" : "DD.MM.YY HH:mm")}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        className={"text-break pt-1 pb-1"}>
                        {
                            translation.map((key, i) =>
                                <span key={i} className={i > 0 ? "pr-1 text-lowercase" : "pr-1"}>
                                    <Trans
                                        i18nKey={key}
                                        // defaults={log.Codes[i]}
                                        // parent={Trim}
                                        values={params[i]}
                                        components={{italic: <i/>, bold: <strong/>}}
                                    />
                                    {(translation.length - 1 > i) && ";"}
                                    {(translation.length - 1 <= i) && "."}
                                </span>
                            )
                        }

                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={6}
                        className={"no-break-word text-left opacity-50"}
                    >
                        <LogDetails log={log}/>
                    </Col>
                    <Col
                        title={user.name}
                        xs={6}
                        className={"no-break-word text-right opacity-50"}
                    >
                        {user.name}
                    </Col>
                </Row>
            </ListItem>
        );
    }
}

export default LogItem;

