import React, {Component} from 'react';
import {connect} from 'react-redux';
import {flatten, get, isArray, isEqual} from "lodash";
import {ACTIONS, LIFECYCLE, STATUS} from "react-joyride";
import {withTranslation} from "react-i18next";
import {getKeyValueLogDict} from "../../../selectors/logSelector";
import {removeCode, setCode, setObjectId, setSearchText, setStartFrom, setUserID} from "../../../actions/logsActions";
import moment from "moment";
import StyledJoyride from "../../styled-joyride/StyledJoyride";
import LogTutorialDetails, {HelperIconID} from "./LogTutorialDetails";
import {withCookies} from "react-cookie";
import ResizableJumbotron from "../../basics/resizable-jumbotron/ResizableJumbotron";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        names: getKeyValueLogDict(state),
        objectId: state.logs.objectId,
        startFrom: state.logs.startFrom,
        searchCode: state.logs.searchCode,
        text: state.logs.search,
        userId: state.logs.userId
    };
}


const Types = {
    OBJ_ID: "objectId",
    USER_ID: "userId",
    SEARCH_CODE: "searchCode",
    START_FROM: "startFrom",
    TEXT: "text"
}

class LogTutorial extends Component {

    constructor(props) {
        super(props);
        this.cookie = this.getCookie();
        this.lastAction = "";
        this.key = "";
        this.state = {
            steps: [],
            run: false,
            stepIndex: 0,
            opened: false
        }
    }

    toggleOpen = () => {
        this.setState(state => ({
            opened: !state.opened
        }))
    }
    getCookie = () => {
        const {cookies} = this.props;
        const defaultCookie = {
            counters: {}
        };
        const cookie = cookies.get("tutorial-logs");
        try {
            return {
                ...defaultCookie,
                ...cookie
            }
        } catch (e) {
            return defaultCookie;
        }
    }

    getCounters = (data = []) => {
        console.time("eeee")
        const result = {
            counters: this.cookie.counters,
            tutorialsAvailable: data.length,
            tutorialsFinished: 0
        };
        data.forEach(({key}) => {
            if (result.counters[key]) {
                result.tutorialsFinished += 1;
            }
        })
        console.timeEnd("eeee")
        return result;
    }

    getData = () => {
        const {names, t} = this.props;
        const today = moment.utc().endOf("day");
        const codes = t([`logsView.shortNames`, "{}"], {returnObjects: true});
        return [
            {
                object: Object.entries(names.animals)[0],
                key: "animal",
                translationPath: "logsView.tutorialAnimal",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.devices)[0],
                key: "device",
                translationPath: "logsView.tutorialDevice",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.settings)[0],
                key: "setting",
                translationPath: "logsView.tutorialSetting",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.dictionaries.NAMES.DICTIONARY)[0],
                key: "dictionary",
                translationPath: "logsView.tutorialDictionary",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.dictionaries.NAMES.MEDICINE)[0],
                key: "medicine",
                translationPath: "logsView.tutorialMedicine",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.dictionaries.NAMES.CONTROLLIST)[0],
                key: "controlList",
                translationPath: "logsView.tutorialControlList",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.dictionaries.NAMES.GRAFTINGPROGRAM)[0],
                key: "graftingProgram",
                translationPath: "logsView.tutorialGraftingProgram",
                type: Types.OBJ_ID
            },
            {
                object: Object.entries(names.dictionaries.NAMES.INGREDIENT)[0],
                key: "ingredient",
                translationPath: "logsView.tutorialIngredient",
                type: Types.OBJ_ID
            },
            {
                object: [+today, today.format("DD.MM.YY")],
                key: "date",
                translationPath: "logsView.tutorialDate",
                type: Types.START_FROM
            },
            {
                object: Object.entries(names.employees).filter(([localUserId, name]) => name && (name !== "Service"))[0],
                key: "user",
                translationPath: "logsView.tutorialUser",
                type: Types.USER_ID
            },
            {
                object: Object.entries(codes).filter(([code, name]) => code && name)[0],
                key: "code",
                translationPath: "logsView.tutorialCode",
                type: Types.SEARCH_CODE
            },
            {
                object: Object.entries(names.buildings)[0],
                key: "building",
                translationPath: "logsView.tutorialBuilding",
                type: Types.OBJ_ID
            }
        ].filter(o => !!o.object).map(o => ({
            value: o.object[0],
            text: o.object[1],
            key: o.key,
            name: t(o.translationPath, {example: o.object[1]}),
            type: o.type
        }))
    }


    start = () => {
        // const {dispatch} = this.props;
        // dispatch(clearLogs());
        const data = this.getData().slice(0);
        const counters = (key) => this.cookie.counters[key] || 0;
        data.sort((o1, o2) => counters(o1.key) - counters(o2.key));
        const item = data[0];
        this.lastAction = "";
        this.key = item.key;
        const {t} = this.props;
        this.setState({
            run: true,
            stepIndex: 0,
            running: true,
            opened: false,
            steps: flatten([
                {
                    content: t("logsView.tutorialSearchBar"),
                    placement: "bottom",
                    target: "#log-search-input",
                    disableBeacon: true
                },
                {
                    content: t(item.name, {example: item.text}),
                    placement: "bottom",
                    target: "#log-search-input",
                    object: item.text,
                    type: Types.TEXT
                },
                {
                    content: t("logsView.clickSuggestionToApplyFilter"),
                    placement: "right",
                    // type: Types.OBJ_ID,
                    target: `#item-${item.value}`,
                    object: item.value,
                },
                {
                    content: t("logsView.clickToDeleteFilter"),
                    placement: "right",
                    target: `#selected-${item.value}`,
                    type: item.type,
                    object: item.value
                },
                {
                    content: t("logsView.clickIconToShowMoreInfo"),
                    placement: "left",
                    target: `#${HelperIconID}`,
                    type: item.type,
                    object: null
                }
            ]),
        })
    }

    stop = () => {
        this.setState({
            run: false,
            stepIndex: 0,
            running: false
        })
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        const {cookies} = this.props;
        cookies.set("tutorial-logs", this.cookie, {
            path: "/",
            expires: moment().add(1, "year").toDate()
        });
    }

    onCompleted = () => {
        console.log(this.key, "finished tutorial")
        if (this.key) {
            this.cookie.counters[this.key] = this.cookie.counters[this.key] ? this.cookie.counters[this.key] + 1 : 1;
        }

    }

    callback = (callback) => {
        console.log(callback, "CB")
        const {action, index, lifecycle, status, size} = callback;
        const {steps} = this.state;
        const {dispatch} = this.props;
        this.lastAction = action;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || [ACTIONS.CLOSE, ACTIONS.RESET].includes(action)) {
            this.stop();
        } else if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action) && (lifecycle === LIFECYCLE.COMPLETE)) {
            const nextIndex = index + (action === ACTIONS.NEXT ? 1 : -1);
            const step = steps[nextIndex];
            if (!step) {
                if (nextIndex === size) {
                    this.onCompleted();
                }
                this.stop();
                return;
            }
            this.setState({
                stepIndex: nextIndex,
                run: !step.object || ![Types.OBJ_ID, Types.SEARCH_CODE, Types.START_FROM, Types.USER_ID].includes(step.type)
            })
            const value = get(step, "object", "");
            switch (step.type) {
                case Types.TEXT:
                    dispatch(setSearchText(value));
                    break;
                case Types.USER_ID:
                    dispatch(setUserID(value || null));
                    break;
                case Types.START_FROM:
                    dispatch(setStartFrom(value || null));
                    break;
                case Types.SEARCH_CODE:
                    dispatch(nextIndex === 3 ? removeCode(steps[1].value) : setCode(value || null));
                    break;
                case Types.OBJ_ID:
                    dispatch(setObjectId(value || null));
                    break;
                default:
                    break;

            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {running, stepIndex, steps, run} = this.state;
        if (running) {
            if (!run) {
                const step = steps[stepIndex];
                if (!(step.type === Types.Text || !step.type)) {
                    if (isEqual(isArray(this.props[step.type]) ? [step.object] : step.object, this.props[step.type])) {
                        this.setState({
                            run: true
                        })
                    }
                }
            }
        }
    }

    render() {
        const {steps, run, stepIndex, opened} = this.state;
        const {t} = this.props;
        const data = this.getData();
        const {tutorialsFinished, tutorialsAvailable} = this.getCounters(data);
        return (
            <div>
                <ResizableJumbotron expanded={tutorialsFinished < tutorialsAvailable} icon={"fa-play fas"} text={t("logsView.tutorial")} onClick={this.start} description={t("logsView.tutorialCount", {number: tutorialsFinished, count: tutorialsAvailable})}/>
                <StyledJoyride
                    steps={steps}
                    autoStart={true}
                    stepIndex={stepIndex}
                    run={run}
                    continuous={true}
                    showSkipButton={false}
                    callback={this.callback}
                />
                <LogTutorialDetails toggleOpen={this.toggleOpen} opened={opened}/>
            </div>
        );
    }
}

LogTutorial = connect(
    mapStateToProps,
)(LogTutorial);

export default withCookies(withTranslation()(LogTutorial));
