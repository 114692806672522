import {setAnimalInsemination} from "../../../IOT/device-functions/DispenserNRFFunctions";
import {get, isFinite, isFunction, isNil} from "lodash";
import moment from "moment";
import {canChangeCurve, getCurveDayShowingType} from "../../../utils/FeedingUtils";
import {CurveDayShowingType} from "../../../constans/feedingTypes";

export function submit(values, dispatch, props, func = setAnimalInsemination, type = "INS") {
    console.log(arguments, "sususbmit")
    const {selectedNodes, receivers, handleHide, clearSelection, feedingCurves, reportData: {WST, NRF} = {}} = props;
    const dataDTM = {
        curveNr: WST.curve ? WST.curve.SetData.Index + 1 : 0,
        offset: 0,
        percentCorrection: 0,
        startTime: +moment().startOf("day").subtract(WST.day - 1, "days"),
        punishment: 0,
        stage: 0,
        type: type
    };
    const dataNRF = {
        curveNr: NRF.curve ? NRF.curve.SetData.Index + 1 : 0,
        offset: 0,
        percentCorrection: 0,
        startTime: getCurveDayShowingType(NRF.curve) === CurveDayShowingType.BOTH ? +moment().startOf("day") : +moment().startOf("day").subtract(NRF.day - 1, "days"),
        punishment: 0,
        stage: getCurveDayShowingType(NRF.curve) === CurveDayShowingType.BOTH ? 1 : 0,
        type: type
    };
    const isValid = ({DevID, number, rfid}) => {
        console.log(DevID, number, rfid, "isValid")

        if (rfid) {
            return canChangeCurve(selectedNodes.find(node => node.name === rfid), NRF.curve, NRF.day, feedingCurves);
        } else if (DevID && isFinite(number)) {
            return canChangeCurve(selectedNodes.find(node => node.receiver && ((node.receiver.deviceId === DevID) && (node.receiver.index === (number - 1)))), WST.curve, WST.day, feedingCurves);
        } else {
            return canChangeCurve(selectedNodes.find(node => node.receiver && ((node.receiver.deviceId === DevID) && (isNil(number)))), NRF.curve, NRF.day, feedingCurves);
        }
    };
    for (let [gatewayID, dispensers] of receivers.entries()) {
        const data = [];
        if (WST.curve) {
            if (dispensers.individualDTM.length) {
                dispensers.individualDTM.forEach((dev) => {
                    dev.outputs.forEach(out => {
                            if (isValid({DevID: dev.DevID, number: out.number})) {
                                data.push({
                                    ...out,
                                    feeding: {...dataDTM, number: out.number}
                                })
                            }
                        }
                    )
                })
            }
        }
        if (NRF.curve) {
            if (dispensers.groupNRF.length) {
                dispensers.groupNRF.forEach((loc) => {
                    loc.animals.forEach(animal => {
                        if (isValid({rfid: animal.RFID})) {
                            data.push({
                                PlcmntID: loc.LocID,
                                ...animal,
                                feeding: dataNRF
                            })
                        }
                    })
                })
            }
            if (dispensers.individualNRF.devices.length > 0) {
                const devices = dispensers.individualNRF.devices.filter(o => isValid({DevID: o}));

                dispensers.individualNRF.animals.filter(anm => {
                    const node = selectedNodes.find(n => n.id === anm.PlcmntID);
                    if (node) {
                        return devices.includes(get(node, "receiver.deviceId"));
                    }
                    return false;

                }).forEach(animal => {
                    data.push({
                        ...animal,
                        feeding: dataNRF
                    })
                })
            }
            if (data.length) {
                func(gatewayID, data);
            }
        }

    }
    if (isFunction(handleHide)) {
        handleHide();
    }
    if (isFunction(clearSelection)) {
        clearSelection();
    }
}
