import React from "react";
import {connectModal} from "redux-modal";
import {Field, reduxForm} from "redux-form";
import {Modal} from "react-bootstrap";
import {ModalHeader} from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {submit} from "./AddChamberSubmit";
import _ from "lodash"
import ReactGA from "react-ga"
import {withTranslation} from "react-i18next";

export const ModalName = 'add-chamber-modal';

export class AddChamberModal extends React.Component {

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
    }

    getChamberOptions() {
        const {chambers} = this.props;
        return _.cloneDeep(chambers.filter(chamber => !chamber.hasOwnProperty('forageID')) || []).map(chamber => {
            return {
                name: chamber.CName,
                value: chamber
            }
        });
    };

    render() {
        const {show, handleHide, handleSubmit, t} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("addChamber")} onCloseClick={handleHide}/>
                    <ModalBody>
                        <Field
                            name="chamber"
                            id="chamber"
                            type="text"
                            label={t("modals.addChamberModal.chamber")}
                            options={this.getChamberOptions()}
                            component={ReduxLabeledSelect}
                        />
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("add")}
                                 onCloseClick={handleHide} formName={ModalName}/>
                </form>
            </Modal>
        )
    }
}

AddChamberModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(AddChamberModal);
AddChamberModal = connectModal({name: ModalName})(AddChamberModal);

export default withTranslation()(AddChamberModal);