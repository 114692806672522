import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class LogDetailCodeHeader extends Component {
    render() {
        const {code, t} = this.props;
        const codes = t([`logsView.shortNames`, "{}"], {returnObjects: true});
        return (
            <h4 className={"text-break"}>
                {code.map(c=>codes[c] ? `${codes[+c]} (${c})` : `(${c})`).join(", ")}
            </h4>
        );
    }
}

LogDetailCodeHeader.propTypes = {
    code: PropTypes.array.isRequired
};

export default withTranslation()(LogDetailCodeHeader);
