import {addLanguage} from "../../../actions/localizationActions";
import {
    addTranslationsNotificationFailure,
    addTranslationsNotificationSuccess,
    updateTranslations
} from "../../../api/translations/updateTranslations";
import {ns} from "../../../i18n"

export function submit(values, dispatch, props) {
    const {code} = values;
    let data = ns.map(namespace => ({
        lang: code,
        path: namespace,
        value: {}
    }));
    return updateTranslations(data).then(res => {
        addTranslationsNotificationSuccess(res);
        dispatch(addLanguage(code));
        props.handleHide();
    }).catch(e => {
        addTranslationsNotificationFailure(e);
    })
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.code) {
        errors.code = t("required");
    }

    return errors;
}