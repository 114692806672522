import React from "react";
import {Field, reduxForm} from "redux-form";
import {validate} from "../CageWeightConfigurationModalSubmit";
import InfoBox from "../../../basics/info-box/InfoBox";
import ReduxLabeledInput from "../../../basics/input/labeled-input/ReduxLabeledInput";
import {getUnit} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {withTranslation} from "react-i18next";

const ModalName = "cage-weight-configurator";

export class CageWeightConfigurationModalPage2 extends React.Component {

    render() {
        const {handleSubmit} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <InfoBox boxColor="info">
                    {this.props.t("modals.cageWeightConfiguration.info2")}
                </InfoBox>
                <Field
                    name="weight"
                    component={ReduxLabeledInput}
                    type="number"
                    label={this.props.t("modals.cageWeightConfiguration.weight")}
                    unit={getUnit("weight", UnitTypes.MEDIUM)}
                />
                <div className="placeholder">
                    PLACEHOLDER
                </div>
            </form>
        );
    }

}

CageWeightConfigurationModalPage2 = reduxForm({
    form: ModalName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(CageWeightConfigurationModalPage2);

export default withTranslation()(CageWeightConfigurationModalPage2);