import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";

class UpdateScaleConfigurationDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const weightFormatter = (value) => convertWeightUnitTo(value, {
            showUnit: true,
            unit: UnitTypes.MEDIUM,
            fixed: 0
        });
        return [
            {
                name: t("newSettings.silo.generalSetup.sweepWeights"),
                value: has(obj, "sweepWeights") && get(obj, "sweepWeights")
            },
            {
                name: t("newSettings.silo.generalSetup.isPin"),
                value: has(obj, "isPin") && get(obj, "isPin")
            },
            {
                name: t("newSettings.silo.generalSetup.pin"),
                value: get(obj, "pin")
            },
            {
                name: t("newSettings.silo.generalSetup.flashDisplay"),
                value: has(obj, "flashDisplay") && get(obj, "flashDisplay")
            },
            ...new Array(8).fill(0).map((o,i)=>({
                name: t("newSettings.silo.generalSetup.minWeights", {type: i + 1}),
                value: has(obj, `minWeights[${i}].value`) && weightFormatter(get(obj, `minWeights[${i}].value`))
            }))
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o,i)=>({...o, valueBefore: dataB[i].value}))
        return (
           <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateScaleConfigurationDetail);
