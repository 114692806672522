import _ from "lodash";
import {setDispensersCondition} from "../../../IOT/device-functions/GatewayFunctions";

export function submit(values, dispatch, props) {
    const {selectedNodes, receivers, handleHide, clearSelection} = props;
    const {doseCorrection} = values;
    for (let [gatewayID, dispensers] of receivers) {
        const data = [];
        if (dispensers.groupNRF.length) {
            dispensers.groupNRF.forEach((loc) => {
                data.push({
                    PlcmntID: loc.LocID,
                    animals: loc.animals.map(animal => ({
                        ...animal,
                        correction: doseCorrection
                    }))
                })
            })
        }
        if (dispensers.individualDTM.length) {
            dispensers.individualDTM.forEach((dev) => {
                data.push({
                    DevID: dev.DevID,
                    dispensers: dev.outputs.map(out => ({
                        number: out.number,
                        AnmID: out.AnmID,
                        correction: doseCorrection
                    }))
                });
            })
        }
        if (dispensers.individualNRF.devices.length) {
            dispensers.individualNRF.devices.forEach(deviceId => {
                data.push({
                    DevID: deviceId,
                    AnmID: _.get(selectedNodes.find(o => o.animal && _.get(o, "receiver.deviceId") === deviceId), "animal.AnmID"),
                    correction: doseCorrection
                })
            });
        }
        if (data.length) {
            setDispensersCondition(gatewayID, {devices: data});
        }
    }
    handleHide();
    clearSelection();
}