import React from "react";
import {reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit} from "./FeedingEmptyForageModalSubmit"
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = 'feeding-empty-forage-modal';

class FeedingEmptyForageModal extends React.Component {

    render() {
        const {show, handleHide, t, handleSubmit} = this.props;

        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.feedingEmptyForageModal.title")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        {i18next.t("modals.feedingEmptyForageModal.description", {type: t("modals.feedingEmptyForageModal.start")})}
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("modals.feedingEmptyForageModal.start")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingEmptyForageModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired
};

FeedingEmptyForageModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(FeedingEmptyForageModal);
export const _FeedingEmptyForageModal = connect(state => ({
    buildings: state.farms.buildings,
}))(FeedingEmptyForageModal);
FeedingEmptyForageModal = connectModal({name: ModalName})(_FeedingEmptyForageModal);

export default withTranslation()(FeedingEmptyForageModal);
