import React from "react";
import EventData from "./EventData";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Field} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {convertWeightToBaseUnit, convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {withTranslation} from "react-i18next";

export class WeightingData extends React.Component {

    render() {
        const {member, weighting, lang, t} = this.props;
        return (
            <EventData member={member}>
                <Field
                    name={member ? `${member}.EvData.Reasn` : "EvData.Reasn"}
                    id={member ? `${member}.EvData.Reasn` : "EvData.Reasn"}
                    component={ReduxLabeledSelect}
                    label={t("reason")}
                    options={weighting ? weighting.WData[lang].map(reason => ({
                        name: reason.Value,
                        value: reason.ID
                    })) : []}
                />
                <Field
                    component={ReduxLabeledInput}
                    name={member ? `${member}.EvData.Weight` : "EvData.Weight"}
                    label={t("weight")}
                    unit={getUnit("weight", UnitTypes.MEDIUM)}
                    type="number"
                    format={value => value !== undefined ? convertWeightUnitTo(+value, {
                        unit: UnitTypes.MEDIUM,
                        rawValue: true
                    }) : null}
                    parse={value => value !== undefined ? convertWeightToBaseUnit(+value, {fromUnit: UnitTypes.MEDIUM}) : null}
                />
            </EventData>
        );
    }

}

WeightingData = connect((state, props) => ({
    weighting: state.dictionary.weighting,
    lang: state.language.lang.lang,
}))(WeightingData);

WeightingData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(WeightingData);