import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import {getFeedingUnit} from "../../../../utils/SettingsUtils";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";

class UpdateDispenserConfigurationDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const unit = getFeedingUnit();
        const weightFormatter = (value) => convertWeightUnitTo(value, {
            showUnit: true,
            unit: unit,
            fixed: unit ? 2 : 0
        });
        return [
            {
                name: t("newSettings.dispenserWST.feeding.maxSingleDose"),
                value: has(obj, "maxSingularDose") && weightFormatter(get(obj, "maxSingularDose"))
            },
            {
                name: t("newSettings.dispenserWST.feeding.timeBetweenDoses"),
                value: has(obj, "timeBetweenDoses") && `${(get(obj, "timeBetweenDoses"))}s`
            },
            {
                name: t("newSettings.dispenserWST.feeding.globalLock"),
                value: get(obj, "globalLock")
            },
            {
                name: t("newSettings.dispenserWST.feeding.overloadDetection"),
                value: get(obj, "overloadDetection")
            },
            {
                name: t("newSettings.dispenserWST.feeding.maxCurrentFluctuation"),
                value: has(obj, "maxCurrentFluctuation") && `${(get(obj, "maxCurrentFluctuation"))}mA`
            },
            {
                name: t("newSettings.dispenserWST.feeding.maxOverfluctuationTime"),
                value: has(obj, "maxOverfluctuationTime") && `${(get(obj, "maxOverfluctuationTime"))}ms`
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o,i)=>({...o, valueBefore: dataB[i].value}))

        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateDispenserConfigurationDetail);
