import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {taskPriorityValueFormatter} from "../../../utils/taskUtils";
import Checkbox from "../../basics/checkbox/Checkbox";
import "./_task-view-modal-body.scss";
import moment from "moment";
import {changeSubtaskStatus} from "../../../api/task/changeSubtaskStatus";

function mapStateToProps(state) {
    return {
        employees: state.user.employees
    };
}

class TaskViewModalBody extends Component {

    state = {
        loadingSubTasks: []
    }

    setLoadingStatus(index, status) {
        this.setState(state => {
            let sliced = state.loadingSubTasks.slice();
            sliced[index] = status;
            return {
                loadingSubTasks: sliced
            }
        })
    }

    async onCheckboxClick(index, status) {
        const {task} = this.props;
        try {
            this.setLoadingStatus(index, !status);
            await changeSubtaskStatus(task.IssueID, index, !status);
        } catch (e) {
            this.setLoadingStatus(index, false);
        }
    }

    employeeFormatter(value) {
        const {employees} = this.props;
        let employee = employees.find(item => item.LocalUserID === value);
        if (employee) {
            return employee.userName;
        }
    }

    render() {
        const {task, t} = this.props;
        const {loadingSubTasks} = this.state;
        return (
            <>
                <h4>{task.Title}</h4>
                <p>{task.Description}</p>
                <div>{t("eventGrid.operator")}: {this.employeeFormatter(task.AssignTo)}</div>
                <div>{t("priority")}: {taskPriorityValueFormatter(task.Priority)}</div>
                <div>{t("estimatedTime")}: {task.EstimatedTime}h</div>
                <div>{t("date")}: {moment(task.DtaPlanned).format("DD.MM.YYYY")}</div>
                {
                    task.ListTODO.length > 0 &&
                    <>
                        <h5>{t("list")}</h5>
                        <ul className="task-list-checkbox">
                            {
                                task.ListTODO.map((row, index) =>
                                    <li key={index}>
                                        <Checkbox label={row.Title}
                                                  checked={loadingSubTasks[index] !== undefined ? loadingSubTasks[index] : row.Done}
                                                  id={index}
                                                  onChange={() => this.onCheckboxClick(index, loadingSubTasks[index] !== undefined ? loadingSubTasks[index] : row.Done)}/>
                                    </li>
                                )
                            }
                        </ul>
                    </>
                }
            </>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(TaskViewModalBody);