import React, {Component} from 'react';
import JsonToTable from "../../basics/json-to-table/JsonToTable";
import {difference} from "./utils";
import {get, isNil, isObject} from "lodash";
import LogDetailCodeHeader from "./LogDetailCodeHeader";

export const SupportedTables = ["animals"];


class LogDetail extends Component {


    constructor(props) {
        super(props);
        const {log} = this.props;
        this.state = {diff: difference(log.ObjNew, this.isNull(log.ObjOld) ? log.ObjNew : log.ObjOld)};
    }


    cellRenderer = (value, {path, defaultRenderer}) => {
        const {diff} = this.state;
        const {log} = this.props;
        console.log(path, diff, value, "DKDK")
        if (!path || !get(diff, path)) return defaultRenderer(value)
        else return <>
            {
                !isNil(get(log.ObjOld, path)) && <>
                    <span className={"color-error"}>{defaultRenderer(get(log.ObjOld, path))}</span>→
                </>
            }
            <span
                className={"color-primary"}>{defaultRenderer(value)}</span>
        </>


    }

    isNull = (object) => !isObject(object) || !Object.keys(object).length;

    render() {
        const {log, code} = this.props;
        return (
            <div>
                <LogDetailCodeHeader code={code}/>
                {
                    (!this.isNull(log.ObjNew) && !this.isNull(log.ObjOld)) &&
                    <JsonToTable key={"tab-1"} json={log.ObjNew || {}} cellRenderer={this.cellRenderer}/>
                }
                {
                    (!this.isNull(log.ObjNew) && !!this.isNull(log.ObjOld)) &&
                    <JsonToTable key={"tab-2"} json={log.ObjNew || {}}/>
                }
            </div>
        );
    }
}

export default LogDetail;
