import {updateAuthorizations} from "../../../actions/userActions";
import store from "../../../store/store";

export function submit(values, dispatch, props) {
    console.log(values);
    const {user: {user: {ClientID, LocalUserID}}} = store.getState();
    const {localUserID, roles} = values;
    let user = {
        LocalUserID: localUserID,
        roles: roles,
    };
    dispatch(updateAuthorizations(user, ClientID, LocalUserID, () => {props.handleHide()}));
}

export function validate(values, props) {
    const errors = {};
    return errors;
}
