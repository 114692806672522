import {
    createSettlement,
    createSettlementNotificationFailure,
    createSettlementNotificationSuccess
} from "../../../api/feeding/Settlement";

export function submit(values, dispatch, props) {
    const {chamberId, handleHide} = props;
    let chamberIDs = Array.isArray(chamberId) ? chamberId : [chamberId];
    let promises = [];
    for (let cid of chamberIDs) {
        promises.push(createSettlement({PlcmntID: cid}));
    }
    return Promise.all(promises).then(r => {
        handleHide();
        createSettlementNotificationSuccess(r);
    }).catch(e => {
        createSettlementNotificationFailure(e);
    })
}