import {
    createSeparation,
    createSeparationNotificationFailure,
    createSeparationNotificationSuccess
} from "../../../api/feeding/Settlement";

export function submit(values, dispatch, props) {
    const {chamberId, handleHide} = props;
    const {piCnt} = values;
    return createSeparation({PlcmntID: chamberId, PiggletCnt: piCnt}).then(r => {
        handleHide();
        createSeparationNotificationSuccess(r);
    }).catch(e => {
        createSeparationNotificationFailure(e);
    })
}