import React from "react";
import EventData from "./EventData";
import PropTypes from "prop-types";
import {Field} from "redux-form";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import animalsDB from "../../../database/animalsDB";
import {connect} from "react-redux";
import {AnimalTypes} from "../../../constans/animalTypes";
import {withTranslation} from "react-i18next";

export class InseminationData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            boars: animalsDB.getAllAnimals(props.farm, AnimalTypes.BOAR, true).map(boar => ({
                name: boar.AnmNo1,
                value: boar.AnmID
            })) || []
        };
    }

    render() {
        const {member, t} = this.props;
        const {boars} = this.state;
        return (
            <EventData member={member}>
                <Field
                    name={member ? `${member}.EvData.BoarID` : "EvData.BoarID"}
                    component={ReduxLabeledSelect}
                    label={t("boar")}
                    options={boars}
                />
            </EventData>
        );
    }
}

InseminationData = connect(state => ({
    farm: state.location.farm,
}))(InseminationData);

InseminationData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(InseminationData);
