import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types"
import moment from "moment";
import {connect} from "react-redux";
import TableGrid from "../../basics/table-grid/TableGrid";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import Button from "../../basics/button/Button";
import "./_cage-expand-data.scss"
import Input from "../../basics/input/Input";
import ReactGA from "react-ga";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {withTranslation} from "react-i18next";

export const ModalName = "cage-expand-data";

export class CageExpandDataModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            date: moment.utc().startOf("day")
        }
        ReactGA.modalview(ModalName);
    }

    onDateChange = value => {
        let date = moment.utc(value);
        this.setState({
            date
        });
        this.checkIfNeedToGetAggregatedData(date.toDate().getTime());
    };

    weightValueFormatter = value => {
        return convertWeightUnitTo(value, {unit: UnitTypes.MEDIUM, showUnit: true, fixed: 2});
    };

    timeValueFormatter = value => {
        return moment(value).format("HH:mm");
    };

    getHeaders() {
        switch (this.props.type) {
            case "RFID":
                return [
                    {
                        name: this.props.t("currentWeight"),
                        field: "weight",
                        valueFormatter: this.weightValueFormatter
                    },
                    {
                        name: "RFID",
                        field: "RFID"
                    },
                    {
                        name: this.props.t("time"),
                        field: "time",
                        valueFormatter: this.timeValueFormatter
                    }
                ];
            case "Middle":
                return [
                    {
                        name: this.props.t("currentWeight"),
                        field: "W",
                        valueFormatter: this.weightValueFormatter
                    },
                    {
                        name: this.props.t("time"),
                        field: "T",
                        valueFormatter: this.timeValueFormatter
                    }
                ];
            default:
                return [];
        }
    }

    checkIfNeedToGetAggregatedData(time) {
        let aggData = this.props.cageData.get(this.props.cage.DevID);
        let hasData = !!aggData;
        if (hasData) {
            hasData = aggData.filter(item => item.AggTi === time).length > 0;
        }
        if (!hasData) {
            let obj = {
                AggDtTime: time
            };
            this.props.dispatch(getAggregatedData(this.props.cage, obj))
        }
    }

    getShadow() {
        try {
            return this.props.shadows.get(this.props.cage.DevID);
        } catch (e) {
            return undefined;
        }
    };

    getMiddlePasses() {
        try {
            const {date} = this.state;
            if (date.isSame(moment.utc(), "day")) {
                let shadow = this.getShadow();
                return shadow.ExpeditionMiddlePasses.slice(0).sort((a, b) => a.time - b.time);
            } else {
                let aggData = this.props.cageData.get(this.props.cage.DevID);
                let data = aggData.find(item => item.AggTi === date.toDate().getTime());
                return data.AggDt[0].ExpMidPass.slice(0).sort((a, b) => a.T - b.T);
            }
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getRFIDPasses() {
        try {
            const {date} = this.state;
            // odkomentowac po poprawieniu backendu
            // if (date.isSame(moment.utc(), "day")) {
            //     let shadow = this.getShadow();
            //     return shadow.RFIDPasses.slice(0).sort((a, b) => b.time - a.time);
            // } else {
                let aggData = this.props.cageData.get(this.props.cage.DevID);
                let data = aggData.find(item => item.AggTi === date.toDate().getTime());
                console.log(data);
                let rfids = [];
                for (let rfid of data.AggDt[0].RFIDPass) {
                    for (let pass of rfid.P) {
                        rfids.push({
                            weight: pass.W,
                            time: pass.T,
                            RFID: rfid.RFID
                        })
                    }
                }
                return rfids.sort((a, b) => b.time - a.time);
            // }
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    getData() {
        switch (this.props.type) {
            case "RFID":
                return this.getRFIDPasses();
            case "Middle":
                return this.getMiddlePasses();
            default:
                return [];
        }
    }

    getTitle() {
        switch (this.props.type) {
            case "RFID":
                return this.props.t("chamber.separationCageInfo.rfidPasses");
            case "Middle":
                return this.props.t("chamber.separationCageInfo.middlePasses");
            default:
                return "";
        }
    }

    checkIfHaveMiddleData(item) {
        return item.AggDt[0].ExpMidPass && item.AggDt[0].ExpMidPass.length > 0;
    }

    checkIfHaveRFIDData(item) {
        return item.AggDt[0].RFIDPass && item.AggDt[0].RFIDPass.length > 0;
    }

    findLowerDateWithData() {
        const {date} = this.state;
        const {cageData, cage} = this.props;
        let aggData = cageData.get(cage.DevID);
        let data = null;
        switch (this.props.type) {
            case "RFID":
                data = aggData.filter(item => item.AggTi < date.toDate().getTime() && this.checkIfHaveRFIDData(item)).sort((a, b) => b.AggTi - a.AggTi)[0];
                break;
            case "Middle":
                data = aggData.filter(item => item.AggTi < date.toDate().getTime() && this.checkIfHaveMiddleData(item)).sort((a, b) => b.AggTi - a.AggTi)[0];
                break;
            default:
                break;
        }
        if (data) {
            return moment(data.AggTi);
        }
        return date.clone().subtract(1, "day");
    }

    findHigherDateWithData() {
        const {date} = this.state;
        const {cageData, cage} = this.props;
        let aggData = cageData.get(cage.DevID);
        let data = null;
        switch (this.props.type) {
            case "RFID":
                data = aggData.filter(item => item.AggTi > date.toDate().getTime() && this.checkIfHaveRFIDData(item)).sort((a, b) => a.AggTi - b.AggTi)[0];
                break;
            case "Middle":
                data = aggData.filter(item => item.AggTi > date.toDate().getTime() && this.checkIfHaveMiddleData(item)).sort((a, b) => a.AggTi - b.AggTi)[0];
                break;
            default:
                break;
        }
        if (data) {
            return moment(data.AggTi);
        }
        return date.clone().add(1, "day");
    }

    onLowerDateClick = () => {
        let date = this.findLowerDateWithData();
        this.setState({
            date
        });
        this.checkIfNeedToGetAggregatedData(date.toDate().getTime());
    };

    onAddDateClick = () => {
        let date = this.findHigherDateWithData()
        this.setState({
            date
        });
        this.checkIfNeedToGetAggregatedData(date.toDate().getTime());
    };

    render() {
        const {show, handleHide} = this.props;
        const {date} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={this.getTitle()}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <div className="controls">
                        <Button icon={<i className="fas fa-chevron-left"/>} onClick={this.onLowerDateClick}/>
                        <Input type="date" value={date.format("YYYY-MM-DD")}
                               onChange={this.onDateChange}/>
                        <Button icon={<i className="fas fa-chevron-right"/>} onClick={this.onAddDateClick}/>
                    </div>
                    <TableGrid data={this.getData()} headers={this.getHeaders()} shouldIndex/>
                </ModalBody>
                <ModalFooter onCloseClick={handleHide}/>
            </Modal>
        );
    }

}

CageExpandDataModal.propTypes = {
    cage: PropTypes.object,
    type: PropTypes.string
};

export const _CageExpandDataModal = connect(state => ({
    cageData: state.aggregatedData.data,
    shadows: state.shadows.shadows,
}))(CageExpandDataModal);

CageExpandDataModal = connectModal({name: ModalName})(_CageExpandDataModal);

export default withTranslation()(CageExpandDataModal);