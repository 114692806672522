import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import {submit} from "./ChamberSettlementModalSubmit"
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {getLicenseByName} from "../../../selectors/roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import withRoles from "../../withRoles";
import {withTranslation} from "react-i18next";

export const ModalName = "chamber-settlement-modal";

export class ChamberSettlementModal extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
    }


    render() {
        const {show, submitting, handleHide, t, handleSubmit} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("settlement")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <p>{t("modals.chamberSettlementModal.settlementText")}</p>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        onCloseClick={handleHide}
                        submitting={submitting}
                        formName={ModalName}
                        confirmText={t("yes")}
                    />
                </form>
            </Modal>
        );
    }

}

ChamberSettlementModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(ChamberSettlementModal);

ChamberSettlementModal = connect(state => ({
    dispenserLicense: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))(ChamberSettlementModal);

ChamberSettlementModal = connectModal({name: ModalName})(ChamberSettlementModal);


ChamberSettlementModal = withRoles({
    clientPackage: {
        [LicPackageKeys.DISPENSER]: LicPackageLevel.BASIC
    }
})(ChamberSettlementModal);

export default withTranslation()(ChamberSettlementModal)