import _ from "lodash";
import {getCurveDayShowingType, prepareFeeding, setAnimalFeedingData} from "../../../utils/FeedingUtils";
import {DevType} from "../../../constans/devices";
import {setAnimal} from "../../../IOT/device-functions/DispenserNRFFunctions";
import moment from "moment";
import {CurveDayShowingType} from "../../../constans/feedingTypes";
import i18next from "i18next";

export function submit(values, dispatch, props) {
    const {receivers, handleHide, clearSelection} = props;
    const dataNRF = prepareFeeding(values, DevType.DISPENSER_NRF);
    for (let [gatewayID, dispensers] of receivers) {
        const data = setAnimalFeedingData(dispensers, dataNRF);
        if (data.length) {
            setAnimal(gatewayID, data);
        }
    }
    handleHide();
    clearSelection && clearSelection();
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const {curve, curveDay, repetitionDate} = values;
    if (_.isNil(curve)) {
        errors.curve = t("required");
    }
    if (!_.isFinite(curveDay)) {
        errors.curveDay = t("required");
    } else {
        const len = _.get(curve, "SetData.Days.length", 1);
        if ((curveDay < 1) || (curveDay > len)) {
            errors.curveDay = i18next.t("errors.mustBeInRange", {start: 1, end: len})
        }
    }

    if (!repetitionDate) {
        errors.repetitionDate = t("required");
    } else if (!_.isNil(curve)) {
        const showingType = getCurveDayShowingType(curve);
        const daysLen = _.get(curve, "SetData.Days.length", 0);
        const repetitionDay = _.get(curve, "SetData.InseminationJumpTo", 0);
        const repetition = moment(repetitionDate, "YYYY-MM-DD").startOf("day");
        const currentDay = moment().startOf("day");
        let daysFromNow = repetition.diff(currentDay, "days");
        console.log(daysFromNow, daysLen, repetition, currentDay);
        switch (showingType) {
            case CurveDayShowingType.BEFORE:
                if (daysFromNow > daysLen) {
                    errors.repetitionDate = i18next.t("errors.curveCantBeSetInFuturePleaseSetDateBelowX", {date: currentDay.clone().add(daysLen + 1, "days").format("DD.MM.YYYY")})

                }
                break;
            case CurveDayShowingType.AFTER: {
                if (daysFromNow > 0) {
                    errors.repetitionDate = i18next.t("errors.curveCantBeSetInFuturePleaseSetDateBelowX", {date: currentDay.clone().add(1, "days").format("DD.MM.YYYY")})
                }
                break;
            }
            case CurveDayShowingType.BOTH:
                if (daysFromNow >= (repetitionDay)) {
                    errors.repetitionDate = i18next.t("errors.curveCantBeSetInFuturePleaseSetDateBelowX", {date: currentDay.clone().add(repetitionDay, "days").format("DD.MM.YYYY")})
                }
                break;
            case CurveDayShowingType.NORMAL:
            default:
                break;
        }
    }
    return errors;
}
