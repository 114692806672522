import ControlList from "../../../beans/ControlList";
import _ from "lodash";
import {updateDictionaryDynamoDB} from "../../../actions/dictionaryActions";
import moment from "moment";

export function submit(values, dispatch, props) {
    const {user} = props;
    let list = new ControlList(_.cloneDeep(values));
    delete list.WData.isArray;
    list.setDtaModTime();
    dispatch(updateDictionaryDynamoDB(list.prepareBeanToSave(), user.ClientID, user.LocalUserID));
    props.handleHide();
}

export function validate(values, props) {
    const errors = {
        WData: {}
    };
    const {t} = props;

    if (values.WData) {
        if (!values.WData.StartDay) {
            errors.WData.StartDay = t("required")
        }

        if (!values.WData.EndDay) {
            errors.WData.EndDay = t("required")
        }

        if (values.WData.StartDay && values.WData.EndDay && values.WData.StartDay > values.WData.EndDay) {
            errors.WData.StartDay = t("errors.mustBeBelowOrEqualStartTime") + moment(values.WData.EndDay).format("DD.MM.YYYY");
        }
    }

    return errors;
}