import React from "react";
import {connectModal} from "redux-modal";
import {Field, FormSection, formValueSelector, reduxForm} from "redux-form";
import {Modal} from "react-bootstrap";
import {ModalHeader} from "../ModalHeader";
import ModalBody from "../ModalBody";
import {connect} from "react-redux";
import ModalFooter from "../ModalFooter";
import {submit, validate} from "./MedicineModalSubmit";
import ReactGA from "react-ga"
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {medicineTypes} from "../../../constans/medicine";
import ColorPicker from "../../redux-color-picker/ColorPicker";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = 'medicine-modal';

export class MedicineModal extends React.Component {

    constructor(props) {
        super(props);
        const {edit, medicine} = props;
        if (edit) {
            this.props.initialize(medicine);
        }
        ReactGA.modalview(ModalName);
    }

    onTypeChange = (event, newValue) => {
        console.log(newValue);
        let unit;
        switch (newValue) {
            case medicineTypes.FORAGE:
                unit = "g/100kg";
                break;
            case medicineTypes.VACCINE:
                unit = "ml/100kg";
                break;
            default:
                unit = null;
        }
        this.props.change("WData.Unit", unit);
    };

    render() {
        const {show, handleHide, handleSubmit, type, edit, medicine, t} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={edit ? i18next.t("modals.medicineModal.title2", {type: medicine.WData.Name}) : t("modals.medicineModal.title1")}
                        onCloseClick={handleHide}/>
                    <ModalBody>
                        <FormSection name="WData">
                            <Field
                                name="Name"
                                component={ReduxLabeledInput}
                                type="text"
                                label={t("designation")}
                                id="name"
                            />
                            <Field
                                name="Dose"
                                component={ReduxLabeledInput}
                                type="number"
                                label={t("dosage")}
                                id="dose"
                                min={1}
                            />
                            <Field
                                name="Type"
                                component={ReduxLabeledSelect}
                                label={t("modals.medicineModal.type")}
                                options={[
                                    {
                                        name: t("modals.medicineModal.stimulator"),
                                        value: medicineTypes.STIMULATOR
                                    },
                                    {
                                        name: t("medicalFeed"),
                                        value: medicineTypes.FORAGE
                                    },
                                    {
                                        name: t("modals.medicineModal.vaccine"),
                                        value: medicineTypes.VACCINE
                                    },
                                    {
                                        name: t("dosatron"), // prawdopodobnie sie zmieni
                                        value: medicineTypes.DOSATRON
                                    }
                                ]}
                                onChange={this.onTypeChange}
                                init={true}
                            />
                            {
                                (type === medicineTypes.STIMULATOR || type === medicineTypes.DOSATRON) &&
                                <Field
                                    name="Unit"
                                    component={ReduxLabeledSelect}
                                    label={t("unit")}
                                    options={[
                                        {
                                            name: "ml/100kg",
                                            value: "ml/100kg"
                                        },
                                        {
                                            name: "g/100kg",
                                            value: "g/100kg"
                                        }
                                    ]}
                                    init={true}
                                />

                            }
                            <Field
                                name="GracePeriod"
                                component={ReduxLabeledInput}
                                type="number"
                                label={t("modals.medicineModal.gracePeriod")}
                                unit={t("days")}
                                id="gracePeriod"
                                min={1}
                            />
                            <Field
                                name="Price"
                                component={ReduxLabeledInput}
                                type="number"
                                label={t("price")}
                                id="price"
                                min={1}
                            />
                            <label>{t("color")}</label>
                            <Field
                                name="Color"
                                component={ColorPicker}
                            />
                        </FormSection>
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("save")} onCloseClick={handleHide}
                                 formName={ModalName}/>
                </form>
            </Modal>
        )
    }
}

MedicineModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(MedicineModal);
MedicineModal = connectModal({name: ModalName})(MedicineModal);
const selector = formValueSelector(ModalName);
MedicineModal = connect((state) => ({
    user: state.user,
    type: selector(state, "WData.Type")
}))(MedicineModal);
export default withTranslation()(MedicineModal);