import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, formValueSelector, isDirty, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import {connect} from "react-redux";
import {submit} from "./ClimateMatsModalSubmit";
import ReactGA from "react-ga";
import {get, maxBy} from "lodash"
import {withTranslation} from "react-i18next";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";

export const ModalName = "climate-mats-modal";


function makeMapStateToProps(initialState, initialProps) {
    const getShadowByDevice = makeGetShadowByDevice();
    const {climates} = initialProps;
    const params = {DevID: climates[0].DevID}
    const selector = formValueSelector(ModalName);
    return function mapStateToProps(state) {
        return {
            ...getShadowByDevice(state, params),
            dirty: isDirty(ModalName)(state),
            selectedMat: selector(state, "selectedMat"),
            maximumMats: selector(state, "maximumMats")
        };
    }
}

export class ClimateMatsModal extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
    }


    componentDidMount() {
        const {initialize} = this.props;
        initialize(this.getValues());
    }

    getValues = () => {
        const {shadow, climates, selectedMat = 0} = this.props;
        const maximumMats = maxBy(climates, (o) => o.getMatsSize()).getMatsSize();
        const _selectedMat = Math.min(selectedMat || 0, maximumMats)
        return {
            day: get(shadow, `mats[${selectedMat}].day`, 0),
            deviation: get(shadow, `mats[${selectedMat}].deviation`, 0),
            selectedMat: _selectedMat,
            maximumMats
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {initialize, selectedMat} = this.props;
        if (selectedMat !== prevProps.selectedMat) {
            initialize(this.getValues());
        }
    }

    getOptions = () => {
        const {maximumMats = 0, t} = this.props;
        const options = [];
        for (let i = 0; i < maximumMats; i++) {
            options.push({
                value: i,
                name: t("farmView.climate.matX", {number: i + 1})
            })
        }
        return options;
    }

    ventilationFormatter = (value) => `${(value || 0).toFixed(1)}%`

    render() {
        const {show, handleHide, t, initialized, submitting} = this.props;
        const options = this.getOptions();
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("farmView.climate.matSettings")}
                    onCloseClick={handleHide}/>
                <ModalBody className={`${ModalName} mh-5rem`} isLoading={!initialized}>
                    <Field
                        name={"selectedMat"}
                        id={"selectedMat"}
                        label={t("farmView.climate.mat")}
                        options={options}
                        component={ReduxLabeledSelect}
                    />
                    <Field
                        name={"day"}
                        id={"day"}
                        label={t("curveDay")}
                        min={0}
                        max={99}
                        step={1}
                        component={ReduxLabeledSlider}
                    />
                    <Field
                        name={"deviation"}
                        id={"deviation"}
                        label={t("level")}
                        min={-99}
                        max={99}
                        step={1}
                        valueFormatter={this.ventilationFormatter}
                        component={ReduxLabeledSlider}
                    />
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    submitting={submitting}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

ClimateMatsModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(ClimateMatsModal);

ClimateMatsModal = connect(makeMapStateToProps)(ClimateMatsModal);

ClimateMatsModal = connectModal({name: ModalName})(ClimateMatsModal);

export default withTranslation()(ClimateMatsModal);
