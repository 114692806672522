import React from "react";
import {Field, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./UserEditRoleSubmit";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import _ from "lodash";
import ReduxRolePicker from "../../users/role-picker/ReduxRolePicker";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import {getAllBuildings} from "../../../actions/farmsActions";
import {getFarmIfUserHasUserManagementRole} from "../../../selectors/farmSelector";

export const ModalName = 'settings-user-edit-role-modal';

class UserEditRoleModal extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, employee} = this.props;
        const farmID = props.farms.length > 0 ? props.farms[0].FarmID : "";
        dispatch(getAllBuildings(farmID));
        dispatch(initialize(ModalName, {
            farm: farmID,
            localUserID: _.get(employee, 'LocalUserID'),
            roles: _.get(employee, 'roles', [])
        }));
        ReactGA.modalview(ModalName);
    }

    onChange = (event, newValue) => {
        const {dispatch} = this.props;
        dispatch(getAllBuildings(newValue));
    };

    render() {
        const {updatingAuthorizations, show, handleHide, t, farms, selectedFarm, handleSubmit, employee: {userType}} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.userEditRoleModal.editRoles")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <Field
                            name="farm"
                            component={ReduxLabeledSelect}
                            label={t("modals.userEditRoleModal.farm")}
                            options={farms.map(farm => ({
                                name: farm.FarmName,
                                value: farm.FarmID
                            }))}
                            onChange={this.onChange}
                        />
                        <Field
                            name="roles"
                            id="roles"
                            required
                            component={ReduxRolePicker}
                            label={t("roles")}
                            farm={selectedFarm}
                            selectedUserType={userType}
                        />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("save")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                        submitting={updatingAuthorizations}
                    />
                </form>
            </Modal>
        );
    }
}

UserEditRoleModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(UserEditRoleModal);

const selector = formValueSelector(ModalName);
export const _UserEditRoleModal = connect(state => ({
    farm: state.location.farm,
    farms: getFarmIfUserHasUserManagementRole(state),
    updatingAuthorizations: state.user.updatingAuthorizations,
    chamberIndividualFeeding: !!selector(state, 'chamberIndividualFeeding'),
    selectedFarm: selector(state, 'farm'),
}))(UserEditRoleModal);

export default withTranslation()(connectModal({name: ModalName})(_UserEditRoleModal));

