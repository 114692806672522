import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {minutesFormatter} from "../../../../views/new-settings-view/settings/climate-sk-3/settings/utils";

class UpdateChainFeedingWorkType extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const workTypeFormatter = (value) => {
            return t(`deviceRows.chainFeeding.chainFeedingRow.workTypes.${["inactive", "manual", "automatic", "schedule"][value]}`);
        }
        const sensorFormatter = (value) => {
            return t(`modals.chainFeedingConfigureModal.sensor`, {number: value + 1})
        }
        return [
            {
                name: t("workType"),
                value: has(obj, "workType") ? workTypeFormatter(get(obj, "workType")) : null
            },
            {
                name: t("modals.chainFeedingConfigureModal.excessWorkTime"),
                value: has(obj, "excessWorkTime") ? minutesFormatter(get(obj, "excessWorkTime")) : null
            },
            {
                name: t("modals.chainFeedingConfigureModal.maxWorkTime"),
                value: has(obj, "maxWorkTime") ? minutesFormatter(get(obj, "maxWorkTime")) : null
            },
            {
                name: t("modals.chainFeedingConfigureModal.activeSensor"),
                value: has(obj, "sensorID") ? sensorFormatter(get(obj, "sensorID")) : null
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateChainFeedingWorkType);
