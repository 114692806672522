import Treatment from "../../../beans/events/Treatment";
import {updateEvent} from "../../../actions/eventsActions";

export function submit(values, dispatch, props) {
    const {user} = props;
    let treatment = new Treatment(values);
    treatment.setDtaModTime();
    dispatch(updateEvent(treatment.prepareBeanToSave(), user.ClientID, user.LocalUserID));
    props.handleHide();
}

export function validate(values, props) {
    const error = {
        EvData: {}
    };
    const {t} = props;

    if (values.EvData && (!values.EvData.Treatment || !values.EvData.Treatment.length)) {
        error.EvData.Treatment = {_error: t("required")};
    }

    return error;
}