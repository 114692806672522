import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import moment from "moment";
import PassageChart from "../../charts/PassageChart";
import "./_compare-day-data.scss"
import Button from "../../basics/button/Button";
import Input from "../../basics/input/Input";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";

export const ModalName = "compare-day-data";

export class CompareDayDataModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            day1: moment.utc().startOf("day"),
            day2: moment.utc().subtract(1, "day").startOf("day")
        }
        ReactGA.modalview(ModalName);
    }

    setData(value, name) {
        if (value.toDate().getTime() <= moment.utc().startOf("day").toDate().getTime()) {
            this.setState({
                [name]: moment.utc(value, "YYYY-MM-DD")
            })
        }
    }

    onSubtractDay(name) {
        let day = this.state[name].clone();
        day.subtract(1, "day");
        this.setData(day, name);
    }

    onAddDay(name) {
        let day = this.state[name].clone();
        day.add(1, "day");
        this.setData(day, name);
    }

    onDayChange(value, name) {
        this.setData(moment.utc(value, "YYYY-MM-DD"), name);
    }

    createDatePicker(number) {
        return (
            <div>
                <label>Dzień {number}</label>
                <div className="compare-day-data-controls-container">
                    <Button icon={<i className="fas fa-chevron-left"/>} onClick={() => this.onSubtractDay(`day${number}`)}/>
                    <Input type={"date"} value={this.state[`day${number}`].format("YYYY-MM-DD")}
                           onChange={value => this.onDayChange(value, `day${number}`)}/>
                    <Button icon={<i className="fas fa-chevron-right"/>} onClick={() => this.onAddDay(`day${number}`)}/>
                </div>
            </div>
        )
    }

    render() {
        const {show, handleHide, chart, device, t} = this.props;
        const {day1, day2} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.compareDaysModal.title")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <div className="compare-day-data-controls">
                        {this.createDatePicker(1)}
                        {this.createDatePicker(2)}
                    </div>
                    <div className="compare-day-data-chart">
                        {
                            chart === "passes" &&
                            <PassageChart cage={device} date={day1} applyShadow={true} shadowDate={day2}/>
                        }
                    </div>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

CompareDayDataModal = connectModal({name: ModalName})(CompareDayDataModal);

export default withTranslation()(CompareDayDataModal);