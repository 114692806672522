import {setMarkingConfig} from "../../../IOT/device-functions/SeparationCageFunctions";

export function submit(values, dispatch, props) {
    console.log(values, props);
    const data = {
        number: values.marker-1,
        time: values.time,
        workType: values.exit,
        minWeight: values.weightRange[0],
        maxWeight: values.weightRange[1]
    };
    console.log(data);
    setMarkingConfig(props.cages, data);
}