import React from "react";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {Modal} from "react-bootstrap";
import ModalFooter from "../ModalFooter";
import {Field, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import moment from "moment";
import {submit, validate} from "./CreateInsertionModalSubmit"
import {connect} from "react-redux";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import {getLocationID} from "../../../utils/BuildingUtils";
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";

export const ModalName = "create-insertion-moal";

export class CreateInsertionModal extends React.Component {

    state = {
        hideDate: false
    }

    constructor(props) {
        super(props);

        const {settlements, cages} = props;
        let hideDate = true;
        for (let cage of cages) {
            let location = cage.getLocation();
            let locID = getLocationID(location[0]);
            let settlement = settlements.find(item => item.PlcmntID === locID && !item.DtaEndTime);
            if (!settlement) hideDate = false;
        }
        this.state.hideDate = hideDate;

        this.props.initialize({
            date: moment().format("YYYY-MM-DD"),
            number: 400,
            weight: convertWeightUnitTo(40000, {
                unit: UnitTypes.MEDIUM,
                fixed: 1,
                rawValue: true
            })
        })
        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, handleHide, handleSubmit} = this.props;
        const {hideDate} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={this.props.t("modals.createInsertionModal.title")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        {
                            !hideDate &&
                            <Field
                                name="date"
                                component={ReduxLabeledInput}
                                label={this.props.t("startDate")}
                                type="date"
                            />
                        }
                        <Field
                            name="sendToDevice"
                            component={ReduxCheckbox}
                            label={this.props.t("modals.createInsertionModal.sendToDevice")}
                        />
                        <Field
                            name="number"
                            component={ReduxLabeledInput}
                            label={this.props.t("modals.createInsertionModal.animalNumber")}
                            type="number"
                        />
                        <Field
                            name="weight"
                            component={ReduxLabeledInput}
                            label={this.props.t("averageWeight")}
                            type="number"
                            unit={getUnit("weight", UnitTypes.MEDIUM)}
                        />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={this.props.t("save")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

CreateInsertionModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(CreateInsertionModal);

CreateInsertionModal = connect(state => ({
    settlements: state.settlement.settlement
}))(CreateInsertionModal);
CreateInsertionModal = connectModal({name: ModalName})(CreateInsertionModal);

export default withTranslation()(CreateInsertionModal);