import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LogDetailCodeHeader from "../LogDetailCodeHeader";
import TableGrid from "../../../basics/table-grid/TableGrid";
import {getRowClassName, universalFormatter} from "../utils";
import DefaultMobileRow from "../../../basics/table-grid/default-mobile-row/DefaultMobileRow";
import {withTranslation} from "react-i18next";

class TemplateBeforeAfterTable extends Component {
    render() {
        const {code, data, t} = this.props;
        const headers = [
            {
                name: t("designation"),
                field: "name",
                colWidth: 2
            },
            {
                name: t("before"),
                field: "valueBefore",
                valueFormatter: universalFormatter
            },
            {
                name: t("after"),
                field: "value",
                valueFormatter: universalFormatter
            }
        ]
        return (
            <>
                <LogDetailCodeHeader code={code}/>
                <TableGrid rowClassName={getRowClassName} data={data} headers={headers} mobileRow={<DefaultMobileRow/>}/>
            </>
        );
    }
}

TemplateBeforeAfterTable.propTypes = {
    data: PropTypes.array.isRequired,
    code: PropTypes.array.isRequired
};

export default withTranslation()(TemplateBeforeAfterTable);
