import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {controlLists, controlListsPeriods} from "../../../../constans/controlLists";
import {flatten, get, has, isArray} from "lodash";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {dateFormatter} from "../utils";

class UpdateControlListDetail extends Component {


    getData = (obj, overridePeriod = false) => {
        const {t} = this.props;
        const periodFormatter = (value) => {
            const types = {
                [controlListsPeriods.END_OF_MONTH]: t("modals.editControlListSettingsModal.endOfMonth"),
                [controlListsPeriods.X_DAYS]: t("modals.editControlListSettingsModal.xDays"),
                [controlListsPeriods.TO_DAY_IN_MONTH]: t("modals.editControlListSettingsModal.dayOfMonth"),
                [controlListsPeriods.RANGE]: t("modals.editControlListSettingsModal.range"),
            }
            return types[value];
        }
        const Period = get(obj, "WData.Period");
        const WordID = get(obj, "WordID");
        return flatten([
            {
                name: t("modals.editControlListSettingsModal.period"),
                value: has(obj, "WData.Period") && periodFormatter(get(obj, "WData.Period"))
            },
            overridePeriod || Period === "0" ? {
                name: t("numberOfDays"),
                value: has(obj, "WData.Day") ? get(obj, "WData.Day") : null
            } : null,
            overridePeriod || Period === "2" ? {
                name: t("modals.editControlListSettingsModal.monthDay"),
                value: has(obj, "WData.MonthDay") ? get(obj, "WData.MonthDay") : null
            } : null,
            overridePeriod || Period === "3" ? {
                name: t("modals.editControlListSettingsModal.startDay"),
                value: has(obj, "WData.StartDay") ? dateFormatter(get(obj, "WData.StartDay")) : null
            } : null,
            overridePeriod || Period === "3" ? {
                name: t("modals.editControlListSettingsModal.endDay"),
                value: has(obj, "WData.EndDay") ? dateFormatter(get(obj, "WData.EndDay")) : null
            } : null,
            (overridePeriod || (WordID === controlLists.INSEMINATIONS)) ? {
                name: t("modals.editControlListSettingsModal.daysToHeat"),
                value: has(obj, "WData.InseminationTime")
                    ?
                    isArray(get(obj, "WData.InseminationTime"))
                        ? `${dateFormatter(get(obj, "WData.InseminationTime[0]"))} - ${dateFormatter(get(obj, "WData.InseminationTime[1]"))}` : dateFormatter(get(obj, "WData.InseminationTime"))
                    : null
            } : null,
            (overridePeriod || (WordID === controlLists.REMANENT)) ? {
                name: t("modals.editControlListSettingsModal.defaultActiveNipples"),
                value: has(obj, "WData.DefaultActiveNipples")
                    ?
                    get(obj, "WData.DefaultActiveNipples")
                    : null
            } : null,
            (overridePeriod || (WordID === controlLists.CONDITION)) ? new Array(get(obj, "WData.ConditionDays.length", 0)).fill(0).map((o, i) => ({
                name: t("modals.editControlListSettingsModal.dayAfterInsemination", {amount: i + 1}),
                value: has(obj, `WData.ConditionDays[${i}]`) ? get(obj, `WData.ConditionDays[${i}]`) : null
            })) : [],
            (overridePeriod || (WordID === controlLists.USG)) ? new Array(get(obj, "WData.USGDays.length", 0)).fill(0).map((o, i) => ({
                name: t("modals.editControlListSettingsModal.dayAfterInsemination", {amount: i + 1}),
                value: has(obj, `WData.USGDays[${i}]`) ? get(obj, `WData.USGDays[${i}]`) : null
            })) : [],
        ]).filter(o => !!o);
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld, true)
        const merged = dataA.map((o, i) => ({
            ...o,
            valueBefore: get(dataB.find(before => o.name === before.name), "value")
        }))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );

    }
}

export default withTranslation()(UpdateControlListDetail);
