import React from "react";
import {connect} from 'react-redux'
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import TableGrid from "../../basics/table-grid/TableGrid";
import moment from "moment";
import Chart from "../../basics/chart/Chart";
import {getFeedingUnit, isUsingFakeData} from "../../../utils/SettingsUtils";
import {cloneDeep, get, isNil} from "lodash";
import {UnitTypes} from "../../../constans/unitTypes";
import ReactGA from "react-ga";
import {FeedingType} from "../../../constans/feedingTypes";
import {getSettlement} from "../../../selectors/settlementSelector";
import {getFeedingRFIDDevices} from "../../../selectors/feedingSelector";
import NewIOT from "../../../IOT/NewIOT";
import {getAggregatedData} from "../../../actions/aggregatedActions";
import LoadingComponent from "../../loading/LoadingComponent";
import {getLicenseByName} from "../../../selectors/roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import InfoBox from "../../basics/info-box/InfoBox";
import TextSwitch from "../../basics/text-switch/TextSwitch";
import {withTranslation} from "react-i18next";
import {sortDateStrings} from "../../../utils/SortUtils";
import {getFakeDataHistoryModal} from "../../../demo/feeding/fakeFunctions";

export const ModalName = 'feeding-history-modal';

class FeedingHistoryModal extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.unit = getFeedingUnit();
        const {selectedNodes = [], handleHide, license} = this.props;
        //jesli wybral wiecej niz 1 zwierze
        if ((selectedNodes.length !== 1)) {
            handleHide();
        }
        this.state = {
            loading: isUsingFakeData() ? false : license === LicPackageLevel.EXTENDED,
            showChart: true,
            devices: license === LicPackageLevel.EXTENDED ? this.getDeviceIDs() : []
        };
        ReactGA.modalview(ModalName);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const newLoading = FeedingHistoryModal.checkLoading(prevState, nextProps);
        if (prevState.loading !== newLoading) {
            return {
                loading: newLoading
            }
        }
        return null;
    }

    static checkLoading = (state, props) => {
        if (isUsingFakeData()) return false;
        const {devices} = state;
        const {license} = props;
        if (license !== LicPackageLevel.EXTENDED) return false;
        for (let i = 0; i < devices.length; i++) {
            if (props.fetchingMap.get(isNil(devices[i].index) ? devices[i].DevID : `${devices[i].DevID}_${devices[i].index}`)) {
                return true;
            }
        }
        return false;
    };

    getParams = () => {
        //todo: 1341 usunać zasiedlenie aż do czasu jego działania
        // const {settlement} = this.props;
        const params = {
            AggDataTimeFrom: +moment.utc().startOf("day").subtract(30, "days"),
            AggDataTimeTo: +moment.utc().startOf("day")
        };
        // if (settlement && settlement.data) {
        //     params.AggDataTimeFrom = +moment(settlement.data.DtaStartTime).utc().startOf("day");
        //     params.AggDataTimeTo = +moment(settlement.data.DtaEndTime).utc().startOf("day");
        // }
        return params;
    };

    componentDidMount() {
        if (!isUsingFakeData()) {
            const {devices} = this.state;
            const {dispatch, data, license} = this.props;
            if (license === LicPackageLevel.EXTENDED) {
                const params = this.getParams();
                devices.forEach(({device, index}) => {
                    let id = device.DevID;
                    if (!isNil(index)) {
                        params.index = index;
                        id = `${id}_${index}`;
                    }
                    NewIOT.sendRequestForAggregatedData(device);
                    let shouldFetch = false;
                    if (data.has(id)) {
                        const diff = Math.abs(params.AggDataTime ? 0 : moment(params.AggDataTimeFrom).diff(moment(params.AggDataTimeTo), "days")) + 1;
                        const currData = this.filterData(data.get(id),
                            params.AggDataTime ? params.AggDataTime : params.AggDataTimeFrom,
                            params.AggDataTime ? params.AggDataTime : params.AggDataTimeTo);
                        if (diff !== currData.length) {
                            shouldFetch = true;
                        }
                    } else {
                        shouldFetch = true;
                    }
                    if (shouldFetch) {
                        dispatch(getAggregatedData(device, params))

                    }
                });
            }
        }

    }

    getDeviceIDs = () => {
        const {type, selectedNodes} = this.props;
        const awaitingDevIDs = [];
        const getObj = (node) => ({
            device: get(node, "device"),
            DevID: get(node, "receiver.deviceId"),
            GwID: get(node, "receiver.gatewayId"),
            index: get(node, "receiver.index")
        });
        if (type === FeedingType.INDIVIDUAL) {
            awaitingDevIDs.push(getObj(selectedNodes[0]));
        } else {
            const {rfidDevices} = this.props;
            for (const node of rfidDevices) {
                awaitingDevIDs.push(getObj(node));
            }
        }
        return awaitingDevIDs;
    };

    filterData = (data, start, stop) => !data ? [] : data.filter(d => (d.AggTi >= start) && (d.AggTi <= stop));

    getData = () => {
        const {license} = this.props;
        if (isUsingFakeData()) return getFakeDataHistoryModal();
        if (license === LicPackageLevel.EXTENDED) {
            return this.getDataExtended();
        }
        return this.getDataSimple();
    };

    /**
     * przygotowanie danych z shadowa
     * @return {*}
     */
    getDataSimple = () => {
        const {selectedNodes} = this.props;
        const lastWeekHistory = cloneDeep(get(selectedNodes, "[0].lastWeekHistory", [])).map(o => ({
            plannedUsage: o.plannedUsage || 0,
            usage: o.usage || 0,
            time: o.date,
            name: moment(o.date).format("DD.MM.YY")

        }));
        lastWeekHistory.sort((o2, o1) => o1.time - o2.time);
        return lastWeekHistory;
    };

    /**
     * przygotowanie danych z agg data
     * @return {unknown[]}
     */
    getDataExtended = () => {
        const params = this.getParams();
        const {devices} = this.state;
        const {data} = this.props;
        const result = {};
        const aggData = [];
        devices.forEach(({DevID, index}) => {
            let aggDevID = isNil(index) ? DevID : `${DevID}_${index}`;
            aggData.push(...this.filterData(data.get(aggDevID), params.AggDataTime ? params.AggDataTime : params.AggDataTimeFrom, params.AggDataTime ? params.AggDataTime : params.AggDataTimeTo));
        });
        aggData.forEach(row => {
            const {type, selectedNodes} = this.props;
            let usage = 0;
            let plannedUsage = 0;
            let time = row.AggTi;
            if (type === FeedingType.INDIVIDUAL) {
                const AnmID = get(selectedNodes, "[0].animal.AnmID");
                switch (row.AggDt.length) {
                    //jesli nie mamy danych to 0
                    case 0:
                        break;
                    //jesli jest jedna dana to znaczy ze raczej stanowisko bylo uruchomione i na slepo przypisujemy
                    case 1:
                        plannedUsage = row.AggDt[0].PCons;
                        usage = row.AggDt[0].Cons.reduce((b, a) => (a.C || 0) + b, 0);
                        break;
                    //jesli jest wiecej niz 1 tzn ze mozliwe ze jest karmienie rfid i wtedy tylko dla AnmID poszukujemy
                    default:
                        if (AnmID) {
                            const animalRow = row.AggDt.find(o => o.AnmID === AnmID);
                            if (animalRow) {
                                plannedUsage = animalRow.PCons;
                                usage = animalRow.Cons.reduce((b, a) => a.C + b, 0);
                            }

                        }
                        break;
                }
            } else if (type === FeedingType.GROUP) {
                const rfid = get(selectedNodes, "[0].name");
                if (rfid) {
                    const rfidRow = row.AggDt.find(o => o.RFID === rfid);
                    if (rfidRow) {
                        plannedUsage = rfidRow.PCons;
                        usage = rfidRow.Cons.reduce((b, a) => a.C + b, 0);
                    }

                }
            }
            if (result[time]) {
                result[time].usage += usage;
                !result[time].plannedUsage && (result[time].plannedUsage = plannedUsage);
            } else {
                result[time] = {};
                result[time].usage = usage;
                result[time].plannedUsage = plannedUsage;
                result[time].time = time;
                result[time].name = moment.utc(time).format("DD.MM.YY")

            }
        });
        return Object.values(result).sort((o2, o1) => o1.time - o2.time);
    };

    formatTime = (value) => moment.utc(value).format("DD.MM.YY");
    formatForage = (value) => convertWeightUnitTo(value, {showUnit: true, fixed: this.unit ? 2 : 0, unit: this.unit})
    formatPlannedUsage = (value) => `${(value.plannedUsage ? (100 * value.usage / value.plannedUsage) : 0).toFixed(0)}%`;

    infoBox = () => {
        //todo: odkomentowac jak zasiedlenie zacznie dzialac
        const {license, t} = this.props;
        const results = {
            show: true,
            text: t("deviceRows.siloses.siloRow.lastXdays", {count: license === LicPackageLevel.EXTENDED ? 30 : 7}),
            color: 'info'};
        // if (license === LicPackageLevel.EXTENDED) {
            // const params = this.getParams();
            // const format = (date) => moment.utc(date).format("DD.MM.YY");
            // if (isNil(settlement.data)) {
            //     const time = `${format(params.AggDataTimeFrom)}-${format(params.AggDataTimeTo)}`;
            //     results.text = t("modals.feedingHistoryModal.notActiveSettlementFound", {time: time})
            //     results.color = "warning";
            // } else {
            //     let time = format(params.AggDataTimeFrom);
            //     if (settlement.state === ChamberSettlement.SHOW_SETTLEMENT) {
            //         time += `-${format(params.AggDataTimeTo)}`
            //     }
            //     results.text = i18next.t("modals.feedingHistoryModal.showingDates", {time: time})
            // }
            // results.show = true;
        // }
        return results;
    };

    handleChange = (index) => {
        this.setState({
            showChart: index !== 0
        })
    };

    render() {
        const {show, handleHide, t, selectedNodes} = this.props;
        this.unit = getFeedingUnit();
        const history = this.getData();
        const {showChart, loading} = this.state;
        const title = get(selectedNodes, "[0].name", t("modals.feedingHistoryModal.feedingHistory"));
        const info = this.infoBox();
        const headers = [
            {
                name: t("date"),
                field: "time",
                valueFormatter: this.formatTime,
                customSort: sortDateStrings
            },
            {
                name: t("forageAmount"),
                field: "usage",
                valueFormatter: this.formatForage
            },
            {
                name: t("modals.feedingHistoryModal.dailyPercentage"),
                field: "",
                valueFormatter: this.formatPlannedUsage
            }
        ];
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={title}
                    loading={loading && !!history.length}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <LoadingComponent isLoading={loading && !history.length}/>
                    {
                        info.show &&
                        <InfoBox boxColor={info.color}>
                            {info.text}
                        </InfoBox>
                    }
                    <TextSwitch className={"mb-2"} onChange={this.handleChange} value={+showChart} options={[{
                        name: t("chart"),
                        value: 1
                    },
                        {
                            name: t("table"),
                            value: 0
                        }]}/>
                    <div>


                        {
                            showChart &&
                            <div style={{height: '20rem'}}>
                                <Chart data={history.slice(0).reverse()}
                                       type={"Composed"}
                                       dataDef={[

                                           {
                                               color: "blue",
                                               dataKey: "usage",
                                               chartType: "Bar",
                                               name: t("modals.feedingHistoryModal.fed"),
                                               unit: getUnit("weight", UnitTypes.MEDIUM),
                                               valueConverter: (value) => convertWeightUnitTo(value, {
                                                   showUnit: false,
                                                   rawValue: true,
                                                   unit: this.unit,
                                                   fixed: this.unit ? 2 : 0,
                                                   defaultValue: 0
                                               }),
                                               opacity: 1,
                                           },
                                           {
                                               color: "green",
                                               dataKey: "plannedUsage",
                                               name: t("modals.feedingHistoryModal.curve"),
                                               chartType: "Area",
                                               unit: getUnit("weight", UnitTypes.MEDIUM),
                                               valueConverter: (value) => convertWeightUnitTo(value, {
                                                   showUnit: false,
                                                   rawValue: true,
                                                   unit: this.unit,
                                                   fixed: this.unit ? 2 : 0,
                                                   defaultValue: 0
                                               }),
                                               opacity: 0.1,
                                           }
                                       ]}
                                       Xaxis={{
                                           name: t("day"),
                                           dataKey: "name"
                                       }}
                                       Yaxis={{
                                           name: t("forageAmount"),
                                           domain: [0, 'auto']
                                       }}
                                />
                            </div>
                        }
                        {
                            !showChart &&
                            <TableGrid headers={headers} data={history}/>
                        }
                    </div>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={false}
                    onCloseClick={handleHide}
                />
            </Modal>
        );
    }

}

FeedingHistoryModal.propTypes = {
    node: PropTypes.object.isRequired
};
FeedingHistoryModal = connect((state, props) => ({
    forages: state.settings.forage,
    rfidDevices: getFeedingRFIDDevices(state, {CID: props.locationID}),
    fetchingMap: state.aggregatedData.fetchingMap,
    data: state.aggregatedData.data,
    settlement: getSettlement(state, {CID: props.locationID}),
    license: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))(FeedingHistoryModal);

FeedingHistoryModal = connectModal({name: ModalName})(FeedingHistoryModal);
export default FeedingHistoryModal = withTranslation()(FeedingHistoryModal);

export const _FeedingHistoryModal_ = FeedingHistoryModal;
