import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'react-bootstrap'
import {connectModal} from 'redux-modal'
import ModalHeader from "../ModalHeader"
import {connect} from "react-redux"
import ModalFooter from "../ModalFooter";
import ModalBody from "../ModalBody";
import ReactGA from "react-ga";

export const ModalName = 'confirm-modal';

@connect((store) => {
    return {
        supportLanguages: store.language.supportedLanguages,
        language: store.language.locale,
        languageFetched: store.language.fetched,
        user: store.user.user,
        isLoading: store.user.fetching
    };
})

/**
 * Komponent od modala
 * @param props
 * @returns {XML}
 * @constructor
 */
export class ConfirmModal extends Component {

    static propTypes = {
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        confirmText: PropTypes.string.isRequired,
        handleHide: PropTypes.func.isRequired,
        onConfirmed: PropTypes.func.isRequired,
        size: PropTypes.string,
        closeText: PropTypes.string,
    };

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, text, confirmText, children, handleHide, onConfirmed, title, size, closeText, optionalButtons} = this.props; //title
        //console.log(this.props);
        return (
            <Modal onHide={handleHide} show={show} size={size}>
                <ModalHeader title={title} onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <div className={"mb-1"}>{text}</div>
                    {children}
                </ModalBody>
                <ModalFooter hasConfirmButton={true} confirmText={confirmText} onCloseClick={handleHide}
                             onConfirmed={() => onConfirmed(this.props)} closeText={closeText}
                             optionalButtons={optionalButtons}/>
            </Modal>
        );
    }
}

export const _ConfirmModal = (ConfirmModal);
export default connectModal({name: ModalName})(_ConfirmModal)