import {
    setExpeditionData,
    setSeparationAnimalCount,
    setSeparationWeightRange,
    setWorkType
} from "../../../IOT/device-functions/SeparationCageFunctions";
import {updateDeviceDynamoDB} from "../../../actions/devicesActions";

export function submit(values, dispatch, props) {
    console.log(values, props);
    const {
        worktype, training, separationWithMiddle, separationMinWeight, separationMaxWeight, separationAnimalAmount,
        startNewSeparation, startNewExpedition, expeditionWorkType, expeditionMinWeight, expeditionMaxWeight,
        expeditionAnimalAmount
    } = values;
    const {cages, user} = props;

    // ustawianie manualu
    if (worktype === "manual") {
        for (let cage of cages) {
            setWorkType(cage, 0, () => {
                cage.endExpedition();
                dispatch(updateDeviceDynamoDB(cage.prepareBeanToSave(), cage.FarmID, user.ClientID, user.LocalUserID));
            });
        }
    }
    // ustawianie treningow
    if (worktype === "training") {
        console.log("training", training);
        for (let cage of cages) {
            setWorkType(cage, training, () => {
                cage.endExpedition();
                dispatch(updateDeviceDynamoDB(cage.prepareBeanToSave(), cage.FarmID, user.ClientID, user.LocalUserID));
            })
        }
    }
    // ustawienie separacji
    if (worktype === "separation") {
        for (let cage of cages) {
            // jezeli wybrano separacje z wyjsciem na srodek
            if (separationWithMiddle) {
                if (startNewSeparation) {
                    setSeparationWeightRange(cage, +separationMinWeight, +separationMaxWeight);
                    setSeparationAnimalCount(cage, +separationAnimalAmount)
                }
            } else {
                // jezeli nie to zeruje stan wag
                setSeparationWeightRange(cage, 0, 0);
            }
            setWorkType(cage, 4, () => {
                cage.endExpedition();
                dispatch(updateDeviceDynamoDB(cage.prepareBeanToSave(), cage.FarmID, user.ClientID, user.LocalUserID));
            });
        }
    }
    // ustawienie ekspedycji
    if (worktype === "expedition") {
        for (let cage of cages) {
            setExpeditionData(cage, +expeditionAnimalAmount, +expeditionMinWeight, +expeditionMaxWeight, expeditionWorkType, !startNewExpedition);
            setWorkType(cage, 5, msg => {
                console.log(msg);
                cage.addExpedition(new Date().getTime(), +expeditionAnimalAmount, +expeditionMinWeight, +expeditionMaxWeight, expeditionWorkType, startNewExpedition);
                dispatch(updateDeviceDynamoDB(cage.prepareBeanToSave(), cage.FarmID, user.ClientID, user.LocalUserID));
            });
        }
    }
    props.handleHide();
}