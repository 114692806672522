import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux"
import {submit, validate} from "./FeedingManageFeedingSubmit"
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {cloneDeep, get, isEqual, isFinite} from "lodash";
import moment from "moment";
import {getFeedingUnit, getSettingClass} from "../../../utils/SettingsUtils";
import {
    CurveDayShowingType,
    CurveTypesWithInsemination,
    FeedingType,
    NRFWorkType
} from "../../../constans/feedingTypes";
import ReactGA from "react-ga";
import Chart from "../../basics/chart/Chart";
import {convertWeightUnitTo, getUnit} from "../../../utils/UnitUtils";
import {
    formatCurveDay,
    getAvailableCurveTypes,
    getCurveDayShowingType,
    getReceiversType,
    isValidCurveForDevType
} from "../../../utils/FeedingUtils";
import LoadingComponent from "../../loading/LoadingComponent";
import {DevType} from "../../../constans/devices";
import ReduxLabeledSliderToSelect from "../../redux-mobile-input-swapper/ReduxLabeledSliderToSelect";
import {getFeedingCurves} from "../../../selectors/feedingSelector";
import {withTranslation} from "react-i18next";

export const ModalName = "feeding-manage-feeding-modal";

class FeedingManageFeedingModal extends React.Component {

    constructor(props) {
        super(props);
        const {selectedNodes, initialize, curves, sectorType} = this.props;
        if (selectedNodes.length) {
            const curveId = get(selectedNodes[0], "curve.id");
            const curve = curves.find(o => (o.SetID === curveId) && getAvailableCurveTypes({sectorType}).includes(o.SetData.Type));
            const isTriggerWorkType = !!selectedNodes.filter(o => o.workType === NRFWorkType.TRIGGER).length;
            const showingType = getCurveDayShowingType(curve);
            const curveDay = get(selectedNodes[0], "curve.day", 1);
            const repeatDays = showingType.NORMAL ? get(selectedNodes[0], "loop.endDay", 0) : (get(selectedNodes[0], "loop.endDay", 0) + get(selectedNodes[0], "loop.insemination", 0));
            console.log(curveDay, repeatDays, selectedNodes[0]);
            let repetitionDate;
            let repetitionDay = get(curve, "SetData.InseminationJumpTo", 0);
            let curveSize = get(curve, "SetData.Days.length", 0);
            const curveStart = moment().startOf("day").subtract((curveDay + repeatDays) - 1, "day").format("YYYY-MM-DD");
            switch (showingType) {
                case CurveDayShowingType.BEFORE: {
                    repetitionDate = moment().startOf("day").subtract(((curveDay + repeatDays) - 1) - curveSize, "day").format("YYYY-MM-DD");
                    break;
                }
                case CurveDayShowingType.AFTER: {
                    repetitionDate = curveStart;
                    break;
                }
                case CurveDayShowingType.BOTH: {
                    repetitionDate = moment().startOf("day").subtract(((curveDay + repeatDays) - 1) - (Math.max(0, repetitionDay - 1)), "day").format("YYYY-MM-DD");
                    break;
                }
                default:
                    repetitionDate = curveStart;
                    break;
            }

            const initialValues = {
                curve: getSettingClass(curve || null),
                curveShift: get(selectedNodes[0], "curve.offset", 0),
                curveDay: isFinite(curveDay) ? curveDay : 1,
                repetitionDate: repetitionDate,
                doseCorrection: get(selectedNodes[0], "curve.correction", 0),
                punishment: isTriggerWorkType ? get(selectedNodes[0], "curve.punishment", 0) : 0
            };
            initialize(initialValues);
        }
        ReactGA.modalview(ModalName);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {curve, change} = this.props;
        if ((prevProps.curve !== undefined) && !isEqual(prevProps.curve, curve)) {
            const showingType = getCurveDayShowingType(curve);
            const curveLen = get(curve, "SetData.Days.length", 0);
            const repetitionDay = get(curve, "SetData.InseminationJumpTo", 1);
            switch (showingType) {
                case CurveDayShowingType.BOTH:
                    change("repetitionDate", moment().startOf("day").add(repetitionDay - 1, "day").format("YYYY-MM-DD"));
                    break;
                case CurveDayShowingType.AFTER:
                    change("repetitionDate", moment().startOf("day").format("YYYY-MM-DD"));
                    break;
                case CurveDayShowingType.BEFORE:
                    change("repetitionDate", moment().startOf("day").add(curveLen, "day").format("YYYY-MM-DD"));
                    break;
                default:
                    change("curveDay", 1);
                    break;
            }
        }
    }

    getCurveDay = () => {
        const {repetitionDate, curveDay, curve} = this.props;
        const showingType = getCurveDayShowingType(curve);
        const curveLen = get(curve, "SetData.Days.length", 0);
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", 0);
        switch (showingType) {
            case CurveDayShowingType.BOTH:
                return repetitionDate ? moment().startOf("day").diff(moment(repetitionDate, "YYYY-MM-DD").subtract(repetitionDay, "day").startOf("day"), "day") : 1;
            case CurveDayShowingType.AFTER:
                return repetitionDate ? moment().startOf("day").diff(moment(repetitionDate, "YYYY-MM-DD").startOf("day"), "day") + 1 : 1;
            case CurveDayShowingType.BEFORE:
                return repetitionDate ? moment().startOf("day").diff(moment(repetitionDate, "YYYY-MM-DD").subtract(curveLen, "day").startOf("day"), "day") + 1 : 1;
            case CurveDayShowingType.NORMAL:
            default:
                return isFinite(curveDay) ? Math.max(Math.min(curveDay, (curveLen || 1)), 1) : 1;

        }
    };

    render() {
        const {initialized, sectorType, show, selectedNodes, receivers, handleHide, curves, curve, type, t, handleSubmit} = this.props;
        let curveType = get(curve, "SetData.Type");
        const isTriggerWorkType = !!selectedNodes.filter(o => o.workType === NRFWorkType.TRIGGER).length;
        const showingType = getCurveDayShowingType(curve);
        //sprawdzamy czy sa jakies WST zaznaczone jesli sa to wyswietlamy mniej krzywych
        const isCompatibilityMode = !!(getReceiversType(receivers) & 0b01);
        const curvesOptions = cloneDeep(curves.filter(c => getAvailableCurveTypes({sectorType}).includes(c.SetData.Type) && (!isCompatibilityMode || isValidCurveForDevType({
            curve: c,
            devType: DevType.DISPENSER
        })))).sort((a, b) => a.SetData.Index - b.SetData.Index).map((cur) => {
            return {
                name: cur.SetData.Name,
                value: cur
            }
        });
        console.log(curve, "curve");

        const days = get(curve, "SetData.Days", []);
        let curveDay = this.getCurveDay();
        console.log("curveDay", curveDay);
        curveDay = Math.min(Math.max(curveDay, 1), days.length);
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", -151);
        const unit = getFeedingUnit();
        const chartDef = [
            {
                color: "green",
                dataKey: "ForageAmount",
                name: t("dosage"),
                unit: getUnit("weight", unit),
                valueConverter: (value) => convertWeightUnitTo(value, {unit: unit, showUnit: false, fixed: 2})
            }
        ];
        const referenceLines = [
            {
                x: `${formatCurveDay(curveDay, curve)}`,
                color: "blue",
                name: t("curveDay")
            },
            getCurveDayShowingType(curve) === CurveDayShowingType.BOTH ?
                {
                    x: `${formatCurveDay(repetitionDay, curve)}`,
                    color: "blue",
                    position: "middle",
                    name: CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("modals.feedingManageFeedingModal.parturitionDate")
                } : null
        ].filter(o => !!o);

        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.feedingManageFeedingModal.manageFeeding")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <div className={"mh-5rem"}>
                            {
                                !initialized &&
                                <LoadingComponent isLoading={!initialized}/>

                            }
                            {!!initialized &&
                            <>

                                <div style={{height: "20rem"}}>
                                    <Chart dataDef={chartDef}
                                           tooltipLabelFormatter={val => `${t("curveDay")} ${val}`}
                                           data={days.map((o, i) => ({...o, name: formatCurveDay(i + 1, curve)}))}
                                           Yaxis={{
                                               name: t("dosage")
                                           }} Xaxis={{
                                        name: t("curveDay"),
                                        dataKey: "name"
                                    }}
                                           referenceLines={referenceLines}
                                    />
                                </div>

                                <Field
                                    name="curve"
                                    id="curve"
                                    required
                                    component={ReduxLabeledSelect}
                                    options={curvesOptions}
                                    label={t("feedingCurve")}
                                />
                                {
                                    (showingType === CurveDayShowingType.NORMAL) &&
                                    <>
                                        <Field
                                            name="curveDay"
                                            label={t("curveDay")}
                                            id="curveDay"
                                            required
                                            type={"number"}
                                            format={(value) => `${value}`}
                                            parse={(value) => isFinite(parseInt(value)) ? parseInt(value) : value}
                                            component={ReduxLabeledInput}
                                        />
                                    </>
                                }
                                {
                                    (showingType !== CurveDayShowingType.NORMAL) &&
                                    <Field
                                        name="repetitionDate"
                                        label={showingType === CurveDayShowingType.BEFORE ? (CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.plannedInseminationDate") : t("modals.feedingManageFeedingModal.plannedParturitionDate")) : CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("modals.feedingManageFeedingModal.parturitionDate")}
                                        id="repetitionDate"
                                        type={"date"}
                                        component={ReduxLabeledInput}
                                    />
                                }

                                {
                                    [FeedingType.INDIVIDUAL].includes(type) && isTriggerWorkType &&
                                    <Field
                                        name="punishment"
                                        id="punishment"
                                        type="number"
                                        required
                                        step={1}
                                        min={0}
                                        max={3}
                                        component={ReduxLabeledSliderToSelect}
                                        valueFormatter={(value) => value ? `${value}` : t("modals.feedingManageFeedingModal.noPunishment")}
                                        label={t("punishment")}
                                    />
                                }
                                <Field
                                    name="doseCorrection"
                                    id="doseCorrection"
                                    type="number"
                                    required
                                    step={5}
                                    min={-25}
                                    max={25}
                                    component={ReduxLabeledSliderToSelect}
                                    valueFormatter={(value) => `${value}%`}
                                    label={t("modals.feedingManageFeedingModal.doseCorrection")}
                                />
                            </>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("modals.feedingManageFeedingModal.set")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingManageFeedingModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired
};

FeedingManageFeedingModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate,
    initialValues: {
        doseCorrection: 0,
        curveDay: 1,
        curveShift: 0,
        curve: undefined,
        punishment: 0
    }
})(FeedingManageFeedingModal);
FeedingManageFeedingModal = connect(state => ({
    curves: getFeedingCurves(state)
}))(FeedingManageFeedingModal);
const selector = formValueSelector(ModalName);
const mapStateToProps = state => {
    return {
        curve: selector(state, "curve"),
        curveDay: selector(state, "curveDay"),
        repetitionDate: selector(state, "repetitionDate"),
    }
};
export const _FeedingManageFeedingModal = withTranslation()(connect(mapStateToProps)(FeedingManageFeedingModal));

export default connectModal({name: ModalName})(_FeedingManageFeedingModal)
