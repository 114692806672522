import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {connectModal} from "redux-modal";
import {Field, FieldArray, reduxForm} from "redux-form";
import ModalHeader from "../ModalHeader";
import {Col, Modal, Row} from "react-bootstrap";
import ModalBody from "../ModalBody";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";
import ModalFooter from "../ModalFooter";
import {submit, validate} from "./PigletScaleSettingsModalSubmit"
import {isNil} from "lodash"
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";

export const ModalName = "piglet-scale-settings-modal";

function mapStateToProps(state) {
    return {
        farm: state.location.farm
    };
}

class PigletScaleSettingsModal extends Component {

    constructor(props) {
        super(props);
        let scale = props.scales[0];
        if (scale.Settings.Configuration && scale.Settings.Configuration.SET_CONFIGURATION) {
            this.props.initialize(scale.Settings.Configuration.SET_CONFIGURATION);
        } else {
            this.props.initialize({
                fromRaw: false,
                cell: [false, false, false, false],
                offset: 0
            })
        }
    }

    renderCells = ({fields}) => (
        <Row>
            {
                fields.map((member, index) =>
                    <Col lg={3} key={index}>
                        <Field
                            name={member}
                            id={member}
                            component={ReduxCheckbox}
                            label={this.props.t("modals.pigletScaleSettings.cell", {number: index + 1})}
                        />
                    </Col>
                )
            }
        </Row>
    )

    render() {
        const {t, handleHide, show, submitting} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.pigletScaleSettings.title")}
                    onCloseClick={handleHide}/>
                <ModalBody>
                    <Field
                        name="minTare"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.minTare")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="maxTare"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.maxTare")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="scale"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.scale")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="tare"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.tare")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="peaksMinHeight"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.peaksMinHeight")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="maxDifferentBetweenValues"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.maxDifferentBetweenValues")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="maxTaraValues"
                        component={ReduxLabeledInput}
                        label={t("modals.pigletScaleSettings.maxTaraValues")}
                        type={"number"}
                        parse={value => !isNil(value) && value !== "" ? +value : null}
                    />
                    <Field
                        name="offset"
                        component={ReduxLabeledSlider}
                        label={t("modals.pigletScaleSettings.offset")}
                        min={0}
                        max={1}
                        step={0.1}
                    />
                    <div>
                        <Field
                            name="fromRaw"
                            component={ReduxCheckbox}
                            label={t("modals.pigletScaleSettings.fromRaw")}
                            id="fromRaw"
                        />
                    </div>
                    <FieldArray name={"cell"} component={this.renderCells}/>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                    submitting={submitting}
                />
            </Modal>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(PigletScaleSettingsModal);