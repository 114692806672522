import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";

class UpdateCageWorkType extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const workTypeFormatter = (value) => t(`chamber.separationCageInfo.work.${value}`);
        return [
            {
                name: t("modals.cageWorkMode.title"),
                value: has(obj, "workType") ? workTypeFormatter(get(obj, "workType")) : null
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateCageWorkType);
