import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {connectModal} from "redux-modal";
import ModalFooter from "../ModalFooter";
import {connect} from "react-redux";
import {Field, formValueSelector, reduxForm} from "redux-form"
import {submit} from "./CageMarkerModalSubmit";
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import Button from "../../basics/button/Button";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import InfoBox from "../../basics/info-box/InfoBox";
import _ from "lodash";
import CageMarkerFillPage from "./pages/CageMarkerFillPage";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = "cage-marker-configurator";

export class CageMarkerModal extends React.Component {

    constructor(props) {
        super(props);
        this.onMarkerChange(1);
        this.state = {
            showFillup: false
        }
        ReactGA.modalview(ModalName);
    }

    onMarkerChange = (marker) => {
        const {initialize} = this.props;
        initialize({
            marker: marker,
            ...this.getMarkerConfig(marker)
        })
    };

    getMarkerConfig = (marker) => {
        const tmp = {};
        const {shadows, cages} = this.props;
        tmp.marker = marker;
        tmp.exit = _.get(shadows.get(cages[0].DevID), `markers[${marker - 1}].workType`, 0);
        tmp.weightRange = [_.get(shadows.get(cages[0].DevID), `markers[${marker - 1}].minWeight`, 0), _.get(shadows.get(cages[0].DevID), `markers[${marker - 1}].maxWeight`, 0)];
        tmp.time = _.get(shadows.get(cages[0].DevID), `markers[${marker - 1}].time`, 500);
        return tmp;
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!_.isEqual(this.props.cages, nextProps.cages)) {
            this.onMarkerChange(nextProps.marker);
        }
        if (!_.isEqual(this.props.marker, nextProps.marker)) {
            if (this.state.showFillup) {
                this.setState({
                    showFillup: false
                })
            }
        }
    }

    weightValueFormatter = (value) => {
        if (value[0] === 0 && value[1] === 0) {
            return this.props.t("all").toUpperCase()
        } else {
            return `${convertWeightUnitTo(value[0], {
                showUnit: false,
                fixed: 0,
                unit: UnitTypes.MEDIUM
            })}-${convertWeightUnitTo(value[1], {showUnit: true, fixed: 0, unit: UnitTypes.MEDIUM})}`
        }
    };

    toggleFillup = () => {
        this.setState(state => ({
            showFillup: !state.showFillup
        }))
    };

    render() {
        const {show, handleHide, t, marker, change, exit, cages} = this.props;
        const {showFillup} = this.state;
        const noMarkerInstalled = cages.filter(cage => !_.get(cage, `Settings.Marker[${marker - 1}]`));
        const eligibleCages = cages.filter(cage => _.get(cage, `Settings.Marker[${marker - 1}]`));
        const option = (index) => (
            <Button selected={exit === index}
                    onClick={() => change('exit', index)}>{t(`modals.cageMarkerModal.exit.${index}`)}</Button>);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.cageMarkerModal.title")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    {
                        !!noMarkerInstalled.length &&
                        <InfoBox boxColor={"warning"}>
                            {i18next.t("modals.cageMarkerModal.noMarkerInstalled", {type: noMarkerInstalled.map(d => d.Name).join(", ")})}
                        </InfoBox>
                    }
                    <label>{t("marker")}</label>
                    <ButtonGroup>
                        <Button selected={marker === 1} onClick={() => this.onMarkerChange(1)}
                                icon={<i className="fas fa-fill-drip"/>}
                        >1</Button>
                        <Button selected={marker === 2} onClick={() => this.onMarkerChange(2)}
                                icon={<i className="fas fa-fill-drip"/>}
                        >2</Button>
                        <Button buttonColor={"info"} icon={showFillup ? <i className="fas fa-chevron-up"/> :
                            <i className="fas fa-chevron-down"/>} buttonStyle={"text"} onClick={this.toggleFillup}
                                selected={showFillup}>
                            {t("modals.cageMarkerModal.paintConf")}
                        </Button>
                    </ButtonGroup>
                    {
                        showFillup &&
                        <CageMarkerFillPage cages={eligibleCages} marker={marker} toggle={this.toggleFillup}/>

                    }
                    <label>{t("worktype")}</label>
                    <ButtonGroup>
                        {new Array(5).fill(1).map((o, i) => option(i))}
                    </ButtonGroup>
                    <Field
                        label={t("modals.cageMarkerModal.weightRange")} isRange min={0} max={180000} step={1000}
                        valueFormatter={this.weightValueFormatter} component={ReduxLabeledSlider} name={'weightRange'}
                        id={'weightRange'}
                    />
                    <Field
                        label={t("modals.cageMarkerModal.time")} min={100} max={500} step={100}
                        valueFormatter={(value) => `${value}ms`} component={ReduxLabeledSlider} name={'time'}
                        id={'time'}
                    />
                </ModalBody>
                <ModalFooter onCloseClick={handleHide} hasConfirmButton={true}
                             confirmText={t("modals.cageMarkerModal.set")}
                             formName={ModalName}/>
            </Modal>
        );
    }

}

CageMarkerModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(CageMarkerModal);

const selector = formValueSelector(ModalName);
CageMarkerModal = connect(state => ({
    marker: selector(state, "marker"),
    exit: selector(state, "exit"),
    shadows: state.shadows.shadows
}))(CageMarkerModal);
CageMarkerModal = connectModal({name: ModalName})(CageMarkerModal);
export default withTranslation()(CageMarkerModal);