export function validate(values, props) {
    const errors = {};
    const {t} = props;

    if (!values.cage) {
        errors.cage = t("required");
    }

    if (!values.weight) {
        errors.weight = t("required");
    }

    return errors;
}