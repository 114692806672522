import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {Field, FormSection, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {submit, validate} from "./EditControlListSettingsModalSubmit"
import ReactGA from "react-ga";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {controlLists, controlListsPeriods} from "../../../constans/controlLists";
import InseminationsListFields from "./list-fields/InseminationsListFields";
import ArrayOfSliders from "./list-fields/ArrayOfSliders";
import "./_edit-control-list-settings.scss";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import moment from "moment";
import RemanentListFields from "./list-fields/RemanentListFields";
import {withTranslation} from "react-i18next";

export const ModalName = "edit-control-list-settings-modal";

export class EditControlListSettingsModal extends React.Component {

    constructor(props) {
        super(props);
        this.props.initialize({
            ...props.list,
            WData: {
                ...props.list.WData,
                isArray: Array.isArray(props.list.WData.InseminationTime)
            }
        });
        ReactGA.modalview(ModalName);
    }

    getOtherFields() {
        switch (this.props.list.WordID) {
            case controlLists.INSEMINATIONS:
                return <InseminationsListFields formName={ModalName}/>;
            case controlLists.CONDITION:
                return <ArrayOfSliders formName={ModalName} name="ConditionDays"/>;
            case controlLists.USG:
                return <ArrayOfSliders name="USGDays" formName={ModalName}/>;
            case controlLists.REMANENT:
                return <RemanentListFields/>
            default:
                return null;
        }
    }

    render() {
        const {show, handleHide, Period, t} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader title={t("modals.editControlListSettingsModal.title")} onCloseClick={handleHide}/>
                <ModalBody>
                    <FormSection name={"WData"}>
                        <Field
                            name="Period"
                            component={ReduxLabeledSelect}
                            label={t("modals.editControlListSettingsModal.period")}
                            options={[
                                {
                                    name: t("modals.editControlListSettingsModal.endOfMonth"),
                                    value: controlListsPeriods.END_OF_MONTH
                                },
                                {
                                    name: t("modals.editControlListSettingsModal.xDays"),
                                    value: controlListsPeriods.X_DAYS
                                },
                                {
                                    name: t("modals.editControlListSettingsModal.dayOfMonth"),
                                    value: controlListsPeriods.TO_DAY_IN_MONTH
                                },
                                {
                                    name: t("modals.editControlListSettingsModal.range"),
                                    value: controlListsPeriods.RANGE
                                }
                            ]}
                            clearButton={false}
                        />
                        {
                            Period === "0" &&
                            <Field
                                name="Day"
                                component={ReduxLabeledSlider}
                                min={1}
                                max={30}
                                label={t("numberOfDays")}
                            />
                        }
                        {
                            Period === "2" &&
                            <Field
                                name="MonthDay"
                                component={ReduxLabeledSlider}
                                min={1}
                                max={31}
                                label={t("modals.editControlListSettingsModal.monthDay")}
                            />
                        }
                        {
                            Period === "3" &&
                            <>
                                <Field
                                    name="StartDay"
                                    component={ReduxLabeledInput}
                                    label={t("modals.editControlListSettingsModal.startDay")}
                                    type={"date"}
                                    parse={(value) => value ? moment.utc(value).toDate().getTime() : null}
                                    format={(value) => value ? moment.utc(value).format(moment.HTML5_FMT.DATE) : null}
                                />
                                <Field
                                    name="EndDay"
                                    component={ReduxLabeledInput}
                                    label={t("modals.editControlListSettingsModal.endDay")}
                                    type={"date"}
                                    parse={(value) => value ? moment.utc(value).toDate().getTime() : null}
                                    format={(value) => value ? moment.utc(value).format(moment.HTML5_FMT.DATE) : null}
                                />
                            </>
                        }
                        {this.getOtherFields()}
                    </FormSection>
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={t("save")}
                             onCloseClick={handleHide} formName={ModalName}/>
            </Modal>
        );
    }

}

EditControlListSettingsModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(EditControlListSettingsModal);

const selector = formValueSelector(ModalName);
EditControlListSettingsModal = connect(state => ({
    Period: selector(state, "WData.Period"),
    user: state.user.user,
}))(EditControlListSettingsModal);

EditControlListSettingsModal = connectModal({name: ModalName})(EditControlListSettingsModal);

export default withTranslation()(EditControlListSettingsModal);