import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import moment from "moment";
import "../../animal-documents/css/_basic-info-box.scss";
import _ from "lodash";
import {connect} from "react-redux";
import {getTimeFromInseminationToPartuition} from "../../../utils/SettingsUtils";
import ReactGA from "react-ga";
import {changeSelectedItem} from "../../../actions/animalDocumentsAction";
import {convertRowsToCycles} from "../../../utils/AnimalDocumentsUtils";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = 'feeding-sow-info-modal';

class FeedingSowInfo extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        this.clearAnimals();
        const animal = _.get(this.props, "selectedNodes[0].animal");
        if (animal) {
            dispatch(changeSelectedItem(animal));
        }
        ReactGA.modalview(ModalName);
    }

    componentWillUnmount() {
        this.clearAnimals();
    }

    clearAnimals = () => {
        const {dispatch} = this.props;
        dispatch(changeSelectedItem(null));
    };

    getValues = (props = this.props) => {
        const {selectedNodes, cycleTable} = this.props;
        const animal = _.get(selectedNodes, "[0].animal");
        const cycles = !_.isEmpty(cycleTable) ? convertRowsToCycles(cycleTable) : [];
        let currentCycle = 0;
        let currentCycleIdx = 0;
        cycles.forEach((row, idx) => {
            if (row && !_.isNil(row.cycle)) {
                currentCycle = Math.max(currentCycle, row.cycle);
                currentCycleIdx = Math.max(currentCycleIdx, idx);
            }
        });
        const inseminations = _.get(cycles, `[${currentCycleIdx}].I`, []);
        const birth = _.get(cycles, `[${currentCycleIdx}].P`, []);
        const hasBirth = !!birth.length;
        const lastBirth = currentCycleIdx ? _.get(cycles, `[${currentCycleIdx - 1}].P`, []) : [];
        const hasLastBirth = !!lastBirth.length;
        const getAliveDeadMummies = (o) => {
            return [
                o.reduce((a, b) => a + +_.get(b, "EvData.HealthyCnt", 0), 0),
                o.reduce((a, b) => a + +_.get(b, "EvData.DeadCnt", 0), 0),
                o.reduce((a, b) => a + +_.get(b, "EvData.MummyCnt", 0), 0),
            ]
        };
        const mommy = _.get(cycles, `[${currentCycleIdx}].W`, []);
        return {
            inseminationDate: inseminations.length ? moment(inseminations[inseminations.length - 1].EvTime).format("DD.MM.YY HH:mm") : "-",
            plannedParturitionDate: inseminations.length ? moment(inseminations[inseminations.length - 1].EvTime).startOf("day").add(getTimeFromInseminationToPartuition(), 'days').format("DD.MM.YY") : "-",
            activeNipples: _.get(animal, `ActiveNipples`, "-"),
            newestAliveDeadMummies: hasBirth ? getAliveDeadMummies(birth) : ["-", "-", "-"],
            lastAliveDeadMummies: hasLastBirth ? getAliveDeadMummies(lastBirth) : ["-", "-", "-"],
            cycle: currentCycle,
            mothering: mommy.length ? mommy.reduce((a, b) => a + +_.get(b, "EvData.PiCnt", 0), 0) : "-"
        };
    };


    render() {
        const {show, handleHide, fetchingEvent, t, selectedNodes} = this.props;
        const animal = _.get(selectedNodes, "[0].animal");
        console.log("Animalssssss:",animal)
        const {inseminationDate, plannedParturitionDate, activeNipples, newestAliveDeadMummies, lastAliveDeadMummies, cycle, mothering} = this.getValues();
        return (
            <Modal show={show} size={"lg"} onHide={handleHide} >
                <ModalHeader
                    title={animal ? i18next.t("modals.feedingSowInfoModal.title", {animal: animal.AnmNo1}) : t("modals.feedingSowInfoModal.titleNotFound")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName} isLoading={fetchingEvent}>
                    {
                        animal && <h5>{i18next.t("cycle", {count: cycle})}</h5>
                    }
                    <div className={"basic-info-box"}>
                        <Row>
                            <Col md={6}>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("modals.feedingSowInfoModal.inseminationDate")}
                                    </div>
                                    <div className="info-column">
                                        {inseminationDate}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("modals.feedingSowInfoModal.plannedParturitionDate")}
                                    </div>
                                    <div className="info-column">
                                        {plannedParturitionDate}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("activeNipples")}
                                    </div>
                                    <div className="info-column">
                                        {activeNipples}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("modals.feedingSowInfoModal.newestAliveDeadMummies")}
                                    </div>
                                    <div className="info-column">
                                        {newestAliveDeadMummies.join(" / ")}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("modals.feedingSowInfoModal.lastAliveDeadMummies")}
                                    </div>
                                    <div className="info-column">
                                        {lastAliveDeadMummies.join(" / ")}
                                    </div>
                                </div>
                                <div className="info-row">
                                    <div className={"info-column header"}>
                                        {t("modals.feedingSowInfoModal.mothering")}
                                    </div>
                                    <div className="info-column">
                                        {mothering}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={t("modals.feedingSowInfoModal.details")}
                    onConfirmed={()=>{this.props.history.push(`/${this.props.farm}/farm/inventory?documentsAnimal=${animal.AnmID}`)}}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }
}

FeedingSowInfo = connect((state) => ({
    fetchingEvent: state.animalDocuments.fetchingEvent,
    cycleTable: state.animalDocuments.cycleTable,
    farm: state.location.farm
}))(FeedingSowInfo);
export default withRouter(withTranslation()(connectModal({name: ModalName})(FeedingSowInfo)))

