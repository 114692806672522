import {checkNip} from "../../../utils/NormalizeFunctions";
import dictionariesDB from "../../../database/dictionariesDB";
import {DICTIONARY_TYPE} from "../../../constans/general";
import {updateDictionaryDynamoDB} from "../../../actions/dictionaryActions";

function updateExistingDictionary(dictionary, values, props) {
    props.supportedLanguages.map(lang => {
        if (!dictionary.WData[lang.lang]) {
            dictionary.WData[lang.lang] = [];
        }
        //wyciągnięcie i usuniecie starego obiektu ze słownika
        let removeIndex = dictionary.WData[lang.lang].map(item => {return item.ID}).indexOf(props.dictionaryItem.ID);
        dictionary.WData[lang.lang].splice(removeIndex, 1);

        //dodanie nowego obiektu do słownika
        switch (props.type) {
            case DICTIONARY_TYPE.suppliers:
                dictionary.WData[lang.lang].push({
                    ID: props.dictionaryItem.ID,
                    Value: values.newValue,
                    Phone: values.phone,
                    Address: values.address,
                    NIP: values.nip,
                    Website: values.website,
                    HerdNumber: values.herdNumber,
                    isShowing: true
                });
                break;
            case DICTIONARY_TYPE.medicine:
                dictionary.WData[lang.lang].push({
                    ID: props.dictionaryItem.ID,
                    Value: values.newValue,
                    Color: values.color,
                    Amount: props.dictionaryItem.Amount,
                    Unit: props.dictionaryItem.Unit,
                    isShowing: true
                });
                break;
            case DICTIONARY_TYPE.fallReasons:
                dictionary.WData[lang.lang].push({
                    ID: props.dictionaryItem.ID,
                    Value: values.newValue,
                    Short: values.abbreviation.trim()
                });
                break;
            default:
                dictionary.WData[lang.lang].push({
                    Value: values.newValue,
                    ID: props.dictionaryItem.ID,
                    isShowing: true
                });
        }
    });
}

export function submit(values, dispatch, props) {
    let dictionaries = dictionariesDB.findDictionary(props.type);
    let dictionary = Object.assign({}, dictionaries[0]);
    dictionary.DtaModTime = new Date().getTime();
    updateExistingDictionary(dictionary, values, props);
    delete dictionary.$loki;
    dispatch(updateDictionaryDynamoDB(dictionary, props.user.ClientID, props.user.LocalUserID));
    props.handleHide();
}

export const validate = (values, props) => {
    const errors = {};
    const {t} = props;
    if (!values.newValue) {
        errors.newValue = t("required");
    }
    if (!values.abbreviation) {
        errors.abbreviation = t("required");
    } else if (values.abbreviation.trim().length !== 1) {
        errors.abbreviation = t("errors.mustBe1Char");
    }
    if (!values.phone) {
        errors.phone = t("required");
    }
    if (!values.address) {
        errors.address = t("required");
    }
    if (!values.nip) {
        errors.nip = t("required");
    }
    if (!values.website) {
        errors.website = t("required");
    }
    if (!values.herdNumber) {
        errors.herdNumber = t("required");
    }
    if (values.nip && values.nip.length !== 13) {
        errors.nip = t("errors.nipLength");
    }
    if (values.nip && values.nip.length === 13 && !checkNip(values.nip)) {
        errors.nip = t("errors.nipError");
    }
    if (!values.unit) {
        errors.unit = t("required");
    }
    if (!values.amountFor100) {
        errors.amountFor100 = t("required");
    }
    return errors
};