import React, {Component} from 'react';
import {getFeedingUnit, getSettingClass} from "../../../../utils/SettingsUtils";
import Curve from "../../../../beans/settings/Curve";
import {get} from "lodash";
import {convertWeightUnitTo, getUnit} from "../../../../utils/UnitUtils";
import {formatCurveDay, getCurveDayShowingType} from "../../../../utils/FeedingUtils";
import {CurveDayShowingType, CurveTypesWithInsemination} from "../../../../constans/feedingTypes";
import Chart from "../../../basics/chart/Chart";
import {withTranslation} from "react-i18next";
import LogDetailCodeHeader from "../LogDetailCodeHeader";

export const SupportedTables = ["animals"];


class UpdateNutritionCurveDetail extends Component {

    renderCurve = (_possiblyACurve) => {
        const {t} = this.props;
        const curve = getSettingClass(_possiblyACurve);
        if (!(curve instanceof Curve)) return null;
        const repetitionDay = get(curve, "SetData.InseminationJumpTo", -151);
        const unit = getFeedingUnit();
        const chartDef = [
            {
                color: "green",
                dataKey: "ForageAmount",
                name: t("dosage"),
                unit: getUnit("weight", unit),
                valueConverter: (value) => convertWeightUnitTo(value, {unit: unit, showUnit: false, fixed: 2}),
                yAxisId: "left",
            },
            {
                color: "error",
                dataKey: "ForageAmount1",
                name: t("dosage"),
                unit: getUnit("weight", unit),
                valueConverter: (value) => convertWeightUnitTo(value, {unit: unit, showUnit: false, fixed: 2}),
                yAxisId: "right",
            }
        ];
        const referenceLines = [
            getCurveDayShowingType(curve) === CurveDayShowingType.BOTH ?
                {
                    x: `${formatCurveDay(repetitionDay, curve)}`,
                    color: "blue",
                    position: "middle",
                    name: CurveTypesWithInsemination.includes(curve.SetData.Type) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("modals.feedingManageFeedingModal.parturitionDate")
                } : null
        ].filter(o => !!o);
        const days = get(curve, "SetData.Days", []);
        return (
            <div style={{height: "20rem"}}>
                <Chart dataDef={chartDef}
                       tooltipLabelFormatter={val => `${t("curveDay")} ${val}`}
                       data={days.map((o, i) => ({...o, name: formatCurveDay(i + 1, curve)}))}
                       Yaxis={{
                           name: t("dosage")
                       }} Xaxis={{
                    name: t("curveDay"),
                    dataKey: "name"
                }}
                       referenceLines={referenceLines}
                />
            </div>
        )
    }

    render() {
        const {log: {ObjNew, ObjOld}, code, t} = this.props;
        const unit = getFeedingUnit();
        const chartDef = [
            {
                color: "error",
                dataKey: "ForageAmount1",
                name: t("$t(dosage) [$t(before)]"),
                chartType: "Bar",
                unit: getUnit("weight", unit),
                valueConverter: (value) => convertWeightUnitTo(value, {unit: unit, showUnit: false, fixed: 2}),
                yAxisId: "left",
            },
            {
                color: "green",
                dataKey: "ForageAmount",
                name: t("$t(dosage) [$t(after)]"),
                chartType: "Bar",
                unit: getUnit("weight", unit),
                valueConverter: (value) => convertWeightUnitTo(value, {unit: unit, showUnit: false, fixed: 2}),
                yAxisId: "left",
            },
            {
                color: "warning",
                dataKey: "DailyPlan1",
                name: t("$t(newSettings.chainFeeding.scheduleAnalog.title) [$t(before)]"),
                chartType: "Area",
                type: "step",
                valueConverter: value => value + 1,
                yAxisId: "right",
            },
            {
                color: "info",
                dataKey: "DailyPlan",
                name: t("$t(newSettings.chainFeeding.scheduleAnalog.title) [$t(after)]"),
                chartType: "Area",
                type: "step",
                valueConverter: value => value + 1,
                yAxisId: "right",
            }
        ];
        const daysAfter = get(ObjNew, "SetData.Days", []);
        const daysBefore = get(ObjOld, "SetData.Days", []);
        const size = Math.max(daysAfter.length, daysBefore.length);
        const weightData = [];
        for (let i = 0; i < size; i++) {
            weightData.push({
                ForageAmount1: get(daysBefore[i], "ForageAmount") || 0,
                DailyPlan: get(daysBefore[i], "DailyPlan") || 0,
                ForageAmount: get(daysAfter[i], "ForageAmount") || 0,
                DailyPlan1: get(daysAfter[i], "DailyPlan") || 0,
                name: `${i + 1}`
            })
        }
        return (
            <div>
                <LogDetailCodeHeader code={code}/>
                <div style={{height: "20rem"}}>
                    <Chart dataDef={chartDef}
                           tooltipLabelFormatter={val => `${t("curveDay")} ${val}`}
                           data={weightData}
                           type={"Composed"}
                           Yaxis={{
                               name: t("dosage")
                           }}
                           secondYaxis={{
                               name: t("newSettings.chainFeeding.scheduleAnalog.title")
                           }} Xaxis={{
                        name: t("curveDay"),
                        dataKey: "name"
                    }}
                    />
                </div>
            </div>
        );
    }
}

export default withTranslation()(UpdateNutritionCurveDetail);
