import {
    updateDevices,
    updateDevicesNotificationFailure,
    updateDevicesNotificationSuccess
} from "../../../api/devices/updateDevices";
import {isNil} from "lodash";

export function submit(values, dispatch, props) {
    const {scales} = props;
    let devices = [];
    for (let scale of scales) {
        let copy = scale.prepareBeanToSave();
        if (!copy.Settings.Configuration) copy.Settings.Configuration = {};
        copy.Settings.Configuration.SET_CONFIGURATION = values;
        devices.push(copy);
    }
    return updateDevices(devices).then(res => {
        props.handleHide();
        updateDevicesNotificationSuccess(res);
    }).catch(e => {
        updateDevicesNotificationFailure(e);
    })
}

export function validate(values, props) {
    const {t} = props;
    const errors = {};

    if (isNil(values.minTare)) {
        errors.minTare = t("required");
    } else {
        if (+values.minTare < 0) {
            errors.minTare = t("errors.noLess", {number: 0})
        }
    }

    if (isNil(values.maxTare)) {
        errors.maxTare = t("required");
    } else {
        if (+values.maxTare < 0) {
            errors.maxTare = t("errors.noLess", {number: 0})
        }
    }

    if (isNil(values.scale)) {
        errors.scale = t("required");
    } else {
        if (+values.scale < 0) {
            errors.scale = t("errors.noLess", {number: 0})
        }
    }

    if (isNil(values.tare)) {
        errors.tare = t("required");
    } else {
        if (+values.tare < 0) {
            errors.tare = t("errors.noLess", {number: 0})
        }
    }

    if (isNil(values.peaksMinHeight)) {
        errors.peaksMinHeight = t("required");
    } else {
        if (+values.peaksMinHeight < 0) {
            errors.peaksMinHeight = t("errors.noLess", {number: 0})
        }
    }

    if (isNil(values.maxDifferentBetweenValues)) {
        errors.maxDifferentBetweenValues = t("required");
    } else {
        if (+values.maxDifferentBetweenValues < 0) {
            errors.maxDifferentBetweenValues = t("errors.noLess", {number: 0})
        }
    }

    if (isNil(values.maxTaraValues)) {
        errors.maxTaraValues = t("required");
    } else {
        if (+values.maxTaraValues < 0) {
            errors.maxTaraValues = t("errors.noLess", {number: 0})
        }
    }

    return errors;
}