import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {get, isNil} from "lodash";
import languages from "../../../../data/languages.json";
import {DICTIONARY_TYPE} from "../../../../constans/general";
import UpdateControlListDetail from "./UpdateControlListDetail";
import UpdateMedicineDetail from "./UpdateMedicineDetail";
import {difference} from "../utils";

class UpdateDictionaryDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        //tutaj wypadaloby jakiegos flata zrobic z pathem do tlumaczen i id i potem porownanie obiektow
        const result = {};
        const WData = get(obj, "WData", {});
        const WeirdNonDictionaryKeyToTranslationMapHere = {
            Address: t("address"),
            HerdNumber: t("dictionariesView.herdNumber"),
            NIP: "NIP",
            Phone: t("phoneNumber"),
            Website: "WWW",
            Short: t("abbreviation"),
            Color: t("color")
        }
        const langs = Object.keys(WData).filter(o => o !== "cimode");//nie chcemy tutaj tego smiecia
        for (let lang of langs) {
            (WData[lang] || []).forEach((data) => {
                const {ID, Value} = data;
                if (!result[lang]) result[lang] = {};
                result[lang][ID] = Value || "";
                const extraParams = [];
                for(let key in WeirdNonDictionaryKeyToTranslationMapHere){
                    if(!isNil(data[key])){
                        extraParams.push(`${WeirdNonDictionaryKeyToTranslationMapHere[key]}=${data[key]}`)
                    }
                }
                if(extraParams.length){
                    result[lang][ID] = `${result[lang][ID]} (${extraParams.join(", ")})`;
                }
            })
        }
        console.log("updatedictinaryy", result, WeirdNonDictionaryKeyToTranslationMapHere);
        return result;
    }

    render() {
        const {log, log: {ObjNew, ObjOld}, code} = this.props;
        const type = get(ObjNew, "Type");
        if (DICTIONARY_TYPE.medicine === type) {
            return  <UpdateMedicineDetail log={log} code={code}/>
        }
        if(DICTIONARY_TYPE.controlLists === type) {
            return <UpdateControlListDetail log={log} code={code}/>
        }
        const dataA = this.getData(ObjNew);
        const dataB = this.getData(ObjOld);
        const diff = difference(dataA, dataB);
        console.log("DIFFFF", diff, dataA, dataB);
        const langKeys = Object.keys(diff);
        const data = [];
        const getLangName = (langCode) => get(languages.find(o => o.code === langCode), "nativeName", langCode);
        for(let langKey of langKeys){
            const name = getLangName(langKey);
            Object.entries(diff[langKey]).forEach(([key, value])=>{
                data.push({
                    name: name,
                    value: value,
                    valueBefore: get(dataB, `${langKey}.${key}`)

                })
            })

        }
        console.log(getLangName);
        return (
            <TemplateBeforeAfterTable code={code} data={data}/>
        );
    }
}

export default withTranslation()(UpdateDictionaryDetail);
