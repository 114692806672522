import React from "react";
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {connectModal} from "redux-modal";
import DropIn from "braintree-web-drop-in-react";
import {initializeDropin, setPaymentMethodRequestable, storeDropinInstance} from "../../../actions/braintreeActions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {reduxForm} from "redux-form";

export const ModalName = "add-payment-methods-modal";

function mapStateToProps(state) {
    return {
        clientToken: state.braintree.clientToken,
        fetched: state.braintree.fetched,
        dropinLoaded: state.braintree.dropinLoaded
    }
}

class AddPaymentMethodsModal extends React.Component {

    constructor(props) {
        super(props);
        props.dispatch(initializeDropin())
    }

    render() {
        const {handleHide, show, title} = this.props;
        return (
            <Modal onHide={handleHide} show={show}>
                <ModalHeader onCloseClick={handleHide} closeButton={handleHide}>
                    <h4>{title}</h4>
                </ModalHeader>
                <ModalBody>
                    <DropIn
                        options={{
                            authorization: this.props.clientToken,
                            locale: this.props.t("subscriptions.locale"),
                            card: {
                                cardholderName: true
                            }
                        }}
                        onInstance={(instance) => {
                            this.instance = instance;
                            this.instance.clearSelectedPaymentMethod();
                            this.props.dispatch(storeDropinInstance(this.instance))
                        }}
                        onPaymentMethodRequestable={() => {
                            this.instance.requestPaymentMethod();
                            this.props.dispatch(setPaymentMethodRequestable(true));
                        }
                        }
                        onNoPaymentMethodRequestable={() => {
                            this.props.dispatch(setPaymentMethodRequestable(false));
                        }
                        }
                        onPaymentOptionSelected={() => {
                            this.props.dispatch(setPaymentMethodRequestable(true));
                        }
                        }
                    />
                </ModalBody>
            </Modal>
        );
    }
}

export default compose(
    withTranslation(),
    connectModal({name: ModalName}),
    connect(mapStateToProps),
    reduxForm({
        form: ModalName
    })
)(AddPaymentMethodsModal);

