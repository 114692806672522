import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {Modal} from "react-bootstrap";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import TaskAddModalForm from "./TaskAddModalForm";
import {isSubmitting} from "redux-form";

export const ModalName = "task-add-modal";

function mapStateToProps(state) {
    return {
        submitting: isSubmitting(ModalName)(state)
    };
}

class TaskAddModal extends Component {

    render() {
        const {handleHide, show, task, t, submitting} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={task ? t("editTask") : t("addTask")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <TaskAddModalForm task={task} hide={handleHide}/>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    formName={ModalName}
                    onCloseClick={handleHide}
                    confirmText={t("save")}
                    submitting={submitting}
                />
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect(mapStateToProps),
)(TaskAddModal);
