import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Field, FieldArray, reduxForm} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ButtonInput from "../../basics/button-input/ButtonInput";
import {submit, validate} from "./TaskAddModalSubmit";
import moment from "moment";
import ReduxInput from "../../basics/input/ReduxInput";
import Button from "../../basics/button/Button";
import "./_task-add-modal-form.scss"
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";
import ReduxSwitch from "../../basics/switch/ReduxSwitch";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
        categories: state.dictionary.taskCategory.WData[state.language.lang.lang],
        employees: state.user.employees
    };
}

class TaskAddModalForm extends Component {

    constructor(props) {
        super(props);

        const {task} = this.props;
        if (task) {
            this.props.initialize({
                ...task,
                DtaPlanned: moment(task.DtaPlanned).format(moment.HTML5_FMT.DATE)
            });
        } else {
            this.props.initialize({
                date: moment().format(moment.HTML5_FMT.DATE),
                ListTODO: [{isAdded: true, Done: false}]
            })
        }
    }

    setEditing(member) {
        this.props.change(`${member}.edit`, true);
    }

    renderList = ({fields}) => (
        <div>
            {
                fields.map((member, index) => {
                    let value = fields.get(index);
                    return (
                        <div className="task-list-row">
                            <div>{index + 1}</div>
                            {
                                !value.isAdded && !value.edit &&
                                <div className={`task-name${value.Done ? " done" : ""}`}
                                     onClick={() => this.setEditing(member)}>{value.Title}</div>
                            }
                            {
                                (value.isAdded || value.edit) &&
                                <Field
                                    name={`${member}.Title`}
                                    type={"text"}
                                    component={ReduxInput}
                                    id={`${member}.name`}
                                />
                            }
                            <Field
                                name={`${member}.Done`}
                                component={ReduxCheckbox}
                                label={""}
                                disabled={!this.props.task}
                                id={`${member}.isDone`}
                            />
                            <Button buttonStyle={"round"} icon={<i className="fas fa-trash"/>}
                                    onClick={() => fields.remove(index)} disabled={fields.length === 1}/>
                        </div>
                    )
                })
            }
            <Button icon={<i className="fas fa-plus"/>}
                    onClick={() => fields.push({isAdded: true, Done: false})}>{this.props.t("add")}</Button>
        </div>
    );

    render() {
        const {t, categories, employees} = this.props;
        return (
            <>
                <Field
                    name="DtaPlanned"
                    component={ReduxLabeledInput}
                    type={"date"}
                    label={t("date")}
                />
                <Field
                    name="Title"
                    component={ReduxLabeledInput}
                    type={"text"}
                    label={t("taskTitle")}
                />
                <Field
                    name="Description"
                    component={ReduxLabeledInput}
                    type={"textarea"}
                    label={t("taskDescription")}
                />
                <Field
                    name="AssignTo"
                    component={ReduxLabeledSelect}
                    options={employees.map(employee => ({
                        name: employee.userName,
                        value: employee.LocalUserID
                    }))}
                    label={t("eventGrid.operator")}
                />
                <Field
                    name="Category"
                    component={ReduxLabeledSelect}
                    options={categories.map(item => ({
                        name: item.Value,
                        value: item.ID
                    }))}
                    label={t("category")}
                />
                <Field
                    name="Private"
                    component={ReduxSwitch}
                    label={t("private")}
                />
                <Field
                    name="Priority"
                    component={ButtonInput}
                    buttons={[
                        {name: t("morning"), value: 1},
                        {name: t("noon"), value: 2},
                        {name: t("afternoon"), value: 3}
                    ]}
                    label={t("priority")}
                />
                <Field
                    name="EstimatedTime"
                    component={ButtonInput}
                    buttons={[
                        {name: "0.5h", value: .5},
                        {name: "1h", value: 1},
                        {name: "2h", value: 2},
                        {name: "4h", value: 4}
                    ]}
                    label={t("estimatedTime")}
                />
                <hr/>
                <h5>{t("list")}</h5>
                <FieldArray component={this.renderList} name={"ListTODO"} rerenderOnEveryChange/>
            </>
        );
    }
}

export default compose(
    withTranslation(),
    reduxForm({
        form: "task-add-modal",
        onSubmit: submit,
        validate
    }),
    connect(mapStateToProps)
)(TaskAddModalForm);
