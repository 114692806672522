import {setForceFeedings as setForceFeedingDTM} from "../../../IOT/device-functions/DispenserDriverFunctions";
import {setForceFeeding as setForceFeedingNRF} from "../../../IOT/device-functions/DispenserNRFFunctions";
import {ModalName} from "./FeedingExtraFeedingModal";

export function submit(values, dispatch, props) {
    console.log(values, props, "KKD");
    const {forageDose} = values;
    const {receivers, handleHide, clearSelection, cookies} = props;
    cookies.set(ModalName, {
        forageDose
    });
    let data = {dose: forageDose, type: 0};

    for (let devs of receivers.values()) {
        devs.individualDTM.forEach(({DevID, outputs}) => {
            outputs.forEach((out) => {
                setForceFeedingDTM(DevID, {...data, number: out.number})
            })
        });
        if (devs.individualNRF.devices.length > 0) {
            setForceFeedingNRF(devs.individualNRF.devices, data)
        }
    }
    handleHide();
    clearSelection();
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const {forageDose} = values;
    if (!forageDose) {
        errors.type = t("required");
    }
    return errors;
}
