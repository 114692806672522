import React from "react";
import {Field, FieldArray, formValueSelector} from "redux-form";
import ReduxLabeledSlider from "../../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {getTimeFromInseminationToPartuition} from "../../../../utils/SettingsUtils";
import Button from "../../../basics/button/Button";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export class ArrayOfSliders extends React.Component {

    onAddNewClick(fields, days) {
        fields.push(days[days.length - 1] + 1);
    }

    renderConditionDays = ({fields, days}) => {
        let timeFrominseminationToParturition = getTimeFromInseminationToPartuition();
        return (
            <>
                {
                    fields.map((field, index) =>
                        <div className="slider-with-remove">
                            <div>
                                <Field
                                    id={field}
                                    component={ReduxLabeledSlider}
                                    name={field}
                                    label={i18next.t("modals.editControlListSettingsModal.dayAfterInsemination", {amount: index + 1})}
                                    min={index === 0 ? 1 : days[index - 1] + 1}
                                    max={timeFrominseminationToParturition}
                                />
                            </div>
                            <div>
                                <i className="fas fa-trash pointer" onClick={fields.length !== 1 ? () => fields.remove(index) : null}/>
                            </div>
                        </div>
                    )
                }
                <Button icon={<i className="fas fa-plus"/>} onClick={() => this.onAddNewClick(fields, days)}
                        disabled={days[days.length - 1] === timeFrominseminationToParturition}>
                    {this.props.t("modals.editControlListSettingsModal.addNew")}
                </Button>
            </>
        )
    }

    render() {
        const {days, name} = this.props;
        return (
            <>
                <FieldArray name={name} component={this.renderConditionDays} days={days}/>
            </>
        );
    }

}

ArrayOfSliders.propTypes = {
    name: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired
}

ArrayOfSliders = connect((state, props) => {
    let selector = formValueSelector(props.formName);
    return {
        days: selector(state, `WData.${props.name}`),
    }
})(ArrayOfSliders);

export default withTranslation()(ArrayOfSliders);