import {cloneDeep, isNil, isEmpty, isEqual} from "lodash";
import moment from "moment";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";
import {getClass, isEventDateInvalid} from "../../../utils/EventUtils";
import {
    editSowCycle,
    editSowCycleNotificationSuccess,
    editSowCycleNotificationFailure,
} from "../../../api/cycles/editSowCycle";
import {convertRowsToCycles} from "../../../utils/AnimalDocumentsUtils";

export function submit(values, dispatch, props) {
    const {animal, cycles} = props;
    const eventsToUpdate = [];
    const cycle = cloneDeep(values);
    const convertedCycles = convertCycles(cycles);
    const converted = convertRowsToCycles(convertedCycles);
    const toCheck = Array.isArray(converted) ? converted.find((item) => item.cycle === cycle.cycle) : {};
    if (!isEmpty(toCheck)) {
        for (let key in cycle) {
            if (cycle.hasOwnProperty(key) && Array.isArray(cycle[key]) && key !== "deleted") {
                for (let event of cycle[key]) {
                    const eventToCheck = toCheck[key].find((item) => item.EvID === event.EvID);
                    if (!isEqual(event, eventToCheck)) {
                        event.EvTime = moment(event.EvTime).toDate().getTime();
                        event.DtaModTime = moment().toDate().getTime();
                        eventsToUpdate.push(event);
                    }
                }
            }
        }
    }
    const eventsToDelete = cycle.deleted ? cycle.deleted.map((event) => {
        const ev = getClass(event);
        ev.EvTime = moment(ev.EvTime).toDate().getTime();
        ev.deleteEvent();
        return ev.prepareBeanToSave();
    }) : [];
    const data = {AnmID: animal.AnmID, eventsToUpdate, eventsToDelete};
    return editSowCycle(data).then((res) => {
        editSowCycleNotificationSuccess(res);
        props.handleHide();
    }).catch((e) => {
        editSowCycleNotificationFailure(e);
    });
}

export function convertCycles(cycles) {
    return cloneDeep(cycles).map(cycle => {
        for (let key in cycle) {
            if (Array.isArray(cycle[key])) {
                for (let event of cycle[key]) {
                    event.EvTime = moment(event.EvTime).format("YYYY-MM-DDTHH:mm");
                }
            }
        }
        return cycle;
    });
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const isService = checkIfUserIsService();
    for (let key in values) {
        if (Array.isArray(values[key]) && key !== "deleted") {
            let keyErrors = values[key].map(event => {
                let error = {EvData: {}};

                if (!isService && isEventDateInvalid(event.EvTime, props.farm)) {
                    error.EvTime = t("required");
                }

                if (!event.EvTime && !isService) {
                    error.EvTime = t("required")
                }

                if (isNil(event.EvData.Reason)) {
                    error.EvData.Reason = t("required");
                }

                if (isNil(event.EvData.Reasn)) {
                    error.EvData.Reasn = t("required");
                }

                if(isNil(event.EvData.BoarID)) {
                    error.EvData.BoarID = t("required");
                }

                if(isNil(event.EvData.OperID)) {
                    error.EvData.OperID = t("required");
                }

                if (isNil(event.EvData.Weight)) {
                    error.EvData.Weight = t("required");
                }

                if (isNil(event.EvData.PiCnt)) {
                    error.EvData.PiCnt = t("required");
                }

                if (isNil(event.EvData.PiWeight)) {
                    error.EvData.PiWeight = t("required");
                }

                if (isNil(event.EvData.HealthyCnt)) {
                    error.EvData.HealthyCnt = t("required");
                }

                if (isNil(event.EvData.DeadCnt)) {
                    error.EvData.DeadCnt = t("required");
                }

                if (isNil(event.EvData.MummyCnt)) {
                    error.EvData.MummyCnt = t("required");
                }

                if (isNil(event.EvData.Piglets)) {
                    error.EvData.Piglets = t("required");
                }

                if (isNil(event.EvData.Nipples)) {
                    error.EvData.Nipples = t("required");
                }

                return error;
            });

            errors[key] = keyErrors;
        }
    }

    return errors;
}
