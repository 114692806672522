import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {Field, formValueSelector, reduxForm, isDirty} from "redux-form";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {connect} from "react-redux";
import * as AnimalTypes from "validators-schema/Api/constants/animalTypes";
import {submit, validate} from "./EditAnimalBasicsModalSubmit"
import {normalizeRFID} from "../../../utils/NormalizeFunctions";
import ReduxSwitch from "../../basics/switch/ReduxSwitch";
import InfoBox from "../../basics/info-box/InfoBox";
import moment from "moment";
import ReactGA from "react-ga";
import {Roles} from "../../../constans/roles";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = "edit-basics-modal";

export class EditAnimalBasicsModal extends React.Component {

    constructor(props) {
        super(props);
        const {animal} = this.props;
        this.props.initialize({
            ...animal.clone(),
            isDead: !!this.props.animal.DtaDthTime,
            DtaDthTime: this.props.animal.DtaDthTime ? moment(animal.DtaDthTime).format("YYYY-MM-DDTHH:mm") : undefined,
            DtaBrthTime: this.props.animal.DtaBrthTime ? moment(animal.DtaBrthTime).format("YYYY-MM-DDTHH:mm") : undefined
        });
        ReactGA.modalview(ModalName);
    }

    isSowOrBoar() {
        const {AnimalKind} = this.props;
        return [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, AnimalTypes.BOAR].includes(AnimalKind);
    }

    render() {
        const {show, handleHide, animal, race, lang, isDead, DtaDthTime, t, handleSubmit, user, submitting, suppliers, DtaBrthTime, dirty} = this.props;
        const isService = user.Roles.find(role => role.Role === Roles._SERVICE);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={i18next.t("modals.editBasicsAnimal.title", {type: animal.AnmNo1})}
                                 onCloseClick={handleHide}/>
                    <ModalBody>
                        <Field
                            component={ReduxLabeledInput}
                            type="text"
                            name="AnmNo1"
                            label={t("animalNumber")}
                        />
                        <Field
                            component={ReduxLabeledInput}
                            type="text"
                            name="AnmNo2"
                            label={t("secondaryNumber")}
                        />
                        {
                            !!isService &&
                            <Field
                                component={ReduxLabeledSelect}
                                name="AnimalKind"
                                label={t("animalType")}
                                options={[
                                    {
                                        name: t("animalKind.0"),
                                        value: AnimalTypes.SOW
                                    },
                                    {
                                        name: t("animalKind.2"),
                                        value: AnimalTypes.PIGLET
                                    },
                                    {
                                        name: t("animalKind.3"),
                                        value: AnimalTypes.PORKER
                                    },
                                    {
                                        name: t("animalKind.4"),
                                        value: AnimalTypes.BOAR
                                    },
                                    {
                                        name: t("animalKind.5"),
                                        value: AnimalTypes.RENOVATION_SOW
                                    }
                                ]}
                            />
                        }
                        <Field
                            component={ReduxSwitch}
                            label={t("modals.editBasicsAnimal.isDead")}
                            name="isDead"
                            disabled={!animal.DtaDthTime}
                        />
                        {
                            isDead &&
                            <>
                                {
                                    DtaDthTime !== moment(animal.DtaDthTime).format("YYYY-MM-DDTHH:mm") &&
                                    <InfoBox boxColor={"error"}>{t("modals.editBasicsAnimal.changeDate")}</InfoBox>
                                }
                                <Field
                                    name="DtaDthTime"
                                    component={ReduxLabeledInput}
                                    type="datetime-local"
                                    label={t("modals.editBasicsAnimal.dtaDthTime")}
                                />
                            </>
                        }
                        {
                            !isDead &&
                            <>
                                {
                                    !!animal.DtaDthTime &&
                                    <InfoBox boxColor="info">{t("modals.editBasicsAnimal.removeEvent")}</InfoBox>
                                }
                                {
                                    (!Array.isArray(animal.PlcmntID) || animal.PlcmntID.length === 1) &&
                                    <>
                                        {
                                            !this.isSowOrBoar() &&
                                            <Field
                                                name="AnmCnt"
                                                component={ReduxLabeledInput}
                                                label={t("animalCount")}
                                                type={"number"}
                                            />
                                        }
                                    </>
                                }
                            </>
                        }
                        <Field
                            component={ReduxLabeledInput}
                            name="Weight"
                            label={t("weight")}
                            type="number"
                            unit="kg"
                            parse={value => value ? +value : null}
                        />
                        {
                            <Field
                                component={ReduxLabeledInput}
                                name="RFID"
                                label={t("rfid")}
                                type="number"
                                normalize={normalizeRFID}
                            />
                        }
                        <Field
                            component={ReduxLabeledInput}
                            name="Price"
                            label={t("price")}
                            type="number"
                            parse={value => value ? +value : null}
                        />
                        <Field
                            component={ReduxLabeledSelect}
                            name="SupplierID"
                            label={t("supplier")}
                            options={suppliers.map(reason => ({
                                name: reason.Value,
                                value: reason.ID
                            }))}
                        />
                        <Field
                            label={t("race")}
                            component={ReduxLabeledSelect}
                            name="Race"
                            options={race.WData[lang] ? race.WData[lang].map(r => ({
                                name: r.Value,
                                value: r.ID
                            })) : []}
                        />
                        <Field
                            label={t("birthDate")}
                            component={ReduxLabeledInput}
                            name="DtaBrthTime"
                            type={"datetime-local"}
                        />
                        {
                            !DtaBrthTime &&
                            <InfoBox boxColor={"warning"}>{t("errors.invalidDate")}</InfoBox>
                        }
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("save")} hasButtonDisabled={!dirty}
                                 onCloseClick={handleHide} formName={ModalName} submitting={submitting}/>
                </form>
            </Modal>
        );
    }

}

EditAnimalBasicsModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(EditAnimalBasicsModal);

const selector = formValueSelector(ModalName);
export const _EditAnimalBasicsModal = connect(state => ({
    farm: state.location.farm,
    lang: state.language.lang.lang,
    race: state.dictionary.race,
    user: state.user.user,
    suppliers: state.dictionary.suppliers.WData[state.language.lang.lang],
    AnimalKind: selector(state, "AnimalKind"),
    isDead: selector(state, "isDead"),
    DtaDthTime: selector(state, "DtaDthTime"),
    DtaBrthTime: selector(state, "DtaBrthTime"),
    PlcmntID: selector(state, "PlcmntID"),
    dirty: isDirty(ModalName)(state)
}))(EditAnimalBasicsModal);

EditAnimalBasicsModal = connectModal({name: ModalName})(_EditAnimalBasicsModal);

export default withTranslation()(EditAnimalBasicsModal);
