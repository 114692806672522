import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit, validate} from "./FeedingExtraFeedingSubmit"
import {Col, Modal, Row} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {get} from "lodash";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {getFeedingUnit} from "../../../utils/SettingsUtils";
import {withCookies} from "react-cookie";
import ReactGA from "react-ga";
import Card from "../../basics/card/Card";
import {getFeedingForages} from "../../../selectors/feedingSelector";
import {withTranslation} from "react-i18next";

export const ModalName = 'feeding-extra-feeding-modal';
const DoseOpt = [200, 500, 1000, 1500, 2000];

class FeedingExtraFeedingModal extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
        const {initialize, cookies} = this.props;
        const doseOptions = DoseOpt.map(o => ({
            name: convertWeightUnitTo(o, {
                unit: getFeedingUnit(),
                showUnit: true,
                fixed: getFeedingUnit() ? 2 : 0
            }), value: o
        }));
        const mostUsedValues = cookies.get(ModalName);
        let showDose = !DoseOpt.includes(get(mostUsedValues, "forageDose", 500));
        initialize({
            forageDose: get(mostUsedValues, "forageDose", 500)
        });
        this.state = {
            doseOptions,
            showDose

        }
    }

    handleClick = (index) => {
        if (DoseOpt[index]) {
            this.setState({
                showDose: false
            }, () => {
                const {change} = this.props;
                change("forageDose", DoseOpt[index])
            })
        } else {
            this.setState({
                showDose: true
            })
        }
    };


    render() {
        const {show, handleHide, t, handleSubmit, forageDose} = this.props;
        const {doseOptions, showDose} = this.state;
        const unit = getFeedingUnit();
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("extraFeeding")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <div>
                            <Row>
                                {
                                    doseOptions.map((dose, idx) => (
                                        <Col xs={6} sd={4} md={3} key={idx}>
                                            <Card onClick={() => this.handleClick(idx)}
                                                  selected={!showDose && dose.value === forageDose}>
                                                {dose.name}
                                            </Card>
                                        </Col>

                                    ))
                                }
                                <Col xs={6} sd={4} md={3}>
                                    <Card onClick={() => this.handleClick(9999)} selected={showDose}>
                                        {t("modals.feedingExtraFeedingModal.other")}
                                    </Card>
                                </Col>
                            </Row>
                            {
                                showDose &&
                                <Field
                                    name="forageDose"
                                    id="forageDose"
                                    type="number"
                                    required
                                    step={100}
                                    min={100}
                                    max={2500}
                                    component={ReduxLabeledSlider}
                                    valueFormatter={(value) => convertWeightUnitTo(value, {
                                        unit: unit,
                                        showUnit: true,
                                        fixed: unit ? 2 : 0
                                    })}
                                    label={t("modals.feedingExtraFeedingModal.forageDose")}
                                />
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("modals.feedingExtraFeedingModal.start")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingExtraFeedingModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired
};

FeedingExtraFeedingModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate,
    initialValues: {
        forageDose: 500
    }
})(FeedingExtraFeedingModal);
const selector = formValueSelector(ModalName);
export const _FeedingExtraFeedingModal = connect(state => ({
    forages: getFeedingForages(state),
    buildings: state.farms.buildings,
    forageDose: selector(state, "forageDose")
}))(FeedingExtraFeedingModal);

FeedingExtraFeedingModal = withCookies(connectModal({name: ModalName})(_FeedingExtraFeedingModal));

export default withTranslation()(FeedingExtraFeedingModal);
