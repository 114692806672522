import React, {Component} from 'react';
import {flatten, get, has} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {
    minutesFormatter,
    percentageFormatter,
    secondsFormatter
} from "../../../../views/new-settings-view/settings/climate-sk-3/settings/utils";
import {calculateVentilation} from "../../../../utils/DevicesUtils";


class UpdateClimateClockMenuDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const ventilationFormatter = value => `${calculateVentilation(value).toFixed(1)}%`;
        const languageFormatter = (value) => ["polski", "русский"][value];
        const heatingTypeFormatter = (value) => [null, t("settings.floorType"), t("settings.waterType")][value];
        const text = ["0% 0M", "2% 0M", "5% 0M", "10% 0M", "15% 0M", "20% 0M", "25% 0M", "35% 0M", "45% 0M", "70% 0M", "100% 0M", "100% 1M", "100% 2M", "100% 3M", "100% 4M"];

        return [
            {
                name: t("settings.language"),
                value: has(obj, "language") ? languageFormatter(get(obj, "language")) : null
            },
            {
                name: t("settings.chimneyFansAmount"),
                value: has(obj, "chimneyVentilatorsCount") ? get(obj, "chimneyVentilatorsCount") : null
            },
            {
                name: t("settings.heatersAmount"),
                value: has(obj, "heatersCount") ? get(obj, "heatersCount") : null
            },
            {
                name: t("settings.mastersAmount"),
                value: has(obj, "mastersCount") ? get(obj, "mastersCount") : null
            },
            {
                name: t("settings.cooling"),
                value: has(obj, "cooling") ? get(obj, "cooling") : null
            },
            {
                name: t("settings.heatingType"),
                value: has(obj, "heatingType") ? heatingTypeFormatter(get(obj, "heatingType")) : null
            },
            {
                name: t("settings.floorHeatingCircuitsAmount"),
                value: has(obj, "floorHeating") ? get(obj, "floorHeating") : null
            },
            {
                name: t("settings.objectDynamics"),
                value: has(obj, "objectDynamics") ? minutesFormatter(get(obj, "objectDynamics")) : null
            },
            {
                name: t("settings.ventilationIntensity"),
                value: has(obj, "ventilationIntensity") ? ventilationFormatter(get(obj, "ventilationIntensity")) : null
            },
            {
                name: t("settings.ventilationCycleTime"),
                value: has(obj, "ventilationCycleTime") ? secondsFormatter(get(obj, "ventilationCycleTime")) : null
            },
            {
                name: t("settings.minRotations"),
                value: has(obj, "minimumRotation") ? percentageFormatter(get(obj, "minimumRotation")) : null
            },
            {
                name: t("settings.calibration55"),
                value: has(obj, "rotation55PercentCalibration") ? percentageFormatter(get(obj, "rotation55PercentCalibration")) : null
            },
            ...new Array(5).fill(0).map((o, i) => ({
                name: t(`$t(calibration) - $t(settings.tempSensor) ${i + 1}`),
                value: has(obj, `temperatureSensorsCalibration[${i}]`) ? percentageFormatter(get(obj, `temperatureSensorsCalibration[${i}]`)) : null
            })),
            ...new Array(15).fill(0).map((o, i) => ({
                name: t("$t(settings.firstAnalOutCurve) $t(settings.pointX)", {number: `${i + 1} ${text[i]}`}),
                value: has(obj, `analogOutputs.firstAnalogOutputCurve[${i}]`) ? percentageFormatter(get(obj, `analogOutputs.firstAnalogOutputCurve[${i}]`)) : null
            })),
            ...flatten(new Array(5).fill(0).map((o, i) => ([
                {
                    name: t("settings.minVoltageAnalOut", {number: i + 2}),
                    value: has(obj, `analogOutputs.additionalAnalogOutputs[${i}].minimumVoltage`) ? percentageFormatter(get(obj, `analogOutputs.additionalAnalogOutputs[${i}].minimumVoltage`)) : null
                },
                {
                    name: t("settings.maxVoltageAnalOut", {number: i + 2}),
                    value: has(obj, `analogOutputs.additionalAnalogOutputs[${i}].maximumVoltage`) ? percentageFormatter(get(obj, `analogOutputs.additionalAnalogOutputs[${i}].maximumVoltage`)) : null
                }
            ])))
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateClimateClockMenuDetail);
