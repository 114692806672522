import WaterFlowMeter from "../../../beans/devices/WaterFlowMeter";
import {updateDeviceDynamoDB} from "../../../actions/devicesActions";

export function submit(values, dispatch, props) {
    const {maxWaterFlowTime, maxWaterConsumption} = values;
    const {devices, handleHide} = props;
    const updateList = [];
    for (let device of devices) {
        if (device instanceof WaterFlowMeter) {
            const copy = device.clone();
            let settings = device.Settings || {};
            delete settings.FlowTimeAlertLimit;
            delete settings.FlowValueAlertLimit;
            if (maxWaterFlowTime) {
                settings.FlowTimeAlertLimit = maxWaterFlowTime;
            }
            if (maxWaterConsumption) {
                settings.FlowValueAlertLimit = maxWaterConsumption;
            }
            copy.Settings = settings;
            copy.setDtaModTime();
            updateList.push(copy.prepareBeanToSave());
        }
    }
    if (updateList.length) {
        dispatch(updateDeviceDynamoDB(updateList, updateList[0].FarmID));
    }
    handleHide();

}