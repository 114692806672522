import React, {Component} from 'react';
import {connect} from 'react-redux';
import BottomScrollListener from "react-bottom-scroll-listener";
import {listLogsDynamoDB} from "../../actions/logsActions";
import ListItem from "../basics/list/list-item/ListItem";
import moment from "moment";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        lastEvaluatedKey: state.logs.lastEvaluatedKey,
        fetching: state.logs.fetching,
        objectId: state.logs.objectId,
        userId: state.logs.userId,
        searchCode: state.logs.searchCode
    };
}

class LogLoader extends Component {

    loadMoreData = () => {
        const {fetching, lastEvaluatedKey, dispatch, objectId, userId, searchCode} = this.props;
        if (!fetching && lastEvaluatedKey) {
            dispatch(listLogsDynamoDB({lastEvaluatedKey, ObjID: objectId, UserID: userId, Codes: searchCode}))
        }
    }

    render() {
        const {fetching, lastEvaluatedKey, index, t} = this.props;
        return (
            <ListItem index={index} disableHover className={"row pb-2 pt-2"}>
                {
                    (!fetching && (!!lastEvaluatedKey)) &&
                    <div onClick={this.loadMoreData}
                         className={"pointer d-flex justify-content-center w-100 align-items-center"}>
                        {t("logsView.loadedLogsTo", {date: moment(lastEvaluatedKey).format("DD.MM.YY HH:mm")})}
                    </div>
                }
                {
                    ((!lastEvaluatedKey)) &&
                    <div onClick={this.loadMoreData}
                         className={`d-flex justify-content-center w-100 align-items-center${fetching ? " opacity-0" : ""}`}>
                        {t("logsView.noMoreLogsFound")}
                    </div>
                }
                {
                    !!fetching && !!lastEvaluatedKey &&
                    <div className={"mh-5rem pb-4 pt-4 d-flex justify-content-center w-100 align-items-center"}>
                        <i className={"fas fa-fw fa-spin fa-circle-notch fa-2x"}/>
                    </div>
                }
                <BottomScrollListener onBottom={this.loadMoreData}/>
            </ListItem>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(LogLoader);
