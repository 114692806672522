import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {getFeedingUnit} from "../../../../utils/SettingsUtils";
import {NRFWorkType} from "../../../../constans/feedingTypes";

class UpdateDispenserNRFWorkType extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const unit = getFeedingUnit();
        const weightFormatter = (value) => convertWeightUnitTo(value, {
            showUnit: true,
            unit: unit,
            fixed: unit ? 2 : 0
        })
        const workTypeFormatter = (value) => {
            switch (value){
                case NRFWorkType.RFID:
                    return t("rfid");
                case NRFWorkType.SCHEDULE:
                    return t("schedule");
                case NRFWorkType.TRIGGER:
                    return t("newSettings.dispenserNRF.workType.toy");
                default:
                    return null;
            }
        }
        const timeOrImpulseFormatter = (value) => {
            switch (value){
                case "T":
                    return t("time");
                case "I":
                    return t("newSettings.dispenserNRF.workType.impulse");
                default:
                    return null;
            }
        }
        const buttonWorkTypeFormatter = (value) => {
            switch (value){
                case 0:
                    return t("newSettings.dispenserNRF.workType.punishment");
                case 1:
                    return t("newSettings.dispenserNRF.workType.extraFeeding");
                default:
                    return null;
            }
        }
        return [
            {
                name: t("newSettings.dispenserNRF.workType.workType"),
                value: has(obj, "workType") ? workTypeFormatter(get(obj, "workType")) : null
            },
            {
                name: t("newSettings.dispenserNRF.workType.initDose"),
                value: has(obj, "initDose") ? weightFormatter(get(obj, "initDose")) : null
            },
            {
                name: t("newSettings.dispenserNRF.workType.maxOneDose"),
                value: has(obj, "maxOneDose") ? weightFormatter(get(obj, "maxOneDose")) : null
            },
            {
                name: t("newSettings.dispenserNRF.workType.timeOrImpulse"),
                value: has(obj, "timeOrImpulse") ? timeOrImpulseFormatter(get(obj, "timeOrImpulse")) : null
            },
            {
                name: t("newSettings.dispenserNRF.workType.buttonWorkType"),
                value: has(obj, "optButt") ? buttonWorkTypeFormatter(get(obj, "optButt")) : null
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateDispenserNRFWorkType);
