import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import InfoBox from "../../../basics/info-box/InfoBox";
import Button from "../../../basics/button/Button";
import NewIOT from "../../../../IOT/NewIOT"
import {connect} from "react-redux";
import {convertWeightUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import {withTranslation} from "react-i18next";

const ModalName = "cage-weight-configurator";

export class CageWeightConfigurationModalPage3 extends React.Component {

    componentDidMount() {
        NewIOT.startSendingDeviceState(this.props.cage)
    }

    getWeight() {
        const {shadows, cage} = this.props;
        try {
            let shadow = shadows.get(cage.DevID);
            return convertWeightUnitTo(shadow.weight, {
                unit: UnitTypes.MEDIUM,
                showUnit: true,
                fixed: 1
            });
        } catch (e) {
            return "-";
        }
    }

    render() {
        return (
            <div className="cage-weight-configuration-page-3">
                <InfoBox boxColor="info">
                    <p>
                        {this.props.t("modals.cageWeightConfiguration.info3")}
                    </p>
                    <p>
                        {this.props.t("modals.cageWeightConfiguration.info4")}
                    </p>
                    <p>
                        {this.props.t("modals.cageWeightConfiguration.info5")}
                    </p>
                </InfoBox>
                <div className="current-weight">
                    {this.props.t("currentWeight")} <strong>{this.getWeight()}</strong>
                </div>
                <div>
                    {this.props.t("currentWeight")}
                </div>
                <div>
                    <Button buttonColor={"success"}
                            onClick={this.props.onYesClick}>{this.props.t("yes")}</Button>
                    <Button buttonColor={"error"}
                            onClick={this.props.onNoClick}>{this.props.t("modals.cageWeightConfiguration.no")}</Button>
                </div>
            </div>
        );
    }

}

CageWeightConfigurationModalPage3 = reduxForm({
    form: ModalName,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(CageWeightConfigurationModalPage3);

const selector = formValueSelector(ModalName);
CageWeightConfigurationModalPage3 = connect(state => ({
    cage: selector(state, "cage"),
    shadows: state.shadows.shadows,
}))(CageWeightConfigurationModalPage3);

export default withTranslation()(CageWeightConfigurationModalPage3);