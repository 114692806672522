import React, {Component} from 'react';
import {connect} from 'react-redux';
import {connectModal} from "redux-modal";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {submit} from "./WaterMeterAlarmModalSubmit"
import {convertVolumeUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import ReduxLabeledSliderToSelect from "../../redux-mobile-input-swapper/ReduxLabeledSliderToSelect";
import _ from "lodash";
import * as moment from "moment";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = "water-meter-alarm-modal";


const selector = formValueSelector(ModalName);

function mapStateToProps(state) {
    return {
        shadows: state.shadows.shadows,
        minAlarm: selector(state, "minAlarm"),
        maxAlarm: selector(state, "maxAlarm")
    };
}

class WaterMeterAlarmModal extends Component {


    componentDidMount() {
        const {initialize, devices} = this.props;
        const initialValues = {
            maxWaterConsumption: _.get(devices, "[0].Settings.FlowValueAlertLimit", 0),
            maxWaterFlowTime: _.get(devices, "[0].Settings.FlowTimeAlertLimit", 0)
        };
        initialize(initialValues);
    }

    flowFormatter = (value) => {
        const {t} = this.props;
        if (!value) return t("noLimit");
        const strValue = convertVolumeUnitTo(value / 6, {
            unit: UnitTypes.MEDIUM,
            showUnit: true,
            fixed: 1
        });
        return i18next.t("modals.waterMeterAlarmModal.YXmins", {value1: strValue, value2: 10});
    };

    timeFormatter = (value) => {
        const {t} = this.props;
        return value ? moment.utc(value).format("HH:mm") : t("noLimit")
    };

    render() {
        const {handleHide, show, t, initialized} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.waterMeterAlarmModal.title")}
                    onCloseClick={handleHide}/>
                <ModalBody>
                    {
                        !!initialized && <>
                            <Field
                                name="maxWaterConsumption"
                                component={ReduxLabeledSliderToSelect}
                                min={0}
                                max={90000}
                                step={3000}
                                label={t("modals.waterMeterAlarmModal.maxWaterConsumption")}
                                valueFormatter={this.flowFormatter}
                            />
                            <Field
                                name="maxWaterFlowTime"
                                component={ReduxLabeledSliderToSelect}
                                min={0}
                                max={24 * 60 * 60 * 1000}
                                step={5 * 1000 * 60}
                                label={t("modals.waterMeterAlarmModal.maxWaterFlowTime")}
                                valueFormatter={this.timeFormatter}
                            />
                        </>
                    }
                </ModalBody>
                <ModalFooter
                    submitting={!initialized}
                    hasConfirmButton={true}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }
}

WaterMeterAlarmModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(WaterMeterAlarmModal);

WaterMeterAlarmModal = connect(
    mapStateToProps,
)(WaterMeterAlarmModal);

export default withTranslation()(connectModal({name: ModalName})(WaterMeterAlarmModal));