import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {reduxForm} from "redux-form";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";


export const ModalName = "requirement-modal";

export class RequirementsModal extends React.Component {

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, handleHide, t} = this.props;

        return (
            <Modal show={show} size={"lg"} onHide={handleHide}>
                <form>
                    <ModalHeader title={t("requirements.requirements")} onCloseClick={handleHide}/>
                    <ModalBody>
                        <a>{t("requirements.browsers")} :</a>
                        <ul>
                            <li>
                                <a>-{t("requirements.browsersValueGoogle")},</a>
                            </li>
                            <li>
                                <a>-{t("requirements.browsersValueFirefox")},</a>
                            </li>
                            <li>
                                <a>-{t("requirements.browsersValueOpera")},</a>
                            </li>
                            <li>
                                <p>-{t("requirements.browsersValueSafari")}</p>
                            </li>
                        </ul>
                        <p>{t("requirements.netSpeed")} : {t("requirements.netSpeedValue")}</p>
                        <p>{t("requirements.operatingSystem")} : {t("requirements.operatingSystemValue")}</p>
                        <p>{t("requirements.ramMemory")} : {t("requirements.ramMemoryValue")}</p>
                        <p>{t("requirements.memory")} : {t("requirements.memoryValue")}</p>
                        <p>{t("requirements.resolution")} : {t("requirements.resolutionValue")}</p>
                    </ModalBody>
                    <ModalFooter onCloseClick={handleHide}/>
                </form>
            </Modal>
        )
    }
}

RequirementsModal = reduxForm({
    form: ModalName,
})(RequirementsModal);

RequirementsModal = connectModal({name: ModalName})(RequirementsModal);

export default withTranslation()(RequirementsModal);