import React, {Component} from "react";
import {Col, Modal, Row} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {ModalHeader} from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import ModalBody from "../ModalBody";
import {connectModal} from "redux-modal";
import animalsDB from "../../../database/animalsDB";
import {AnimalTypes} from "../../../constans/animalTypes";
import {connect} from "react-redux";
import {Field, FormSection, formValueSelector, reduxForm} from "redux-form";
import {submit, validate} from "./AssignAnimalToStandingModalSubmit";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {cloneDeep, get, isEmpty, isEqual, isFinite, isNil, set} from "lodash";
import ReduxSwitch from "../../basics/switch/ReduxSwitch";
import {
    getAvailableCurveTypes,
    getCurveDayShowingType,
    initializeFeeding,
    isValidCurveForDevType
} from "../../../utils/FeedingUtils";
import {CurveDayShowingType, CurveTypesWithInsemination} from "../../../constans/feedingTypes";
import {DevType} from "../../../constans/devices";
import {isMobile} from "../../../utils/MobileUtils";
import InfoBox from "../../basics/info-box/InfoBox";
import {getFeedingCurves} from "../../../selectors/feedingSelector";

export const ModalName = "assign-animal-to-standing-modal";


class AssignAnimalToStandingModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialSearchValue: "",
            createNewAnimalMod: false,
            animalsOptions: [],
            curvesOptions: [],
            optionsLoaded: false,
            time: 0
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.time !== nextProps.time) {
            return {optionsLoaded: false}
        }
        return null;
    }

    _getOptions = (props) => {
        const {farm, time, t, curves, standing: {device} = {}, sectorType} = props;
        let animalsOptions = animalsDB.getAllAnimals(farm, {"$in": [AnimalTypes.RENOVATION_SOW, AnimalTypes.SOW, AnimalTypes.BOAR]}, undefined, false).map((a) => ({
            name: a.AnmNo1,
            value: a
        }));
        animalsOptions.unshift({name: `+ ${t("modals.assignAnimalToStandingModal.createNewAnimal")}`, value: null});
        //todo: "na PR 127 jest kolejny aruments" - dalej ma byc kolejny argument czy mozna usuwać todo?
        //todo: tutaj byla zmiana zobaczyc czy dziala
        const curvesOptions = !device || cloneDeep(curves.filter(c => {
            return getAvailableCurveTypes({sectorType}).includes(c.SetData.Type) &&
                isValidCurveForDevType({
                    curve: c,
                    devType: isNil(device.Index) ? DevType.DISPENSER_NRF : DevType.DISPENSER
                })
        }))
            .sort((a, b) => a.SetData.Index - b.SetData.Index)
            .map((cur) => ({
                name: get(cur, "SetData.Name"),
                value: cur
            }));
        return {animalsOptions, curvesOptions, time, optionsLoaded: true};
    };

    componentDidMount() {
        this.setState({
            ...this._getOptions(this.props)
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {animal, curve, newAnmNo1, change} = this.props;
        const {optionsLoaded} = this.state;
        if (prevProps.optionsLoaded !== optionsLoaded) {
            if (!optionsLoaded) {
                this.setState({
                    ...this._getOptions(this.props)
                })
            }
        }
        const mobile = isMobile();
        if (!mobile) {
            if (!isEqual(prevProps.animal, animal)) {
                if (!!animal) {
                    this.setState({
                        initialSearchValue: ""
                    }, () => {
                        change("feeding", this.initializeFeeding(this.props));
                        change("setFeeding", true);
                    });
                } else {
                    change("setFeeding", false);
                }
            } else if (!isEqual(prevProps.newAnmNo1, newAnmNo1)) {
                if (newAnmNo1) {
                    change("setFeeding", true);
                }
                change("animal", null);
                change("feeding", this.initializeFeeding(this.props));
            } else if (!isEqual(prevProps.curve, curve)) {
                //na zmianie krzywej inicializacja 'najlepszym' dniem
                const {curves, sectorType} = this.props;
                if (!animal || !curve || get(animal, "feedParam.curveNr") !== get(curve, "SetData.Index", 0) + 1) {
                    change("feeding", initializeFeeding({
                        animal: {
                            feedParam: {
                                startTime: +new Date(),
                                curveNr: curve ? curve.SetData.Index + 1 : 0,
                                stage: 0
                            }
                        }, curves, sectorType
                    }))
                }

            }
        }
    }

    onSearchChange = (value) => {
        const {animalsOptions} = this.state;
        if (!isEmpty(animalsOptions) && value) {
            this.props.change("setFeeding", true);
            const potentialAnmNo1s = [];
            for (let option of animalsOptions) {
                const {name} = option;
                //niestety ten warunek jest konieczny, bo inaczej wywali gdy swinia bedzie miala numer undefined czy cuś
                if (typeof name === "string" && name.startsWith(value)) {
                    potentialAnmNo1s.push(option);
                }
            }
            if (isEmpty(potentialAnmNo1s)) {
                this.setState({
                    createNewAnimalMod: true,
                    initialSearchValue: ""
                }, () => {
                    this.props.change("newAnmNo1", value);
                });
            } else {
                this.setState({
                    createNewAnimalMod: false,
                    initialSearchValue: value
                }, () => {
                    this.props.change("newAnmNo1", undefined);
                });
            }
        } else if (!value && !isEmpty(animalsOptions)) {
            this.setState({
                createNewAnimalMod: false,
                initialSearchValue: value
            }, () => {
                this.props.change("newAnmNo1", undefined);
            })
        } else {
            this.props.change("setFeeding", false);
        }
    };

    onMobileAnmNo1Change = (value) => {
        if (value) {
            this.props.change("feeding", this.initializeFeeding(this.props, {no1: value}));
            this.props.change("setFeeding", true);
        } else {
            this.props.change("setFeeding", false);
        }
    };

    // jesli edytujesz ta funkcje zmien tez w RFIDTransferStand!!!
    initializeFeeding = (props, additionalProps = {}, removeCurveTime = false) => {
        const {animal, curves, farm, sectorType} = props;
        const {no1} = additionalProps;
        const anm = cloneDeep(isMobile() ? animalsDB.getAnimalByAnmNo1(no1, farm) : animal);
        if (removeCurveTime) {
            set(anm, "feedParam.curveStart", undefined);
        }
        return initializeFeeding({animal: anm, curves, sectorType});
    };

    getBoxName = (props) => {
        const {standing, chamber} = props;
        return chamber.Boxes.find(box => box.BID === standing.id).BoxesName;
    };

    getAnmLocation = (anm) => {
        let locations = animalsDB.getAnimalLocationsByPlcmntID(anm.PlcmntID);
        return locations.map(location => location.name).join(", ");
    };

    onSelectAnimalChange = (value) => {
        if (isNil(value)) {
            let initialSearchValue = cloneDeep(this.state.initialSearchValue);
            this.setState({
                createNewAnimalMod: true,
                initialSearchValue: ""
            }, () => {
                this.props.change("newAnmNo1", initialSearchValue);
                this.props.change("animal", null);
            });
        } else {
            return value;
        }
    };

    // odkomentowac jak bedzie potrzeba
    // renderInfoBox = (key, {startTime, stage, curveNr} = {}) => {
    //     const {t, curves} = this.props;
    //     const data = initializeFeeding({
    //         curves,
    //         sectorType: "override",
    //         animal: {
    //             feedParam: {
    //                 startTime,
    //                 stage,
    //                 curveNr
    //             }
    //         }
    //     }, true);
    //     const curveType = get(data, "curve.SetData.Type");
    //     const curveName = curveNr ? get(data, "curve.SetData.Name", t("settings.unknownCurveOnDevice")) : t("deviceRows.feeding.dispenserRow.stop");
    //     const json = {
    //         [t("feedingCurve")]: curveName
    //     };
    //     switch (curveType) {
    //         case CurveType.INDIVIDUAL:
    //             json[t("curveDay")] = data.curveDay;
    //             break;
    //         case CurveType.MATING:
    //             json[t("inseminationDay")] = data.repetitionDate;
    //             break;
    //         case CurveType.PARTURITION:
    //             json[t("parturitionDate")] = data.repetitionDate;
    //             break;
    //         default:
    //             break;
    //     }
    //     return <Card key={key} shadow={false} colorDensity={300}>
    //         <strong>{t(key)}</strong>
    //         <JsonToTable json={json}/>
    //     </Card>
    //
    // }

    render() {
        const {submitting, handleHide, show, t, standing: {device, animal: standingAnimal}, setFeeding, curve, chamber, anmNo1, farm, animal, newAnmNo1} = this.props;
        const {animalsOptions, curvesOptions, optionsLoaded, createNewAnimalMod, initialSearchValue} = this.state;
        const showingType = !device || getCurveDayShowingType(curve);
        const curveType = !device || get(curve, "SetData.Type");
        const mobile = isMobile();
        let _animal = mobile && animalsDB.checkIfAnimalExistOnFarm(anmNo1, farm);
        if (_animal) {
            _animal = animalsDB.getAnimalByAnmNo1(anmNo1, farm);
        }
        // const animalFeedParam = get(mobile ? _animal : animal, "feedParam");
        return (
            <Modal onHide={handleHide} show={show} size="lg">
                <ModalHeader title={`${t("modals.assignAnimalToStandingModal.title")} - ${this.getBoxName(this.props)}`}
                             onCloseClick={handleHide}/>
                <ModalBody isLoading={!optionsLoaded} className={ModalName}>
                    {
                        !!standingAnimal && <InfoBox boxColor={"warning"}>
                            {
                                t("modals.assignAnimalToStandingModal.animalPresent", {
                                    animal: standingAnimal.AnmNo1,
                                    chamber: chamber.CName
                                })
                            }
                        </InfoBox>
                    }
                    {
                        isMobile() && !_animal && anmNo1 && !isEqual(_animal, standingAnimal) &&
                        <InfoBox boxColor={"success"}>
                            {
                                t("modals.assignAnimalToStandingModal.willBeCreated", {
                                    number: anmNo1
                                })
                            }
                        </InfoBox>
                    }
                    {
                        isMobile() && !!animal && animal !== "+" && !newAnmNo1 && !isEqual(_animal, standingAnimal) ?
                            <InfoBox boxColor={"info"}>
                                {
                                    t("modals.assignAnimalToStandingModal.animalGoal", {
                                        AnmNo1: _animal.AnmNo1,
                                        PlcmntID: this.getAnmLocation(_animal)
                                    })
                                }
                            </InfoBox> :
                            !!animal && !newAnmNo1 && !isEqual(animal, standingAnimal) ?
                                <InfoBox boxColor={"info"}>
                                    {
                                        t("modals.assignAnimalToStandingModal.animalGoal", {
                                            AnmNo1: animal.AnmNo1,
                                            PlcmntID: this.getAnmLocation(animal)
                                        })
                                    }
                                </InfoBox> : null
                    }
                    {
                        mobile && <Field
                            name="anmNo1"
                            component={ReduxLabeledInput}
                            label={t("modals.assignAnimalToStandingModal.animal")}
                            onChange={this.onMobileAnmNo1Change}
                            required
                        />
                    }
                    {
                        !createNewAnimalMod && !mobile ?
                            <Field
                                name="animal"
                                onChange={this.onSelectAnimalChange}
                                component={ReduxLabeledSelect}
                                label={t("modals.assignAnimalToStandingModal.animal")}
                                options={animalsOptions}
                                clearButton={false}
                                placeholder={t("modals.assignAnimalToStandingModal.insertAnimalNumber")}
                                initialSearch={initialSearchValue}
                                onSearchChange={this.onSearchChange}
                                autofocus={true}
                            /> : !mobile && <Field
                            name="newAnmNo1"
                            component={ReduxLabeledInput}
                            label={t("newAnimalNumber")}
                            onChange={this.onSearchChange}
                            autofocus={true}
                            required
                        />
                    }
                    <>
                        {
                            !!device &&
                            <Row className={"align-items-center justify-content-end"}>
                                <Col className={"col-auto"}>
                                    <Field
                                        name="setFeeding"
                                        component={ReduxSwitch}
                                        disabled={createNewAnimalMod && !isMobile() ? !newAnmNo1 : !isMobile() ? !animal : !anmNo1}
                                        reverseOrder={true}
                                        label={t("configureCurveFeeding")}
                                    />
                                </Col>
                            </Row>
                        }
                        {
                            !!setFeeding &&
                            <FormSection name={"feeding"}>
                                {/*{this.renderInfoBox("events.transferEvent.currentLocation", feedParam)}*/}
                                {/*{this.renderInfoBox("events.transferEvent.newAnimal", animalFeedParam)}*/}
                                <Field
                                    name="curve"
                                    id="curve"
                                    required
                                    component={ReduxLabeledSelect}
                                    clearButton={false}
                                    placeholder={t("modals.assignAnimalToStandingModal.insertFeedingCurve")}
                                    options={curvesOptions}
                                    label={t("feedingCurve")}
                                />
                                {
                                    (showingType === CurveDayShowingType.NORMAL) &&
                                    <>
                                        <Field
                                            name="curveDay"
                                            label={t("curveDay")}
                                            id="curveDay"
                                            type={"number"}
                                            placeholder={t("modals.assignAnimalToStandingModal.insertCurveDay")}
                                            required
                                            format={(value) => `${value}`}
                                            parse={(value) => isFinite(parseInt(value)) ? parseInt(value) : value}
                                            component={ReduxLabeledInput}
                                        />
                                    </>
                                }
                                {
                                    (showingType !== CurveDayShowingType.NORMAL) &&
                                    <Field
                                        name="repetitionDate"
                                        label={showingType === CurveDayShowingType.BEFORE ? (CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.plannedInseminationDate") : t("modals.feedingManageFeedingModal.plannedParturitionDate")) : CurveTypesWithInsemination.includes(curveType) ? t("modals.feedingManageFeedingModal.inseminationDate") : t("modals.feedingManageFeedingModal.parturitionDate")}
                                        id="repetitionDate"
                                        placeholder={t("modals.feedingManageFeedingModal.plannedDate")}
                                        type={"date"}
                                        component={ReduxLabeledInput}
                                    />
                                }
                            </FormSection>
                        }
                    </>
                </ModalBody>
                <ModalFooter submitting={submitting} onCloseClick={handleHide} hasConfirmButton={true}
                             confirmText={t("modals.assignAnimalToStandingModal.assign")}
                             formName={ModalName}/>
            </Modal>
        );
    }

}

AssignAnimalToStandingModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate,
    destroyOnUnmount: true
})(AssignAnimalToStandingModal);

const selector = formValueSelector(ModalName);

AssignAnimalToStandingModal = connect(state => ({
    farm: state.location.farm,
    animal: selector(state, "animal"),
    curve: selector(state, "feeding.curve"),
    setFeeding: selector(state, "setFeeding"),
    newAnmNo1: selector(state, "newAnmNo1"),
    anmNo1: selector(state, "anmNo1"), //tylko dla mobilki
    shadows: state.shadows.shadows,
    curves: getFeedingCurves(state),
    time: state.animals.time
}))(AssignAnimalToStandingModal);
AssignAnimalToStandingModal = connectModal({name: ModalName})(AssignAnimalToStandingModal);
export default withTranslation()(AssignAnimalToStandingModal);
