import {
    updateTranslations,
    updateTranslationsNotificationFailure,
    updateTranslationsNotificationSuccess
} from "../../../api/translations/updateTranslations";
import {stringBuilder} from "../../../utils/TextUtils";
import {getTranslation} from "../../../actions/localizationActions";
import {get} from "lodash";
import {flatObject} from "../../../selectors/translateSelectors";

export function submit(values, dispatch, props) {
    const {locale} = props;
    const {addNewObject, path, showTextArea, newObjectKey, newDataObject, newDataArray} = values;
    let p = path.object.path;
    if (addNewObject) {
        if (p !== "") {
            p += `.${newObjectKey}`;
        } else {
            p += `${newObjectKey}`;
        }
    }
    let data = [];
    if (showTextArea) {
        let obj = JSON.parse(newDataObject);
        for (let key in obj) {
            data.push({lang: locale, path: `${p}.${key}`, value: obj[key]});
        }
    } else {
        data = newDataArray.map(item => ({
            lang: locale,
            path: `${p}` !== "" ? `${p}.${item.key}` : `${item.key}`,
            value: item.value
        }));
    }
    return updateTranslations(data).then(res => {
        updateTranslationsNotificationSuccess(res);
        dispatch(getTranslation([locale], false));
        props.handleHide();
    }).catch(e => {
        updateTranslationsNotificationFailure(e);
    })
}

export function validate(values, props) {
    const errors = {};
    const {t, language} = props;

    if (!values.newDataObject) {
        errors.newDataObject = t("required")
    } else {
        try {
            let obj = JSON.parse(values.newDataObject);
            if (values.path) {
                let path = values.path.object.path;
                if (values.newObjectKey) {
                    path += "." + values.newObjectKey;
                }
                let array = flatObject(obj, path);
                for (let row of array) {
                    if (get(language, row.path)) {
                        errors.newDataObject = t("errors.foundKeyDuplicate", {key: row.path});
                    }
                }
            }
        } catch (e) {
            errors.newDataObject = t("errors.cantParse");
        }
    }

    if (!values.newObjectKey) {
        errors.newObjectKey = t("required");
    }

    if (!values.path) {
        errors.path = t("required");
    }

    if (values.newDataArray) {
        errors.newDataArray = values.newDataArray.map(item => {
            const fieldErrors = {};

            if (!item.key) {
                fieldErrors.key = t("required");
            } else {
                if (values.path) {
                    let path = values.path.object.path;
                    if (values.newObjectKey) {
                        path += "." + values.newObjectKey;
                    }
                    path += "." + item.key;
                    if (get(language, path)) {
                        fieldErrors.key = t("errors.foundKeyDuplicate", {key: path});
                    }
                }
            }

            if (!item.value) {
                fieldErrors.value = t("required");
            }

            let rule = props.i18n.services.pluralResolver.getRule(props.locale).numbers;
            if (rule.length === 2) {
                if (item.generated && !item.key.includes("_plural")) {
                    fieldErrors.key = stringBuilder(t("errors.mustInclude"), "_plural");
                }
                if ((item.generated || item.count) && !item.value.includes("{{count}}")) {
                    fieldErrors.value = stringBuilder(t("errors.mustInclude"), "{{count}}");
                }
            } else {
                if (item.count && !item.key.includes("_0")) {
                    fieldErrors.key = stringBuilder(t("errors.mustInclude"), "_0");
                }
                if (item.index && !item.key.includes(`_${item.index}`)) {
                    fieldErrors.key = stringBuilder(t("errors.mustInclude"), `_${item.index}`);
                }
                if ((item.index || item.count) && !item.value.includes("{{count}}")) {
                    fieldErrors.value = stringBuilder(t("errors.mustInclude"), "{{count}}");
                }
            }

            return fieldErrors;
        })
    }

    return errors;
}

export function warn(values, props) {
    const warnings = {};
    const {t, validationLanguage} = props;
    try {
        let obj = JSON.parse(values.newDataObject);
        if (values.path) {
            let path = values.path.object.path;
            if (values.newObjectKey) {
                path += "." + values.newObjectKey;
            }
            let array = flatObject(obj, path);
            console.log(array);
            for (let row of array) {
                let hasSameValue = validationLanguage.find(validation => validation.value === row.value);
                if (hasSameValue) {
                    warnings.newDataObject = t("errors.foundValueDuplicate", {key: hasSameValue.path});
                }
            }
        }
    } catch (e) {
    }
    if (values.newDataArray) {
        warnings.newDataArray = values.newDataArray.map(item => {
            const fieldWarnings = {};

            if (item.value) {
                let hasSameValue = validationLanguage.find(validation => validation.value === item.value.trim().toLowerCase());
                if (hasSameValue) {
                    fieldWarnings.value = t("errors.foundValueDuplicate", {key: hasSameValue.path});
                }
            }
            return fieldWarnings;
        })
    }
    console.log(warnings);
    return warnings;
}