import React from "react";
import EventData from "./EventData";
import PropTypes from "prop-types";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {Field} from "redux-form";
import {withTranslation} from "react-i18next";

export class MommyData extends React.Component {

    render() {
        const {member, t} = this.props;
        return (
            <EventData member={member}>
                <Field
                    name={member ? `${member}.EvData.PiCnt` : "EvData.PiCnt"}
                    component={ReduxLabeledInput}
                    type="number"
                    label={t("eventData.piglets")}
                    parse={value => value ? +value : null}
                />
            </EventData>
        );
    }

}

MommyData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(MommyData);