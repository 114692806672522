import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./_log-chip.scss"
import {getIconClassName} from "../../utils/LogsViewUtils";
import Tooltip from "../basics/tooltip/Tooltip";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class LogChip extends Component {

    handleSelect = () => {
        const {dispatch, value, onSelect, selected, onRemove} = this.props;
        dispatch((onRemove && selected) ? onRemove(value) : onSelect(selected ? null : value));
    }

    name = () => {
        const {name, search} = this.props;
        const searchLowered = search.trim().toLowerCase();
        const nameLowered = name.toLowerCase();
        const searchSize = searchLowered.length;
        const splitLowered = nameLowered.split(searchLowered);
        const maxIndex = splitLowered.length - 1;
        const newName = [];
        let start = 0;
        const searchOrg = name.substr(nameLowered.indexOf(searchLowered), searchLowered.length);
        splitLowered.forEach((o = "", i) => {
            console.log(start, o.length, name.substr(start, o.length))
            newName.push(name.substr(start, o.length));
            start += o.length;
            if (maxIndex !== i) {
                start += Math.max(0, searchSize);
                newName.push(<span className={"font-weight-bold"}>{searchOrg}</span>)
            }
        })
        return newName;
    }

    render() {
        const {name, selected, type, value, t} = this.props;
        const className = ["fetura-log-chip", selected ? "selected" : ""].filter(o => !!o).join(" ");
        return (
            <Tooltip tooltipContent={selected ? t("logsView.removeFilter", {name}) : t("logsView.addFilter", {name})}>
                <div className={className} onClick={this.handleSelect}
                     id={selected ? `selected-${value}` : `item-${value}`}>
                    <i className={getIconClassName(type)}/>{selected ? name : this.name()}<i
                    className={selected ? "pl-1 fas fa-fw fa-times" : "pl-1"}/>
                </div>
            </Tooltip>

        );
    }
}
export default compose(
    withTranslation(),
    connect(state => ({
        search: state.logs.search
    }))
)(LogChip);
