import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {withCookies} from "react-cookie";
import {connectModal} from "redux-modal";
import {withTranslation} from "react-i18next";
import DispenserNRF from "../../../beans/devices/DispenserNRF";
import {get, memoize} from "lodash";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {Col, Modal, Row} from "react-bootstrap";
import {convertVolumeUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import Card from "../../basics/card/Card";
import moment from "moment";
import {setAdditionalWater} from "../../../IOT/device-functions/DispenserNRFFunctions";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import ModalFooter from "../ModalFooter";

export const ModalName = "feeding-extra-water-modal";

const selector = formValueSelector(ModalName);

const AmountOptions = [250, 500, 750, 1000, 1500, 2000]; //ilosc wody w ml

function mapStateToProps(state) {
    return {
        amount: selector(state, "amount"),
        index: selector(state, "index"),
    };
}

class FeedingExtraWaterModal extends Component {


    notSupportedNames = memoize((selectedNodes) => {
        return selectedNodes.filter(o => {
            if (o.device instanceof DispenserNRF) {
                return !o.device.hasWater();
            }
            return true;
        }).map(o => o.name);
    }, (...args) => JSON.stringify(args))

    componentWillUnmount() {
        const {cookies, amount} = this.props;
        //clear cache to save dat ram
        this.notSupportedNames.cache.clear();
        //save last selected value to cookies
        cookies.set(ModalName, {amount: amount || 500}, {
            path: "/",
            expires: moment().add(1, "year").toDate()
        });
    }

    componentDidMount() {
        const {initialize, cookies} = this.props;
        const newAmount = +get(cookies.get(ModalName), "amount", 500);
        const newIndex = AmountOptions.indexOf(newAmount);
        initialize({amount: newAmount, index: newIndex !== -1 ? newIndex : AmountOptions.length});
    }

    handleChange = (index) => {
        const {change} = this.props;
        const isOption = index < AmountOptions.length;
        change("index", index);
        if (isOption) {
            change("amount", AmountOptions[index]);
        }
    }

    formatter = (value) => convertVolumeUnitTo(value, {showUnit: true, fixed: 1, unit: UnitTypes.SMALL});

    render() {
        const {handleHide, handleSubmit, show, t, amount, index, submitting} = this.props;
        const showSlider = index >= AmountOptions.length;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("extraWater")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <Row>
                            {
                                AmountOptions.map((value, idx) => (
                                    <Col xs={6} sd={4} md={3} key={idx}>
                                        <Card onClick={() => this.handleChange(idx)}
                                              selected={!showSlider && amount === value}>
                                            {this.formatter(value)}
                                        </Card>
                                    </Col>

                                ))
                            }
                            <Col xs={6} sd={4} md={3}>
                                <Card onClick={() => this.handleChange(2137)} selected={showSlider}>
                                    {t("modals.feedingExtraWater.other")}
                                </Card>
                            </Col>
                        </Row>
                        {
                            showSlider && <Field
                                name={"amount"}
                                id={"amount"}
                                min={250}
                                step={10}
                                max={10000}
                                label={t("modals.feedingExtraWater.amount")}
                                component={ReduxLabeledSlider}
                                valueFormatter={this.formatter}
                            />
                        }

                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("start")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                        submitting={submitting}
                    />

                </form>
            </Modal>
        );
    }
}

const submit = (values, dispatch, props) => {
    const {amount, selectedNodes, receivers, handleHide} = props;
    const allowedDevIDs = selectedNodes.filter(o => {
        if (o.device instanceof DispenserNRF) {
            return !!o.device.hasWater();
        }
        return false;
    }).map(o => o.device.DevID);
    return Promise.all([...receivers.values()].map(dispensers => {
        return new Promise((resolve, reject) => {
            const deviceIDs = dispensers.individualNRF.devices.filter(DevID => allowedDevIDs.includes(DevID));
            let id;
            if (deviceIDs.length) {
                id = setAdditionalWater(deviceIDs, {amount}, {onFailure: reject, onSuccess: resolve});
            }
            if (!id) resolve();
        })
    })).then(res => {
        handleHide();
    }).catch(err=>{
        console.error(err);
    })
}

FeedingExtraWaterModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired
};

FeedingExtraWaterModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    initialValues: {
        amount: AmountOptions[0],
        index: 0
    }
})(FeedingExtraWaterModal);
FeedingExtraWaterModal = connect(mapStateToProps)(FeedingExtraWaterModal);

FeedingExtraWaterModal = withCookies(connectModal({name: ModalName})(FeedingExtraWaterModal));

export default withTranslation()(FeedingExtraWaterModal);
