import {addLocation} from "../../../actions/farmsActions";
import {generateBuilding, generateChamber, generateSector, generateStandings} from "../../../utils/BuildingUtils";
import _ from "lodash";

export function submit(values, dispatch, props) {
    console.log(values, props, "DJIJIOJSAOIJDAIODSIOJ");
    const {parent, handleHide} = props;
    //pobieramy rodzaj parenta
    const key = ["CID", "SID", "BgID", "BID", "FarmID"].filter(k => !!parent[k])[0];
    let location;
    switch (key) {
        case "CID":
            location = generateStandings({
                name: values.locationName,
                startFrom: +values.boxFrom,
                amount: +values.boxAmount
            });
            break;
        case "SID":
            location = generateChamber({
                name: values.locationName,
                individualFeeding: values.chamberIndividualFeeding,
                standingsInRow: +values.chamberStandingInRow,
                standingsOrder: +values.chamberStandingsOrder,
                chamberSize: values.chamberSize,
                createStandings: !!(values.chamberStandingFrom && values.chamberStandingSize),
                standingsAmount: +values.chamberStandingSize,
                standingsFrom: +values.chamberStandingFrom,
                standingsName: values.chamberStandingName,

            });
            break;
        case "BgID":
            location = generateSector({
                name: values.locationName,
                type: +values.sectorType
            });
            break;
        case "FarmID":
            location = generateBuilding({
                name: values.locationName,
                FarmID: props.parent.FarmID
            });
            break;
        default:
            throw new Error("not implemented");

    }
    return dispatch(addLocation(location, parent[key], () => handleHide()));
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const {locationName, sectorType, chamberSize, chamberStandingInRow, boxFrom, boxAmount, chamberStandingFrom, chamberStandingSize, chamberStandingsOrder, chamberStandingName} = values;
    //all locations
    if (!locationName) {
        errors.locationName = t("required");
    }
    //sector
    if (!sectorType || +sectorType <= 0) {
        errors.sectorType = t("required");
    }
    //chamber
    if (!chamberStandingName) {
        errors.chamberStandingName = t("required");
    }
    if (chamberStandingName && chamberStandingName.length > 16) {
        errors.chamberStandingName = t("errors.standingError");
    }
    if (!chamberSize || +chamberSize <= 0) {
        errors.chamberSize = t("required");
    }
    if (!chamberStandingFrom || +chamberStandingFrom <= 0) {
        errors.chamberStandingFrom = t("required");
    }
    if (!chamberStandingInRow || +chamberStandingInRow <= 0) {
        errors.chamberStandingInRow = t("required");
    }
    if (!chamberStandingSize || +chamberStandingSize <= 0) {
        errors.chamberStandingSize = t("required");
    }
    if (+chamberStandingSize > 200) {
        errors.chamberStandingSize = t("errors.chamberSize");
    }
    if (_.isNil(chamberStandingsOrder) || +chamberStandingsOrder < 0) {
        errors.chamberStandingsOrder = t("required");
    }
    if (!boxAmount || +boxAmount <= 0) {
        errors.boxAmount = t("required");
    }
    if (!boxFrom || +boxFrom < 0) {
        errors.boxFrom = t("required");
    }
    return errors;
}
