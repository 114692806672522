import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {getKeyValueLogDict} from "../../../selectors/logSelector";
import {Col, Collapse, Row} from "react-bootstrap";
import {memoize, shuffle, uniq} from "lodash";
import {utc} from "moment";
import RightDrawer from "../../right-drawer/RightDrawer";
import JsonToTable from "../../basics/json-to-table/JsonToTable";

function mapStateToProps(state) {
    return {
        names: getKeyValueLogDict(state)
    };
}

export const HelperIconID = "helper-icon";

class LogTutorialDetails extends Component {

    getItems = memoize((items) => {
        return shuffle(uniq(items)).slice(0, 5);
    }, (items) => JSON.stringify(items).slice(0, 25))

    constructor(props) {
        super(props);
        this.state = {
            expanded: {},
        }
    }

    toggleAllExamples = (key) => {
        const expanded = {...this.state.expanded};
        if (expanded[key]) {
            expanded[key] = expanded[key] === "showExamples" ? true : "showExamples";
            this.setState({
                expanded
            })
        }


    }

    toggleKey = (key) => {
        const expanded = {...this.state.expanded};
        expanded[key] = !expanded[key];
        this.setState({
            expanded
        })
    }

    renderField = (key, icon, header, text, items = [], fullList = []) => {
        const {expanded = {}} = this.state;
        const {t} = this.props;
        const isExpanded = !!expanded[key];
        const showExamples = expanded[key] === "showExamples";
        return <Col key={key} xs={12}>
            <Row>
                <Col xs={12} onClick={() => this.toggleKey(key)} className={" opacity-75 mb-1 mt-1"}>
                    <i className={`fas fa-fw pr-1 ${isExpanded ? "fa-chevron-down" : "fa-chevron-right"}`}/>
                    <i className={icon}/>
                    <strong className={"ml-1"}>{header}</strong>
                </Col>
                <Collapse in={isExpanded}>
                    <div>
                        <Col xs={12} className={"ml-4 mb-2 mt-2"}>
                            <p>
                                {text}
                            </p>
                        </Col>
                        {
                            items.length && <Col xs={12} className={"ml-4 text-break"}>
                                <i>{t("logsView.example")}</i>
                                <div>
                                    <code>{items.join(", ")}</code>
                                </div>
                                {
                                    !!fullList.length && <>
                                        <i onClick={() => this.toggleAllExamples(key)} className={"pointer"}>{t("logsView.allExamples")}</i>
                                        {
                                            showExamples &&
                                            <JsonToTable json={fullList}/>
                                        }
                                    </>
                                }
                            </Col>
                        }

                    </div>
                </Collapse>
            </Row>
        </Col>
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.opened && (prevProps.run !== this.props.run) && (this.props.run)) {
            this.setState({
                opened: false
            })
        }
    }

    render() {
        const {names, t, opened, toggleOpen} = this.props;
        const dataObject = [
            {
                key: "animals",
                icon: "fas fa-pig fa-fw",
                header: t("userRolePicker.animals"),
                text: t("logsView.details.animalsDesc"),
                items: this.getItems(Object.values(names.animals))
            },
            {
                key: "devices",
                icon: "fas fa-microchip fa-fw",
                header: t("newSettings.users.changeRolesView.devices"),
                text: t("logsView.details.devicesDesc"),
                items: this.getItems(Object.values(names.devices))
            },
            {
                key: "settings",
                icon: "fas fa-cog fa-fw",
                header: t("settings.settings"),
                text: t("logsView.details.settingsDesc"),
                items: this.getItems(Object.values(names.settings))
            },
            {
                key: "dictionary",
                icon: "fas fa-book fa-fw",
                header: t("modals.authorization.dictionaries"),
                text: t("logsView.details.dictionaryDesc"),
                items: this.getItems(Object.values(names.dictionaries.NAMES.DICTIONARY))
            },
            {
                key: "medicine",
                icon: "fas fa-pills fa-fw",
                header: t("medicines"),
                text: t("logsView.details.medicineDesc"),
                items: this.getItems(Object.values(names.dictionaries.NAMES.MEDICINE))
            },
            {
                key: "controlList",
                icon: "fas fa-list-alt fa-fw",
                header: t("controlLists.mainView.header"),
                text: t("logsView.details.controlListDesc"),
                items: this.getItems(Object.values(names.dictionaries.NAMES.CONTROLLIST))
            },
            {
                key: "graftingPrograms",
                icon: "fas fa-syringe fa-fw",
                header: t("logsView.details.graftingPrograms"),
                text: t("logsView.details.graftingProgramsDesc"),
                items: this.getItems(Object.values(names.dictionaries.NAMES.GRAFTINGPROGRAM))
            },
            {
                key: "ingredients",
                icon: "fas fa-wheat fa-fw",
                header: t("logsView.details.ingredients"),
                text: t("logsView.details.ingredientsDesc"),
                items: this.getItems(uniq(Object.values(names.dictionaries.NAMES.INGREDIENT)))
            },
            {
                key: "buildings",
                icon: "fas fa-farm fa-fw",
                header: t("buildings"),
                text: t("logsView.details.buildingsDesc"),
                items: this.getItems(uniq(Object.values(names.buildings)))
            }
        ]
        const date = utc().startOf("day")
        const dateObject = [
            {
                key: "date",
                icon: "fas fa-calendar-day fa-fw",
                header: t("lastScannedRFID.days"),
                text: t("logsView.details.dateDesc"),
                items: [date.format("DD.MM.YY"), date.clone().subtract(5, "days").format("DD.MM.YY")]
            }
        ]

        const userObject = [
            {
                key: "users",
                icon: "fas fa-user fa-fw",
                header: t("users"),
                text: t("logsView.details.usersDesc"),
                items: this.getItems(uniq(Object.values(names.employees)))
            }
        ]
        const codes = t([`logsView.shortNames`, "{}"], {returnObjects: true})
        const codeObject = [
            {
                key: "codes",
                icon: "fas fa-text fa-fw",
                header: t("logsView.details.codes"),
                text: t("logsView.details.codesDesc"),
                items: this.getItems(uniq(codes.filter(o => !!o))),
                fullList: uniq(codes.filter(o => !!o)).sort()
            }
        ]
        return (
            <RightDrawer
                icon={"fa-fw fas fa-question"}
                iconId={HelperIconID}
                onToggle={toggleOpen}
                header={t("help")}
                opened={opened}>
                <Row>
                    <Col xs={12}>
                        {t("logsView.description")}
                        <h4>{t("logsView.details.objects")}</h4>
                        <i className={"opacity-75"}>{t("logsView.max1item")}</i>
                        {
                            dataObject.map(o => this.renderField(o.key, o.icon, o.header, o.text, o.items))
                        }
                        <h4>{t("logsView.details.time")}</h4>
                        <i className={"opacity-75"}>{t("logsView.max1item")}</i>
                        {
                            dateObject.map(o => this.renderField(o.key, o.icon, o.header, o.text, o.items))
                        }
                        <h4>{t("logsView.details.user")}</h4>
                        <i className={"opacity-75"}>{t("logsView.max1item")}</i>
                        {
                            userObject.map(o => this.renderField(o.key, o.icon, o.header, o.text, o.items))
                        }
                        <h4>{t("logsView.details.codes")}</h4>
                        <i className={"opacity-75"}>{t("logsView.maxLogItem")}</i>
                        {
                            codeObject.map(o => this.renderField(o.key, o.icon, o.header, o.text, o.items, o.fullList))
                        }
                    </Col>
                </Row>
            </RightDrawer>
        );
    }
}

LogTutorialDetails = connect(
    mapStateToProps,
)(LogTutorialDetails);

export default withTranslation()(LogTutorialDetails);
