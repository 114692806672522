import React from "react";
import {Field} from "redux-form";
import ReduxLabeledSlider from "../../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {withTranslation} from "react-i18next";

export class RemanentListFields extends React.Component {

    render() {
        const {t} = this.props;
        return (
            <Field
                name={"DefaultActiveNipples"}
                component={ReduxLabeledSlider}
                label={t("modals.editControlListSettingsModal.defaultActiveNipples")}
                min={1}
                max={15}
            />
        );
    }

}

export default withTranslation()(RemanentListFields);