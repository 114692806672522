import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LogLinkedDetail from "./LogLinkedDetail";
import {Col, Row} from "react-bootstrap";
import {cloneDeep, isArray} from "lodash";
import {LogParser} from "../../../beans/LogParser";
import {getKeyValueLogDict} from "../../../selectors/logSelector";
// import settingsDB from "../../../database/settingsDB";
// import {LogTableTypes} from "../../../constans/logCodeTypes";
import {connect} from 'react-redux';
import "./_log-linked-ids.scss"
import {compose} from "redux";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        dictionaries: getKeyValueLogDict(state)
    }
}

class LogLinkedIDs extends Component {


    state = {
        selected: null
    }

    onSelect = ({ID, TableName}) => {
        const {selected} = this.state;
        if (selected && selected.ID === ID) {
            this.onClear();
            return;
        }
        this.setState({
            selected: {
                ID,
                TableName
            }
        })
    }

    onClear = () => {
        this.setState({
            selected: null
        })
    }

    renderRow = ({ID, TableName}) => {
        const {dictionaries, t} = this.props;
        const {iconClassName, name} = cloneDeep(LogParser.createLogParser({
            ObjID: ID,
            TableName: TableName
        }, dictionaries).getData());
        return (
            <Col xs={12}>
                <Row>
                    <Col xs={2} className={"d-flex position-relative justify-content-center align-items-center"}>
                        <i className={`${iconClassName} fa-2x opacity-25`}/>
                        <div>
                            <strong>{TableName}</strong>
                        </div>
                    </Col>
                    <Col className={"d-flex justify-content-center align-items-center flex-column"}>
                        {name}
                        <i className={"opacity-50"}>ID: {ID}</i>
                    </Col>
                    <Col className={"d-flex justify-content-center align-items-center"}>
                        <i className={"pointer"}
                           onClick={() => this.onSelect({ID, TableName})}>{t("logsView.showObject")}</i>
                    </Col>
                </Row>
            </Col>

        )
    }

    render() {
        const {log: {LinkedIDs = []} = {}, t} = this.props;
        const {selected} = this.state;
        if (!isArray(LinkedIDs) || LinkedIDs.length === 0) return null;
        return (
            <div className={"log-linked-ids"}>
                <h4 className={"justify-content-center"}>{t("logsView.linkedObjects")}</h4>
                <Col xs={12}>
                    {
                        LinkedIDs.map(({ID, TableName}) =>
                            <div className={`linked-id${!!selected && (selected.ID === ID) ? " selected" : ""}`}>
                                {this.renderRow({ID, TableName})}
                                {
                                    !!selected && (selected.ID === ID) &&
                                    <Col xs={12}>
                                        {t("logsView.databaseObject")}
                                        <LogLinkedDetail
                                            onClear={this.onClear}
                                            key={`${selected.ID}_${selected.TableName}`}
                                            ID={selected.ID}
                                            TableName={selected.TableName}/>
                                    </Col>

                                }
                            </div>)
                    }
                </Col>
            </div>
        );
    }
}

LogLinkedIDs.propTypes = {
    log: PropTypes.object.isRequired
};

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(LogLinkedIDs);
