import React, {Component} from 'react';
import {connect} from 'react-redux';
import {connectModal} from "redux-modal";
import {Field, reduxForm} from "redux-form";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import {submit} from "./SiloSettingsModalSubmit"
import {withTranslation} from "react-i18next";

export const ModalName = "silo-settings-modal"

function mapStateToProps(state) {
    return {
        shadows: state.shadows.shadows,
    };
}

class SiloSettingsModal extends Component {

    constructor(props) {
        super(props);
        const {siloses, shadows} = this.props;
        try {
            let shadow = shadows.get(siloses[0].device.DevID);
            let minWeight = shadow.configuration.minWeights[siloses[0].index].value || 1000;
            this.props.initialize({
                weight: minWeight
            })
        } catch (e) {
            console.error(e);
            this.props.initialize({
                weight: 0
            })
        }
    }


    render() {
        const {handleHide, show, t} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.siloSettings.title")}
                    onCloseClick={handleHide}/>
                <ModalBody>
                    <Field
                        name="weight"
                        component={ReduxLabeledSlider}
                        min={0}
                        max={5 * 1000 * 1000}
                        step={100 * 1000}
                        label={t("modals.siloSettings.weight")}
                        valueFormatter={value => value !== null && value !== undefined ? value === 0 ? t("modals.siloSettings.alarmOff") : convertWeightUnitTo(value, {
                            unit: UnitTypes.BIG,
                            showUnit: true,
                            fixed: 1
                        }) : "-"}
                    />
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }
}

SiloSettingsModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(SiloSettingsModal);

SiloSettingsModal = connect(
    mapStateToProps,
)(SiloSettingsModal);

SiloSettingsModal = connectModal({name: ModalName})(SiloSettingsModal);

export default withTranslation()(SiloSettingsModal);
