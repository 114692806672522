import React from "react";
import {connectModal} from "redux-modal";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import {Col, Modal, Row} from "react-bootstrap";
import {ModalHeader} from "../ModalHeader";
import ModalBody from "../ModalBody";
import {connect} from "react-redux";
import ModalFooter from "../ModalFooter";
import {submit, validate, warn} from "./AddTranslationRowModalSubmit"
import ReduxTreeSelect from "../../basics/tree-select/ReduxTreeSelect";
import ReduxSwitch from "../../basics/switch/ReduxSwitch";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReduxInput from "../../basics/input/ReduxInput";
import Button from "../../basics/button/Button";
import InfoBox from "../../basics/info-box/InfoBox";
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";
import {withTranslation} from "react-i18next";
import {getTranslationSelector, validateTranslationSelector} from "../../../selectors/translateSelectors";
import {compose} from "redux";

export const ModalName = 'add-translation-row-modal';
const selector = formValueSelector(ModalName);

function mapStateToProps(state, props) {
    return {
        language: getTranslationSelector(state, props.locale),
        validationLanguage: validateTranslationSelector(state, props.locale),
        addNewObject: selector(state, "addNewObject"),
        showTextArea: selector(state, "showTextArea"),
        path: selector(state, "path"),
        newDataArray: selector(state, "newDataArray")
    }
}

export class AddTranslationRowModal extends React.Component {

    state = {
        tree: this.createTreeData(this.props.language)
    }

    constructor(props) {
        super(props);

        this.props.initialize({
            addNewObject: false,
            showTextArea: false,
            newDataArray: [{}]
        })
    }


    createTreeData(language, path = "") {
        let array = [];
        if (typeof language === "object") {
            for (let key in language) {
                if (language[key] && typeof language[key] !== "string") {
                    let p = path ? `${path}.${key}` : key;
                    array.push({
                        name: "key",
                        object: {
                            key,
                            path: p,
                            object: language[key]
                        },
                        key: "path",
                        children: this.createTreeData(language[key], p)
                    })
                }
            }
        }
        if (path === "") {
            array.push({
                name: "name",
                object: {
                    name: this.props.t("modals.addTranslation.new"),
                    path,
                    isNew: true
                },
                key: "path",
                children: []
            });
        }
        return array;
    }

    onChangeCount = (event, newValue, previousValue, name) => {
        const {newDataArray, i18n, locale} = this.props;
        let index = +name.split("[")[1].split("]")[0];
        let array = []
        if (newValue) {
            for (let i = 0; i < newDataArray.length; i++) {
                if (index === i) {
                    let rule = i18n.services.pluralResolver.getRule(locale).numbers;
                    if (rule.length === 2) {
                        array.push({...newDataArray[i], value: (newDataArray[i].value || "") + "{{count}}"});
                        array.push({generated: true, parent: index, key: "_plural", value: "{{count}}"});
                    } else {
                        array.push({
                            ...newDataArray[i],
                            key: (newDataArray[i].key || "") + "_0",
                            value: (newDataArray[i].value || "") + "{{count}}"
                        });
                        for (let j = 1; j < rule.length; j++) {
                            array.push({generated: true, parent: index, key: `_${j}`, index: j, value: "{{count}}"});
                        }
                    }
                } else {
                    array.push(newDataArray[i]);
                }
            }
        } else {
            array = newDataArray.filter(item => item.parent !== index);
        }
        this.props.change("newDataArray", array);
    }

    renderFieldArray = ({fields}) => (
        <div>
            <Row>
                <Col xs={10}>
                    <Row>
                        <Col xs={6}>{this.props.t("modals.addTranslation.key")}</Col>
                        <Col xs={6}>{this.props.t("value")}</Col>
                    </Row>
                </Col>
                <Col xs={1}>Licznik</Col>
                <Col xs={1}/>
            </Row>
            {
                fields.map((member, index) => {
                    let values = fields.get(index);
                    console.log(values);
                    return (
                        <Row>
                            <Col xs={10}>
                                <Row>
                                    <Col xs={6}>
                                        <Field
                                            name={`${member}.key`}
                                            component={ReduxInput}
                                            type="text"
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Field
                                            name={`${member}.value`}
                                            component={ReduxInput}
                                            type="text"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={1} style={{paddingTop: ".25rem"}}>
                                {
                                    !values.generated &&
                                    <Field
                                        component={ReduxCheckbox}
                                        name={`${member}.count`}
                                        id={`${member}.count`}
                                        label=""
                                        onChange={this.onChangeCount}
                                    />
                                }
                            </Col>
                            <Col xs={1} style={{paddingTop: ".25rem"}}>
                                {
                                    !values.generated &&
                                    <Button buttonStyle={"round"} icon={<i className="fas fa-trash pointer"/>}
                                            onClick={() => fields.remove(index)} disabled={fields.length === 1}/>
                                }
                            </Col>
                        </Row>
                    )
                })
            }
            <Button icon={<i className="fas fa-plus"/>}
                    onClick={() => fields.push({})}>{this.props.t("modals.addTranslation.addNext")}</Button>
        </div>
    )

    hasValueType(type) {
        const {path} = this.props;
        if (path) {
            if (path.object.isNew) return true;
            for (let key in path.object.object) {
                if (typeof path.object.object[key] === type) {
                    return true;
                }
            }
        }
        return false;
    }

    render() {
        const {show, handleHide, handleSubmit, submitting, addNewObject, showTextArea, t} = this.props;
        const {tree} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"xl"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={t("modals.addTranslation.title")} onCloseClick={handleHide}/>
                    <ModalBody>
                        <Field
                            name="path"
                            component={ReduxTreeSelect}
                            options={tree}
                        />
                        <Row>
                            <Col sm={6}>
                                <Field
                                    name="addNewObject"
                                    component={ReduxSwitch}
                                    label={t("modals.addTranslation.addNewObject")}
                                    id="addNewObject"
                                />
                            </Col>
                            <Col sm={6}>
                                <Field
                                    name="showTextArea"
                                    component={ReduxSwitch}
                                    label={t("modals.addTranslation.addWithObject")}
                                    id="showTextArea"
                                />
                            </Col>
                        </Row>
                        {
                            addNewObject &&
                            <>
                                {
                                    !this.hasValueType("object") &&
                                    <InfoBox boxColor={"warning"}>{t("modals.addTranslation.noObjectWarning")}</InfoBox>
                                }
                                <Field
                                    name="newObjectKey"
                                    component={ReduxLabeledInput}
                                    type="text"
                                    label={t("modals.addTranslation.newKeyName")}
                                />
                            </>
                        }
                        {
                            !addNewObject && !this.hasValueType("string") &&
                            <InfoBox boxColor={"warning"}>{t("modals.addTranslation.noStringWarning")}</InfoBox>
                        }
                        {
                            showTextArea &&
                            <Field
                                name="newDataObject"
                                component={ReduxLabeledInput}
                                type="textarea"
                                label={t("value")}
                            />
                        }
                        {
                            !showTextArea &&
                            <FieldArray name="newDataArray" component={this.renderFieldArray}/>
                        }
                    </ModalBody>
                    <ModalFooter hasConfirmButton confirmText={t("save")}
                                 onCloseClick={handleHide}
                                 formName={ModalName} submitting={submitting}/>
                </form>
            </Modal>
        )
    }
}

export default compose(
    withTranslation(),
    connectModal({name: ModalName}),
    connect(mapStateToProps),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate,
        warn
    })
)(AddTranslationRowModal);
