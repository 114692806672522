import React, {Component} from 'react';
import {get} from "lodash";
import {withTranslation} from "react-i18next";
import LogDetailCodeHeader from "../LogDetailCodeHeader";
import TableGrid from "../../../basics/table-grid/TableGrid";
import {percentageFormatter} from "../../../../views/new-settings-view/settings/climate-sk-3/settings/utils";
import {timeFormatter} from "../utils";

class UpdateNutritionScheduleDetail extends Component {


    render() {
        const {log: {ObjNew, ObjOld}, code, t} = this.props;
        const sizeA = get(ObjNew, "SetData.Doses.length", 0);
        const sizeB = get(ObjOld, "SetData.Doses.length", 0);
        const size = Math.max(sizeA, sizeB);
        const dataA = [];
        const dataB = [];
        for (let i = 0; i < size; i++) {
            if (i < sizeA) {
                dataA.push({
                    name: t("dosage", {count: i + 1}),
                    start: get(ObjNew, `SetData.Doses[${i}].Start`),
                    stop: get(ObjNew, `SetData.Doses[${i}].Stop`),
                    dosage: get(ObjNew, `SetData.Doses[${i}].Percent`),
                })
            }
            if (i < sizeB) {
                dataB.push({
                    name: t("dosage", {count: i + 1}),
                    start: get(ObjOld, `SetData.Doses[${i}].Start`),
                    stop: get(ObjOld, `SetData.Doses[${i}].Stop`),
                    dosage: get(ObjOld, `SetData.Doses[${i}].Percent`),
                })
            }

        }
        const headers = [
            {
                name: t("designation"),
                field: "name"
            },
            {
                name: t("newSettings.feeding.plans.editDoses.startTime"),
                field: "start",
                valueFormatter: timeFormatter
            },
            {
                name: t("newSettings.feeding.plans.editDoses.stopTime"),
                field: "stop",
                valueFormatter: timeFormatter
            },
            {
                name: t("dosage"),
                field: "dosage",
                valueFormatter: percentageFormatter
            }
        ]
        // const json = [{
        //     // [t("dosage")]: [],
        //     // [t("$t(dosage):")]: [{
        //     //     [t("before")]: [],
        //     //     [t("after")]: []
        //     // }],
        //     // [t("newSettings.feeding.plans.editDoses.startTime")]: [{
        //     //     [t("before")]: [],
        //     //     [t("after")]: []
        //     // }],
        //     // [t("newSettings.feeding.plans.editDoses.stopTime")]: [{
        //     //     [t("before")]: [],
        //     //     [t("after")]: []
        //     // }],
        //     [t("$t(dosage)")]: [],
        //     [t("$t(dosage) $t(before)")]: [],
        //     [t("$t(dosage) $t(after)")]: [],
        //     [t("$t(newSettings.feeding.plans.editDoses.startTime) $t(before)")]: [],
        //     [t("$t(newSettings.feeding.plans.editDoses.startTime) $t(after)")]: [],
        //     [t("$t(newSettings.feeding.plans.editDoses.stopTime) $t(before)")]: [],
        //     [t("$t(newSettings.feeding.plans.editDoses.stopTime) $t(after)")]: [],
        // }]
        // for (let i = 0; i < size; i++) {
        // json[t("dosage", {count: i + 1})] = {
        //     [t("dosage")]: {
        //         [t("before")]: get(ObjOld, `SetData.Doses[${i}].Percent`, t("none")),
        //         [t("after")]: get(ObjOld, `SetData.Doses[${i}].Percent`, t("none")),
        //     },
        //     [t("newSettings.feeding.plans.editDoses.startTime")]: {
        //         [t("before")]: get(ObjOld, `SetData.Doses[${i}].Start`, t("none")),
        //         [t("after")]: get(ObjOld, `SetData.Doses[${i}].Start`, t("none")),
        //     },
        //     [t("newSettings.feeding.plans.editDoses.stopTime")]: {
        //         [t("before")]: get(ObjOld, `SetData.Doses[${i}].Stop`, t("none")),
        //         [t("after")]: get(ObjOld, `SetData.Doses[${i}].Stop`, t("none")),
        //     }
        // }
        // json[0][t("dosage")].push(i+1);
        // json[0][t("$t(dosage) $t(before)")].push(get(ObjOld, `SetData.Doses[${i}].Percent`, t("none")));
        // json[0][t("$t(dosage) $t(after)")].push(get(ObjNew, `SetData.Doses[${i}].Percent`, t("none")));
        // json[0][t("$t(newSettings.feeding.plans.editDoses.startTime) $t(before)")].push(get(ObjOld, `SetData.Doses[${i}].Start`, t("none")));
        // json[0][t("$t(newSettings.feeding.plans.editDoses.startTime) $t(after)")].push(get(ObjNew, `SetData.Doses[${i}].Start`, t("none")));
        // json[0][t("$t(newSettings.feeding.plans.editDoses.stopTime) $t(before)")].push(get(ObjOld, `SetData.Doses[${i}].Stop`, t("none")));
        // json[0][t("$t(newSettings.feeding.plans.editDoses.stopTime) $t(after)")].push(get(ObjNew, `SetData.Doses[${i}].Stop`, t("none")));
        // json[0][t("dosage")].push(i + 1);
        // json[0][t("$t(dosage):")][0][t("before")].push(get(ObjOld, `SetData.Doses[${i}].Percent`, t("none")));
        // json[0][t("$t(dosage):")][0][t("after")].push(get(ObjNew, `SetData.Doses[${i}].Percent`, t("none")));
        // json[0][t("newSettings.feeding.plans.editDoses.startTime")][0][t("before")].push(get(ObjOld, `SetData.Doses[${i}].Start`, t("none")));
        // json[0][t("newSettings.feeding.plans.editDoses.startTime")][0][t("after")].push(get(ObjNew, `SetData.Doses[${i}].Start`, t("none")));
        // json[0][t("newSettings.feeding.plans.editDoses.stopTime")][0][t("before")].push(get(ObjOld, `SetData.Doses[${i}].Stop`, t("none")));
        // json[0][t("newSettings.feeding.plans.editDoses.stopTime")][0][t("after")].push(get(ObjNew, `SetData.Doses[${i}].Stop`, t("none")));
        // }

        return (
            <div>
                <LogDetailCodeHeader code={code}/>
                <h4>{t("before")}</h4>
                <TableGrid data={dataB} headers={headers}/>
                <h4>{t("after")}</h4>
                <TableGrid data={dataA} headers={headers}/>
            </div>
        );
    }
}

export default withTranslation()(UpdateNutritionScheduleDetail);
