import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {connectModal} from "redux-modal";
import ModalFooter from "../ModalFooter";
import CageWeightConfigurationModalPage1 from "./pages/CageWeightConfigurationModalPage1";
import {doTare, setScale} from "../../../IOT/device-functions/SeparationCageFunctions";
import CageWeightConfigurationModalPage2 from "./pages/CageWeightConfigurationModalPage2";
import CageWeightConfigurationModalPage3 from "./pages/CageWeightConfigurationModalPage3";
import {reset} from "redux-form"
import "./_cage-weight-configuration.scss";
import {convertWeightToBaseUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

export const ModalName = "cage-weight-configurator";

@connect()
export class CageWeightConfiguratorModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            step: 1,
            loading: false
        };

        this.props.dispatch(reset(ModalName));
        ReactGA.modalview(ModalName);
    }

    onFailure = () => {
        this.setState({
            loading: false
        })
    };

    onTareSuccess = () => {
        this.setState(state => ({
            step: state.step + 1,
            loading: !state.loading
        }))
    };

    onFirstPageSubmit = (values) => {
        const {cage} = values;
        this.setState({
            loading: true
        });
        doTare(cage, this.onTareSuccess, this.onFailure);
    };

    onScaleSuccess = () => {
        this.setState(state => ({
            loading: false,
            step: state.step + 1
        }));
    };

    onSecondPageSubmit = values => {
        const {cage, weight} = values;
        this.setState({
            loading: true
        });
        setScale(cage, convertWeightToBaseUnit(+weight, {fromUnit: UnitTypes.MEDIUM}), this.onScaleSuccess, this.onFailure);
    };

    onNoClick = () => {
        this.setState({
            step: 1
        })
    };

    render() {
        const {step, loading} = this.state;
        const {show, handleHide} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={this.props.t("modals.cageWeightConfiguration.title")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName} isLoading={loading}>
                    {
                        step === 1 &&
                        <CageWeightConfigurationModalPage1 onSubmit={this.onFirstPageSubmit}/>
                    }
                    {
                        step === 2 &&
                        <CageWeightConfigurationModalPage2 onSubmit={this.onSecondPageSubmit}/>
                    }
                    {
                        step === 3 &&
                        <CageWeightConfigurationModalPage3 onYesClick={handleHide} onNoClick={this.onNoClick}/>
                    }
                </ModalBody>
                <ModalFooter onCloseClick={handleHide} hasConfirmButton={step !== 3}
                             confirmText={this.props.t("modals.cageWeightConfiguration.next")} formName={ModalName}
                             submitting={loading}/>
            </Modal>
        );
    }

}

CageWeightConfiguratorModal = connectModal({name: ModalName})(CageWeightConfiguratorModal);

export default withTranslation()(CageWeightConfiguratorModal);