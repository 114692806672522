import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, formValueSelector, isDirty, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import {connect} from "react-redux";
import {submit, validate} from "./ClimateWorkTypeModalSubmit";
import ReactGA from "react-ga";
import {get} from "lodash"
import {withTranslation} from "react-i18next";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import ReduxButtonPicker from "../../basics/redux-button-picker/ReduxButtonPicker";
import Button from "../../basics/button/Button";
import InfoBox from "../../basics/info-box/InfoBox";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {getClimateCurves} from "../../../selectors/device-rows/climateSelector";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ClimateCurveSK3 from "../../../beans/settings/ClimateCurveSK3";
import ClimateCurveChartSK3 from "../../charts/ClimateCurveChartSK3";
import {convertTemperatureUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import Setting from "../../../beans/Setting";
import {SettingTypes} from "../../../constans/settingTypes";

export const ModalName = "climate-work-type-modal";

const WorkTypes = ["inactive", "manual", "automatic", "curve"];

function makeMapStateToProps(initialState, initialProps) {
    const getShadowByDevice = makeGetShadowByDevice();
    const {climates} = initialProps;
    const params = {DevID: climates[0].DevID}
    const selector = formValueSelector(ModalName);
    return function mapStateToProps(state) {
        return {
            ...getShadowByDevice(state, params),
            dirty: isDirty(ModalName)(state),
            workType: selector(state, "workType"),
            currentCurveDay: selector(state, "currentCurveDay"),
            curve: selector(state, "curve"),
            climateCurves: getClimateCurves(state)
        };
    }
}

export class ClimateWorkTypeModal extends React.Component {

    constructor(props) {
        super(props);
        const {t} = this.props;
        this.deviceCurve = Setting.createSetting(SettingTypes.CLIMATE_SK3_CURVE, {
            Name: t("settings.unknownCurveOnDevice"),
            Stages: []
        })
        ReactGA.modalview(ModalName);
    }


    componentDidMount() {
        const {initialize} = this.props;
        initialize(this.getValues());
    }

    getValues = () => {
        const {shadow, climateCurves} = this.props;
        const stages = get(shadow, "climateCurve.stages", []);
        this.deviceCurve.setBackendStages(stages);
        return {
            workType: WorkTypes[get(shadow, "ambientConditions.workType", 0)],
            requestedTemperature: get(shadow, "configuration.requestedTemperature", 20),
            requestedTemperatureDeviation: get(shadow, "configuration.requestedTemperatureDeviation", 0),
            maximumVentilation: get(shadow, "configuration.ventilationConfiguration.maximumVentilation", 100),
            minimumVentilation: get(shadow, "configuration.ventilationConfiguration.minimumVentilation", 10),
            minimumVentilationDeviation: get(shadow, "configuration.ventilationConfiguration.minimumVentilationDeviation", 0),
            currentCurveDay: get(shadow, "climateCurve.currentCurveDay", 1) || 1,
            manual: get(shadow, "configuration.manual", 90),
            curve: climateCurves.find(curve => curve.equalCheck(stages)) || this.deviceCurve.SetData.Stages.length === 8 ? this.deviceCurve : null
        }
    }

    getCurveOptions = () => {
        const {climateCurves} = this.props
        const stages = this.deviceCurve.getBackendStages();
        return [...((this.deviceCurve.SetData.Stages.length === 8 ? climateCurves.find(curve => curve.equalCheck(stages)) : true) ? [] : [this.deviceCurve]), ...climateCurves].map(o => ({
            value: o,
            name: o.SetData.Name
        }))
    }

    temperatureFormatter = (value) => {
        return convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            fixed: 1,
            showUnit: true
        })
    }

    ventilationFormatter = (value) => `${value.toFixed(1)}%`

    render() {
        const {show, handleHide, t, initialized, workType, curve, currentCurveDay, submitting} = this.props;
        const curveOptions = this.getCurveOptions();
        const translation = [
            "deviceRows.chainFeeding.chainFeedingRow.workTypes.inactive",
            "deviceRows.chainFeeding.chainFeedingRow.workTypes.manual",
            "deviceRows.chainFeeding.chainFeedingRow.workTypes.automatic",
            "modals.feedingHistoryModal.curve"
        ]
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("worktype")}
                    onCloseClick={handleHide}/>
                <ModalBody className={`${ModalName} mh-5rem`} isLoading={!initialized}>
                    <label>{t("worktype")}</label>
                    <Field
                        name="workType"
                        component={ReduxButtonPicker}
                    >
                        {
                            WorkTypes.map((value, i) => (
                                <Button buttonStyle={"text"}
                                        value={value}>{t(translation[WorkTypes.indexOf(value)])}</Button>
                            ))
                        }
                    </Field>
                    {
                        workType === "inactive" &&
                        <>
                            <InfoBox boxColor={"info"}>
                                {t("farmView.climate.inactiveDesc")}
                            </InfoBox>

                        </>
                    }
                    {
                        workType === "manual" &&
                        <>
                            <InfoBox boxColor={"info"}>
                                {t("farmView.climate.manualDesc")}
                            </InfoBox>
                            <Field
                                name={"manual"}
                                id={"manual"}
                                label={t("settings.manualOperation")}
                                min={0}
                                max={100}
                                step={0.2}
                                valueFormatter={this.ventilationFormatter}
                                component={ReduxLabeledSlider}
                            />
                        </>
                    }
                    {
                        workType === "automatic" &&
                        <>
                            <InfoBox boxColor={"info"}>
                                {t("farmView.climate.automaticDesc")}
                            </InfoBox>
                            <Field
                                name={"requestedTemperature"}
                                id={"requestedTemperature"}
                                label={t("requestedTemperature")}
                                min={10}
                                max={50}
                                step={0.2}
                                valueFormatter={this.temperatureFormatter}
                                component={ReduxLabeledSlider}
                            />
                            <Field
                                name={"minimumVentilation"}
                                id={"minimumVentilation"}
                                label={t("minimumVentilation")}
                                min={0}
                                max={50}
                                step={0.2}
                                valueFormatter={this.ventilationFormatter}
                                component={ReduxLabeledSlider}
                            />
                            <Field
                                name={"maximumVentilation"}
                                id={"maximumVentilation"}
                                label={t("maximumVentilation")}
                                min={50.6}
                                max={100}
                                step={0.2}
                                valueFormatter={this.ventilationFormatter}
                                component={ReduxLabeledSlider}
                            />
                        </>
                    }
                    {
                        workType === "curve" &&
                        <>
                            <InfoBox boxColor={"info"}>
                                {t("farmView.climate.curveDesc")}
                            </InfoBox>
                            <ClimateCurveChartSK3 curveDay={currentCurveDay}
                                                  stages={curve instanceof ClimateCurveSK3 ? curve.getBackendStages() : []}/>
                            <Field
                                name={"curve"}
                                id={"curve"}
                                label={t("climateCurve")}
                                options={curveOptions}
                                disableSort
                                component={ReduxLabeledSelect}
                            />
                            <Field
                                name={"currentCurveDay"}
                                id={"currentCurveDay"}
                                label={t("curveDay")}
                                min={1}
                                max={curve instanceof ClimateCurveSK3 ? curve.getLength() : 1}
                                step={1}
                                component={ReduxLabeledSlider}
                            />
                            <Field
                                name={"requestedTemperatureDeviation"}
                                id={"requestedTemperatureDeviation"}
                                label={t("settings.desiredTempDeviation")}
                                min={-10}
                                max={10}
                                step={0.2}
                                valueFormatter={this.temperatureFormatter}
                                component={ReduxLabeledSlider}
                            />
                            <Field
                                name={"minimumVentilationDeviation"}
                                id={"minimumVentilationDeviation"}
                                label={t("settings.minVentingDeviation")}
                                min={-50}
                                max={50}
                                step={0.2}
                                valueFormatter={this.ventilationFormatter}
                                component={ReduxLabeledSlider}
                            />

                        </>
                    }
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    submitting={submitting}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

ClimateWorkTypeModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(ClimateWorkTypeModal);

ClimateWorkTypeModal = connect(makeMapStateToProps)(ClimateWorkTypeModal);

ClimateWorkTypeModal = connectModal({name: ModalName})(ClimateWorkTypeModal);

export default withTranslation()(ClimateWorkTypeModal);
