import {get, isString} from "lodash";
import devicesDB from "../../../database/devicesDB";
import buildingsDB from "../../../database/buildingsDB";
import animalsDB from "../../../database/animalsDB";
import groupsDB from "../../../database/groupsDB";
import {validate as curveValidator} from "../feeding-manage-feeding/FeedingManageFeedingSubmit";
import {prepareFeeding} from "../../../utils/FeedingUtils";
import {DevType} from "../../../constans/devices";
import {
    createTransfer,
    createTransferNotificationFailure,
    createTransferNotificationSuccess
} from "../../../api/events/Transfer";
import {isMobile} from "../../../utils/MobileUtils";
import {AnimalTypes} from "../../../constans/animalTypes";
import Animal from "../../../beans/Animal";
import Event from "../../../beans/Event";
import {createAnimalDynamoDB} from "../../../actions/animalsActions";
import moment from "moment";
import {EventTypes} from "../../../constans/eventTypes";
import {createEvent} from "../../../actions/eventsActions";
import {SectorType} from "../../../constans/sectorTypes";

export function submit(values, dispatch, props) {
    const {animal, setFeeding, feeding, newAnmNo1, anmNo1} = values;
    const {standing, chamber, handleHide, farm} = props;
    let created = false;
    let animalObject = {};
    let sectorType = buildingsDB.getSectorTypeByChamberID(chamber.CID);
    let renovationSectorType = sectorType === SectorType.RENOVATION_SOWS;
    if (!isMobile() && !newAnmNo1) {
        animalObject = animal;
        created = false;
    } else if (!isMobile() && newAnmNo1) {
        animalObject = Animal.createAnimal(newAnmNo1, renovationSectorType ? AnimalTypes.RENOVATION_SOW : AnimalTypes.SOW, 1, farm, chamber.CID);
        created = true;
    } else if (isMobile() && anmNo1) {
        let exist = animalsDB.checkIfAnimalExistOnFarm(anmNo1, farm);
        if (exist) {
            animalObject = animalsDB.getAnimalByAnmNo1(anmNo1, farm);
            created = false;
        } else {
            animalObject = Animal.createAnimal(anmNo1, renovationSectorType ? AnimalTypes.RENOVATION_SOW : AnimalTypes.SOW, 1, farm, chamber.CID);
            created = true;
        }
    } else {
        created = false;
    }

    const getDevicesByPlacementID = (plcmntID) => {
        if (isString(plcmntID)) {
            return devicesDB.getDevicesInPlcmntID(plcmntID, {showDevicesInChildren: false}).map(o => ({
                GwID: o.GatewayID,
                DevID: o.DevID
            }))
        } else {
            return [];
        }
    };

    const getGrID = (animalId) => {
        return (!!animalId && get(groupsDB.getGroupWithAnimal(animalId, farm), "[0].GrID")) || undefined
    };

    const dataNRF = prepareFeeding({
        curve: get(feeding, "curve"),
        curveDay: get(feeding, "curveDay"),
        doseCorrection: get(feeding, "percentCorrection") || 0,
        punishment: get(feeding, "punishment") || 0,
        repetitionDate: get(feeding, "repetitionDate"),
        stage: get(feeding, "stage") || 0
    }, DevType.DISPENSER_NRF);


    const requestBody = {
        AnmID: animalObject.AnmID,
        transferTo: standing.id,
        amount: 1,
        GrID: getGrID(animalObject.AnmID),
        EvTime: +new Date(),
        stand: true,
        clearFeeding: isString(animalObject.PlcmntID) ? !!get(buildingsDB.getLocationByID(animalObject.PlcmntID), "BID") : false,
        animalInStand: standing.animal ? {
            AnmID: standing.animal.AnmID,
            transferTo: chamber.CID,
            GrID: getGrID(standing.animal.AnmID),
            DevIDsOldPlcmnt: getDevicesByPlacementID(standing.animal.PlcmntID),
            DevIDsNewPlcmnt: getDevicesByPlacementID(chamber.CID)
        } : undefined,
        DevIDsNewPlcmnt: [{GwID: standing.device.GatewayID, DevID: standing.device.DevID}],
        DevIDsOldPlcmnt: getDevicesByPlacementID(animalObject.PlcmntID),
        feeding: setFeeding ? {
            curveNumber: dataNRF.curveNr,
            doseCorrection: dataNRF.percentCorrection,
            curveStart: dataNRF.startTime,
            punishment: dataNRF.punishment,
            stage: dataNRF.stage
        } : undefined
    };

    if (created) {
        dispatch(createAnimalDynamoDB({animals: [animalObject]}, {
            onSuccess: ({animals: animalsToSendOnDynamoDB, response}) => {
                console.log(animalsToSendOnDynamoDB, response);
                if (!renovationSectorType) {
                    animalsToSendOnDynamoDB.forEach((anm) => {
                        const event = Event.createEvent(anm.AnmID, EventTypes.SOW_CYCLES, anm.AnmCnt, +moment(), {
                            Cycles: 1,
                            LastSeparation: +moment()
                        });
                        dispatch(createEvent({events: [event]}, {type: EventTypes.SOW_CYCLES}));
                    });
                }
                createTransfer([requestBody]).then(r => {
                    createTransferNotificationSuccess(r);
                }).catch(e => {
                    createTransferNotificationFailure(e);
                });
            }
        }));
        handleHide();
    } else {
        return createTransfer([requestBody]).then(r => {
            handleHide();
            createTransferNotificationSuccess(r);
        }).catch(e => {
            createTransferNotificationFailure(e);
        });
    }
}

export function validate(values, props) {
    const {t} = props;
    //cba with writing validator all over again so im gonna use feeding modal validator for curve - ok mate
    const errors = {feeding: curveValidator(values.feeding || {}, props)};
    const {standing: {animal: standingAnimal}, farm} = props;
    const {animal, newAnmNo1, anmNo1} = values;
    if (!animal) {
        errors.animal = t("required");
    }
    if (!!standingAnimal && !!animal && standingAnimal.AnmNo1 === animal.AnmNo1) {
        errors.animal = t("modals.assignAnimalToStandingModal.isAssigned");
    }
    if (!newAnmNo1) {
        errors.newAnmNo1 = t("required");
    }
    if (!anmNo1) {
        errors.anmNo1 = t("required");
    }
    if (!!standingAnimal && standingAnimal.AnmNo1 === anmNo1) {
        errors.anmNo1 = t("modals.assignAnimalToStandingModal.isAssigned");
    }
    if (!!standingAnimal && standingAnimal.AnmNo1 === newAnmNo1) {
        errors.newAnmNo1 = t("modals.assignAnimalToStandingModal.isAssigned");
    }
    if (newAnmNo1) {
        if (animalsDB.checkIfAnimalExistOnFarm(newAnmNo1, farm)) {
            errors.newAnmNo1 = t("errors.exist");
        }
    }
    return errors;
}
