import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {get, has} from "lodash";
import {medicineTypes} from "../../../../constans/medicine";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";

class UpdateMedicineDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const typeFormatter = (value) => {
            const types = {
                [medicineTypes.STIMULATOR]: t("modals.medicineModal.stimulator"),
                [medicineTypes.FORAGE]: t("medicalFeed"),
                [medicineTypes.VACCINE]: t("modals.medicineModal.vaccine"),
                [medicineTypes.DOSATRON]: t("dosatron"),
            }
            return types[value];
        }
        return [
            {
                name: t("modals.medicineModal.type"),
                value: has(obj, "WData.Type") && typeFormatter(get(obj, "WData.Type"))
            },
            {
                name: t("designation"),
                value: has(obj, "WData.Name") && get(obj, "WData.Name")
            },
            {
                name: t("dosage"),
                value: has(obj, "WData.Dose") && get(obj, "WData.Dose")
            },
            {
                name: t("unit"),
                value: has(obj, "WData.Unit") && get(obj, "WData.Unit")
            },
            {
                name: t("modals.medicineModal.gracePeriod"),
                value: has(obj, "WData.GracePeriod") && get(obj, "WData.GracePeriod")
            },
            {
                name: t("price"),
                value: has(obj, "WData.Price") && get(obj, "WData.Price")
            },
            {
                name: t("color"),
                value: has(obj, "WData.Color") && get(obj, "WData.Color")
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateMedicineDetail);
