import {addNotification} from "reapop/lib/store/notifications";
import Paths from "../../../api/paths";
import {invokeApig} from "../../../libs/awsLib";
import {validateEmail} from "../../../utils/TextUtils";
import i18next from "i18next";

export async function submit(values, dispatch, props, notify = {}) {
    //console.log("VALUES", values);
    try {
        await invokeApig({
            ...Paths.sendEmail(),
            body: values
        });
        notify = {
            ...notify,
            title: i18next.t("notifications.mailSuccess"),
            status: 'success',
            dismissible: true,
            dismissAfter: 3000
        };
        dispatch(addNotification(notify));

    } catch (err) {
        notify.title = i18next.t("notifications.mailFailure");
        notify.message = err + "";
        notify.status = "error";
        notify.dismissible = true;
        notify.dismissAfter = 3000;
        dispatch(addNotification(notify));
    }

    props.handleHide();
}

export const validate = (values, props) => {
    const errors = {};
    const {t} = props;
    if (!values.email) {
        errors.email = t("required");
    } else {
        if (!validateEmail(values.email)) {
            errors.email = t("errors.invalidEmail");
        }
    }
    if (!values.message) {
        errors.message = t("required");
    } else {
        if (values.message.length < 10) {
            errors.message = i18next.t("errors.messageMustBeGreaterThan", {count: 10});
        }
    }
    return errors;
};