import _ from "lodash";
import {updateLocation} from "../../../actions/farmsActions";

export function submit(values, dispatch, props) {
    const {chamber} = values;
    const {forage} = props;
    let _chamber = _.cloneDeep(chamber);
    _chamber.forageID = forage.SetID;
    dispatch(updateLocation(_chamber));
    props.handleHide();
}