import React from "react";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {submit} from "./FeedingSkipDosesSubmit"
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";
import InfoBox from "../../basics/info-box/InfoBox";
import ReactGA from "react-ga";
import {DevType} from "../../../constans/devices";
import settingsDB from "../../../database/settingsDB";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = 'feeding-skip-doses-modal';


class FeedingSkipDosesModal extends React.Component {

    constructor(props) {
        super(props);
        const {selectedNodes, initialize} = this.props;
        let scheduleMap = new Map();
        const nonEligible = [];
        selectedNodes.forEach(node => {
            const scheduleId = _.get(node, "schedule.id");
            const deviceId = _.get(node, "receiver.deviceId");
            const deviceIndex = _.get(node, "receiver.index");
            let devType = _.isNil(deviceIndex) ? DevType.DISPENSER_NRF : DevType.DISPENSER;
            let boxName = _.get(node, "box.BoxesName");
            if (scheduleId /*&& (devType === DevType.DISPENSER)*/) {
                let values = scheduleMap.get(scheduleId) || {
                    dispensers: [],
                    standingNames: [],
                    skipDoses: this.getDoses(_.get(node, "skipDoses")),
                    schedule: settingsDB.getSettingByID(scheduleId, {showDeleted: true})
                };
                let index = _.findIndex(values.dispensers, (o) => o.DevID === deviceId);
                if (~index) {
                    values.dispensers[index].indexes = [...new Set([...values.dispensers[index].indexes, deviceIndex])].filter(o => !_.isNil(o));
                } else {
                    values.dispensers.push({
                        DevID: deviceId,
                        indexes: [deviceIndex].filter(o => !_.isNil(o)),
                        DevType: devType
                    })
                }
                values.standingNames = [...new Set([...values.standingNames, boxName])];
                scheduleMap.set(scheduleId, values);
            } else {
                nonEligible.push(boxName)
            }
        });
        initialize({
            schedules: [...scheduleMap.values()],
            nonEligible: nonEligible
        });
        ReactGA.modalview(ModalName);
    }

    getDoses = (doses = []) => {
        return new Array(6).fill(0).map((o, index) => !!doses[index]);
    };

    renderSchedules = ({fields, meta: {touched, error, submitFailed}}) => {
        return (
            <>
                {
                    fields.map((member, index) => (
                        <React.Fragment key={index}>
                            <InfoBox>
                                {i18next.t("modals.feedingSkipDosesModal.affectedStandings", {type: fields.get(index).standingNames.join(", ")})}
                            </InfoBox>
                            {
                                _.get(fields.get(index), "schedule.SetData.Doses", fields.get(index).skipDoses).map((dose, doseIndex) => (
                                    <Field
                                        key={doseIndex}
                                        name={`${member}.skipDoses[${doseIndex}]`}
                                        id={`${member}.skipDoses[${doseIndex}]`}
                                        component={ReduxCheckbox}
                                        label={
                                            _.isObject(dose) ?
                                                `${moment(dose.Start).format("HH:mm")}-${moment(dose.Stop).format("HH:mm")} (${dose.Percent}%)`
                                                : i18next.t("dosage", {count: doseIndex + 1})
                                        }
                                    />
                                ))
                            }
                            {
                                ((index + 1) < fields.length) && <hr/>
                            }
                        </React.Fragment>

                    ))
                }
            </>
        )
    };

    render() {
        const {show, handleHide, t, nonEligible = [], handleSubmit} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.feedingSkipDosesModal.titleSkipDoses")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <div>
                            {
                                !!nonEligible.length &&
                                <InfoBox boxColor={"warning"}>
                                    {
                                        i18next.t("modals.feedingSkipDosesModal.nonEligible", {type: nonEligible.join(", ")})
                                    }
                                </InfoBox>
                            }
                            <FieldArray name={"schedules"} component={this.renderSchedules}/>
                        </div>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("modals.feedingSkipDosesModal.set")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingSkipDosesModal.propTypes = {
    dispensers: PropTypes.array.isRequired,
    selectedNodes: PropTypes.array.isRequired
};

FeedingSkipDosesModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(FeedingSkipDosesModal);
const selector = formValueSelector(ModalName);
export const _FeedingSkipDosesModal = withTranslation()(connect(state => ({
    nonEligible: selector(state, "nonEligible")
}))(FeedingSkipDosesModal));
export default connectModal({name: ModalName})(_FeedingSkipDosesModal)
