export function submit(values, dispatch, props) {
    console.log(values, props, " <= custom role submit");
    const {chosenLocations} = values;
    const results = {
        type: 3,
        Plcmnts: [],
    };
    chosenLocations.forEach((item) => {
        const {locationID} = item;
        results.Plcmnts.push(locationID);
    });
    if (results.Plcmnts.length === 0) {
        results.type = 0;
    }
    props.onModalSubmit(results.type, results.Plcmnts);
    props.handleHide();
}
