import React from "react";
import {Modal} from "react-bootstrap";
import {Field, reduxForm} from "redux-form";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {DICTIONARY_TYPE} from "../../../constans/general";
import ColorPicker from "../../redux-color-picker/ColorPicker";
import {normalizeNIP} from "../../../utils/NormalizeFunctions";
import {submit, validate} from "./EditDictionaryModalSubmit";
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";

export const ModalName = "edit-dictionary-modal";

export class EditDictionaryModal extends React.Component {

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, handleHide, handleSubmit} = this.props;

        return (
            <Modal show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={`Edit ${this.props.title}`} onCloseClick={handleHide}/>
                    <ModalBody>
                        <Field
                            name="newValue"
                            id="newValue"
                            type="text"
                            label={this.props.t("designation")}
                            required
                            component={ReduxLabeledInput}
                        />
                        {
                            DICTIONARY_TYPE.medicine === this.props.type &&
                            <div>
                                <label>{this.props.t("color")}</label>
                                <Field
                                    name="color"
                                    id="color"
                                    component={ColorPicker}
                                />
                            </div>
                        }
                        {
                            [DICTIONARY_TYPE.fallReasons].includes(this.props.type) &&
                            <Field
                                name="abbreviation"
                                id="abbreviation"
                                type="text"
                                label={this.props.t("dictionariesView.abbreviation")}
                                required
                                component={ReduxLabeledInput}
                            />
                        }
                        {
                            DICTIONARY_TYPE.suppliers === this.props.type &&
                            <div>
                                <Field
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    label={this.props.t("phoneNumber")}
                                    required
                                    component={ReduxLabeledInput}
                                />
                                <Field
                                    name="address"
                                    id="address"
                                    type="text"
                                    label={this.props.t("address")}
                                    required
                                    component={ReduxLabeledInput}
                                />
                                <Field
                                    name="nip"
                                    id="nip"
                                    type="text"
                                    label="NIP"
                                    required
                                    component={ReduxLabeledInput}
                                    normalize={normalizeNIP}
                                />
                                <Field
                                    name="website"
                                    id="website"
                                    type="text"
                                    label="WWW"
                                    required
                                    component={ReduxLabeledInput}
                                />
                                <Field
                                    name="herdNumber"
                                    id="herdNumber"
                                    type="text"
                                    label={this.props.t("dictionariesView.herdNumber")}
                                    component={ReduxLabeledInput}
                                />
                            </div>
                        }
                    </ModalBody>

                    <ModalFooter hasConfirmButton confirmText={this.props.t("edit")}
                                 onCloseClick={handleHide}
                                 formName={ModalName}/>
                </form>
            </Modal>
        )
    }
}

EditDictionaryModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(EditDictionaryModal);
EditDictionaryModal = connectModal({name: ModalName})(EditDictionaryModal);
EditDictionaryModal = connect((state) => ({
    user: state.user.user,
    dictionary: state.dictionary,
    lang: state.language.lang.lang,
    userAttr: state.user.attributes,
    supportedLanguages: state.language.supportedLanguages,
}))(EditDictionaryModal);

export default withTranslation()(EditDictionaryModal)
export const _EditDictionaryModal_ = EditDictionaryModal;