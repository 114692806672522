import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import WeightChart from "../../charts/WeightChart";
import "./_comapre-insertion.scss";
import moment from "moment";
import LabeledSelect from "../../basics/select/labeled-select/LabeledSelect";
import GainChart from "../../charts/GainChart";
import PassageInDaysChart from "../../charts/PassageInDaysChart";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getLocationID} from "../../../utils/BuildingUtils";
import {getSettlementList} from "../../../selectors/settlementSelector";
import {compose} from "redux";

export const ModalName = "compare-insertion-modal";

export class CompareInsertionsModal extends React.Component {

    constructor(props) {
        super(props);
        const {settlements} = props;

        this.state = {
            insertion1: settlements[settlements.length - 2],
            insertion2: settlements[settlements.length - 1]
        }
        ReactGA.modalview(ModalName);
    }

    onSelectChange(value, name) {
        this.setState({
            [name]: value
        })
    }

    render() {
        const {show, handleHide, cage, chart, t, settlements} = this.props;
        const {insertion1, insertion2} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.compareInsertionModal.title")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <div className="compare-insertion-modal-controls">
                        <div>
                            <LabeledSelect label={t("modals.compareInsertionModal.insertion1")} value={insertion1}
                                           options={settlements.map((s) => ({
                                               name: s.DtaEndTime ? `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(s.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ...`,
                                               value: s
                                           }))} onChange={value => this.onSelectChange(value, "insertion1")}/>
                        </div>
                        <div>
                            <LabeledSelect label={t("modals.compareInsertionModal.insertion2")} value={insertion2}
                                           options={settlements.map((s) => ({
                                               name: s.DtaEndTime ? `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ${moment.utc(s.DtaEndTime).format("DD.MM.YYYY")}` : `${moment.utc(s.DtaStartTime).format("DD.MM.YYYY")} - ...`,
                                               value: s
                                           }))} onChange={value => this.onSelectChange(value, "insertion2")}/>
                        </div>
                    </div>
                    <div className="compare-insertion-modal-chart">
                        {
                            chart === "weight" &&
                            <WeightChart cage={cage} applyShadow={true} settlement={insertion1}
                                         shadowInsertion={insertion2}/>
                        }
                        {
                            chart === "gain" &&
                            <GainChart cage={cage} applyShadow={true} settlement={insertion1}
                                       shadowInsertion={insertion2}/>
                        }
                        {
                            chart === "dayPasses" &&
                            <PassageInDaysChart cage={cage} applyShadow={true} settlement={insertion1}
                                                shadowInsertion={insertion2}/>
                        }
                    </div>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }
}

export default compose(
    connectModal({name: ModalName}),
    withTranslation(),
    connect((state, props) => {
        const {cage} = props;
        if (cage) {
            let locations = cage.getLocation();
            let locID = getLocationID(locations[0]);
            return {
                settlements: getSettlementList(state, {CID: locID})
            }
        }
        return {
            settlements: []
        }
    })
)(CompareInsertionsModal);
