import React, {Component} from 'react'
import {Modal} from 'react-bootstrap'
import {Field, reduxForm} from 'redux-form'
import {connectModal} from 'redux-modal'
import ModalHeader from "../ModalHeader"
import ModalFooter from "../ModalFooter"
import {connect} from "react-redux"
import {submit, validate} from "./DictionaryModalSubmit";
import 'moment/locale/pl'
import {DICTIONARY_TYPE} from "../../../constans/general";
import {normalizeNIP} from "../../../utils/NormalizeFunctions";
import ModalBody from "../ModalBody";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";

export const ModalName = "dictionary-modal";

export class DictionaryModal extends Component {

    constructor(props) {
        super(props);

        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, handleHide, handleSubmit} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader title={this.props.title} onCloseClick={handleHide}/>
                    <ModalBody className="dictionary-modal">
                        <Field
                            name="newValue"
                            id="newValue"
                            type="text"
                            label={this.props.t("dictionariesView.newValue")}
                            required
                            component={ReduxLabeledInput}
                        />
                        {
                            [DICTIONARY_TYPE.fallReasons].includes(this.props.type) &&
                            <Field
                                name="abbreviation"
                                id="abbreviation"
                                type="text"
                                label={this.props.t("dictionariesView.abbreviation")}
                                required
                                component={ReduxLabeledInput}
                            />
                        }
                        {
                            DICTIONARY_TYPE.suppliers === this.props.type &&
                            <div>
                                <Field
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    label={this.props.t("phoneNumber")}
                                    required
                                    component={ReduxLabeledInput}
                                />
                                <Field
                                    name="address"
                                    id="address"
                                    type="text"
                                    label={this.props.t("address")}
                                    required
                                    component={ReduxLabeledInput}
                                />
                                <Field
                                    name="nip"
                                    id="nip"
                                    type="text"
                                    label="NIP"
                                    required
                                    component={ReduxLabeledInput}
                                    normalize={normalizeNIP}
                                />
                                <Field
                                    name="website"
                                    id="website"
                                    type="text"
                                    label="WWW"
                                    required
                                    component={ReduxLabeledInput}
                                />
                                <Field
                                    name="herdNumber"
                                    id="herdNumber"
                                    type="text"
                                    label={this.props.t("dictionariesView.herdNumber")}
                                    component={ReduxLabeledInput}
                                />
                            </div>
                        }

                    </ModalBody>

                    <ModalFooter hasConfirmButton confirmText={this.props.t("add")}
                                 onCloseClick={handleHide} formName={ModalName}/>
                </form>
            </Modal>
        );
    }

}

let _DictionaryModal = reduxForm({
    form: ModalName, // a unique identifier for this form
    onSubmit: submit, // submit function must be passed to onSubmit
    validate
})(DictionaryModal);
_DictionaryModal = connectModal({name: ModalName})(_DictionaryModal);
_DictionaryModal = connect((state, props) => ({
    user: state.user.user,
    dictionary: state.dictionary,
    lang: state.language.lang.lang,
    userAttr: state.user.attributes,
    supportedLanguages: state.language.supportedLanguages,
    supportLanguages: state.language.supportedLanguages,
    language: state.language.locale,
    languageFetched: state.language.fetched,
}))(_DictionaryModal);

export default withTranslation()(_DictionaryModal)