import React, {Component} from 'react';
import {getLogDetailRenderer} from "./utils";
import ViewContainerModal from "../../basics/view-containter-modal/ViewContainerModal";
import Card from "../../basics/card/Card";
import {Modal} from "react-bootstrap";
import ModalHeader from "../../modals-new/ModalHeader";
import ModalBody from "../../modals-new/ModalBody";
import ModalFooter from "../../modals-new/ModalFooter";
import {isMobile} from "../../../utils/MobileUtils";
import {withTranslation} from "react-i18next";
import LogLinkedIDs from "./LogLinkedIDs";
import {checkIfUserIsService} from "../../../utils/NewRolesUtils";
import ReactDOM from "react-dom";

export const SupportedTables = ["animals"];

class LogDetails extends Component {

    state = {
        expanded: false,
        mobile: isMobile()
    }

    onHide = () => {
        this.setState({
            expanded: false
        })
    }

    onShow = () => {
        this.setState({
            expanded: true
        })
    }

    getContent = () => {
        const {log} = this.props;
        const array = [];
        for (let code of log.Codes) {
            const component = getLogDetailRenderer(log, +code)
            if (!component) continue;
            //mergowanie tych samych komponentow bo i tak biora z tego samego loga dane XD
            const index = array.findIndex(o => (o.component.type === component.type))
            const item = array[index] || {
                props: {log: log, code: []},
                component
            };
            item.props.code.push(+code);
            (index === -1) ? array.push(item) : array[index] = item;
        }
        return array.map((o,key) => React.cloneElement(o.component, {...o.props, key}));
    }

    render() {
        const {expanded, mobile} = this.state;
        const {t, log} = this.props;
        const data = this.getContent();
        const shouldShow = !!data.filter(o => !!o).length;
        if (!shouldShow) return null;
        const service = checkIfUserIsService();
        return (
            <div>
                <i className={"pointer text-lowercase"} onClick={this.onShow}>{t("modals.feedingSowInfoModal.details")}</i>
                {
                    mobile &&
                    <ViewContainerModal name={t("modals.feedingSowInfoModal.details")} show={expanded}
                                        onHide={this.onHide}>
                        <Card>
                            {data}
                            {
                                !!service &&
                                <LogLinkedIDs log={log}/>
                            }
                        </Card>
                    </ViewContainerModal>
                }
                {
                    !mobile &&
                    ReactDOM.createPortal(
                        <Modal restoreFocus={false} onHide={this.onHide} show={expanded} size={"xl"}>
                            <ModalHeader
                                title={t("modals.feedingSowInfoModal.details")}
                                onCloseClick={this.onHide}/>
                            <ModalBody disableFocus>
                                {data}
                                {
                                    !!service &&
                                    <LogLinkedIDs log={log}/>
                                }
                            </ModalBody>
                            <ModalFooter
                                hasConfirmButton={false}
                                confirmText={t("close")}
                                onCloseClick={this.onHide}
                            />
                        </Modal>
                    ,document.getElementById("center-content") || document.createElement("div"))
                }
            </div>
        );
    }
}

export default withTranslation()(LogDetails);
