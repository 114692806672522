import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {cloneDeep, isNil} from "lodash";
import JsonToTable from "../../basics/json-to-table/JsonToTable";
import {getLogObjectByID} from "../../../api/logs/getLogObjectByID";
import NotFound from "../../NotFound";
import {compose} from "redux";
import {withTranslation} from "react-i18next";

class LogLinkedDetail extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isError: false,
            data: null
        }
    }

    componentDidMount() {
        const {ID, TableName} = this.props;
        getLogObjectByID({ObjID: ID, TableName}).then(this.handleData).catch(this.handleError);
    }

    handleError = (err) => {
        this.setState({
            isError: true,
            isLoading: false
        })
    }

    handleData = (res) => {
        this.setState({
            isError: false,
            isLoading: false,
            data: cloneDeep(res) || null
        })
    }

    render() {
        const {isLoading, isError, data} = this.state;
        const {onClear, t} = this.props;
        return (
            <div onClick={onClear}>
                {isLoading && <span className={"loading-animation"}>{t("logView.loading")}</span>}
                {isError && <span>{t("farms.tabs.generalTab.errorLoadingData")}</span>}
                {
                    !isNil(data) && <>
                        <div className={"table-responsive"}>
                            <JsonToTable json={data}/>
                        </div>
                    </>
                }
                {
                    isNil(data) && (!isLoading) &&
                    <NotFound/>
                }
            </div>
        );
    }
}

LogLinkedDetail.propTypes = {
    ID: PropTypes.string.isRequired,
    TableName: PropTypes.string.isRequired
};

export default compose(
    withTranslation()
)(LogLinkedDetail);
