import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import _ from "lodash";
import {submit} from "./FeedingConditionModalSubmit"
import {connect} from "react-redux";
import ReactGA from "react-ga";
import Card from "../../basics/card/Card";
import moment from "moment";
import TinyList from "../../basics/tiny-list/TinyList";
import {withTranslation} from "react-i18next";

export const ModalName = "feeding-condition-modal";
const correctionOptions = [-25, -10, 0, 10, 25];

export class FeedingConditionModal extends React.Component {

    constructor(props) {
        super(props);

        const {selectedNodes} = this.props;
        const initialCorrection = _.get(selectedNodes[0], "curve.settings.doseCorrection", 0);
        this.props.initialize({
            doseCorrection: initialCorrection
        });
        this.state = {
            showSlider: !correctionOptions.includes(initialCorrection)
        };
        ReactGA.modalview(ModalName);
    }


    handleClick = (idx) => {
        const {change} = this.props;
        this.setState({
            showSlider: idx > 900
        });
        if (idx < 900) {
            change("doseCorrection", correctionOptions[idx]);
        }
    };


    render() {
        const {show, handleHide, t, handleSubmit, doseCorrection, selectedNodes} = this.props;
        const {showSlider} = this.state;
        const list = selectedNodes.map(node => {
            const name = [node.name];
            if (node.animal) {
                name.push(node.animal.AnmNo1);
                if (node.animal.ConditionTime) {
                    name.push(moment(node.animal.ConditionTime).format("DD.MM.YY"))
                } else {
                    name.push(t("modals.feedingConditionModal.notChecked"))
                }
            } else {
                name.push(t("modals.feedingConditionModal.noAnimal"))
            }
            return name.join(" - ")
        });
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("reportCondition")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <div>
                            <TinyList list={list} header={t("modals.feedingConditionModal.lastConditions")}
                                      maxItems={10}/>
                            <hr/>
                            <Row>
                                {
                                    correctionOptions.map((correction, idx) => (
                                        <Col xs={6} sd={4} md={3} key={idx}>
                                            <Card onClick={() => this.handleClick(idx)}
                                                  selected={!showSlider && (correction === doseCorrection)}>
                                                {`${correction > 0 ? "+" : ""}${correction}%`}
                                            </Card>
                                        </Col>

                                    ))
                                }
                                <Col xs={6} sd={4} md={3}>
                                    <Card onClick={() => this.handleClick(9999)} selected={showSlider}>
                                        {t("modals.feedingConditionModal.other")}
                                    </Card>
                                </Col>
                            </Row>
                            {
                                showSlider &&
                                <Field
                                    name="doseCorrection"
                                    id="doseCorrection"
                                    type="number"
                                    required
                                    step={5}
                                    min={-25}
                                    max={25}
                                    component={ReduxLabeledSlider}
                                    valueFormatter={(value) => `${value}%`}
                                    label={t("doseCorrection")}
                                />
                            }
                        </div>

                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingConditionModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(FeedingConditionModal);
const selector = formValueSelector(ModalName);
FeedingConditionModal = connect(state => ({
    doseCorrection: selector(state, "doseCorrection")
}))(FeedingConditionModal);

FeedingConditionModal = connectModal({name: ModalName})(FeedingConditionModal);

export default withTranslation()(FeedingConditionModal);