import React from "react";
import {connectModal} from "redux-modal";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Col, Modal, Row} from "react-bootstrap";
import {Field, formValueSelector, reduxForm} from "redux-form";
import Button from "../../basics/button/Button";
import "./_cage-set-work-type-modal.scss"
import ReduxButtonPicker from "../../basics/redux-button-picker/ReduxButtonPicker";
import PropTypes from "prop-types"
import {connect} from "react-redux";
import ReduxSwitch from "../../basics/switch/ReduxSwitch";
import {submit} from "./CageSetWorkTypeModalSubmit"
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {convertWeightUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import _ from "lodash";
import {
    setExpeditionAnimalNumber,
    setExpeditionWeightRange,
    setExpeditionWorkType
} from "../../../IOT/device-functions/SeparationCageFunctions";
import ReactGA from "react-ga";
import {updateDeviceDynamoDB} from "../../../actions/devicesActions";
import animalsDB from "../../../database/animalsDB";
import {EventTypes} from "../../../constans/eventTypes";
import TableGrid from "../../basics/table-grid/TableGrid";
import moment from "moment";
import {withTranslation} from "react-i18next";

export const ModalName = 'cage-set-work-type-modal';

export class CageSetWorkTypeModal extends React.Component {

    constructor(props) {
        super(props);
        this.props.initialize({
            ...this.getDataOfCage(),
            startNewSeparation: true,
            startNewExpedition: true
        });
        ReactGA.modalview(ModalName);
    }

    getWorkTypeName(worktype) {
        switch (worktype) {
            case 0:
                return "manual";
            case 1:
            case 2:
            case 3:
            case 6:
                return "training";
            case 4:
                return "separation";
            case 5:
                return "expedition";
            default:
                return "manual";
        }
    }

    getDataOfCage() {
        let cage = _.get(this.props.cages, '[0]');
        let shadow = cage && this.props.shadows.get(cage.DevID);
        return {
            worktype: this.getWorkTypeName(+_.get(shadow, "configuration.workType")),
            expeditionMinWeight: +_.get(shadow, "expeditionData.minWeight", 120000),
            expeditionMaxWeight: +_.get(shadow, "expeditionData.maxWeight", 150000),
            expeditionAnimalAmount: +_.get(shadow, "expeditionData.animalsNumber", 40),
            expeditionWorkType: +_.get(shadow, "expeditionData.workType", 1),
            separationMinWeight: +_.get(shadow, "separationData.minWeight", 120000),
            separationMaxWeight: +_.get(shadow, "separationData.maxWeight", 150000),
            separationAnimalAmount: +_.get(shadow, "separationData.animalsNumber", 40),
            separationWorkType: +_.get(shadow, "separationData.workType", 1),
            training: 1
        }
    }

    getExpeditionWorkType(worktype) {
        switch (worktype) {
            case 1:
                return this.props.t("modals.cageWorkMode.sepMode");
            case 2:
                return this.props.t("left") + " " + this.props.t("modals.cageWorkMode.exitOpen");
            case 3:
                return this.props.t("right") + " " + this.props.t("modals.cageWorkMode.exitOpen");
            case 4:
                return this.props.t("modals.cageWorkMode.altOpen");
            default:
                return "";
        }
    }

    saveExpedition(expeditionAnimalAmount, expeditionMinWeight, expeditionMaxWeight, expeditionWorkType, startNewExpedition) {
        let cages = this.props.cages.map(cage => {
            let clone = cage.prepareBeanToSave();
            let lastExpedition = cage.getLastExpedition();
            if (lastExpedition) {
                if (expeditionAnimalAmount === null) expeditionAnimalAmount = lastExpedition.AnimalAmount;
                if (expeditionMinWeight === null) expeditionMinWeight = lastExpedition.MinWeight;
                if (expeditionMaxWeight === null) expeditionMaxWeight = lastExpedition.MaxWeight;
                if (expeditionWorkType === null) expeditionWorkType = lastExpedition.ExpeditionWorktype;
                clone.addExpedition(new Date().getTime(), expeditionAnimalAmount, +expeditionMinWeight, +expeditionMaxWeight, expeditionWorkType, startNewExpedition);
                return clone;
            } else {
                return null
            }
        });
        cages.filter(item => item);
        this.props.dispatch(updateDeviceDynamoDB(cages, this.props.farm, this.props.user.ClientID, this.props.user.LocalUserID));
    }

    saveMinAndMaxWeight = () => {
        setExpeditionWeightRange(this.props.cages, this.props.expeditionMinWeight, this.props.expeditionMaxWeight, () => {
            this.saveExpedition(null, +this.props.expeditionMinWeight, +this.props.expeditionMaxWeight, null, false);
        });
    };

    saveAnimalAmount = () => {
        setExpeditionAnimalNumber(this.props.cages, this.props.expeditionAnimalAmount, () => {
            this.saveExpedition(+this.props.expeditionAnimalAmount, null, null, null, false);
        });
    };

    saveExpeditionWorkType = () => {
        setExpeditionWorkType(this.props.cages, this.props.expeditionWorkType, () => {
            this.saveExpedition(null, null, null, this.props.expeditionWorkType, false);
        })
    };

    isGracePeriod = () => {
        const {farm, cages, medicines} = this.props;
        let cagesPlcmntID = cages.map(e => e.PlcmntID.map(r => r.PlcmntID));
        let anim = animalsDB.getAllAnimals(farm);
        let findAnimal = [];
        let groupedArray = [];
        let groupArray = [];
        let sortedGroupArray = [];
        let grafting;
        let medicine = "";
        let _grafting = false;
        let endGracePeriod = undefined;
        let listGracePeriod = [];
        let animalListGracePeriod = [];

        cagesPlcmntID.map(e => e.map(r =>
                findAnimal = anim.filter(p => p.PlcmntID === r)
            )
        );
        if (findAnimal[0]) {
            findAnimal.map(e => {
                    grafting = e.events.filter(e => e.EvCode === EventTypes.GRAFTING);
                    if (grafting[0]) {
                        grafting.map(e => {
                                let gracePeriod = 0;
                                if (e.EvData.Medicine && medicines[0]) {
                                    console.log(e.EvData.Medicine, medicines[0])
                                    gracePeriod = medicines[0].WData.GracePeriod * 86400000;
                                    medicine = e.EvData.Medicine;
                                }
                                if (e.EvData.EndTime + gracePeriod > Date.now()) {
                                    endGracePeriod = new Date(e.EvData.EndTime + gracePeriod);
                                    listGracePeriod.push({medicine: medicine, time: endGracePeriod});
                                    _grafting = true;
                                }
                            }
                        );
                        if (listGracePeriod[0]) {
                            let maxTime = Math.max(...listGracePeriod.map(l => l.time));
                            animalListGracePeriod = [...animalListGracePeriod, listGracePeriod.find(e => e.time.getTime() === maxTime)];
                        }
                        listGracePeriod = [];
                    }

                }
            );
        }
        if (animalListGracePeriod[0]) {
            let grouped = _.mapValues(_.groupBy(animalListGracePeriod, 'time'));
            let cnt = 0;
            for (let x in grouped) {
                if (x) {
                    groupedArray = [...groupedArray, Object.values(grouped)[cnt]];
                    cnt++;
                }
            }
            groupedArray.map(e => {
                groupArray = [...groupArray, {amount: e.length, medicine: e[0].medicine, time: e[0].time.getTime()}];
            });
            sortedGroupArray = groupArray.sort((a, b) => b.time - a.time);
            groupArray = [];
            sortedGroupArray.map(e => {
                groupArray = [...groupArray, {
                    amount: e.amount,
                    medicine: e.medicine,
                    time: moment(e.time).format("DD.MM.YYYY")
                }]
            });
        }
        if (_grafting) {
            if (groupArray[0]) {
                return groupArray;
            }
        }
    };

    render() {
        const {
            cages, show, handleHide, worktype, separationWithMiddle, startNewSeparation,
            expeditionMinWeight, t
        } = this.props;
        console.log(cages);
        const headers = [
            {
                name: t("modals.cageWorkMode.endGracePeriod"),
                field: "time"
            },
            {
                name: t("medicine"),
                field: "medicine"
            },
            {
                name: t("animalCount"),
                field: "amount"
            }

        ];
        let shadow = cages.length === 1 ? this.props.shadows.get(cages[0].DevID) : null;

        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={this.props.t("modals.cageWorkMode.title")}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <div className="work-types">
                        <Field
                            name="worktype"
                            component={ReduxButtonPicker}
                        >
                            <Button buttonStyle={"text"}
                                    value="manual">{this.props.t("modals.cageWorkMode.manual")}</Button>
                            <Button buttonStyle={"text"}
                                    value="training">{this.props.t("modals.cageWorkMode.training")}</Button>
                            <Button buttonStyle={"text"}
                                    value="separation">{this.props.t("modals.cageWorkMode.separation")}</Button>
                            <Button buttonStyle={"text"}
                                    value="expedition">{this.props.t("modals.cageWorkMode.expedition")}</Button>
                        </Field>
                        <div className="placeholder">
                            PLACEHOLDER
                        </div>
                        <div>
                            {
                                worktype === "training" &&
                                <>
                                    <h4>Typ treningu</h4>
                                    <Field
                                        name="training"
                                        component={ReduxButtonPicker}
                                    >
                                        <Button buttonStyle={"text"}
                                                value={1}>{this.props.t("modals.cageWorkMode.training")} 1</Button>
                                        <Button buttonStyle={"text"}
                                                value={2}>{this.props.t("modals.cageWorkMode.training")} 2</Button>
                                        <Button buttonStyle={"text"}
                                                value={3}>{this.props.t("modals.cageWorkMode.training")} 3</Button>
                                        <Button buttonStyle={"text"}
                                                value={6}>{this.props.t("modals.cageWorkMode.training")} 4</Button>
                                    </Field>
                                </>
                            }
                            {
                                worktype === "separation" &&
                                <>
                                    <Row className="justify-content-center">
                                        <Col lg={6}>
                                            <Field
                                                name="separationWithMiddle"
                                                id="separationWithMiddle"
                                                component={ReduxSwitch}
                                                label={this.props.t("modals.cageWorkMode.separationWithMiddleExit")}
                                            />
                                        </Col>
                                    </Row>
                                    {
                                        separationWithMiddle &&
                                        <>
                                            <Row className="justify-content-center">
                                                <Col lg={6}>
                                                    <Field
                                                        name="startNewSeparation"
                                                        id="startNewSeparation"
                                                        component={ReduxSwitch}
                                                        label={this.props.t("modals.cageWorkMode.startNewSeparation")}
                                                    />
                                                </Col>
                                            </Row>
                                            {
                                                startNewSeparation &&
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            <Field
                                                                name="separationMinWeight"
                                                                component={ReduxLabeledSlider}
                                                                min={1000}
                                                                max={180000}
                                                                step={1000}
                                                                valueFormatter={value => convertWeightUnitTo(+value, {
                                                                    unit: UnitTypes.MEDIUM,
                                                                    fixed: 0,
                                                                    showUnit: true
                                                                })}
                                                                label={this.props.t("modals.cageWorkMode.minWeight")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            <Field
                                                                name="separationMaxWeight"
                                                                component={ReduxLabeledSlider}
                                                                min={1000}
                                                                max={180000}
                                                                step={1000}
                                                                valueFormatter={value => convertWeightUnitTo(+value, {
                                                                    unit: UnitTypes.MEDIUM,
                                                                    fixed: 0,
                                                                    showUnit: true
                                                                })}
                                                                label={this.props.t("modals.cageWorkMode.maxWeight")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            <Field
                                                                name="separationAnimalAmount"
                                                                component={ReduxLabeledSlider}
                                                                min={0}
                                                                max={600}
                                                                valueFormatter={value => value === 0 ? this.props.t("noLimit") : value + " " + this.props.t("pcs")}
                                                                label={this.props.t("animalCount")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                            {
                                                !startNewSeparation && shadow && shadow.separationData &&
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            {this.props.t("modals.cageWorkMode.minWeight")}: <strong>{convertWeightUnitTo(shadow.separationData.minWeight, {
                                                            unit: UnitTypes.MEDIUM,
                                                            showUnit: true,
                                                            fixed: 0
                                                        })}</strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            {this.props.t("modals.cageWorkMode.maxWeight")}: <strong>{convertWeightUnitTo(shadow.separationData.maxWeight, {
                                                            unit: UnitTypes.MEDIUM,
                                                            showUnit: true,
                                                            fixed: 0
                                                        })}</strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col lg={6}>
                                                            {this.props.t("animalCount")}:
                                                            <strong>{shadow.separationData.animalsNumber === 0 ? this.props.t("noLimit") : shadow.separationData.animalsNumber} {shadow.separationData.animalsNumber !== 0 ? this.props.t("pcs") : ""}</strong>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                            {
                                worktype === "expedition" &&
                                <>
                                    {this.isGracePeriod() &&
                                    <div>
                                        <p style={{
                                            textAlign: 'center',
                                            fontWeight: "700"
                                        }}>{t("modals.cageWorkMode.findAnimals")}</p>
                                        <TableGrid data={this.isGracePeriod()} headers={headers}/>
                                        < br/>
                                    </div>
                                    }
                                    <Row className="justify-content-center">
                                        <Col lg={6}>
                                            <Field
                                                name="startNewExpedition"
                                                id="startNew"
                                                component={ReduxSwitch}
                                                label={this.props.t("modals.cageWorkMode.startNewExpedition")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={6}>
                                            <Field
                                                name="expeditionMinWeight"
                                                component={ReduxLabeledSlider}
                                                min={1000}
                                                max={180000}
                                                step={1000}
                                                valueFormatter={value => convertWeightUnitTo(+value, {
                                                    unit: UnitTypes.MEDIUM,
                                                    fixed: 0,
                                                    showUnit: true
                                                })}
                                                label={this.props.t("modals.cageWorkMode.minWeight")}
                                            />
                                        </Col>
                                        <Col lg={1} className="d-flex align-items-end">
                                            <Button buttonStyle={"round"} icon={<i className="fas fa-save"/>}
                                                    type={"button"} onClick={this.saveMinAndMaxWeight}/>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={6}>
                                            <Field
                                                name="expeditionMaxWeight"
                                                component={ReduxLabeledSlider}
                                                min={expeditionMinWeight}
                                                max={180000}
                                                step={1000}
                                                valueFormatter={value => convertWeightUnitTo(+value, {
                                                    unit: UnitTypes.MEDIUM,
                                                    fixed: 0,
                                                    showUnit: true
                                                })}
                                                label={this.props.t("modals.cageWorkMode.maxWeight")}
                                            />
                                        </Col>
                                        <Col lg={1} className="d-flex align-items-end">
                                            <Button buttonStyle={"round"} icon={<i className="fas fa-save"/>}
                                                    type={"button"} onClick={this.saveMinAndMaxWeight}/>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={6}>
                                            <Field
                                                name="expeditionAnimalAmount"
                                                component={ReduxLabeledSlider}
                                                min={0}
                                                max={600}
                                                valueFormatter={value => value === 0 ? this.props.t("noLimit") : value + " " + this.props.t("pcs")}
                                                label={this.props.t("animalCount")}
                                            />
                                        </Col>
                                        <Col lg={1} className="d-flex align-items-end">
                                            <Button buttonStyle={"round"} icon={<i className="fas fa-save"/>}
                                                    type={"button"} onClick={this.saveAnimalAmount}/>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={6}>
                                            <Field
                                                name="expeditionWorkType"
                                                component={ReduxLabeledSelect}
                                                label={this.props.t("modals.cageWorkMode.workMode")}
                                                options={[
                                                    {
                                                        name: this.props.t("modals.cageWorkMode.sepMode"),
                                                        value: 1
                                                    },
                                                    {
                                                        name: this.props.t("left") + " " + this.props.t("modals.cageWorkMode.exitOpen"),
                                                        value: 2
                                                    },
                                                    {
                                                        name: this.props.t("right") + " " + this.props.t("modals.cageWorkMode.exitOpen"),
                                                        value: 3
                                                    },
                                                    {
                                                        name: this.props.t("modals.cageWorkMode.altOpen"),
                                                        value: 4
                                                    }
                                                ]}
                                            />
                                        </Col>
                                        <Col lg={1} className="d-flex align-items-end">
                                            <Button buttonStyle={"round"} icon={<i className="fas fa-save"/>}
                                                    type={"button"} onClick={this.saveExpeditionWorkType}/>
                                        </Col>
                                    </Row>
                                </>
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={this.props.t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

CageSetWorkTypeModal.propTypes = {
    cages: PropTypes.array.isRequired
};

CageSetWorkTypeModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(CageSetWorkTypeModal);

const selector = formValueSelector(ModalName);
export const _CageSetWorkTypeModal = connect(state => ({
    shadows: state.shadows.shadows,
    user: state.user.user,
    medicines: state.dictionary.medicine,
    worktype: selector(state, "worktype"),
    separationWithMiddle: selector(state, "separationWithMiddle"),
    startNewSeparation: selector(state, "startNewSeparation"),
    startNewExpedition: selector(state, "startNewExpedition"),
    expeditionMinWeight: selector(state, "expeditionMinWeight"),
    expeditionMaxWeight: selector(state, "expeditionMaxWeight"),
    expeditionAnimalAmount: selector(state, "expeditionAnimalAmount"),
    expeditionWorkType: selector(state, "expeditionWorkType"),
    farm: state.location.farm
}))(CageSetWorkTypeModal);
CageSetWorkTypeModal = connectModal({name: ModalName})(_CageSetWorkTypeModal);
export default withTranslation()(CageSetWorkTypeModal);