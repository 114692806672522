import {groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {setClimateAlarms} from "../../../IOT/device-functions/ClimateFunctions";
import {DevType} from "../../../constans/devices";

export function submit(values, dispatch, props) {
    const {climates} = props;
    const devices = groupDevicesByGatewayID(climates);
    return Promise.all([...devices.values()].map(deviceList => {
        return new Promise((resolve, reject) => {
            setClimateAlarms(deviceList[DevType.CLIMATE_SK3], values,
                {onSuccess: resolve, onFailure: reject});
        })
    })).then(() => {
        props.handleHide();
    })
}
