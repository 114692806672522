import animalsDB from "../../../database/animalsDB";
import {isNil} from "lodash";
import {isEventDateInvalid} from "../../../utils/EventUtils";
import Animal from "../../../beans/Animal";
import buildingsDB from "../../../database/buildingsDB";
import {addZerosToRfid} from "../../../utils/AnimalsUtils";
import {editAnimal, editAnimalNotificationFailure, editAnimalNotificationSuccess} from "../../../api/animal/EditAnimal";
import devicesDB from "../../../database/devicesDB";
import moment from "moment";

export function submit(values, dispatch, props) {
    let newAnimal = new Animal(values).prepareBeanToSave();
    console.log(newAnimal);
    delete newAnimal.isDead;
    if (!values.isDead) {
        delete newAnimal.DtaDthTime;
    }
    return editAnimal([{
        animal: {
            ...newAnimal,
            DtaBrthTime: !!values.DtaBrthTime ? moment(newAnimal.DtaBrthTime).toDate().getTime() : null,
            RFID: addZerosToRfid(newAnimal.RFID)
        },
        devices: devicesDB.getDevicesInPlcmntID(newAnimal.PlcmntID, {showDevicesInChildren: false}).map(device => ({
            DevID: device.DevID,
            GwID: device.GatewayID
        }))
    }]).then(res => {
        props.handleHide();
        editAnimalNotificationSuccess(res);
    }).catch(e => {
        editAnimalNotificationFailure(e);
    })
}

export function validate(values, props) {
    const errors = {};
    const {t, animal} = props;
    if (!values.AnmNo1) {
        errors.AnmNo1 = t("required");
    } else {
        if (values.AnmNo1 !== animal.AnmNo1 && animalsDB.checkIfAnimalExistOnFarm(values.AnmNo1, values.FarmID, values.AnmID)) {
            errors.AnmNo1 = t("errors.exist");
        }
    }

    if (values.DtaBrthTime && isEventDateInvalid(values.DtaBrthTime, props.farm)) {
        errors.DtaBrthTime = t("required");
    }

    if (isNil(values.DtaDthTime)) {
        errors.DtaDthTime = t("required");
    } else if (isEventDateInvalid(values.DtaDthTime, props.farm)) {
        errors.DtaDthTime = t("required");
    }

    if (values.RFID) {
        const rfid = addZerosToRfid(values.RFID);
        if ((animalsDB.checkIfHaveRFID(rfid, values.FarmID) || !!buildingsDB.getLocationByRFID(rfid, values.FarmID)) && rfid !== animal.RFID) {
            errors.RFID = t("errors.duplicate");
        } else if (+values.AnmCnt > 1) {
            errors.RFID = t("errors.anmCntTooBigForRFID");
        }
    }

    if (isNil(values.AnimalKind)) {
        errors.AnimalKind = t("required");
    }

    if (!values.AnmCnt) {
        errors.AnmCnt = t("required");
    }

    return errors;
}
