import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import {submit, validate} from "./ChamberSettlementWeightModalSubmit"
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {getUnit} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";
import moment from "moment";
import {connect} from "react-redux";
import {getLocationID} from "../../../utils/BuildingUtils";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";

export const ModalName = "chamber-settlement-weight-modal";

export class ChamberSettlementWeightModal extends React.Component {

    state = {
        onlyWeight: false
    }

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);

        const {scales, settlements} = props;
        let onlyWeight = true;
        for (let scale of scales) {
            let locs = scale.getLocation();
            let locationID = getLocationID(locs[0] || {});
            let settlement = settlements.find(item => item.PlcmntID === locationID && !item.DtaEndTime);
            if (!settlement) onlyWeight = false;
        }

        this.state.onlyWeight = onlyWeight;

        this.props.initialize({
            date: moment().format(moment.HTML5_FMT.DATE),
            weight: 8,
            age: 28,
            plannedWeight: 30
        })
    }

    render() {
        const {show, submitting, handleHide, t, handleSubmit, weight} = this.props;
        const {onlyWeight} = this.state;
        const weightUnit = getUnit("weight", UnitTypes.MEDIUM);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("settlement")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        {
                            !onlyWeight &&
                            <Field
                                name={"date"}
                                component={ReduxLabeledInput}
                                type={"date"}
                                label={t("settlementDate")}
                            />
                        }
                        <Field
                            name={"weight"}
                            component={ReduxLabeledInput}
                            type={"number"}
                            unit={weightUnit}
                            label={t("modals.chamberSettlementWeightModal.weight")}
                        />
                        <Field
                            name={"age"}
                            component={ReduxLabeledInput}
                            type={"number"}
                            unit={t("days")}
                            label={t("modals.chamberSettlementWeightModal.age")}
                        />
                        <Field
                            name={"plannedWeight"}
                            component={ReduxLabeledSlider}
                            label={t("modals.chamberSettlementWeightModal.plannedWeight")}
                            min={weight ? +weight : 30}
                            max={200}
                            valueFormatter={value => value ? value + weightUnit : "-"}
                        />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        onCloseClick={handleHide}
                        submitting={submitting}
                        formName={ModalName}
                        confirmText={t("save")}
                    />
                </form>
            </Modal>
        );
    }

}

ChamberSettlementWeightModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(ChamberSettlementWeightModal);

const selector = formValueSelector(ModalName);

ChamberSettlementWeightModal = connect(state => ({
    settlements: state.settlement.settlement,
    weight: selector(state, "weight")
}))(ChamberSettlementWeightModal)

ChamberSettlementWeightModal = connectModal({name: ModalName})(ChamberSettlementWeightModal);

export default withTranslation()(ChamberSettlementWeightModal)