import React, {Component} from 'react';
import {get, has, isArray} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import ClimateCurveChart from "../../../charts/ClimateCurveChart";
import {timeFormatter} from "../utils";


class UpdateClimateClockMenuDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const curveDayFormatter = (value) => {
            return value - get(obj ,"stages.[1].days", 0) || null
        }
        return [
            {
                name: t("curveDay"),
                value: has(obj, "currentCurveDay") ? curveDayFormatter(get(obj, "currentCurveDay")) : null
            },
            {
                name: t("modals.climateCurve.recorderEraseHour"),
                value: has(obj, "recorderEraseTime") ? timeFormatter(get(obj, "recorderEraseTime")) : null
            }
        ];
    }

    convertStages = (_stages) => {
        const stages = isArray(_stages) ? _stages : [];
        return stages.map((stage, i) => ({
            Days: +stage.days,
            Ventilation: [+stage.minimumVentilation, +stage.maximumVentilation],
            ExpectedTemperature: +stage.requestedTemperature
        }))
    }

    render() {
        const {log: {ObjNew, ObjOld}, code, t} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const stagesA = this.convertStages(get(ObjNew, "stages"));
        const stagesB = this.convertStages(get(ObjOld, "stages"));
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <>
                <TemplateBeforeAfterTable code={code} data={merged}/>
                <h4>{t("before")}</h4>
                <ClimateCurveChart stages={stagesB}/>
                <h4>{t("after")}</h4>
                <ClimateCurveChart stages={stagesA}/>
            </>
        );
    }
}

export default withTranslation()(UpdateClimateClockMenuDetail);
