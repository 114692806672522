import React from "react";
import {Modal} from "react-bootstrap";
import {ModalHeader} from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connectModal} from "redux-modal";
import {Field, reduxForm} from "redux-form";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";
import {submit, validate} from "./SelectDefaultFarmModalSubmit";

export const ModalName = "select-default-farm-modal";

class SelectDefaultFarmModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: SelectDefaultFarmModal.getFarmsOptions(this.props)
        }
    }

    static getFarmsOptions = (props) => {
        return props.farms.map((farm) => ({
            name: farm.FarmName,
            value: farm.FarmID
        }))
    };

    render() {
        const {t, handleHide, show, submitting} = this.props;
        const {options} = this.state;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader title={t("modals.selectDefaultFarmModal.title")} onCloseClick={handleHide}/>
                <ModalBody>
                    <Field
                        name="defaultFarm"
                        component={ReduxLabeledSelect}
                        label={t("modals.selectDefaultFarmModal.selectFarm")}
                        options={options}
                        init={true}
                    />
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                    submitting={submitting}
                />
            </Modal>
        );
    }
}

export default compose(
    withTranslation(),
    connectModal({name: ModalName}),
    reduxForm({
        form: ModalName,
        onSubmit: submit,
        validate
    })
)(SelectDefaultFarmModal);
