import {isNil} from "lodash";
import {
    setClimateWorkTypeAutomatic,
    setClimateWorkTypeCurve,
    setClimateWorkTypeInactive,
    setClimateWorkTypeManual
} from "../../../IOT/device-functions/ClimateFunctions";
import {groupDevicesByGatewayID} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";

export function submit(values, dispatch, props) {
    const {workType} = values;
    const {climates} = props;
    const data = {};
    const devices = groupDevicesByGatewayID(climates);
    return Promise.all([...devices.values()].map(deviceList => {
        return new Promise((resolve, reject) => {
            switch (workType) {
                case "inactive":
                    setClimateWorkTypeInactive(deviceList[DevType.CLIMATE_SK3], null, {onSuccess: resolve, onFailure: reject})
                    break;
                case "manual":
                    const {manual} = values;
                    data.workType = 1;
                    data.manual = manual;
                    setClimateWorkTypeManual(deviceList[DevType.CLIMATE_SK3], data, {onSuccess: resolve, onFailure: reject})
                    break;
                case "automatic":
                    const {maximumVentilation, minimumVentilation, requestedTemperature} = values;
                    data.workType = 3;
                    data.maximumVentilation = maximumVentilation;
                    data.minimumVentilation = minimumVentilation;
                    data.requestedTemperature = requestedTemperature;
                    setClimateWorkTypeAutomatic(deviceList[DevType.CLIMATE_SK3], data, {onSuccess: resolve, onFailure: reject})
                    break;
                case "curve":
                    const {curve, currentCurveDay, minimumVentilationDeviation, requestedTemperatureDeviation} = values;
                    data.workType = 4;
                    data.currentCurveDay = currentCurveDay;
                    data.minimumVentilationDeviation = minimumVentilationDeviation;
                    data.requestedTemperatureDeviation = requestedTemperatureDeviation;
                    data.stages = curve.getBackendStages();
                    setClimateWorkTypeCurve(deviceList[DevType.CLIMATE_SK3], data, {onSuccess: resolve, onFailure: reject})
                    break;
                default:
                    console.error("wrong work type provided");
                    break
            }
        })
    })).then(() => {
        props.handleHide();
    })
}

export function validate(values, props) {
    const {t} = props;
    const errors = {};

    if (isNil(values.workType)) {
        errors.workType = t("required");
    }

    if (isNil(values.curve)) {
        errors.curve = t("required");
    }

    return errors;
}
