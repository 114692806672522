import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {submit} from "./ChamberSeparationModalSubmit"
import {connect} from "react-redux";
import ReactGA from "react-ga";
import {getLicenseByName} from "../../../selectors/roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import withRoles from "../../withRoles";
import {withTranslation} from "react-i18next";

export const ModalName = "chamber-separation-modal";

export class ChamberSeparationModal extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
    }


    render() {
        const {show, submitting, handleHide, t, handleSubmit, dispenserLicense} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("separation")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <p>{t("modals.chamberSeparationModal.separationText")}</p>
                        {
                            (dispenserLicense === LicPackageLevel.EXTENDED) &&
                            <Field
                                name="piCnt"
                                id="piCnt"
                                type="number"
                                step={1}
                                min={0}
                                max={1000}
                                component={ReduxLabeledSlider}
                                label={t("animalCount")}
                            />
                        }
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        onCloseClick={handleHide}
                        submitting={submitting}
                        formName={ModalName}
                        confirmText={t("yes")}
                    />
                </form>
            </Modal>
        );
    }

}

ChamberSeparationModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(ChamberSeparationModal);

ChamberSeparationModal = connect(state => ({
    dispenserLicense: getLicenseByName(state, {licenseName: LicPackageKeys.DISPENSER})
}))(ChamberSeparationModal);

ChamberSeparationModal = connectModal({name: ModalName})(ChamberSeparationModal);


ChamberSeparationModal = withRoles({
    clientPackage: {
        [LicPackageKeys.DISPENSER]: LicPackageLevel.BASIC
    }
})(ChamberSeparationModal);


export default withTranslation()(ChamberSeparationModal);