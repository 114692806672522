import {isFunction} from "lodash";
import {setEmptyOutput,} from "../../../IOT/device-functions/DispenserDriverFunctions";
import {setEmptyDispenser} from "../../../IOT/device-functions/DispenserNRFFunctions";

export function submit(values, dispatch, props) {
    const {receivers, handleHide, clearSelection} = props;
    for (let dispensers of receivers.values()) {
        dispensers.individualDTM.forEach(({DevID, outputs}) => {
            outputs.forEach((out) => {
                setEmptyOutput(DevID, {number: out.number})
            })
        });
        if (dispensers.individualNRF.devices.length > 0) {
            setEmptyDispenser(dispensers.individualNRF.devices)
        }
    }
    if (isFunction(handleHide)) {
        handleHide();
    }
    if (isFunction(clearSelection)) {
        clearSelection();
    }
}
