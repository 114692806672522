import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {flatten, get, has} from "lodash";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {getDictionaryDictionary} from "../../../../selectors/logSelector";
import {connect} from "react-redux";

function mapStateToProps(state, props) {
    return {
        dictionaries: getDictionaryDictionary(state)
    }
}

class UpdateGraftingProgramDetail extends Component {


    getData = (obj) => {
        const {t, dictionaries: {NAMES: {MEDICINE} = {}} = {}} = this.props;
        const animalKindFormatter = (value) => t(`animalKind.${value}`);
        const medicineFormatter = (value) => MEDICINE[value] || null;
        return flatten([
            {
                name: t("modals.medicineModal.type"),
                value: has(obj, "WData.AnimalKind") ? animalKindFormatter(get(obj, "WData.AnimalKind")) : null
            },
            {
                name: t("designation"),
                value: has(obj, "WData.Name") && get(obj, "WData.Name")
            },
            ...new Array(get(obj, "WData.MedicineList.length")).fill(0).map((o, i) => ([
                {
                    name: t("medicine"),
                    value: has(obj, `WData.MedicineList[${i}].Age`) ? get(obj, `WData.MedicineList[${i}].Age`) : null
                },
                {
                    name: t("medicine"),
                    value: has(obj, `WData.MedicineList[${i}].Medicine`) ? medicineFormatter(get(obj, `WData.MedicineList[${i}].Medicine`)) : null
                }
            ]))
        ]);
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

UpdateGraftingProgramDetail = connect(mapStateToProps)(UpdateGraftingProgramDetail);

export default withTranslation()(UpdateGraftingProgramDetail);
