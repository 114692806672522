import {createDictionaryDynamoDB, updateDictionaryDynamoDB} from "../../../actions/dictionaryActions";
import dictionariesDB from "../../../database/dictionariesDB"
import {myID} from "../../../libs/generateID";
import {DICTIONARY_TYPE} from "../../../constans/general";
import {checkNip} from "../../../utils/NormalizeFunctions";

function insertIntoDictionary(dictionary, values, props) {
    let id = myID();
    props.supportedLanguages.map(lang => {
        if (!dictionary.WData[lang.lang]) dictionary.WData[lang.lang] = [];
        switch (props.type) {
            case DICTIONARY_TYPE.fallReasons:
                dictionary.WData[lang.lang].push({ID: id, Value: values.newValue, Short: values.abbreviation.trim()});
                break;
            case DICTIONARY_TYPE.suppliers:
                dictionary.WData[lang.lang].push({
                    ID: id,
                    Value: values.newValue,
                    Phone: values.phone,
                    Address: values.address,
                    NIP: values.nip,
                    Website: values.website,
                    HerdNumber: values.herdNumber
                });
                break;
            default:
                dictionary.WData[lang.lang].push({ID: id, Value: values.newValue});
        }
        dictionary.WData[lang.lang].map(d => {
            delete d.isShowing;
        })
    })
}

export function submit(values, dispatch, props) {
    // let html = `Imię i nazwisko: ${props.userAttr.name}<br/>Klient: ${props.user.ClientID}<br/>Słownik: ${props.title}<br/>Wartość: ${values.newValue}`;
    // let title = `Propozycja nowego słownika: ${props.title}`;
    // dispatch(sendDictionaryRequest(html, title));
    let dictionaries = dictionariesDB.findDictionary(props.type);
    if (dictionaries.length > 0) {
        let dictionary = Object.assign({}, dictionaries[0]);
        insertIntoDictionary(dictionary, values, props);
        dictionary.DtaModTime = new Date().getTime();
        delete dictionary.$loki;
        delete dictionary.meta;
        dispatch(updateDictionaryDynamoDB(dictionary, props.user.ClientID, props.user.LocalUserID));
    } else {
        let tmp = {
            ClientID: props.user.ClientID,
            DtaModTime: new Date().getTime(),
            Type: props.type,
            WordID: myID(),
            WData: {}
        };
        insertIntoDictionary(tmp, values, props);
        dispatch(createDictionaryDynamoDB(tmp, props.user.ClientID, props.user.LocalUserID));
    }
    props.handleHide();
}

export const validate = (values, props) => {
    const errors = {};
    const {t} = props;
    if (!values.newValue) {
        errors.newValue = t("required");
    }
    if (!values.abbreviation) {
        errors.abbreviation = t("required");
    } else if (values.abbreviation.trim().length !== 1) {
        errors.abbreviation = t("errors.mustBe1Char");
    }
    if (!values.phone) {
        errors.phone = t("required");
    }
    if (!values.address) {
        errors.address = t("required");
    }
    if (!values.nip) {
        errors.nip = t("required");
    }
    if (!values.website) {
        errors.website = t("required");
    }
    if (!values.herdNumber) {
        errors.herdNumber = t("required");
    }
    if (values.nip && values.nip.length !== 13) {
        errors.nip = t("errors.nipLength");
    }
    if (values.nip && values.nip.length === 13 && !checkNip(values.nip)) {
        errors.nip = t("errors.nipError");
    }
    if (!values.unit) {
        errors.unit = t("required");
    }
    if (!values.amountFor100) {
        errors.amountFor100 = t("required");
    }
    return errors
};