import {setConfiguration} from "../../../IOT/device-functions/ScaleHubFunctions";

export function submit(values, dispatch, props) {
    const {weight} = values;
    const {siloses} = props;
    let data = {};
    for (let silo of siloses) {
        let id = silo.device.DevID;
        let d = data[id] || {silo: silo.device, minWeights: []};
        d.minWeights.push({
            number: silo.index,
            value: weight
        });
        data[id] = d;
    }
    for (let val of Object.values(data)) {
        setConfiguration(val.silo, {minWeights: val.minWeights});
    }
    props.handleHide();
}
