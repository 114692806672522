import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ViewContainerModal from "../../basics/view-containter-modal/ViewContainerModal";
import TaskViewModalBody from "./TaskViewModalBody";
import Card from "../../basics/card/Card";
import "./_task-view-drawer.scss"
import ButtonGroup from "../../basics/button/button-group/ButtonGroup";
import Button from "../../basics/button/Button";
import {TaskStatus} from "../../../constans/task";
import {getSelectedTask} from "../../../selectors/taskSelectors";
import {
    changeTaskStatus,
    changeTaskStatusNotificationFailure,
    changeTaskStatusNotificationSuccess
} from "../../../api/task/changeTaskStatus";
import {removeTask, removeTaskNotificationFailure, removeTaskNotificationSuccess} from "../../../api/task/removeTask";

function mapStateToProps(state) {
    return {
        task: getSelectedTask(state)
    };
}

class TaskViewModalDrawer extends Component {

    state = {
        changingStatus: false,
        removingTask: false
    }

    container = React.createRef();

    onEditClick = () => {
        const {onEditClick} = this.props;
        onEditClick();
        setTimeout(() => {
            this.container.current.onHide();
        }, 400);
    }

    onConfirmed = async () => {
        const {task} = this.props;
        try {
            this.setState({
                changingStatus: true
            })
            let res = await changeTaskStatus(task.IssueID);
            changeTaskStatusNotificationSuccess(res);
            this.setState({
                changingStatus: false
            })
        } catch (e) {
            changeTaskStatusNotificationFailure(e);
            this.setState({
                changingStatus: false
            })
        }
    }

    onDeleteClick = async () => {
        const {task, onHide} = this.props;
        try {
            this.setState({
                removingTask: true
            })
            let res = await removeTask(task.IssueID);
            removeTaskNotificationSuccess(res);
            onHide();
        } catch (e) {
            removeTaskNotificationFailure(e);
            this.setState({
                removingTask: false
            })
        }
    }

    render() {
        const {task, show, onHide, t} = this.props;
        const {removingTask, changingStatus} = this.state;
        return (
            <ViewContainerModal name={t("taskDescription")} show={show} className="task-view-drawer"
                                addPaddingForButtons onHide={onHide} ref={this.container}>
                <Card>
                    <TaskViewModalBody task={task}/>
                </Card>
                <ButtonGroup className="task-view-drawer-buttons">
                    <Button icon={<i className="fas fa-edit"/>} onClick={this.onEditClick}/>
                    <Button icon={<i className="fas fa-trash"/>} onClick={this.onDeleteClick} isLoading={removingTask}/>
                    <Button
                        icon={task && task.Status === TaskStatus.DONE ? <i className="far fa-check-square"/> :
                            <i className="far fa-square"/>} onClick={this.onConfirmed} isLoading={changingStatus}/>
                </ButtonGroup>
            </ViewContainerModal>
        );
    }
}

export default compose(
    withTranslation(),
    connect(mapStateToProps)
)(TaskViewModalDrawer);