import React from "react";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import {createAlias, createAliasFailure, createAliasSuccess} from "../../../api/user/alias";

export const ModalName = 'client-alias-modal';

class ClientAliasModal extends React.Component {

    componentDidMount() {
        const {initialize, ClientID, Alias} = this.props;
        initialize({alias: Alias || ClientID});
    }

    render() {
        const {show, handleHide, t, handleSubmit, submitting} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("newSettings.devices.form.alias")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <Field
                            name={"alias"}
                            id={"alias"}
                            label={t("newSettings.devices.form.alias")}
                            component={ReduxLabeledInput}
                            type={"text"}
                        />
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("save")}
                        submitting={submitting}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

const submit = (values, dispatch, props) => {
    const {user: {LocalUserID}, ClientID} = props;
    const {alias} = values;
    return createAlias({ClientID, LocalUserID, Alias: alias}).then(() => {
        props.handleHide();
        createAliasSuccess();
        window.location.reload();
    }).catch(() => {
        createAliasFailure();
    });
}

ClientAliasModal.propTypes = {
    ClientID: PropTypes.string.isRequired,
    Alias: PropTypes.string.isRequired
};

ClientAliasModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(ClientAliasModal);
ClientAliasModal = connect(state => ({
    user: state.user.user,
}))(ClientAliasModal);
ClientAliasModal = connectModal({name: ModalName})(ClientAliasModal);

export default withTranslation()(ClientAliasModal);
