import moment from "moment";
import {setSchedule, setWorkType} from './../../../IOT/device-functions/ChainFeedingFunctions';
import _ from "lodash";
import {checkIfTimeRangeOverlaps, getTimeAsTimestamp} from "../../../utils/DateTimeUtils";

const WorkTypesInOrder = ["inactive", "manual", "automatic", "schedule"];

export function submit(values, dispatch, props) {
    console.log(values, props);
    const {workType, sensor, schedule, maxWorkTime, excessWorkTime} = values;
    const {devices, handleHide} = props;
    const workData = {};
    workData.workType = WorkTypesInOrder.indexOf(workType);
    workData.excessWorkTime = excessWorkTime * 60 * 1000;
    workData.maxWorkTime = maxWorkTime * 60 * 1000;
    workData.sensorID = sensor;
    const scheduleData = [];
    if (workType === "schedule") {
        schedule.forEach(schedRow => {
            scheduleData.push({
                start: +moment(schedRow.start, "HH:mm"),
                stop: +moment(schedRow.stop, "HH:mm"),
                workTime: schedRow.time * 60 * 1000
            })
        })
        schedule.sort((o1, o2) => o1.start - o2.start);

    }
    devices.forEach(device => {
        setWorkType(device, workData);
        if (workType === "schedule") {
            setSchedule(device, {schedule: scheduleData});
        }
    })
    console.log(workData, scheduleData)
    handleHide();
}

export function validate(values, props) {
    const errors = {};
    const {t} = props;
    const {excessWorkTime, maxWorkTime, sensor, workType, schedule = []} = values;
    if (!_.isFinite(excessWorkTime)) {
        errors.excessWorkTime = t("required");
    }
    if (!_.isFinite(maxWorkTime)) {
        errors.maxWorkTime = t("required");
    }
    if (!_.isFinite(sensor)) {
        errors.sensor = t("required");
    }
    if (!WorkTypesInOrder.includes(workType)) {
        errors.workType = t("required");
    } else {
        if (workType === "schedule") {
            if (_.get(schedule, "length", 0) === 0) {
                errors.schedule = t("required");
            } else {
                const scheduleErrors = schedule.map((schedRow, index) => {
                    const schedError = {};
                    if (!schedRow.time || !_.isFinite(+schedRow.time) || +schedRow.time === 0) {
                        schedError.time = t("required");
                    }
                    if (!schedRow.start) {
                        schedError.start = t("required");
                    }
                    if (!schedRow.stop) {
                        schedError.stop = t("required");
                    }
                    if (schedRow.start && schedRow.stop) {
                        let overlaps = false;
                        schedule.filter((d, i) => d.start && d.stop && i !== index).forEach(d => {
                            if (checkIfTimeRangeOverlaps(getTimeAsTimestamp(schedRow.start), getTimeAsTimestamp(schedRow.stop), getTimeAsTimestamp(d.start), getTimeAsTimestamp(d.stop))) {
                                overlaps = true;
                            }
                        });
                        if (overlaps) {
                            schedError.start = t("errors.duplicate");
                            schedError.stop = t("errors.duplicate");
                        }
                        if (getTimeAsTimestamp(schedRow.start) > getTimeAsTimestamp(schedRow.stop)) {
                            schedError.start = t('errors.mustBeBelow') + t("modals.chainFeedingConfigureModal.stop");
                            schedError.stop = t("errors.mustBeAbove") + t("modals.chainFeedingConfigureModal.start");
                        }
                    }
                    return schedError
                });
                errors.schedule = scheduleErrors;
            }
        }
    }
    return errors;
}