import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import {convertPressureUnitTo, convertTemperatureUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {calculateVentilation} from "../../../../utils/DevicesUtils";
import {secondsFormatter, percentageFormatter} from "../../../../views/new-settings-view/settings/climate-sk-3/settings/utils";

class UpdateClimateConfigurationDetail extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const temperatureFormatter = value => convertTemperatureUnitTo(value, {
            fixed: 1,
            unit: UnitTypes.SMALL,
            showUnit: true,
            acceptNil: true
        })

        const pressureFormatter = value => convertPressureUnitTo(value, {
            fixed: 1,
            unit: UnitTypes.SMALL,
            showUnit: true,
            acceptNil: true
        })
        const ventilationFormatter = value => `${calculateVentilation(value).toFixed(1)}%`;

        return [
            {
                name: t("settings.desiredTemp"),
                value: has(obj, "requestedAirTemperature") ? temperatureFormatter(get(obj, "requestedAirTemperature")) : null
            },
            {
                name: t("settings.desiredTempDeviation"),
                value: has(obj, "requestedTemperatureDeviation") ? temperatureFormatter(get(obj, "requestedTemperatureDeviation")) : null
            },
            {
                name: t("settings.bandwithVenting"),
                value: has(obj, "bandWidth") ? temperatureFormatter(get(obj, "bandWidth")) : null
            },
            {
                name: t("settings.minVenting"),
                value: has(obj, "ventilationConfiguration.minimumVentilation") ? ventilationFormatter(get(obj, "ventilationConfiguration.minimumVentilation")) : null
            },
            {
                name: t("settings.maxVenting"),
                value: has(obj, "ventilationConfiguration.maximumVentilation") ? ventilationFormatter(get(obj, "ventilationConfiguration.maximumVentilation")) : null
            },
            {
                name: t("settings.stopVenting"),
                value: has(obj, "ventilationConfiguration.ventilationSuspension") ? secondsFormatter(1000*get(obj, "ventilationConfiguration.ventilationSuspension")) : null
            },
            {
                name: t("settings.minVentingDeviation"),
                value: has(obj, "ventilationConfiguration.minimumVentilationDeviation") ? ventilationFormatter(get(obj, "ventilationConfiguration.minimumVentilationDeviation")) : null
            },
            {
                name: t("settings.maximumVentilationDeviation"),
                value: has(obj, "ventilationConfiguration.maximumVentilationDeviation") ? ventilationFormatter(get(obj, "ventilationConfiguration.maximumVentilationDeviation")) : null
            },
            {
                name: t("$t(settings.floor) 1"),
                value: has(obj, "floors[0]") ? temperatureFormatter(get(obj, "floors[0]")) : null
            },
            {
                name: t("$t(settings.floor) 2"),
                value: has(obj, "floors[1]") ? temperatureFormatter(get(obj, "floors[1]")) : null
            },
            {
                name: t("$t(settings.floor) 3"),
                value: has(obj, "floors[2]") ? temperatureFormatter(get(obj, "floors[2]")) : null
            },
            {
                name: t("settings.holdFor20Percent"),
                value: has(obj, "maximumHoldFor20Percent") ? temperatureFormatter(get(obj, "maximumHoldFor20Percent")) : null
            },
            {
                name: t("settings.manualOperation"),
                value: has(obj, "manual") ? percentageFormatter(get(obj, "manual")) : null
            },
            {
                name: t("settings.minTempAlarmDeviation"),
                value: has(obj, "alarms.minimumTemperatureAlarmDeviation") ? temperatureFormatter(get(obj, "alarms.minimumTemperatureAlarmDeviation")) : null
            },
            {
                name: t("settings.maxTempAlarmDeviation"),
                value: has(obj, "alarms.maximumTemperatureAlarmDeviation") ? temperatureFormatter(get(obj, "alarms.maximumTemperatureAlarmDeviation")) : null
            },
            {
                name: t("settings.alarmDeviationFromTemp"),
                value: has(obj, "alarms.outsideAlarmDeviation") ? temperatureFormatter(get(obj, "alarms.outsideAlarmDeviation")) : null
            },
            {
                name: t("settings.absoluteAlarm"),
                value: has(obj, "alarms.absoluteAlarm") ? temperatureFormatter(get(obj, "alarms.absoluteAlarm")) : null
            },
            {
                name: t("settings.heaterVentilation"),
                value: has(obj, "additionalHeatingVentilation") ? percentageFormatter(get(obj, "additionalHeatingVentilation")) : null
            },
            {
                name: t("settings.heatingDeviation"),
                value: has(obj, "heatingDeviation") ? temperatureFormatter(get(obj, "heatingDeviation")) : null
            },
            {
                name: t("settings.heaterOpTime"),
                value: has(obj, "heatingWorkTime") ? secondsFormatter(1000*get(obj, "heatingWorkTime")) : null
            },
            {
                name: t("settings.heaterBreakTime"),
                value: has(obj, "heatingBreakTime") ? secondsFormatter(1000*get(obj, "heatingBreakTime")) : null
            },
            {
                name: t("$t(settings.switchOnTemp) ($t(chamber.chart.cooling))"),
                value: has(obj, "coolingConfiguration.coolingEnableTemperature") ? temperatureFormatter(get(obj, "coolingConfiguration.coolingEnableTemperature")) : null
            },
            {
                name: t("$t(settings.operatingTime) ($t(chamber.chart.cooling))"),
                value: has(obj, "coolingConfiguration.coolingWorkTime") ? secondsFormatter(1000*get(obj, "coolingConfiguration.coolingWorkTime")) : null
            },
            {
                name: t("$t(settings.breakTime) ($t(chamber.chart.cooling))"),
                value: has(obj, "coolingConfiguration.coolingBreakTime") ? secondsFormatter(1000*get(obj, "coolingConfiguration.coolingBreakTime")) : null
            },
            {
                name: t("$t(settings.maxHumidity) ($t(chamber.chart.cooling))"),
                value: has(obj, "coolingConfiguration.coolingMaximumHumidity") ? percentageFormatter(get(obj, "coolingConfiguration.coolingMaximumHumidity")) : null
            },
            {
                name: t("$t(settings.minPressure) ($t(chamber.chart.cooling))"),
                value: has(obj, "coolingConfiguration.coolingMinimumPressure") ? pressureFormatter(get(obj, "coolingConfiguration.coolingMinimumPressure")) : null
            },
            {
                name: t("$t(settings.maxPressuer) ($t(chamber.chart.cooling))"),
                value: has(obj, "coolingConfiguration.coolingMaximumPressure") ? pressureFormatter(get(obj, "coolingConfiguration.coolingMaximumPressure")) : null
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o,i)=>({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateClimateConfigurationDetail);
