import React from "react";
import EventData from "./EventData";
import PropTypes from "prop-types";
import {Field, formValueSelector} from "redux-form";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import * as usgStates from "validators-schema/Api/constants/usgStates";
import ReduxLabeledSelect from "../../basics/select/labeled-select/ReduxLabeledSelect";

export class USGData extends React.Component {

    render() {
        const {member, t, usgResult} = this.props;
        const options = [
            {
                name: t("events.usgEvent.negative"),
                value: usgStates.USG_STATE.NEGATIVE,
            },
            {
                name: t("events.usgEvent.positive"),
                value: usgStates.USG_STATE.POSITIVE,
            },
            {
                name: t("events.usgEvent.toRepeat"),
                value: usgStates.USG_STATE.REPEAT
            }
        ];
        const initialResult = options.find(o => o.value === usgResult);
        return (
            <EventData member={member}>
                <Field
                    label={t("testResult")}
                    name={member ? `${member}.EvData.Pregnant` : "EvData.Pregnant"}
                    component={ReduxLabeledSelect}
                    options={options}
                    init={initialResult}
                />
            </EventData>
        );
    }

}

const selector = formValueSelector("edit-sow-cycle-modal");

USGData = connect((state, props) => ({
    usgResult: selector(state, props.member ? `${props.member}.EvData.Pregnant` : "EvData.Pregnant")
}))(USGData);

USGData.propTypes = {
    member: PropTypes.string,
};

export default withTranslation()(USGData);
