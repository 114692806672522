import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import {submit} from "./ChamberTransferModalSubmit"
import ReactGA from "react-ga";
import {LicPackageKeys, LicPackageLevel} from "../../../constans/roles";
import withRoles from "../../withRoles";
import {withTranslation} from "react-i18next";

export const ModalName = "chamber-transfer-modal";

export class ChamberTransferModal extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, submitting, handleHide, t, handleSubmit, endInsertion} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t(endInsertion ? "modals.chamberTransferModal.titleEndInsertion" : "modals.chamberTransferModal.title")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <p>{t(endInsertion ? "modals.chamberTransferModal.endInsertionText" : "modals.chamberTransferModal.transferText")}</p>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        onCloseClick={handleHide}
                        submitting={submitting}
                        formName={ModalName}
                        confirmText={t("yes")}
                    />
                </form>
            </Modal>
        );
    }

}

ChamberTransferModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(ChamberTransferModal);

ChamberTransferModal = connectModal({name: ModalName})(ChamberTransferModal);

ChamberTransferModal = withRoles({
    clientPackage: {
        [LicPackageKeys.DISPENSER]: LicPackageLevel.BASIC
    }
})(ChamberTransferModal);

export default withTranslation()(ChamberTransferModal);