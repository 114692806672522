import {
    createEndInsertionNotificationFailure,
    createEndInsertionNotificationSuccess,
    createTransfer,
    createTransferNotificationFailure,
    createTransferNotificationSuccess
} from "../../../api/feeding/Settlement";

export function submit(values, dispatch, props) {
    const {chamberId, handleHide, onSuccess, endInsertion} = props;
    let chamberIDs = Array.isArray(chamberId) ? chamberId : [chamberId];
    let promises = [];
    for (let cid of chamberIDs) {
        promises.push(createTransfer({PlcmntID: cid}));
    }
    return Promise.all(promises).then(r => {
        handleHide();
        if (endInsertion) {
            createEndInsertionNotificationSuccess(r);
        } else {
            createTransferNotificationSuccess(r);
        }
        onSuccess && onSuccess();
    }).catch(e => {
        if (endInsertion) {
            createEndInsertionNotificationFailure(e);
        } else {
            createTransferNotificationFailure(e);
        }
    })
}