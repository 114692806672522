import React from "react";
import {change, Field, FieldArray, formValueSelector, initialize, reduxForm} from "redux-form";
import {connect} from "react-redux"
import {submit, validate} from "./StandingsPairDeviceSubmit"
import {Col, Modal, Row} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import ReduxSelect from "../../basics/select/ReduxSelect";
import ReduxTableGrid from "../../basics/table-grid/redux-table-grid/ReduxTableGrid";
import {formatDeviceForPairingSelect, getFlatDevices} from "../../../utils/DevicesUtils";
import {DevType} from "../../../constans/devices";
import DevicesDB from "../../../database/devicesDB";
import {cloneDeep, get, isFinite, isNil} from "lodash";
import {getLocationName} from "../../../utils/BuildingUtils";
import Button from "../../basics/button/Button";
import ReduxLabeledSelect, {SortKey} from "../../basics/select/labeled-select/ReduxLabeledSelect";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import ReduxCheckbox from "../../basics/checkbox/ReduxCheckbox";

export const ModalName = "settings-buildings-pair-standings";


class StandingsPairDeviceModal extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, chamber, devices} = this.props;
        let standings = cloneDeep(get(chamber, "Boxes", [])).map((standing) => {
            let devices = DevicesDB.getDevicesInPlcmnt(standing);
            console.log(devices);
            let dev = null;
            if (devices[0]) {
                let adr = devices[0].getAddressForLocation(standing.BID);
                let obj = {
                    DevID: devices[0].DevID,
                    DevAdr: devices[0].Address
                };
                if (adr !== null) {
                    obj.Adr = adr;
                }
                dev = obj;
            }
            return {
                BID: standing.BID,
                name: standing.BoxesName,
                device: dev,
                devicesLen: devices.length
            }

        });
        dispatch(initialize(ModalName, {
            standings,
            startingValues: standings
        }));
        let _devs = getFlatDevices(devices.filter((dev) => [DevType.DISPENSER, DevType.DISPENSER_NRF].includes(dev.DevType)));
        this.state = {
            dispenserOptions: _devs.map(dev => {
                let tmp = {DevID: dev.device.DevID};
                if (!isNil(dev.device.Address)) {
                    tmp.DevAdr = +dev.device.Address
                }
                if (!isNil(dev.index)) {
                    tmp.Adr = +dev.index;
                }
                return {
                    value: tmp,
                    name: formatDeviceForPairingSelect(tmp)
                }
            }),
            standingOptions: cloneDeep(chamber).Boxes.map((box, index) => {
                return {
                    name: box.BoxesName,
                    value: index
                }
            })
        };

        ReactGA.modalview(ModalName);
    }

    setDevices() {
        const {devices, startingDevice: {DevID, Adr}, standings, dispatch, startingStanding, fillEmpty} = this.props;
        const startingDevice = DevicesDB.getDeviceByID(DevID);
        if (!startingDevice) throw new Error("No device found for automatic pairing");
        const flatDevices = getFlatDevices(devices);
        const devicesAfter = [];
        flatDevices.forEach(({device, index}) => {
            if (startingDevice.DevType === device.DevType) {
                if (
                    (device.DevType === startingDevice.DevType) &&
                    (device.ParentID === startingDevice.ParentID) &&
                    (device.Interface === startingDevice.Interface)
                ) {
                    if (device.DevType === DevType.DISPENSER_NRF && device.Address >= startingDevice.Address) {
                        devicesAfter.push({
                            DevID: device.DevID,
                            SortKey: +device.Address || 0,
                            DevAdr: +device.Address
                        })
                    } else if (device.DevType === DevType.DISPENSER && device.Address >= startingDevice.Address && (device.DevID !== startingDevice.DevID || index >= Adr)) {
                        devicesAfter.push({
                            DevID: device.DevID,
                            SortKey: (+device.Address * 100) + index,
                            Adr: +index,
                            DevAdr: +device.Address
                        })
                    }
                }

            }
        })
        devicesAfter.sort((o1, o2) => {
            return get(o1, "SortKey", 0) - get(o2, "SortKey", 0);
        })
        const newStandings = cloneDeep(standings);
        const offset = isFinite(+startingStanding) ? +startingStanding : 0;
        let counter = 0;
        standings.forEach((o, index) => {
            if (index >= offset) {
                if (!fillEmpty || !get(o, "device.DevID")) {
                    if (devicesAfter[counter]) {
                        delete devicesAfter[counter].SortKey;
                        newStandings[index].device = devicesAfter[counter]
                        counter++;
                    }
                }
            }
        })
        dispatch(change(ModalName, "standings", newStandings));
    }

    render() {
        const {chamber, updating, show, handleHide, startingDevice, t, handleSubmit} = this.props;
        const {standingOptions, dispenserOptions} = this.state;
        let headers = [
            {
                name: t("modals.standingsPairDeviceModal.standingName"),
                field: "name",
                fieldToGet: "name",
                dontShowInput: true
            }, {
                name: t("modals.standingsPairDeviceModal.dispenser"),
                component: ReduxSelect,
                options: [{name: "Nieprzypisany", value: null}, ...dispenserOptions],
                field: "device",
                colWidth: 2
            },
        ];

        return (
            <Modal onHide={handleHide} show={show} backdrop={"static"} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={i18next.t("modals.standingsPairDeviceModal.pairingStandingsIn", {type: getLocationName(chamber)})}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <Row>
                            <Col md={6}>
                                <Field
                                    name="startingDevice"
                                    id="startingDevice"
                                    options={dispenserOptions}
                                    component={ReduxLabeledSelect}
                                    label={t("modals.standingsPairDeviceModal.startFromDevice")}
                                    sortKey={SortKey.ADDRESS}
                                />
                            </Col>
                            <Col md={6}>
                                <Field
                                    name="startingStanding"
                                    id="startingStanding"
                                    options={standingOptions}
                                    component={ReduxLabeledSelect}
                                    label={t("modals.standingsPairDeviceModal.startFromStanding")}
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    name="fillEmpty"
                                    id="fillEmpty"
                                    component={ReduxCheckbox}
                                    label={t("fillOnlyEmptyStandings")}
                                />
                            </Col>
                        </Row>


                        <Button type={"button"} onClick={() => this.setDevices()}
                                disabled={!startingDevice}>{t("modals.standingsPairDeviceModal.set")}</Button>
                        <FieldArray component={ReduxTableGrid} name="standings" headers={headers}/>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("add")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                        submitting={updating}
                    />
                </form>
            </Modal>
        );
    }

}

StandingsPairDeviceModal.propTypes = {
    chamber: PropTypes.string.isRequired
};

StandingsPairDeviceModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(StandingsPairDeviceModal);
const selector = formValueSelector(ModalName);
export const _StandingsPairDeviceModal = connect(state => ({
    farm: state.location.farm,
    devices: state.farmDevices.devices,
    updating: state.farms.updating,
    user: state.user.user,
    startingDevice: selector(state, "startingDevice"),
    startingStanding: selector(state, "startingStanding"),
    fillEmpty: selector(state, "fillEmpty"),
    standings: selector(state, "standings")
}))(StandingsPairDeviceModal);

StandingsPairDeviceModal = connectModal({name: ModalName})(_StandingsPairDeviceModal)
export default withTranslation()(StandingsPairDeviceModal)
