import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import {connectModal} from "redux-modal";
import ModalFooter from "../ModalFooter";
import {connect} from "react-redux";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form"
import {submit, validate} from "./ChainFeedingConfigureModalSubmit";
// import _ from "lodash";
import {withTranslation} from "react-i18next";
import Button from "../../basics/button/Button"
import ReduxButtonPicker from "../../basics/redux-button-picker/ReduxButtonPicker"
import ReduxTableGrid from "../../basics/table-grid/redux-table-grid/ReduxTableGrid";
import ReduxInput from './../../basics/input/ReduxInput';
import ReduxLabeledSliderToSelect from './../../redux-mobile-input-swapper/ReduxLabeledSliderToSelect';
import {makeGetShadowByDevice} from '../../../selectors/shadowSelectors';
import {get} from "lodash";
import moment from "moment";

export const ModalName = "chain-feeding-configure-modal";
const WorkTypes = ["inactive", "manual", "automatic", "schedule"];

function makeMapStateToProps() {
    const getShadowByDevice = makeGetShadowByDevice();
    return function mapStateToProps(state,props){
        return {
            farm: state.location.farm,
            ...getShadowByDevice(state, get(props, "devices[0]", {})),
            workType: selector(state, "workType"),
        }
    }
}

export class ChainFeedingConfigureModal extends React.Component {

    constructor(props) {
        super(props)
        const {shadow} = props;
        const initialValues = {};

        initialValues.schedule = shadow ? shadow.schedule.map(o => ({
            start: moment(o.start).format("HH:mm"),
            stop: moment(o.stop).format("HH:mm"),
            time: Math.max(1, o.workTime / 60000)
        })) : [
            {start: "10:15", stop: "12:45", time: "10"}
        ]
        initialValues.sensor = get(shadow, "workType.sensorID", 1);
        initialValues.workType = WorkTypes[get(shadow, "workType.workType", 0)];
        initialValues.excessWorkTime = get(shadow, "workType.excessWorkTime", 60000) / 60000;
        initialValues.maxWorkTime = get(shadow, "workType.maxWorkTime", 60000) / 60000;
        this.props.initialize(initialValues);

    }


    render() {
        const {submitting, show, handleHide, t, workType, devices} = this.props;
        const headers = [
            {
                name: t("modals.chainFeedingConfigureModal.start"),
                field: "start",
                type: "time",
                component: ReduxInput
            },
            {
                name: t("modals.chainFeedingConfigureModal.stop"),
                field: "stop",
                type: "time",
                component: ReduxInput
            },
            {
                name: t("modals.chainFeedingConfigureModal.time"),
                field: "time",
                type: "number",
                unit: t(`modals.chainFeedingConfigureModal.minute`),
                component: ReduxInput
            },
        ];
        const getSensorName = (index) =>
            get(devices, "length", 0) === 1 ? get(devices, `[0].Settings.Aliases[${index}]`) || t(`modals.chainFeedingConfigureModal.sensor`, {number: index + 1}) : t(`modals.chainFeedingConfigureModal.sensor`, {number: index + 1})
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("modals.chainFeedingConfigureModal.title")}
                    onCloseClick={handleHide}/>
                <ModalBody lassName={ModalName}>
                    <Field
                        name="workType"
                        component={ReduxButtonPicker}
                    >
                        {
                            WorkTypes.map((value, i) => (
                                <Button buttonStyle={"text"}
                                        value={value}>{t(`deviceRows.chainFeeding.chainFeedingRow.workTypes.${value}`)}</Button>
                            ))
                        }
                    </Field>
                    {
                        (workType === "schedule") &&
                        <>
                            <FieldArray shouldIndex addDefaultValue={{start: 0, stop: 0, time: 0}}
                                        component={ReduxTableGrid} name="schedule" headers={headers} addAddButton
                                        addRemoveButton/>
                        </>

                    }
                    {
                        (workType === "automatic") &&
                        <>
                            <label>{t("modals.chainFeedingConfigureModal.activeSensor")}</label>
                            <Field
                                name="sensor"
                                component={ReduxButtonPicker}
                            >
                                {
                                    new Array(4).fill(1).map((o, i) => (
                                        <Button buttonStyle={"text"}
                                                value={i}>{getSensorName(i)}</Button>
                                    ))
                                }
                            </Field>
                            <Field
                                id="excessWorkTime"
                                name="excessWorkTime"
                                label={t(`modals.chainFeedingConfigureModal.excessWorkTime`)}
                                component={ReduxLabeledSliderToSelect}
                                valueFormatter={value => `${value}${t(`modals.chainFeedingConfigureModal.minute`)}`}
                                min={1}
                                max={60}
                                step={1}
                            />
                            <Field
                                id="maxWorkTime"
                                name="maxWorkTime"
                                label={t(`modals.chainFeedingConfigureModal.maxWorkTime`)}
                                component={ReduxLabeledSliderToSelect}
                                valueFormatter={value => `${value}${t(`modals.chainFeedingConfigureModal.minute`)}`}
                                min={1}
                                max={60}
                                step={1}
                            />
                        </>

                    }
                </ModalBody>
                <ModalFooter submitting={submitting} onCloseClick={handleHide} hasConfirmButton={true}
                             confirmText={t("save")}
                             formName={ModalName}/>
            </Modal>
        );
    }

}

ChainFeedingConfigureModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(ChainFeedingConfigureModal);
const selector = formValueSelector(ModalName);

ChainFeedingConfigureModal = connect(makeMapStateToProps)(ChainFeedingConfigureModal);
ChainFeedingConfigureModal = connectModal({name: ModalName})(ChainFeedingConfigureModal);
export default withTranslation()(ChainFeedingConfigureModal);
