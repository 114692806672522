import React from "react";
import {Field, initialize, reduxForm} from "redux-form";
import {submit, validate} from "./FeedingSetTypeEventSubmit"
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import {SectorType} from "../../../constans/sectorTypes";
import ReduxLabeledInput from "../../basics/input/labeled-input/ReduxLabeledInput";
import moment from "moment";
import {DispenserNRFEventTypes} from "../../../constans/mqttMessages";
import ReduxItemPicker from "../../basics/item-picker/ReduxItemPicker";
import insemination from "../../../resources/images/events/insemination.svg";
import parturition from "../../../resources/images/events/parturition.svg";
import noevent from "../../../resources/images/various/ban.svg";
import ReactGA from "react-ga";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

export const ModalName = 'feeding-set-type-event-modal';

@connect()
class FeedingSetTypeEventModal extends React.Component {

    constructor(props) {
        super(props);
        const {dispatch, sectorType} = this.props;
        dispatch(initialize(ModalName, {
            startDate: moment().format(moment.HTML5_FMT.DATE),
            eventType: sectorType === SectorType.DELIVERY ? DispenserNRFEventTypes.PARTURITION : DispenserNRFEventTypes.INSEMINATION
        }));
        ReactGA.modalview(ModalName);
    }

    render() {
        const {show, handleHide, t, handleSubmit} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("modals.feedingSetTypeEventModal.reportEvent")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        <div>
                            <Field
                                name="startDate"
                                label={t("modals.feedingSetTypeEventModal.startDate")}
                                id="startDate"
                                type={"date"}
                                component={ReduxLabeledInput}
                            />
                            <Field
                                name="eventType"
                                id="eventType"
                                type="text"
                                showLabels
                                required
                                component={ReduxItemPicker}
                                options={[
                                    {
                                        value: DispenserNRFEventTypes.NO_EVENT,
                                        svgPath: noevent,
                                        label: t("modals.feedingSetTypeEventModal.noevent")
                                    },
                                    {
                                        value: DispenserNRFEventTypes.INSEMINATION,
                                        svgPath: insemination,
                                        label: t("insemination")
                                    },
                                    {
                                        value: DispenserNRFEventTypes.PARTURITION,
                                        svgPath: parturition,
                                        label: t("birth")
                                    }]}
                                label={t("modals.feedingSetTypeEventModal.eventType")}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={true}
                        confirmText={t("modals.feedingSetTypeEventModal.report")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingSetTypeEventModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired,
    receivers: PropTypes.array.isRequired,
    sectorType: PropTypes.oneOf([SectorType.DELIVERY, SectorType.MATING]).isRequired
};

FeedingSetTypeEventModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(FeedingSetTypeEventModal);
export default FeedingSetTypeEventModal = withTranslation()(connectModal({name: ModalName})(FeedingSetTypeEventModal));
export const _FeedingSetTypeEventModal_ = FeedingSetTypeEventModal;
