import React from "react";
import {reduxForm, submit as submitForm} from "redux-form";
import {connect} from 'react-redux'
import {submit} from "./FeedingReportInseminationModalSubmit"
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import PropTypes from "prop-types";
import TinyList from "../../basics/tiny-list/TinyList";
import {onReportInseminationData} from "../../../selectors/feedingSelector";
import {canSetEvent} from "../../../utils/FeedingUtils";
import {withTranslation} from "react-i18next";
import i18next from "i18next";

export const ModalName = 'feeding-report-insemination-modal';

class FeedingReportInseminationModal extends React.Component {

    constructor(props) {
        super(props);
        const {selectedNodes, type, reportData: {WST, NRF} = {}, feedingCurves} = this.props;
        const {nodesAlreadyReported, nodesMissingSettings} = canSetEvent(selectedNodes, type, WST, NRF, feedingCurves);
        const showModal = !!nodesAlreadyReported.length || !!nodesMissingSettings.length;
        if (!showModal) {
            this.props.dispatch(submitForm(ModalName));
        }
    }


    render() {
        const {show, handleHide, t, handleSubmit, selectedNodes, type, reportData: {WST, NRF} = {}, feedingCurves} = this.props;
        const {nodesEligible, nodesAlreadyReported, nodesMissingSettings} = canSetEvent(selectedNodes, type, WST, NRF, feedingCurves);
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <form onSubmit={handleSubmit}>
                    <ModalHeader
                        title={t("deviceRows.feeding.dispenserButtons.confirmSetInsemination")}
                        onCloseClick={handleHide}/>
                    <ModalBody className={ModalName}>
                        {
                            !!nodesEligible.length &&
                            <>
                                <div
                                    className={"mb-1"}>{t("deviceRows.feeding.dispenserButtons.confirmSetInseminationText")}</div>
                                <TinyList list={nodesEligible.map(o => o.name)} maxItems={10}/>
                            </>
                        }


                        {
                            !!nodesAlreadyReported.length && <div>
                                {i18next.t("deviceRows.feeding.dispenserButtons.confirmSetInseminationAlreadyReportedText", {type: nodesAlreadyReported.map(node => node.name).join(", ")})}
                            </div>
                        }
                        {
                            !!nodesMissingSettings.length && <div>
                                {i18next.t("deviceRows.feeding.dispenserButtons.confirmSetInseminationMissingConfigurationText", {type: nodesMissingSettings.map(node => node.name).join(", ")})}
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter
                        hasConfirmButton={!!nodesEligible.length}
                        confirmText={t("deviceRows.feeding.dispenserButtons.start")}
                        onCloseClick={handleHide}
                        formName={ModalName}
                    />
                </form>
            </Modal>
        );
    }

}

FeedingReportInseminationModal.propTypes = {
    selectedNodes: PropTypes.array.isRequired
};

FeedingReportInseminationModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(FeedingReportInseminationModal);
export const _FeedingReportInseminationModal = withTranslation()(connect(state => ({
    buildings: state.farms.buildings,
    feedingCurves: state.settings.feedingCurves,
    reportData: onReportInseminationData(state),
}))(FeedingReportInseminationModal));

export default connectModal({name: ModalName})(_FeedingReportInseminationModal)
