import {setSkipDoses as skipDosesDTM} from "../../../IOT/device-functions/DispenserDriverFunctions";
import  {setSkipDoses as skipDosesNRF} from "../../../IOT/device-functions/DispenserNRFFunctions";
import {DevType} from "../../../constans/devices";

export function submit(values, dispatch, props) {
    const {schedules=[]} = values;
    const {handleHide, clearSelection} = props;
    console.log(values, props, "DSJKAADSIOSDAJADSIOJDASIOASDJASDIOXD");
    for(let schedule of schedules){
        for(let dev of schedule.dispensers){
            if(dev.DevType === DevType.DISPENSER_NRF){
                skipDosesNRF(dev.DevID, {dosesToSkip: schedule.skipDoses.map(o=>+o)});
            } else if(dev.DevType === DevType.DISPENSER){
                let data = [];
                dev.indexes.forEach((index) => {
                    data.push({dosesToSkip: schedule.skipDoses.map(o=>+o), number: index + 1});
                });
                skipDosesDTM(dev.DevID, data)
            }
        }
    }
    handleHide();
    clearSelection();

}
