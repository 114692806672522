import Medicine from "../../../beans/Medicine";
import {createDictionaryDynamoDB, updateDictionaryDynamoDB} from "../../../actions/dictionaryActions";
import i18next from "i18next";

export async function submit(values, dispatch, props) {
    const {edit, medicine} = props;
    let user = props.user.user;

    if (!edit) {
        const {WData: {Color, Dose, GracePeriod, Name, Price, Type, Unit}} = values;
        let med = Medicine.createMedicine(Name, +Dose, Type, Unit, +GracePeriod, +Price, Color);
        dispatch(createDictionaryDynamoDB(med, user.ClientID, user.LocalUserID));
    } else {
        let clone = medicine.clone();
        clone.WData = values.WData;
        dispatch(updateDictionaryDynamoDB(clone.prepareBeanToSave(), user.ClientID, user.LocalUserID));
    }
    props.handleHide();
}

export function validate(values, props) {
    const errors = {
        WData: {}
    };
    const {t} = props;
    if (!values.WData) {
        errors.WData.Name = t("required");
        errors.WData.Dose = t("required");
        errors.WData.Type = t("required");
        errors.WData.Unit = t("required");
        errors.WData.GracePeriod = t("required");
        errors.WData.Price = t("required");
        errors.WData.Color = t("required");
    } else {
        if (!values.WData.Name) {
            errors.WData.Name = t("required");
        }
        if (!values.WData.Dose) {
            errors.WData.Dose = t("required");
        } else {
            if (values.WData.Dose <= 0) {
                errors.WData.Dose = i18next.t("errors.lessVal", {count: 1})
            }
        }
        if (!values.WData.Type) {
            errors.WData.Type = t("required");
        }
        if (!values.WData.Unit) {
            errors.WData.Unit = t("required");
        }
        if (!values.WData.GracePeriod) {
            errors.WData.GracePeriod = t("required");
        } else {
            if (values.WData.GracePeriod <= 0) {
                errors.WData.GracePeriod = i18next.t("errors.lessVal", {count: 1})
            }
        }
        if (!values.WData.Price) {
            errors.WData.Price = t("required");
        } else {
            if (values.WData.Price <= 0) {
                errors.WData.Price = i18next.t("errors.lessVal", {count: 1})
            }
        }
        if (!values.WData.Color) {
            errors.WData.Color = t("required");
        }
    }
    return errors;
}