import {createUser, createUserNotificationFailure, createUserNotificationSuccess} from "../../../api/user/create";
import {redirect} from "../../../actions/historyActions";

export function submit(values, dispatch, props) {
    const {defaultFarm} = values;
    const {userValues} = props;
    return createUser({...userValues, defaultFarm}).then((res) => {
        createUserNotificationSuccess(res);
        dispatch(redirect("/farmSettings/users"));
    }).catch((err) => {
        createUserNotificationFailure(err);
    });
}

export function validate(values, props) {
    const errors = {};
    const {defaultFarm} = values;
    const {t} = props;
    if (!defaultFarm) {
        errors.defaultFarm = t("required");
    }
    return errors;
}
