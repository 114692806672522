import React from "react";
import {Modal} from "react-bootstrap";
import {FieldArray, reduxForm} from "redux-form";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {submit, validate} from "./EditTreatmentModalSubmit";
import {connect} from "react-redux";
import ReactGA from "react-ga";
import DateChooser from "../../basics/date-chooser/DateChooser";
import "../../../views/event-chooser-view/events/treatment/_treatment.scss"
import ReduxTableGrid from "../../basics/table-grid/redux-table-grid/ReduxTableGrid";
import moment from "moment";
import Button from "../../basics/button/Button";
import InfoBox from "../../basics/info-box/InfoBox";
import {withTranslation} from "react-i18next";

export const ModalName = "edit-treatment-modal";

export class EditTreatmentModal extends React.Component {

    constructor(props) {
        super(props);

        let minDate = props.treatment.EvData.Treatment.reduce((a, event) => {
            let min = Math.min(...event.Dates);
            return min < a ? min : a;
        }, moment().toDate().getTime());

        this.state = {
            selectedDates: [],
            selectedMedicines: [],
            minDate: moment(minDate)
        }

        ReactGA.modalview(ModalName);
        this.props.initialize(props.treatment)
    }

    onChange = (array) => {
        this.setState({
            selectedDates: array
        })
    };

    isSelected(medicine) {
        let {selectedMedicines} = this.state;
        return !!selectedMedicines.find(item => item === medicine.WordID);
    }

    onMedicineClick(medicine) {
        let {selectedMedicines} = this.state;
        let selectedMedicinesCopy = selectedMedicines.slice(0);
        let hasMedicine = !!selectedMedicinesCopy.find(item => item === medicine.WordID);
        if (hasMedicine) {
            selectedMedicinesCopy = selectedMedicinesCopy.filter(item => item !== medicine.WordID);
        } else {
            selectedMedicinesCopy.push(medicine.WordID);
        }
        this.setState({
            selectedMedicines: selectedMedicinesCopy
        })
    }

    daysValueFormatter = value => {
        return value.map(date => moment(date).format("DD.MM.YYYY")).join(", ");
    };

    medicinesValueFormatter = value => {
        const {medicines} = this.props;
        let meds = value.map(medicineID => medicines.find(item => item.WordID === medicineID)).filter(item => item);
        return meds.map(med => med.WData.Name).join(", ");
    };

    onAddClick = () => {
        const {selectedDates, selectedMedicines} = this.state;
        if (selectedDates.length > 0 && selectedMedicines.length > 0) {
            this.props.array.push("EvData.Treatment", {
                Medicine: selectedMedicines,
                Dates: selectedDates.map(date => date.toDate().getTime())
            });
            this.setState({
                selectedDates: [],
                selectedMedicines: [],
                failedAdd: false
            })
        } else {
            this.setState({
                failedAdd: true
            })
        }
    };

    render() {
        const {show, handleHide, medicines, t, failedAdd, selectedMedicines} = this.props;
        const {selectedDates, minDate} = this.state;
        const headers = [
            {
                name: t("medicine"),
                field: "Medicine",
                dontShowInput: true,
                valueFormatter: this.medicinesValueFormatter
            },
            {
                name: t("days"),
                field: "Dates",
                dontShowInput: true,
                valueFormatter: this.daysValueFormatter
            }
        ]
        return (
            <Modal show={show} size={"lg"}>
                <ModalHeader title={t("modals.editTreatmentModal.title")} onCloseClick={handleHide}/>
                <ModalBody className="treatment">
                    {
                        medicines.map((medicine, index) =>
                            <div
                                className={this.isSelected(medicine) ? "medicine-item selected" : "medicine-item"}
                                key={index} onClick={() => this.onMedicineClick(medicine)}>
                                <div className="medicine-text">{medicine.WData.Name}</div>
                                <div style={{background: medicine.WData.Color || "#fff"}} className="color"/>
                            </div>
                        )
                    }
                    <DateChooser selectedDates={selectedDates} onChange={this.onChange} startingDate={minDate}/>
                    <Button buttonColor="primary" type="button"
                            onClick={this.onAddClick}>{t("add")}</Button>
                    {
                        failedAdd && (selectedMedicines.length === 0 || selectedDates.length === 0) &&
                        <InfoBox boxColor="error">{t("events.treatmentEvent.treatmentRequirement")}</InfoBox>
                    }
                    <FieldArray component={ReduxTableGrid} name="EvData.Treatment" headers={headers} addRemoveButton
                                minLength={0}/>
                </ModalBody>
                <ModalFooter hasConfirmButton confirmText={"Zapisz"} onCloseClick={handleHide}
                             formName={ModalName}/>
            </Modal>
        )
    }
}

EditTreatmentModal = reduxForm({
    form: ModalName,
    onSubmit: submit,
    validate
})(EditTreatmentModal);
EditTreatmentModal = connectModal({name: ModalName})(EditTreatmentModal);
EditTreatmentModal = connect((state) => ({
    medicines: state.dictionary.medicine,
    user: state.user.user,
}))(EditTreatmentModal);

export default withTranslation()(EditTreatmentModal);