import devicesDB from "../../../database/devicesDB";
import {updateDeviceDynamoDB} from "../../../actions/devicesActions";
import {isArray, isEqual, isNil, isString, uniqBy} from "lodash"

export function submit(values, dispatch, props) {
    console.log(values, props);
    const devicesToUpdate = [];
    const devicesList = devicesDB.getDevices(props.farm);
    const {startingValues, standings} = values;
    for (let [index, row] of standings.entries()) {
        if (!isEqual(row, startingValues[index]) || startingValues[index].devicesLen > 0) {
            //usuniecie starych
            const allDevs = uniqBy([...devicesList, ...devicesToUpdate], d => d.DevID);
            allDevs.forEach(d => {
                const deviceTmp = d.clone();
                const condition1 = isString(d.PlcmntID) && d.PlcmntID === row.BID;
                const condition2 = isArray(d.PlcmntID) && !!d.PlcmntID.filter(p => p.PlcmntID === row.BID).length;
                if (condition1 || condition2) {
                    deviceTmp.removeLocation(row.BID);
                    const idx = devicesToUpdate.findIndex(de => de.DevID === deviceTmp.DevID);
                    if (idx !== -1) {
                        devicesToUpdate[idx] = deviceTmp;
                    } else {
                        devicesToUpdate.push(deviceTmp);
                    }
                }
            });
            //dodanie nowych jesli jest urzadzenie
            if (row.device) {
                const updateIndex = devicesToUpdate.findIndex(d => d.DevID === row.device.DevID);
                let device;
                if (updateIndex !== -1) {
                    device = devicesToUpdate[updateIndex];
                } else {
                    device = devicesList.find(d => d.DevID === row.device.DevID);
                    if (device) {
                        device = device.clone();
                    }
                }
                if (device) {
                    device.addLocation(row.BID, isNil(row.device.Adr) ? null : row.device.Adr);
                    if (updateIndex !== -1) {
                        devicesToUpdate[updateIndex] = device;
                    } else {
                        devicesToUpdate.push(device)
                    }
                } else {
                    console.error("Device not found id: ", row.device.DevID);
                }
            }
        }
    }
    const devices = devicesToUpdate.map(d => d.prepareBeanToSave());
    if (devices.length > 0) {
        dispatch(updateDeviceDynamoDB(devices, props.farm, props.user.ClientID, props.user.LocalUserID))
    }

    props.handleHide();

}

export function validate(values, props) {
    const errors = {};
    const {standings = []} = values || {};
    const {t} = props;
    errors.standings = standings.map((o, index) => {
        const rowErrors = {};
        for (let i = 0; i < standings.length; i++) {
            if (!!o.device && (i !== index && isEqual(standings[i].device, o.device))) {
                rowErrors.device = t("errors.duplicate");
            }
        }
        return rowErrors;
    })
    return errors;
}
