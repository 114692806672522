import React, {Component} from "react";
import {Fade, Modal} from "react-bootstrap";
import PropTypes from "prop-types";

class ModalBody extends Component {
    constructor(props) {
        super(props);
        this.body = React.createRef();
    }

    componentDidMount() {
        const {disableFocus} = this.props;
        if (!disableFocus) {
            this.getFocus();
        }
    }

    getFocus = () => {
        this.timer = setTimeout(() => {
            if (this.body.current) {
                let findElement;
                let input = this.body.current.getElementsByTagName("input");
                for (let i = 0; i < input.length; i++) {
                    if (input[i].readOnly === false && input[i].type !== "search") {
                        findElement = input[i];
                        break;
                    }
                }
                if (findElement) {
                    findElement.focus();
                }
            }
        }, 200)
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const {className, isLoading, children} = this.props;
        return (
            <Modal.Body className={className} ref={this.body}>
                <Fade in={isLoading} timeout={500} unmountOnExit={true}>
                    <div className="loading">
                        <i className="fas fa-circle-notch fa-spin"/>
                    </div>
                </Fade>
                {children}
            </Modal.Body>
        );
    }
}

export default (ModalBody)

ModalBody.propTypes = {
    className: PropTypes.string, //doatkowa nazwa
    isLoading: PropTypes.bool,
    children: PropTypes.node.isRequired,
    disableFocus: PropTypes.bool
};

ModalBody.defaultProps = {
    isLoading: false
};
