import React from "react";
import {Modal} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {Field, isDirty, reduxForm} from "redux-form";
import {connectModal} from "redux-modal";
import {connect} from "react-redux";
import {submit} from "./ClimateBandwidthModalSubmit";
import ReactGA from "react-ga";
import {get} from "lodash"
import {withTranslation} from "react-i18next";
import {makeGetShadowByDevice} from "../../../selectors/shadowSelectors";
import ReduxLabeledSlider from "../../basics/slider/labeled-slider/ReduxLabeledSlider";
import {convertTemperatureUnitTo} from "../../../utils/UnitUtils";
import {UnitTypes} from "../../../constans/unitTypes";

export const ModalName = "climate-bandwidth-modal";


function makeMapStateToProps(initialState, initialProps) {
    const getShadowByDevice = makeGetShadowByDevice();
    const {climates} = initialProps;
    const params = {DevID: climates[0].DevID}
    return function mapStateToProps(state) {
        return {
            ...getShadowByDevice(state, params),
            dirty: isDirty(ModalName)(state)
        };
    }
}

export class ClimateBandwidthModal extends React.Component {

    constructor(props) {
        super(props);
        ReactGA.modalview(ModalName);
    }


    componentDidMount() {
        const {initialize} = this.props;
        initialize(this.getValues());
    }

    getValues = () => {
        const {shadow} = this.props;
        return {
            bandwidth: get(shadow, "configuration.bandwidth", 4)
        }
    }

    temperatureFormatter = (value) => {
        return convertTemperatureUnitTo(value, {
            unit: UnitTypes.SMALL,
            fixed: 1,
            showUnit: true
        })
    }

    ventilationFormatter = (value) => `${value.toFixed(1)}%`

    render() {
        const {show, handleHide, t, initialized} = this.props;
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={t("settings.bandwithVenting")}
                    onCloseClick={handleHide}/>
                <ModalBody className={`${ModalName} mh-5rem`} isLoading={!initialized}>
                    <Field
                        name={"bandwidth"}
                        id={"bandwidth"}
                        label={t("settings.bandwithVenting")}
                        min={0}
                        max={10}
                        step={0.2}
                        valueFormatter={this.temperatureFormatter}
                        component={ReduxLabeledSlider}
                    />
                </ModalBody>
                <ModalFooter
                    hasConfirmButton={true}
                    confirmText={t("save")}
                    onCloseClick={handleHide}
                    formName={ModalName}
                />
            </Modal>
        );
    }

}

ClimateBandwidthModal = reduxForm({
    form: ModalName,
    onSubmit: submit
})(ClimateBandwidthModal);

ClimateBandwidthModal = connect(makeMapStateToProps)(ClimateBandwidthModal);

ClimateBandwidthModal = connectModal({name: ModalName})(ClimateBandwidthModal);

export default withTranslation()(ClimateBandwidthModal);
