import React from "react";
import {Col, Modal, Row} from "react-bootstrap";
import ModalHeader from "../ModalHeader";
import ModalBody from "../ModalBody";
import ModalFooter from "../ModalFooter";
import {connectModal} from "redux-modal";
import {connect} from "react-redux";
import LabeledSelect from "../../basics/select/labeled-select/LabeledSelect";
import NewIOT from "../../../IOT/NewIOT"
import _ from "lodash"
import TableGrid from "../../basics/table-grid/TableGrid";

export const ModalName = "ascii-climate-current-values";

export class ASCIIClimateCurrentValues extends React.Component {

    state = {
        climate: this.props.asciiClimates.length > 0 ? this.props.asciiClimates[0] : null,
        shadow: null,
        data1: [],
        data2: []
    };

    constructor(props) {
        super(props);

        if (this.state.climate) {
            NewIOT.startSendingDeviceState(this.state.climate);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.state, nextState);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let shadow = nextProps.shadows.get(prevState.climate.DevID);
        if (shadow && !_.isEqual(shadow, prevState.shadow)) {
            let keys = Object.keys(shadow).filter(key => key !== "metadata");
            let half = Math.ceil(keys.length / 2);
            let firstKeys = keys.slice(0, half);
            let secondKeys = keys.slice(half, keys.length);
            let data1 = firstKeys.map(key => ({
                key,
                value: shadow[key]
            }));
            let data2 = secondKeys.map(key => ({
                key,
                value: shadow[key]
            }));
            return {
                shadow,
                data1,
                data2
            };
        }
        return null;
    }

    onChangeClimate = value => {
        this.setState({
            value
        });
        NewIOT.startSendingDeviceState(value);
    };

    render() {
        const {show, handleHide, asciiClimates} = this.props;
        const {climate, data1, data2} = this.state;
        const headers = [
            {
                name: "Klucz",
                field: "key"
            },
            {
                name: "Wartość",
                field: "value",
                headerClassName: "index",
                itemClassName: "index"
            }
        ];
        return (
            <Modal onHide={handleHide} show={show} size={"lg"}>
                <ModalHeader
                    title={"Aktualne wartości klimatu"}
                    onCloseClick={handleHide}/>
                <ModalBody className={ModalName}>
                    <LabeledSelect label={"Klimat"} options={asciiClimates.map(cl => ({
                        name: cl.Name,
                        value: cl
                    }))} value={climate} onChange={this.onChangeClimate} clearButton={false}/>
                    <Row>
                        <Col lg={6}>
                            <TableGrid data={data1} headers={headers}/>
                        </Col>
                        <Col lg={6}>
                            <TableGrid data={data2} headers={headers}/>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter
                    onCloseClick={handleHide}
                />
            </Modal>
        );
    }

}

ASCIIClimateCurrentValues = connect(state => ({
    asciiClimates: state.farmDevices.devices.filter(item => item.DevType === "CL_ASCII"),
    shadows: state.shadows.shadows
}))(ASCIIClimateCurrentValues);

ASCIIClimateCurrentValues = connectModal({name: ModalName})(ASCIIClimateCurrentValues);

export default ASCIIClimateCurrentValues;