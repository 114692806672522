import React, {Component} from 'react';
import {get, has} from "lodash";
import {withTranslation} from "react-i18next";
import TemplateBeforeAfterTable from "./TemplateBeforeAfterTable";
import {minutesFormatter, secondsFormatter} from "../../../../views/new-settings-view/settings/climate-sk-3/settings/utils";

class UpdateDispenserNRFMotorSetup extends Component {


    getData = (obj) => {
        const {t} = this.props;
        const milliSecondsFormatter = (value) => `${value}ms`;
        //todo: nie dziala widok wiec nie wygeneruje logu :(
        return [
            {
                name: t("newSettings.dispenserNRF.motorSetup.timeOn"),
                value: has(obj, "timeOn") ? milliSecondsFormatter(get(obj, "timeOn")) : null
            },
            {
                name: t("newSettings.dispenserNRF.motorSetup.timeBreak"),
                value: has(obj, "timeBreak") ? secondsFormatter(get(obj, "timeBreak")) : null
            },
            {
                name: t("newSettings.dispenserNRF.motorSetup.timeBetweenImpulses"),
                value: has(obj, "timeBetweenImpulses") ? milliSecondsFormatter(get(obj, "timeBetweenImpulses")) : null
            },
            {
                name: t("newSettings.dispenserNRF.motorSetup.currentOverloadTime"),
                value: has(obj, "currentOverloadTime") ? milliSecondsFormatter(get(obj, "currentOverloadTime")) : null
            },
            {
                name: t("newSettings.dispenserNRF.motorSetup.timeAbsent"),
                value: has(obj, "timeAbsent") ? minutesFormatter(get(obj, "timeAbsent")) : null
            }
        ];
    }

    render() {
        const {log: {ObjNew, ObjOld}, code} = this.props;
        const dataA = this.getData(ObjNew)
        const dataB = this.getData(ObjOld)
        const merged = dataA.map((o, i) => ({...o, valueBefore: dataB[i].value}))
        return (
            <TemplateBeforeAfterTable code={code} data={merged}/>
        );
    }
}

export default withTranslation()(UpdateDispenserNRFMotorSetup);
